import { useState } from 'react';
import * as DOMPurify from 'dompurify';
import truncate from "../../../libs/utils";
import { useNavigate } from 'react-router-dom';
import placeholderImg from '../../../assets/images/placeholder.png';

const ProductCard =({product,team})=>{    
    
    const navigate = useNavigate();

    function viewProduct(product) {
        navigate('/team/'+team._id+'/shop/'+product._id,{
          state:{
            product:product,
          }          
        })
    }

    
    return(
        <div className="productcard relative">
            <div className="image-wrapper relative rounded-[14px] overflow-hidden cursor-pointer" onClick={()=>viewProduct(product)}>
                {product.image ? 
                    <img src={product.image} alt={product.name} className="absolute object-cover w-full h-full" />
                    : 
                    <img src={placeholderImg} alt="" className="absolute object-cover w-full h-full" />
                }
            </div>
            <div className="product-overview">
                <div className='product-name cursor-pointer' onClick={()=>viewProduct(product)}>{product.name}</div>
                <div className='product-price'>{team.currency_symbol}{(product.display_price_with_transactionfee/100).toFixed(2)}</div>                                
            </div>
        </div>
    );
}

export default ProductCard;