import { useState, useEffect, useContext } from "react";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import { TeamContextProvider, TeamContext } from "../../../contexts/TeamContext";
import { useParams, NavLink } from "react-router-dom";
import Sidebar from "../../../components/Sidebar/Sidebar";
import AdminNavbar from "../../../components/AdminNavbar/AdminNavbar";
import placeholderImg from '../../../assets/images/placeholder.png';
import fantacyLogo from '../../../assets/images/Fantasy-PLAI_LOGO.png';
//import trophy from '../../../assets/images/trophy.png';
import lineImg from '../../../assets/images/line.svg';
import Spinner from "../../../components/Spinner/Spinner";
import ad from '../../../assets/images/PT_Ad_Placeholder.png';
import { getLeaderShipData } from "../../../libs/dataFunctions";
import LeadershipBoardRow from "./LeadershipBoardRow";

dayjs.extend(utc);
dayjs.extend(tz);

const LeaderShipBoardView = () =>{
    const { team, teamUpdating } = useContext(TeamContext);
    const [loading,setLoading] = useState(false);
    const [tabActive, setTabActive] = useState('teamoftheweek');
    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
    ? localStorage.getItem('auth_token')
    : '';

    const LeaderBoardHeader = () =>{

        return(
            <>
                <div className='breadcrumb flex item-center pt-1.5 pb-1 pl-5 pr-7 w-full'>
                    <NavLink to="/" className='home'>
                        <span>Dashboard</span>
                    </NavLink>
                    <span className='separator'>&lt;</span>
                    <NavLink to={`/team/${team._id}`}>
                        <span>{team.name}</span>
                    </NavLink>
                    <span className='separator'>&lt;</span>
                    Leaderboard
                </div>
                <div className="team-header px-5 py-[35px]">
                    <div className="flex flex-col sm:flex-row items-center justify-between">
                        <div className="text-white text-[34px] leading-[51px] font-medium">Leaderboard</div>
                        <div className="text-[#FBDB03] text-[24px] leading-[36px] font-semibold">{team.name}</div>
                        <div className="flex items-center justify-between">
                            <div className="image-wrapper rounded-full w-[65px] h-[65px] border-2 border-white border-solid overflow-hidden relative mb-2.5 sm:mb-0 sm:ml-[18px]">
                            {team.logo && team.logo !== "" ?                                
                                <img src={team.logo} alt={team.name} className="absolute object-cover w-full h-full" />
                                :
                                <img src={placeholderImg} alt={team.name} className="absolute object-cover w-full h-full" />
                            }
                            </div>
                            <div className="mr-[16px] ml-[30px]"><img src={lineImg} alt="" /></div>
                            <div><img src={fantacyLogo} alt="" /></div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    const TabsMenu = ( { tabActive, setTabActive }) => {
        let tabmenuItems = [
            {
                name: 'Players Leaderboard',
                slug: 'playerleaderboard',
                class: tabActive === 'playerleaderboard' ? 'active' : '',
                hide: false,
            },
            {
                name: 'Team of the week',
                slug: 'teamoftheweek',
                class: tabActive === 'teamoftheweek' ? 'active' : '',
                hide: false,
            },
            {
                name: 'Most selected players',
                slug: 'mostselectedplayers',
                class: tabActive === 'mostselectedplayers' ? 'active' : '',
                hide: false,
            },            
            {
                name: 'Trending',
                slug: 'trending',
                class: tabActive === 'trending' ? 'active' : '',
                hide: false,
            },
        ]
        return(
            <ul className="tabs-menu flex">
                {tabmenuItems.map((el,id) => {
                    return(
                        !el.hide &&
                        <li key={id} className={el.class} onClick={() => {
                            setTabActive(el.slug)
                        }}>{el.name}
                        </li>                            
                    )
                })}
            </ul>
        );
    }
    const TabPlayerLeaderBoard = () =>{
        const [leaderList, setLeaderList]=useState([]);
        const [lastUpdate, setLastUpdated] = useState(null);
        const [q, setSearchQuery] = useState("");    
        const [searchParam] = useState(["name"]);

        const search=(items)=>{
            return items.filter((item) => {
                return searchParam.some((newItem) => {
                    return (
                        item[newItem]
                            .toString()
                            .toLowerCase()
                            .indexOf(q.toLowerCase()) > -1
                    );
                });
            });
        }

        const refreshPlayerLeaderBoard= async ()=>{
            const [leaderBoardData] = await Promise.all([
                getLeaderShipData(token,'leaderboard',team._id)
            ]);
            if(leaderBoardData.status===1){
                setLeaderList(leaderBoardData.data);
                setLastUpdated(leaderBoardData.last_updated_date);
            }
        }
        useEffect(()=>{
            refreshPlayerLeaderBoard();
        },[]);
        return(
            <>
                <div className="flex p-7.5">
                    <div className="sm:mr-6 sm:w-3/4">
                        <div className="flex justify-between mb-5">
                            <div>Last Updated on: {lastUpdate!==null ? dayjs(lastUpdate).format("DD MMM YYYY") : "Not available"}</div>
                            <div className="max-w-[230px]">
                                <label htmlFor="search-form" className="search-field relative">
                                    <input
                                        type="search"
                                        name="search-field"
                                        id="search-form"
                                        className="search-input"
                                        placeholder="Search Player"
                                        value={q}
                                        onChange={(e) => {setSearchQuery(e.target.value)}}
                                    />                        
                                </label>
                            </div>
                        </div>
                        <div>
                            <div className="flex justify-between mb-2.5">
                                <div className="uppercase text-[16px] leading-[24px] font-medium text-[#363F6C]">Rank</div>
                                <div className="uppercase text-[16px] leading-[24px] font-medium text-[#363F6C]">Points</div>
                            </div>
                            <div className="leaderboard-list">
                                {search(leaderList).length > 0 ?
                                    <>
                                    {search(leaderList).map((leaderItem, index)=>{
                                        return(
                                            <LeadershipBoardRow leaderItem={leaderItem} index={index} key={leaderItem.sub} token={token} team={team} hasdetail={true} />
                                        )
                                    })}
                                    </>
                                :
                                    null
                                }
                            </div>
                        </div>
                    </div>
                    <div className="ads hidden md:block md:w-1/4 md:text-right">
                        <img className="sm:ml-auto" src={ad} alt="" width={370} height={690} />
                    </div>
                </div>
            </>   
        )
    }
    const TabTeamOfTheWeekBoard = () =>{
        const [leaderList, setLeaderList]=useState([]);
        const [lastUpdate, setLastUpdated] = useState(null);
        const [q, setSearchQuery] = useState("");    
        const [searchParam] = useState(["name"]);

        const search=(items)=>{
            return items.filter((item) => {
                return searchParam.some((newItem) => {
                    return (
                        item[newItem]
                            .toString()
                            .toLowerCase()
                            .indexOf(q.toLowerCase()) > -1
                    );
                });
            });
        }

        const refreshTeamOfWeekLeaderBoard= async ()=>{
            const [leaderBoardData] = await Promise.all([
                getLeaderShipData(token,'leaderboard_team_of_week',team._id)
            ]);
            if(leaderBoardData.status===1){
                setLeaderList(leaderBoardData.data);
                setLastUpdated(leaderBoardData.last_updated_date);
            }
            else{
                setLeaderList([]);
                setLastUpdated(null);
            }
        }
        useEffect(()=>{
            refreshTeamOfWeekLeaderBoard();
        },[]);
        return(
            <>
                <div className="flex p-7.5">
                    <div className="sm:mr-6 sm:w-3/4">
                        <div className="flex justify-between mb-5">
                            <div>Last Updated on: {lastUpdate!==null ? dayjs(lastUpdate).format("DD MMM YYYY") : "Not available"}</div>
                            <div className="max-w-[230px]">
                                <label htmlFor="search-form" className="search-field relative">
                                    <input
                                        type="search"
                                        name="search-field"
                                        id="search-form"
                                        className="search-input"
                                        placeholder="Search Player"
                                        value={q}
                                        onChange={(e) => {setSearchQuery(e.target.value)}}
                                    />                        
                                </label>
                            </div>
                        </div>
                        <div>
                            <div className="flex justify-between mb-2.5">
                                <div className="uppercase text-[16px] leading-[24px] font-medium text-[#363F6C]">Rank</div>
                                <div className="uppercase text-[16px] leading-[24px] font-medium text-[#363F6C]">Points</div>
                            </div>
                            <div className="leaderboard-list">
                                {search(leaderList).length > 0 ?
                                    <>
                                    {search(leaderList).map((leaderItem, index)=>{
                                        return(
                                            <LeadershipBoardRow leaderItem={leaderItem} index={index} key={leaderItem.sub} token={token} team={team} hasdetail={false} />
                                        )
                                    })}
                                    </>
                                :
                                    null
                                }
                            </div>
                        </div>
                    </div>
                    <div className="ads hidden md:block md:w-1/4 md:text-right">
                        <img className="sm:ml-auto" src={ad} alt="" width={370} height={690} />
                    </div>
                </div>
            </>   
        )
    }
    const TabMostSelectedBoard = () =>{
        const [leaderList, setLeaderList]=useState([]);
        const [lastUpdate, setLastUpdated] = useState(null);
        const [q, setSearchQuery] = useState("");    
        const [searchParam] = useState(["name"]);

        const search=(items)=>{
            return items.filter((item) => {
                return searchParam.some((newItem) => {
                    return (
                        item[newItem]
                            .toString()
                            .toLowerCase()
                            .indexOf(q.toLowerCase()) > -1
                    );
                });
            });
        }

        const refreshMostSelectedBoard= async ()=>{
            const [leaderBoardData] = await Promise.all([
                getLeaderShipData(token,'leaderboard_most_selected',team._id)
            ]);
            if(leaderBoardData.status===1){
                setLeaderList(leaderBoardData.data);
                setLastUpdated(leaderBoardData.last_updated_date);
            }
        }
        useEffect(()=>{
            refreshMostSelectedBoard();
        },[]);
        return(
            <>
                <div className="flex p-7.5">
                    <div className="sm:mr-6 sm:w-3/4">
                        <div className="flex justify-between mb-5">
                            <div>Last Updated on: {lastUpdate!==null ? dayjs(lastUpdate).format("DD MMM YYYY") : "Not available"}</div>
                            <div className="max-w-[230px]">
                                <label htmlFor="search-form" className="search-field relative">
                                    <input
                                        type="search"
                                        name="search-field"
                                        id="search-form"
                                        className="search-input"
                                        placeholder="Search Player"
                                        value={q}
                                        onChange={(e) => {setSearchQuery(e.target.value)}}
                                    />                        
                                </label>
                            </div>
                        </div>
                        <div>
                            <div className="flex justify-between mb-2.5">
                                <div className="uppercase text-[16px] leading-[24px] font-medium text-[#363F6C]">Rank</div>
                                <div className="uppercase text-[16px] leading-[24px] font-medium text-[#363F6C]">Points</div>
                            </div>
                            <div className="leaderboard-list">
                                {search(leaderList).length > 0 ?
                                    <>
                                    {search(leaderList).map((leaderItem, index)=>{
                                        return(
                                            <LeadershipBoardRow leaderItem={leaderItem} index={index} key={leaderItem.sub} token={token} team={team} hasdetail={false} />
                                        )
                                    })}
                                    </>
                                :
                                    null
                                }
                            </div>
                        </div>
                    </div>
                    <div className="ads hidden md:block md:w-1/4 md:text-right">
                        <img className="sm:ml-auto" src={ad} alt="" width={370} height={690} />
                    </div>
                </div>
            </>   
        )
    }
    const TabTrendingBoard = () =>{
        const [leaderList, setLeaderList]=useState([]);
        const [lastUpdate, setLastUpdated] = useState(null);
        const [q, setSearchQuery] = useState("");    
        const [searchParam] = useState(["name"]);

        const search=(items)=>{
            return items.filter((item) => {
                return searchParam.some((newItem) => {
                    return (
                        item[newItem]
                            .toString()
                            .toLowerCase()
                            .indexOf(q.toLowerCase()) > -1
                    );
                });
            });
        }

        const refreshTrendingBoard= async ()=>{
            const [leaderBoardData] = await Promise.all([
                getLeaderShipData(token,'leaderboard_most_trending',team._id)
            ]);
            if(leaderBoardData.status===1){
                setLeaderList(leaderBoardData.data);
                setLastUpdated(leaderBoardData.last_updated_date);
            }            
        }
        useEffect(()=>{
            refreshTrendingBoard();
        },[]);
        return(
            <>
                <div className="flex p-7.5">
                    <div className="sm:mr-6 sm:w-3/4">
                        <div className="flex justify-between mb-5">
                            <div>Last Updated on: {lastUpdate!==null ? dayjs(lastUpdate).format("DD MMM YYYY") : "Not available"}</div>
                            <div className="max-w-[230px]">
                                <label htmlFor="search-form" className="search-field relative">
                                    <input
                                        type="search"
                                        name="search-field"
                                        id="search-form"
                                        className="search-input"
                                        placeholder="Search Player"
                                        value={q}
                                        onChange={(e) => {setSearchQuery(e.target.value)}}
                                    />                        
                                </label>
                            </div>
                        </div>
                        <div>
                            <div className="flex justify-between mb-2.5">
                                <div className="uppercase text-[16px] leading-[24px] font-medium text-[#363F6C]">Rank</div>
                                <div className="uppercase text-[16px] leading-[24px] font-medium text-[#363F6C]">Points</div>
                            </div>
                            <div className="leaderboard-list">
                                {search(leaderList).length > 0 ?
                                    <>
                                    {search(leaderList).map((leaderItem, index)=>{
                                        return(
                                            <LeadershipBoardRow leaderItem={leaderItem} index={index} key={leaderItem.sub} token={token} team={team} hasdetail={false} />
                                        )
                                    })}
                                    </>
                                :
                                    null
                                }
                            </div>
                        </div>
                    </div>
                    <div className="ads hidden md:block md:w-1/4 md:text-right">
                        <img className="sm:ml-auto" src={ad} alt="" width={370} height={690} />
                    </div>
                </div>
            </>   
        )
    }
    return(
        !loading && !teamUpdating ?
        <>
            <LeaderBoardHeader />
            <div className="body px-5 lg:px-8 py-10">
                <div className="event-contents rounded-xl bg-white">
                    <TabsMenu
                        tabActive={tabActive}
                        setTabActive={setTabActive}                        
                    />
                    <div className="tabs-content">
                        {tabActive === 'playerleaderboard' && <TabPlayerLeaderBoard />}
                        {tabActive === 'teamoftheweek' && <TabTeamOfTheWeekBoard />}
                        {tabActive === 'mostselectedplayers' && <TabMostSelectedBoard />}
                        {tabActive === 'trending' && <TabTrendingBoard />}
                    </div>
                </div>                
            </div>
        </>
        :
        <>
            <div className="py-5 text-center"><Spinner /></div>
        </>
    )
}

const LeaderShipBoard = () =>{
    const {id}  = useParams();    
    return(        
        <>
            <Sidebar type="thin"/>
            <TeamContextProvider id={id}>                
                <div id="page" className="page-leadership">
                    <AdminNavbar heading="Leaderboard" />
                    <LeaderShipBoardView />
                </div>
            </TeamContextProvider>
        </>
    );
}

export default LeaderShipBoard;