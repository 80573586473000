import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import AdminNavbar from '../../components/AdminNavbar/AdminNavbar';
import Sidebar from '../../components/Sidebar/Sidebar';
import './Home.scss';
import TeamsSlider from '../../components/TeamsSlider/TeamsSlider';
import LatestNews from '../../components/LatestNews/LatestNews';
import Spinner from '../../components/Spinner/Spinner';
import { getTeams, getLatestNews, getAdminUpdateRequest } from '../../libs/dataFunctions';
import AdminUpdateRequest from './AdminUpdateRequest';

function Home() {
    const [teams,setTeams] = useState([]);
    const [latests,setLatests] = useState([]);
    const [isPendngRequest, setIsPendingRequest] = useState(false);
    const [loadingHome,setHomeLoading] = useState(false);
    const [adminRequestData,setAdminUpdateRequest] = useState([]);
    const [isAdminUpdateRequest,setIsAdminUpdateRequest] = useState(false);
    const [isECBConcent, setIsECBConcent] = useState(true);
    const [showECBConcent, setShowECBConcent] = useState(false);

    const token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
    ? localStorage.getItem('auth_token')
    : '';

    async function refreshHome(){
      setHomeLoading(true);
      const [teamsData, latestsData,adminUpdateData] = await Promise.all([
        getTeams(token),
        getLatestNews(token),
        getAdminUpdateRequest(token),
      ]);
      if(teamsData.status===1){
        setTeams(teamsData.data);
        setIsPendingRequest(teamsData.pending_request_count > 0 ? true : false);
      }
      if(adminUpdateData.status===0){
        setIsAdminUpdateRequest(false);
      }
      else{
        setAdminUpdateRequest(adminUpdateData.data);
        setIsAdminUpdateRequest(true);
      }
      setLatests(latestsData.data);
      setIsECBConcent(latestsData.ecb_consent_given);
      if(!latestsData.ecb_consent_given){
        setShowECBConcent(true);
      }
      else{
        setShowECBConcent(false);
      }
      setHomeLoading(false);
    }

    async function refreshLatest(){
      const [latestsData] = await Promise.all([          
        getLatestNews(token),
      ]);
      setLatests(latestsData.data);        
    }

    const ECBConcentPopup=()=>{
      const [ecbStatus, setECbStatus] = useState('Yes');
      const [ecbloading, setEcbLoading] = useState(false);

      const handleSubmitECB=async()=>{
        setEcbLoading(true);
        var formdata=new FormData();        
        formdata.append("type", "submit_ecb_consent");        
        formdata.append("ecb_consent",ecbStatus==='Yes' ? "true" : "false");
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };
        await fetch(process.env.REACT_APP_APIURL + '/app_user' , requestOptions)
        .then(response => response.text())
        .then(result => {
            var res=JSON.parse(result);
            setEcbLoading(false);
            setShowECBConcent(false);
        })
        .catch(error => console.log('error', error));
      }
      return(
        <div className="popup-form">
            <div className="popup-form-wrap popup-form-memberdetails !max-w-[670px] relative !px-2.5 sm:!px-5">                    
              <div className='relative'>
                {!ecbloading ?
                <>
                  <h2 className="text-[22px] leading-[33px] text-center font-semibold mb-5">Your club is integrated with Play-Cricket on PLAI...</h2>
                  <p className='mb-5'>Choose YES to get information from the England and Wales Cricket Board (ECB) such as tickets, offers, news, competitions, prize draws and other ways to get involved in cricket.</p>
                  <div className='flex justify-center gap-2.5 mb-5'>
                      <button type='button' className={`button ${ecbStatus==='Yes' && 'active'}`} onClick={()=>setECbStatus('Yes')}>Yes</button>
                      <button type='button' className={`button ${ecbStatus==='No' && 'active'}`} onClick={()=>setECbStatus('No')}>No</button>
                  </div>
                  <p className='mb-5'>If you choose ‘YES’, PLAI will provide your registration information to the ECB. The ECB’s privacy policy which explains how your personal data will be used is available at <a href="http://www.ecb.co.uk/privacy" target="_blank" rel="noreferrer" className='underline'>www.ecb.co.uk/privacy</a></p>
                  <div className='flex justify-center'>
                      <button type='button' className={`button`} onClick={()=>handleSubmitECB()}>Submit</button>                  
                  </div>
                </>
                :
                <>
                  <div className="text-center py-5"><Spinner /></div>
                </>
                }
              </div>              
            </div>
        </div>
      )
    }
    useEffect(() => {
      refreshHome();      
    }, []);
    
    return (      
      <>
        <Sidebar />
        <div id="page" className="page-home">
          <AdminNavbar />
          <div className='breadcrumb flex item-center pt-1.5 pb-1 pl-5 pr-7 w-full'>
              <NavLink to="/" className='home'>
                  <span>Dashboard</span>
              </NavLink>
          </div>
          <div className='body'>
          {!loadingHome ?
            <>
              <TeamsSlider teams={teams} isPendngRequest={isPendngRequest} />
              <LatestNews latests={latests} refreshLatest={refreshLatest} />
            </>
            :
            <>
              <div className="py-5 text-center"><Spinner /></div>
            </>
          }
          </div>
        </div>
        {isAdminUpdateRequest &&
          <AdminUpdateRequest setShowForm={setIsPendingRequest} requestData={adminRequestData} />
        }
        {(!isECBConcent && showECBConcent) &&
          <ECBConcentPopup />
        }
      </>      
    );
  }
  
export default Home;