import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getMyPaymentCards, getSMScharges } from "../../../libs/dataFunctions";
import { formatCreditCardNumber, formatExpirationDate, formatCVC, getIsUser } from '../../../libs/utils';
import { generateRandomName } from "../../../components/ImageCropper/cropUtils";
import CountriesList from '../../../libs/countries';
import Amex from '../../../assets/images/amex.svg';
import Discover from '../../../assets/images/discover.svg';
import DinnersClub from '../../../assets/images/dinersclub.svg';
import Jcb from '../../../assets/images/jcb.svg';
import Mastercard from '../../../assets/images/mastercard.svg';
import UnionPay from '../../../assets/images/unionpay.svg';
import Visa from '../../../assets/images/visa.svg';

const CardIcon=({value})=>{
    const icons = {
        'amex':Amex,
        'mastercard':Mastercard,
        'visa':Visa,
        'discover':Discover,
        'jcb':Jcb,
        'dinersclub': DinnersClub,
        'uninpay': UnionPay,
        'elo':null,
        'hipercard':null,
        'troy':null,
        'maestro':null,
        'laser':null
    }
    if (!value) {
        return null;
    }
    
    return(
        icons[value]!==null ? <img src={icons[value]} alt="" width={24} /> : null
    )
}
const SMSChargePreviewForm = ({setShowForm, team, fomrSData, selectedGroups}) => {
    const [SMSLoader,setSMSLoader] = useState(false);
    const [loading,setLoading] = useState(false);
    const [smsChargMessage,setSmsChargeMessage] = useState('');
    const [smsChargAmount,setSmsChargeAmount] = useState(0);
    const [smspopLoaded, setSmsPopLoaded] = useState(false);
    const [currentCardID,setCurrentCardID]  = useState('');
    const [paymentCards,setPaymentCards] = useState([]);
    const [cardNum, setCardNum] = useState('');
    const [cardExpiry, setCardExpiry] = useState('');
    const [cardCVV, setCardCVV] = useState('');
    const [country, setCountry] = useState('');
    const [cardicon, setCardIcon] = useState(null);
    const [cardHolderName, setCardName] = useState('');
    const [error, setError] = useState('');

    const navigate= useNavigate();
    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
    ? localStorage.getItem('auth_token')
    : '';

    const refreshCardsData = async()=>{
        setSMSLoader(true);
        const [mycardsData, smsChargeData] = await Promise.all([
            getMyPaymentCards(token),
            getSMScharges(token, selectedGroups, team._id)
        ]);

        setSmsChargeMessage(smsChargeData.message.replace(/\n/g, "<br />"));
        setSmsChargeAmount(smsChargeData.data.sms_charges_amount);
        if(mycardsData.data && mycardsData.data.length > 0){
            setPaymentCards(mycardsData.data);
            mycardsData.data.forEach(element => {
                if(element.default_source){
                    setCurrentCardID(element.id);
                }
            });
        }
        setSMSLoader(false);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError("");
        let previewData = fomrSData;
        if(currentCardID !== ''){
            previewData['card_id'] = currentCardID;
            previewData['sms_charges_amount']=smsChargAmount;
            if(previewData['is_draft']!==undefined && previewData['is_draft']==='Yes'){
                saveNewsDraft(previewData);
            }
            else{
                navigate('/team/'+team._id+'/news/preview',{
                    state:{
                        preViewData:previewData,
                        team:team,
                        preview:true
                    }          
                });
            }
        }
        else{
            if(cardNum!=="" && cardExpiry!=="" && cardCVV!=="" && country!==""){
                setLoading(true);
                setError('');
                let formdata = new FormData();
                let expiry = cardExpiry.split("/");
                let today, cardday;
                today = new Date();
                cardday = new Date();
                cardday.setFullYear(parseInt(expiry[1])+2000,expiry[0]-1,0);
                if(cardicon==='amex'){
                    if(cardNum.replace(/\D+/g, '').length<15){
                        setError("Invalid card number");
                        if(cardCVV.length<4){
                            setError("Invalid card number, cvc number");
                        }
                        return;
                    }
                    if(cardCVV.length<4){
                        setError("Invalid CVC number");
                        return;
                    }
                }
                else if(cardicon==='dinersclub'){
                    if(cardNum.replace(/\D+/g, '').length<14){
                        setError("Invalid card number");
                        return;
                    }
                }
                else{
                    if(cardNum.replace(/\D+/g, '').length<16){
                        setError("Invalid card number");
                        return;
                    }
                }
                if (cardday < today) {
                    setError("Invalid expiry date");
                }
                else{
                    formdata.append('type','add_card');                    
                    formdata.append('card_number',cardNum);
                    formdata.append('name',cardHolderName);
                    formdata.append('exp_year',expiry[1]);
                    formdata.append('exp_month',expiry[0]);
                    formdata.append('country',country);
                    formdata.append('cvc',cardCVV);
                    formdata.append('is_default_card','Yes');

                    var secureHeader2 = new Headers();
                    secureHeader2.append('Authorization','Bearer ' + token);
                    secureHeader2.append('device_type','W');
                    const requestOptions = {
                        method: 'POST',
                        redirect: 'follow',
                        body: formdata,
                        headers: secureHeader2
                    }
                    
                    await fetch(process.env.REACT_APP_APIURL + '/app_stripe_card' , requestOptions)
                    .then(response => response.text())
                    .then(result => {
                        let rdata = JSON.parse(result);
                        if(rdata.status !== 1){
                            setLoading(false);
                            setError(rdata.message);
                        }
                        else{
                            previewData['card_id'] = rdata.data[0].id;
                            previewData['sms_charges_amount']=smsChargAmount;
                            if(previewData['is_draft']!==undefined && previewData['is_draft']==='Yes'){
                                saveNewsDraft(previewData);
                            }
                            else{
                                navigate('/team/'+team._id+'/news/preview',{
                                    state:{
                                        preViewData:previewData,
                                        team:team,
                                        preview:true
                                    }          
                                });
                            }
                        }
                    })
                    .catch(error => console.log('error', error));
                }            
            }
            else{
                setError("Please fill all fields above.");
            }
        }
    }

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            const base64String = reader.result
                    .replace('data:', '')
                    .replace(/^.+,/, '');
            resolve(base64String);
        }
        reader.onerror = error => reject(error);
    });

    const saveNewsDraft=async(previewData)=>{
        setLoading(true);
        var formdata = new FormData();
        formdata.append("type", "create_news");
        formdata.append("title", previewData.title);
        formdata.append("hour", previewData.hour);
        formdata.append("date", previewData.newsdate);
        formdata.append("published", previewData.publish);
        formdata.append("group_ids", previewData.group_ids.join('@#'));
        formdata.append("details", previewData.details);
        formdata.append("commentsEnabled", previewData.commentsEnabled);
        formdata.append("notify_email", previewData.notify_email);
        formdata.append("notify_notification",previewData.notify_notification);
        formdata.append("notify_sms",previewData.notify_sms);
        formdata.append("team_id", team._id);
        if(previewData.notify_sms==='Yes' && previewData.card_id !==undefined){
            formdata.append("card_id",previewData.card_id);
            formdata.append("sms_details",previewData.sms_details);
            formdata.append("sms_charges_amount",previewData.sms_charges_amount);
        }

        if(previewData.image !== undefined){
            var formdatas3 = new FormData();
            let tmpFilename = generateRandomName();
            const imgFileObj = new File([previewData.image], tmpFilename+'.webp', { lastModified: new Date().getTime(), type: previewData.image.type })
            formdatas3.append("files[]", imgFileObj);
            var requestOptionss3 = {
                method: 'PUT',
                body: formdatas3,
                redirect: 'follow'
            };
            const responsesApp = await fetch("https://dashboard.plaisport.com/upload_content", requestOptionss3);
            const resultsApp = await responsesApp.text();
            const rdatasApp = JSON.parse(resultsApp);
            formdata.append('content',rdatasApp.data[0]);
        }
        else if(previewData.imageUrl !== undefined){
            formdata.append("content", previewData.imageUrl);
            formdata.append("extension", previewData.extension);            
        }

        if((previewData.pdfFiles !== undefined && previewData.pdfFiles.length > 0) || (previewData.pdfOldFiles!==undefined && previewData.pdfOldFiles.length > 0)){
            let pdfcodes = [];
            let exts = [];
            if(previewData.pdfOldFiles !== undefined && previewData.pdfOldFiles.length > 0){
                for(let i=0; i<previewData.pdfOldFiles.length; i++){
                    pdfcodes.push(previewData.pdfOldFiles[i]);
                    exts.push('pdf');
                };
            }
            if(previewData.pdfFiles !== undefined && previewData.pdfFiles.length > 0){
                for(let i=0; i<previewData.pdfFiles.length; i++){
                    let bs64 = await toBase64(previewData.pdfFiles[i]);
                    pdfcodes.push(bs64);
                    exts.push(previewData.pdfexts[i]);
                };
            }
            formdata.append("document_content", pdfcodes.join('@#'));
            formdata.append("document_extension", exts.join('@#'));
        }
        formdata.append('separate_by','@#');
        formdata.append('is_draft','Yes');

        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        await fetch(process.env.REACT_APP_APIURL + '/app_news' , requestOptions)
        .then(response => response.text())
        .then(result => {
            let rdata = JSON.parse(result);
            if(rdata.status !== 1){
                setLoading(false);                
            }
            else{
                navigate('/team/'+team._id);
            }
        })
        .catch(error => console.log('error', error));
        
    }

    useEffect(()=>{
        setSmsPopLoaded(true);
        if(!smspopLoaded){
            refreshCardsData();        
        }        
    },[smspopLoaded]);

    return(
        <>
        {!SMSLoader ?
            <>
                <button type="button" className="close-popup" onClick={(e)=>{setShowForm(false)}}><span>close</span></button>
                <form onSubmit={handleSubmit}>
                    <div className="form-heading text-center mb-[30px]">SMS Charges</div>
                    <div className="text-center" dangerouslySetInnerHTML={{__html: smsChargMessage}} />
                    {paymentCards.length > 0 ? 
                        <>
                            <div className='form-field-wrap w-full  mb-5 no-field-wrap relative'>
                                <select onChange={(e)=>{setCurrentCardID(e.target.value)}}>
                                    {paymentCards.map((card,index) => {
                                        return(
                                            <option key={card.id} value={card.id}>****{card.last4} ({card.name})</option>
                                        )
                                    })}
                                </select>
                            </div>
                            <div className='form-field-wrap mb-5 w-full no-field-wrap relative text-center'>                    
                                {!loading ?
                                    <button type="submit" className='button button--large'>CONFIRM</button>
                                :
                                <div className='flex justify-center items-center'>
                                    <img src="data:image/svg+xml,%3Csvg version='1.1' id='loader-1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='20px' height='20px' viewBox='0 0 40 40' enable-background='new 0 0 40 40' xml:space='preserve'%3E%3Cpath opacity='0.2' fill='%23000' d='M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z'%3E%3C/path%3E%3Cpath fill='%23000' d='M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 20 20' to='360 20 20' dur='0.5s' repeatCount='indefinite'%3E%3C/animateTransform%3E%3C/path%3E%3C/svg%3E" width={40} height={40} alt="" />
                                </div>
                                }
                            </div>
                        </>
                    :
                        <div className='flex flex-wrap'>
                            <div className='form-field-wrap w-full mb-5 no-field-wrap relative'>
                                <input 
                                    placeholder="0000 0000 0000 0000" className='text-field' 
                                    type='tel'
                                    name='number'
                                    maxLength='19'
                                    required
                                    autoComplete="off"
                                    onChange={(e)=>{
                                        e.target.value = formatCreditCardNumber(e.target.value);
                                        setCardNum(e.target.value);
                                        let icon = getIsUser(e.target.value);
                                        setCardIcon(icon);
                                        setError('');
                                    }}
                                />
                                <div className='absolute top-[18px] right-[20px]'>
                                    <CardIcon value={cardicon}/>
                                </div>
                            </div>
                            <div className='form-field-wrap w-full sm:w-1/2 mb-5 no-field-wrap sm:pr-1 sm:pl-1 relative'>
                                <input 
                                    placeholder="MM/YY" className='text-field' 
                                    type='tel'
                                    name='expiry'
                                    pattern='\d\d/\d\d'
                                    required
                                    autoComplete="off"
                                    onChange={(e)=>{
                                        e.target.value = formatExpirationDate(e.target.value);
                                        setCardExpiry(e.target.value);
                                        setError('');
                                    }}
                                />                                
                            </div>
                            <div className='form-field-wrap w-full sm:w-1/2 mb-5 no-field-wrap sm:pl-1 relative'>
                                <input 
                                    type='tel'
                                    name='cvc'
                                    className='text-field'
                                    placeholder='CVC'
                                    required
                                    autoComplete="off"
                                    onChange={(e)=>{
                                        e.target.value = formatCVC(e.target.value, {number:cardNum});
                                        setCardCVV(e.target.value);
                                        setError('');
                                    }}
                                />                                
                            </div>
                            <div className='form-field-wrap w-full mb-5 no-field-wrap relative'>
                                <input 
                                    type='text'
                                    name='name'
                                    className='text-field'
                                    placeholder='Card Holder Name'
                                    required
                                    autoComplete="off"
                                    onChange={(e)=>{
                                        setCardName(e.target.value);
                                        setError('');
                                    }}
                                />                                
                            </div>
                            <div className='form-field-wrap w-full mb-5 select-field-wrap country-field-wrap relative'>
                                <select name="country" onChange={(e)=>{setCountry(e.target.value)}}>
                                    <option value="">Select Country *</option>
                                    {CountriesList.map((c,index)=>{
                                        return(
                                            <option key={index} value={c.code}>{c.name}</option>
                                        );
                                    })}
                                </select>
                            </div>
                            {error!=="" && <p className='text-red-700 error mb-5'>{error}</p>}
                            <div className='form-field-wrap mb-5 w-full text-center no-field-wrap relative'>                    
                                {!loading ?
                                    <button type="submit" className='button button--large'>CONFIRM</button>
                                :
                                <div className='flex justify-center items-center'>
                                    <img src="data:image/svg+xml,%3Csvg version='1.1' id='loader-1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='20px' height='20px' viewBox='0 0 40 40' enable-background='new 0 0 40 40' xml:space='preserve'%3E%3Cpath opacity='0.2' fill='%23000' d='M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z'%3E%3C/path%3E%3Cpath fill='%23000' d='M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 20 20' to='360 20 20' dur='0.5s' repeatCount='indefinite'%3E%3C/animateTransform%3E%3C/path%3E%3C/svg%3E" width={40} height={40} alt="" />
                                </div>
                                }
                            </div>
                        </div>
                    }
                </form>
            </>    
        :
            <div className='flex justify-center items-center'>
                <img src="data:image/svg+xml,%3Csvg version='1.1' id='loader-1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='20px' height='20px' viewBox='0 0 40 40' enable-background='new 0 0 40 40' xml:space='preserve'%3E%3Cpath opacity='0.2' fill='%23000' d='M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z'%3E%3C/path%3E%3Cpath fill='%23000' d='M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 20 20' to='360 20 20' dur='0.5s' repeatCount='indefinite'%3E%3C/animateTransform%3E%3C/path%3E%3C/svg%3E" width={40} height={40} alt="" />
            </div>            
        }
        </>
    )
}

export default SMSChargePreviewForm;