import { useState } from 'react';
import placeholderImg from '../../assets/images/placeholder.png';
import { calculate_age } from '../../libs/utils';
import SignUpChildernForm from './SignUpChildrenForm';

const SignUpChildRow = ({childitem, setChildrenList, childrenList, index}) =>{
    const [showChildEditForm, setShowChildEditForm] = useState(false);
    const ChildEditForm = ({setShowForm}) =>{
        return(
            <div className="popup-form">
                <div className="popup-form-wrap !w-full !max-w-[870px] relative !px-7.5">                    
                    <SignUpChildernForm isEditChild={true} childitem={childitem} setShowForm={setShowForm} setChildrenList={setChildrenList} childrenList={childrenList} index={index} />
                </div>
            </div>
        )
    }
    const deleteChild = () => {
        setChildrenList(oldValues => {
          return oldValues.filter((_, i) => i !== index)
        });
    }
    return(
        <>
        <div className="w-full p-2.5 flex justify-between rounded-[16px] bg-white items-center shadow-[0_10px_20px_-0_rgba(54,63,108,0.1)] mb-5">
            <div className="flex items-center">
                {childitem.content ?
                    <img src={`data:image/`+childitem.extension+`;base64,`+childitem.content} width="50" height="50" className="rounded-full" alt="" />
                :
                    <img src={placeholderImg} width="50" height="50" className="rounded-full" alt="" />
                }
                <div className='pl-[16px] pr-2.5'>
                    <div className="text-[#363F6C] text-[16px] leading-[24px] font-medium">{childitem.firstName} {childitem.lastName}</div>
                    <div className='text-[12px] leading-[24px] text-black font-light'>Age: <strong className='font-bold'>{calculate_age(childitem.dateOfBirth)}</strong></div>
                </div>
            </div>
            <div className="flex items-center justify-end w-[70px]">
                <button type="button" className="button-tiny blue edit rounded-full mr-1.5" onClick={()=>{setShowChildEditForm(true);}}></button>
                <button type="button" className="button-tiny blue delete rounded-full" onClick={()=>{deleteChild()}}></button>
            </div>
        </div>
        {showChildEditForm &&
            <ChildEditForm setShowForm={setShowChildEditForm} />
        }
        </>
    )
}

export default SignUpChildRow;