import { useState, useEffect } from "react";
import { getMyDocuments } from "../../../libs/dataFunctions";
import Spinner from "../../../components/Spinner/Spinner";
import AddDocumentsForm from "./AddDocumentForm";
import MyDocumentsRow from "./MyDocumentRow";

const MyDocuments=()=>{
    const [loading,setLoading] = useState();
    const [myDocs,setMyDocs] = useState([]);
    const [docsFetchError, setDocsFetchError] = useState('');
    const [showForm, setShowDocForm] = useState(false);

    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
    ? localStorage.getItem('auth_token')
    : '';

    async function refreshMyDocs(){
        setLoading(true);
        setDocsFetchError('');
        let myDocsData = await getMyDocuments(token);
        if(myDocsData.data && myDocsData.data.length > 0){
            setMyDocs(myDocsData.data);
        }
        else{
            setDocsFetchError(myDocsData.message);
        }
        setLoading(false);
    }
    
    const AddDocuments = ({setShowForm}) =>{
        return(
            <div className="popup-form">
                <div className="popup-form-wrap !w-full !max-w-[800px] !px-7.5 relative">                    
                    <AddDocumentsForm setShowForm={setShowForm} refreshMyDocs={refreshMyDocs}/>
                </div>
            </div>
        )
    }

    useEffect(()=>{
        refreshMyDocs();
    },[]);

    return(
        !loading ?
        <>
            <div className="my-documents">
                <div className="ln-header flex justify-between items-center w-full flex-wrap pb-5 relative">
                    <h2 className="title mr-5">My Documents</h2>
                    <div className="sep mr-5 sm:mr-11"></div>
                    <button type="button" className="button" onClick={()=>{setShowDocForm(true)}}>Add documents</button>
                </div>                
                {myDocs.length > 0 ?
                <>
                    <ul className="flex flex-wrap">
                        {myDocs.map((doc,index) => { 
                            return (
                                doc.document!=="" &&
                                <li key={index} className="w-full md:w-1/2 md:px-2.5 lg:w-full lg:px-0 xl:w-1/2 xl:px-2.5 mb-5">
                                    <MyDocumentsRow doc={doc} refreshMyDocs={refreshMyDocs} />
                                </li>                                
                            )
                        })}
                    </ul>
                </>
                :
                <p className="mb-5">{docsFetchError}</p>
                }
            </div>
            {showForm &&
                <AddDocuments setShowForm={setShowDocForm} />
            }
        </>
        :
        <>
           <div className="py-5 text-center"><Spinner /></div>
        </>
    );
}
export default MyDocuments