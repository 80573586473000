import { useState } from "react";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(tz);

const MediaApprovalCard = ({carditem, token, teamId, fetchUnapprovedMedia}) =>{
    const [loading, setLoading] = useState(false);

    const AcceptMedia = async(approve_status) =>{
        setLoading(true);
        var formdata = new FormData();
        formdata.append("type", "update_media_status");
        formdata.append("media_id",carditem._id);
        formdata.append("team_id", teamId);
        formdata.append("is_approved",approve_status)
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        await fetch(process.env.REACT_APP_APIURL + '/app_gallery' , requestOptions)
        .then(response => response.text())
        .then(result => {
            setLoading(false);
            fetchUnapprovedMedia();
        })
        .catch(error => console.log('error', error));
    }
    return(
        <>
        <div className="bg-[#F9F9FB] rounded-xl px-5 py-3.5 flex flex-wrap justify-between">
            <div>
                <div className="flex mb-1.5">
                    <div className="w-[40px] h-[50px] mr-3.5 rounded-md overflow-hidden relative"><img src={carditem.image} alt="" className="object-cover w-[40px] h-[50px] absolute top-0 left-0" /></div>
                    <div className="flex items-center">
                        <span className="text-[#363F6C] font-bold text-[14px]">{carditem.folder_name}</span>
                    </div>
                </div>
                <div className="text-[12px]">by {carditem.created_by} on {dayjs.utc(carditem.created_at).format("DD MMM YYYY at HH:mm:ss")}</div>
            </div>
            <div className="flex items-end justify-end mt-2.5">
                {!loading ?
                <>
                <button type="button" className="bg-[#1AB900] uppercase text-[14px] text-white rounded-full text-center w-[100px] py-1 font-semibold mr-3" onClick={()=>{AcceptMedia("Yes")}}>Accept</button>
                <button type="button" className="bg-[#ff0000] uppercase text-[14px] text-white rounded-full text-center w-[100px] py-1 font-semibold mr-3" onClick={()=>{AcceptMedia("No")}}>Decline</button>
                </>
                :
                <img src="data:image/svg+xml,%3Csvg version='1.1' id='loader-1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='20px' height='20px' viewBox='0 0 40 40' enable-background='new 0 0 40 40' xml:space='preserve'%3E%3Cpath opacity='0.2' fill='%23000' d='M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z'%3E%3C/path%3E%3Cpath fill='%23000' d='M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 20 20' to='360 20 20' dur='0.5s' repeatCount='indefinite'%3E%3C/animateTransform%3E%3C/path%3E%3C/svg%3E" width={40} height={40} alt="" />
                }
            </div>
        </div>
        </>
    )
}

export default MediaApprovalCard;