import './AdminNavbar.scss';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from '../../contexts/AuthProvider';
import placeholderImg from '../../assets/images/placeholder.png';
import IsMobile from '../isMobile';

export default function AdminNavbar({heading}){
    const navigate = useNavigate();
    const userDetails = useAuthState() //read user details from context
    const handleClickMobmenu = (event) => {
        document.getElementById('sidebar-menu').classList.toggle('expand');
        const menuBtn = document.getElementsByClassName('mobile-nav');
        for (let i = 0; i < menuBtn.length; i++) {
            menuBtn[i].classList.toggle('expand');
        }
    };
    
    const openSearchPage=()=>{
        navigate('/search-page');
    }
    const ismobile = IsMobile(window);
    return(
        <>
            <nav className="admin-navbar">
                <div className="container max-w-full mx-auto"> 
                    <div className="flex justify-between items-center w-full py-3 pl-5 pr-5 lg:pr-7">
                        <h4 className="title">{heading ? heading : 'Dashboard'}</h4>
                        <div className="flex items-center md:w-full md:max-w-[337px] lg:max-w-[315px]">
                            <div className="search-bar relative flex items-center mr-2.5 lg:mr-5 py-2">
                                <span className="search-icon"></span>
                                <input placeholder="Search for a team..." onClick={()=>openSearchPage()} className="search-input w-full focus:outline-none focus:ring-0 bg-[#f4f5fa]" />
                            </div>
                            <a href="/calendar" className={`button-icon button-icon-calendar mr-2`}><span>Sort</span></a>
                            <div className='profile mr-2 lg:mr-0 w-[32px]'>
                                <a href="/profile">
                                    {userDetails?.user?.profilePic !== "" ?
                                    <img src={userDetails?.user?.profilePic} className="rounded-lg" alt="" width={32} height={32} />
                                    :
                                    <img src={placeholderImg} alt="" className="rounded-lg" width={32} height={32} />
                                    }
                                </a>
                            </div>
                            {/*
                            <div className='menu'>
                                <img src={menu} alt="" width={3} height={19} />
                            </div>
                            */}
                            <button type='button' className='mobile-nav' onClick={(e)=>handleClickMobmenu(e)}><span className='hidden'>Menu</span></button>
                        </div>
                    </div>
                </div>
            </nav>
        </>
    )
}