import { useState, useEffect } from "react";
import Spinner from "../../../components/Spinner/Spinner";
import { getMergeMemberList } from "../../../libs/dataFunctions";
import placeholderImg from '../../../assets/images/placeholder.png';
import iconCall from "../../../assets/images/icon-call-yellow.svg";
import iconGender from "../../../assets/images/icon-gender-yellow.svg";
import iconDate from "../../../assets/images/icon-date-yellow.svg";
import iconChild from "../../../assets/images/icon-children-yellow.svg";

import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(tz);

const MergeMemberRow=({mitem, tmpID, selectedMainMembers, setSelectedMainMembers})=>{
    const [selectedMemberSub, setSelectedMemberSub] = useState(null);
    const [selectedPhone, setSelectedPhone] = useState(null);
    const [selectedBdate, setSelectedBdate] = useState(null);
    const [selectedGender, setSelectedGender] = useState(null);
    const [selectedChildren, setSelectedChildren] = useState(null);
    const handleRemainThiSub=(sub)=>{
        setSelectedMemberSub(sub);
        const oldState=selectedMainMembers;
        var removeUserSubs = [];
        mitem.members.forEach((tmitem)=>{
            if(tmitem.sub!==sub){
                removeUserSubs.push(tmitem.sub);
            }
        });
        const newState= [
            ...oldState.slice(0, tmpID), // everything before current post
            {
                ...oldState[tmpID],
                remain_this_sub: sub,
                remove_user_sub_ids: removeUserSubs
            },
            ...oldState.slice(tmpID + 1), // everything after current post
        ];
        setSelectedMainMembers(newState);
        
    }
    const handlePhone=(member)=>{
        const oldState=selectedMainMembers;        
        const newState= [
            ...oldState.slice(0, tmpID), // everything before current post
            {
                ...oldState[tmpID],
                phoneCode: member.phoneCode,
                phoneNumber: member.phoneNumber,
            },
            ...oldState.slice(tmpID + 1), // everything after current post
        ];
        setSelectedMainMembers(newState);
        setSelectedPhone(member.sub);
    }
    const handleBdate=(member)=>{
        const oldState=selectedMainMembers;        
        const newState= [
            ...oldState.slice(0, tmpID), // everything before current post
            {
                ...oldState[tmpID],
                is_above_18: member.is_above_18,
                dateOfBirth: (member.dateOfBirth!==null && member.dateOfBirth!=="") ? member.dateOfBirth : "",
            },
            ...oldState.slice(tmpID + 1), // everything after current post
        ];
        setSelectedMainMembers(newState);
        setSelectedBdate(member.sub);
    }
    const handleGender=(member)=>{
        const oldState=selectedMainMembers;        
        const newState= [
            ...oldState.slice(0, tmpID), // everything before current post
            {
                ...oldState[tmpID],
                gender: member.gender,
            },
            ...oldState.slice(tmpID + 1), // everything after current post
        ];
        setSelectedMainMembers(newState);
        setSelectedGender(member.sub);
    }
    const handleChildren=(member)=>{
        const oldState=selectedMainMembers;  
        var childSubs = [];
        if(mitem.members[0].childrens.length>0){
            member.childrens.forEach((chitem)=>{
                childSubs.push(chitem.sub);
            });
        }
        const newState= [
            ...oldState.slice(0, tmpID), // everything before current post
            {
                ...oldState[tmpID],
                remain_childrens_sub: childSubs,
            },
            ...oldState.slice(tmpID + 1), // everything after current post
        ];
        setSelectedMainMembers(newState);
        setSelectedChildren(member.sub);
    }
    
    useEffect(()=>{
        setSelectedPhone(mitem.members[0].sub);
        setSelectedMemberSub(mitem.members[0].sub);
        setSelectedBdate(mitem.members[0].sub);
        setSelectedGender(mitem.members[0].sub);
        setSelectedChildren(mitem.members[0].sub);

        const oldState=selectedMainMembers;
        var childSubs = [];
        var removeUserSubs = [];
        if(mitem.members[0].childrens.length>0){
            mitem.members[0].childrens.forEach((chitem)=>{
                childSubs.push(chitem.sub);
            });
        }

        mitem.members.forEach((tmitem,index)=>{
            if(index!==0){
                removeUserSubs.push(tmitem.sub);
            }
        });

        const newState= [
            ...oldState.slice(0, tmpID), // everything before current post
            {
                ...oldState[tmpID],
                name: mitem.members[0].name,
                gender: mitem.members[0].name,
                is_above_18: mitem.members[0].is_above_18,
                dateOfBirth: (mitem.members[0].dateOfBirth!==null && mitem.members[0].dateOfBirth!=="") ? mitem.members[0].dateOfBirth : "",
                phoneCode: mitem.members[0].phoneCode,
                phoneNumber: mitem.members[0].phoneNumber,
                remain_this_sub: mitem.members[0].sub,
                remain_childrens_sub: childSubs,
                remove_user_sub_ids: removeUserSubs
            },
            ...oldState.slice(tmpID + 1), // everything after current post
        ];
        
        setSelectedMainMembers(newState);        
    },[])

    return(
        <div className="rounded-xl p-5 bg-[var(--color-darkblue)] text-white">
        {mitem.members.map((member)=>{
            var memchild=[];
            if(member.childrens.length>0){
                member.childrens.forEach((childitem)=>{
                    memchild.push(childitem.name);
                });
            }

            return(
                <div className="pb-5 mb-5 border-b-2 border-white/50" key={member._id}>
                    <div className="flex justify-between mb-2.5">
                        <div className="flex-1 flex gap-2.5 items-center">
                            <div className='w-6 lg:w-12.5 mr-2.5'>
                                {member.profilePicture !== '' ?  
                                    <img src={member.profilePicture} alt="" width={50} height={50} className="rounded-full w-6 lg:w-12.5 h-6 lg:h-12.5" />
                                :
                                    <img src={placeholderImg} alt="" width={50} height={50} className="rounded-full w-6 lg:w-12.5 h-6 lg:h-12.5" />
                                }
                            </div>
                            <div className="flex-1">
                                <h6 className="text-medium text-lg">{member.name}</h6>
                                <span className="text-sm font-thin">{member.email}</span>
                            </div>
                        </div>
                        <div className='flex'>
                            <input  
                                type="radio"
                                name={`membersub-${tmpID}`}
                                className="appearance-none relative peer w-4.5 h-4.5 !border-2 !border-white shrink-0"
                                checked={selectedMemberSub===member.sub ? true : false}
                                onChange={(e)=>{
                                    if(e.target.checked){
                                        handleRemainThiSub(member.sub);
                                    }
                                }}
                            />
                            <svg 
                                className="absolute w-4 h-4 ml-[1px] hidden pointer-events-none peer-checked:block"
                                width="16" 
                                height="16" 
                                viewBox="0 0 16 16" 
                                fill="none" 
                                xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.9 10.5594L3.14375 7.80315L2.225 8.7219L5.9 12.3969L13.775 4.5219L12.8562 3.60315L5.9 10.5594Z" fill="white" stroke="white" strokeWidth="1"/>
                            </svg>
                        </div>
                    </div>
                    <div className="flex justify-between pl-6 lg:pl-12.5 mb-2.5">
                        <div className="flex-1 text-medium text-sm flex items-center gap-4">
                            <img src={iconCall} width={20} height={20} alt=""/>
                            <span>{member.phoneCode}{member.phoneNumber}</span>
                        </div>
                        <div className='flex'>
                            <input  
                                type="radio"
                                name={`memberphone-${tmpID}`}
                                className="appearance-none relative peer w-4.5 h-4.5 !border-2 !border-white shrink-0"
                                checked={selectedPhone===member.sub ? true : false}
                                onChange={(e)=>{
                                    if(e.target.checked){
                                        handlePhone(member);
                                    }                                    
                                }}
                            />
                            <svg 
                                className="absolute w-4 h-4 ml-[1px] hidden pointer-events-none peer-checked:block"
                                width="16" 
                                height="16" 
                                viewBox="0 0 16 16" 
                                fill="none" 
                                xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.9 10.5594L3.14375 7.80315L2.225 8.7219L5.9 12.3969L13.775 4.5219L12.8562 3.60315L5.9 10.5594Z" fill="white" stroke="white" strokeWidth="1"/>
                            </svg>
                        </div>
                    </div>
                    <div className="flex justify-between pl-6 lg:pl-12.5 mb-2.5">
                        <div className="flex-1 text-medium text-sm flex items-center gap-4">
                            <img src={iconDate} width={20} height={20} alt="" />
                            <span>
                                {member.is_above_18 ?
                                <>Over 18 years</>
                                :
                                <>
                                    {(member.dateOfBirth!=="" && member.dateOfBirth!=="over_18") ?
                                        <>{dayjs(member.dateOfBirth).format('DD MMM YYYY')}</>
                                    :
                                        <>Not set</>
                                    }
                                </>
                                }
                            </span>
                        </div>
                        <div className='flex'>
                            <input  
                                type="radio"
                                name={`memberbdate-${tmpID}`}
                                className="appearance-none relative peer w-4.5 h-4.5 !border-2 !border-white shrink-0"
                                checked={selectedBdate===member.sub ? true : false}
                                onChange={(e)=>{
                                    if(e.target.checked){
                                        handleBdate(member);
                                    }                                    
                                }}
                            />
                            <svg 
                                className="absolute w-4 h-4 ml-[1px] hidden pointer-events-none peer-checked:block"
                                width="16" 
                                height="16" 
                                viewBox="0 0 16 16" 
                                fill="none" 
                                xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.9 10.5594L3.14375 7.80315L2.225 8.7219L5.9 12.3969L13.775 4.5219L12.8562 3.60315L5.9 10.5594Z" fill="white" stroke="white" strokeWidth="1"/>
                            </svg>
                        </div>
                    </div>
                    <div className="flex justify-between pl-6 lg:pl-12.5 mb-2.5">
                        <div className="flex-1 text-medium text-sm flex items-center gap-4">
                            <img src={iconGender} width={20} height={20} alt="" />
                            <span>{member.gender}</span>
                        </div>
                        <div className='flex'>
                            <input  
                                type="radio"
                                name={`membergender-${tmpID}`}
                                className="appearance-none relative peer w-4.5 h-4.5 !border-2 !border-white shrink-0"
                                checked={selectedGender===member.sub ? true : false}
                                onChange={(e)=>{
                                    if(e.target.checked){
                                        handleGender(member);
                                    }                                    
                                }}
                            />
                            <svg 
                                className="absolute w-4 h-4 ml-[1px] hidden pointer-events-none peer-checked:block"
                                width="16" 
                                height="16" 
                                viewBox="0 0 16 16" 
                                fill="none" 
                                xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.9 10.5594L3.14375 7.80315L2.225 8.7219L5.9 12.3969L13.775 4.5219L12.8562 3.60315L5.9 10.5594Z" fill="white" stroke="white" strokeWidth="1"/>
                            </svg>
                        </div>
                    </div>
                    <div className="flex justify-between pl-6 lg:pl-12.5 mb-2.5">
                        <div className="flex-1 text-medium text-sm flex items-center gap-4">
                            <img src={iconChild} width={20} height={20} alt="" />
                            <span>
                                {memchild.length>0 ?
                                    <>
                                    {memchild.join(',')}
                                    </>
                                    :
                                    <>Not child members available</>
                                }
                            </span>
                        </div>
                        <div className='flex'>
                            <input  
                                type="radio"
                                name={`memberchildren-${tmpID}`}
                                className="appearance-none relative peer w-4.5 h-4.5 !border-2 !border-white shrink-0"
                                checked={selectedChildren===member.sub ? true : false}
                                onChange={(e)=>{
                                    if(e.target.checked){
                                        handleChildren(member);
                                    }                                    
                                }}
                            />
                            <svg 
                                className="absolute w-4 h-4 ml-[1px] hidden pointer-events-none peer-checked:block"
                                width="16" 
                                height="16" 
                                viewBox="0 0 16 16" 
                                fill="none" 
                                xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.9 10.5594L3.14375 7.80315L2.225 8.7219L5.9 12.3969L13.775 4.5219L12.8562 3.60315L5.9 10.5594Z" fill="white" stroke="white" strokeWidth="1"/>
                            </svg>
                        </div>
                    </div>
                </div>
            )
        })}                                        
    </div>
    )
}

const MergeMembersPopup=({setShowForm, team, token})=>{
    const [loading, setLoading] = useState();
    const [membersList, setMembersList] = useState([]);
    const [selectedMainMembers,setSelectedMainMembers] = useState([]);
    const [error, setErrorMessage] = useState(null);
    const fetchMergeMembers=async()=>{
        setLoading(true);
        let [membersListdata] = await Promise.all([
            getMergeMemberList(token, team._id)
        ]);
        setMembersList(membersListdata.data);
        setLoading(false);
    }
    
    const handleMerging=async()=>{
        setLoading(true);
        setErrorMessage(null);
        if(selectedMainMembers.length > 0){
            var formdata = new FormData();
            formdata.append("type", "remove_duplicate_members");
            formdata.append("team_id", team._id);        
            formdata.append('remove_users',JSON.stringify(selectedMainMembers));        
            var secureHeader = new Headers();
            secureHeader.append('Authorization','Bearer ' + token);
            secureHeader.append('device_type','W');  
            const requestOptions = {
                method: 'POST',
                redirect: 'follow',
                body: formdata,
                headers: secureHeader
            };          
            await fetch(process.env.REACT_APP_APIURL + '/app_members' , requestOptions)
            .then(response => response.text())
            .then(result => {
                setLoading(false);
                let rdata = JSON.parse(result);
                if(rdata.status!==1){
                    setErrorMessage(rdata.message);
                }
                else{
                    setShowForm(false);
                }
            })
            .catch(error => console.log('error', error));
        }
        else{
            setErrorMessage("No members selected for merge");
        }
    }

    useEffect(()=>{
        fetchMergeMembers();
    },[]);

    return(
        <>
        <div className="popup-form">
            <div className="popup-form-wrap popup-form-memberdetails !max-w-[670px] relative !px-2.5 sm:!px-8">                    
                <button type="button" className="close-popup" onClick={(e)=>{setShowForm(false);}}><span>close</span></button>
                <div className="relative">
                {!loading ?
                <>
                    <div>
                        <div className="mb-7">
                            <h2 className="text-[22px] leading-[33px] text-center font-semibold">Member Merging</h2>
                        </div>
                        <p className="mb-10 text-sm text-center">
                            Merge two member profile, simply by selecting the information accross two existing member that you wish to consolidate into one profile.
                        </p>
                        {error!==null &&
                            <p className="text-sm text-center text-red-600 mb-10">{error}</p>
                        }
                        {membersList.length > 0 ?
                        <>
                        <div className="flex flex-col gap-5">
                            {membersList.map((mitem, index)=>{
                                return(
                                    <MergeMemberRow mitem={mitem} key={index} tmpID={index} setSelectedMainMembers={setSelectedMainMembers} selectedMainMembers={selectedMainMembers} />
                                )
                            })}
                            <div className="flex items-center justify-center mt-5">
                                <button type="button" className="button" onClick={()=>handleMerging()}>Confirm</button>
                            </div>
                        </div>
                        </>
                        :
                        <div className="text-center">No duplicate members available.</div>
                        }
                    </div>                    
                </>
                :
                <>
                    <div className="text-center py-5"><Spinner /></div>
                </>
                }
                </div>
            </div>
        </div>
        </>
    )
}

export default MergeMembersPopup;