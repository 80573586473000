import { useState, useEffect } from 'react';
import placeholderImg from '../../../assets/images/placeholder.png';
import trophy from '../../../assets/images/trophy.png';
import { getLeaderShipDetail } from '../../../libs/dataFunctions';
import Spinner from '../../../components/Spinner/Spinner';
import './leadership.scss';

const LeadershipBoardRow=({leaderItem, index, token, team , hasdetail})=>{
    const [showDetails, setShowDetails] = useState(false);
    const LeaderShipBoardDetail = ({setShowForm}) =>{
        const [leaderDetail, setLeaderDetail] = useState([]);
        const [loading, setLoading] = useState(false);
        async function refreshLeaderDetails(){
            setLoading(true);
            const [leaderData] = await Promise.all([
                getLeaderShipDetail(token,leaderItem.sub,team._id)
            ]);
            
            if(leaderData.status===1){
                setLeaderDetail(leaderData.data);
            }
            else{
                setLeaderDetail([]);
            }
            setLoading(false);
        }
        useEffect(()=>{
            refreshLeaderDetails();
        },[]);
        
        return(
            <div className="popup-form">
                <div className="popup-form-wrap relative !w-full !max-w-[970px] !px-2.5 sm:!px-8">                    
                  <button type="button" className="close-popup" onClick={(e)=>{setShowForm(false);}}><span>close</span></button>
                  {!loading ?
                  <>
                    <div>
                        <div className='text-center uppercase text-[22px] leading-[33px] font-semibold mb-[30px]'>Teams</div>
                        <div className='fantacygame-header px-10 pt-[35px] pb-[30px] rounded-xl mb-[26px]'>
                            <div className="flex flex-col sm:flex-row items-center justify-between">
                                <div className='flex flex-col sm:flex-row items-center'>
                                    <div className='w-[100px] h-[100px] relative rounded-full overflow-hidden mr-[22px]'>
                                        {leaderItem.profilePicture !=="" ?
                                            <img src={leaderItem.profilePicture} width="100" height="100" className="w-[100px]" alt="" />
                                        :
                                            <img src={placeholderImg} width="100" height="100" className="w-[100px]" alt="" />
                                        }
                                    </div>
                                    <div>
                                        <div className='text-white text-[20px] leading-[30px] font-bold'>{leaderItem.name}</div>
                                        <div className="flex items-center">
                                            <div className="trophy mr-5"><img src={trophy} width={'25px'} alt="" /></div>
                                            <div className="text-white text-[34px] leading-[51px] font-bold">{leaderItem.points}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className='flex flex-col sm:flex-row items-center'>
                                    <div className='text-[20px] leading-[30px] font-medium text-white mr-[15px]'>Rank</div>
                                    <div className='text-[64px] leading-[64px] font-extrabold text-[#FBDB09]'>{(index+1).toString().padStart(2, '0')}</div>
                                </div>
                            </div>
                        </div>
                        {leaderDetail?.players!==undefined && leaderDetail?.players.length &&
                            <div className="flex flex-wrap justify-center -mx-5">
                                {leaderDetail?.players.map((player)=>{
                                    const getRatingContent = () => {
                                        let ratingContent = [];
                                        for (let i = 1; i <= player.rating; i++) {
                                            ratingContent.push(<span className="star" key={i}>&#9733;</span>);
                                        }
                                        return ratingContent;
                                    };

                                    return(
                                        <div className="w-full max-w-[310px] flex px-2.5 mb-5" key={player._id}>
                                            <div className={`player-item w-full px-2.5 py-2.5 flex justify-between rounded-[16px] items-center !bg-[#EFF1F9] !shadow-none`}>
                                                <div className="flex items-center flex-1">
                                                    {player.profilePicture !=="" ?
                                                        <img src={player.profilePicture} width="50" height="50" className="rounded-full w-[50px] mr-[18px]" alt="" />
                                                    :
                                                        <img src={placeholderImg} width="50" height="50" className="rounded-full w-[50px] mr-[18px]" alt="" />
                                                    }
                                                    <div className="flex-1 pr-2.5">
                                                        <div className="player-name text-[16px] leading-[24px] font-semibold">{player.name}</div>
                                                        <div className="flex text-[14px] leading-[21px] font-semibold items-center">
                                                            <div className='player-price mr-1'>{`Price: $${player.price}`}m</div>
                                                            {player.trending_status!=='equal' &&
                                                                <>
                                                                    <div className={`${player.trending_status==='up' ? 'trending-up' : 'trending-down'}`}></div>   
                                                                </>
                                                            }                            
                                                            <div className='ratings'>
                                                                {getRatingContent()}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>                                                
                                            </div>
                                        </div>
                                    )
                                })}                                
                            </div>
                        }
                    </div>
                  </>
                  :
                  <>
                      <div className="py-5 text-center"><Spinner /></div>
                  </>
                  }
                </div>
            </div>
        )
    }
    return(
        <>
            <div className={`leadership-row flex items-center justify-between py-2.5 px-8 mb-5 ${hasdetail ? 'cursor-pointer hasdetail':''}`} onClick={()=>{if(hasdetail){setShowDetails(true)}}}>
                <div className="flex items-center">
                    <div className="indexnum text-[16px] leading-[24px] mr-11">{(index+1).toString().padStart(2, '0')}</div>
                    <div className="w-[50px] mr-11 rounded-full overflow-hidden border border-[#000000]">
                        {leaderItem.profilePicture !=="" ?
                            <img src={leaderItem.profilePicture} width="50" height="50" className="w-[50px]" alt="" />
                        :
                            <img src={placeholderImg} width="50" height="50" className="w-[50px]" alt="" />
                        }
                    </div>
                    <div className="leadership-name text-[18px] leading-[27px] font-medium">{leaderItem.name}</div>
                </div>
                <div className="flex items-center">
                    <div className="trophy mr-5"><img src={trophy} width={'17px'} alt="" /></div>
                    <div className="points text-[18px] leading-[27px] font-bold">{leaderItem.points}</div>
                </div>
            </div>
            {showDetails && hasdetail &&
                <LeaderShipBoardDetail setShowForm={setShowDetails} />
            }
        </>
    )
}

export default LeadershipBoardRow;