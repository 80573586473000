import { useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import placeholderImg from '../../../assets/images/placeholder.png';
import iconTimeTiny from '../../../assets/images/icon-time-tiny.svg';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {
    EmailShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    FacebookIcon,
    LinkedinIcon,
    TwitterIcon,
    WhatsappIcon,
    EmailIcon,
} from "react-share";

dayjs.extend(utc);
dayjs.extend(tz);

const FacilityCard = ({facility, token, fetchAllFacilities}) =>{
    const [bookingStatus, setBookingStatus] = useState(null);
    const [loading, setLoading] = useState(false);
    const [delLoading, setDelLoading] = useState(false);
    const [showShare, setShowShare] = useState(false);

    useEffect(()=>{
        setBookingStatus(facility.accept_booking);
    },[]);

    const ShareFacility = ({setShowShare}) =>{
        return(
            <div className="popup-form">
                <div className="popup-form-wrap relative w-full max-w-[400px]">
                    <button type="button" className="close-popup" onClick={(e)=>{setShowShare(false)}}><span>close</span></button>
                    <div className="form-heading text-center mb-[30px]">Share</div>
                    <div className='flex flex-wrap items-center'>
                        <div className='px-2.5 mb-5'>
                            <FacebookShareButton url={facility.share_message}>
                                <FacebookIcon size={32} round />
                            </FacebookShareButton>
                        </div>
                        <div className='px-2.5 mb-5'>
                            <LinkedinShareButton url={facility.share_message} source='PLAI Sports'>
                                <LinkedinIcon size={32} round />
                            </LinkedinShareButton>
                        </div>
                        <div className='px-2.5 mb-5'>                            
                            <TwitterShareButton url={facility.share_message} title={facility.name}>
                                <TwitterIcon size={32} round />
                            </TwitterShareButton>
                        </div>
                        <div className='px-2.5 mb-5'>
                            <WhatsappShareButton url={facility.share_message} title={facility.name}>
                                <WhatsappIcon size={32} round />
                            </WhatsappShareButton>
                        </div>
                        <div className='px-2.5 mb-5'>
                            <EmailShareButton url={facility.share_message} body={facility.name}>
                                <EmailIcon size={32} round />
                            </EmailShareButton>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const toggleBookingStatus=async()=>{
        setLoading(true);
        var formdata=new FormData();
        var updatedStatus = bookingStatus.toLowerCase()==='yes' ? 'No' : 'Yes';
        formdata.append("type", "update_facilities_booking_status");
        formdata.append("team_id", facility.team_id);
        formdata.append("booking_facilities_id",facility._id);
        formdata.append("accept_booking", updatedStatus);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };
        await fetch(process.env.REACT_APP_APIURL + '/app_booking_facilities' , requestOptions)
        .then(response => response.text())
        .then(result => {
            setBookingStatus(updatedStatus);
            setLoading(false);
        })
        .catch(error => console.log('error', error));
    }

    const deleteFacilityAction=async()=>{
        setDelLoading(true);
        var formdata = new FormData();
        formdata.append("type", "delete_facility");
        formdata.append("booking_facilities_id",facility._id);
        formdata.append("team_id", facility.team_id);
        
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        await fetch(process.env.REACT_APP_APIURL + '/app_booking_facilities' , requestOptions)
        .then(response => response.text())
        .then(result => {
            setDelLoading(false);
            fetchAllFacilities();
        })
        .catch(error => console.log('error', error));
    }

    const deleteFacility=()=>{
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                <div className='confirm-ui'>
                    <div className="font-semibold uppercase text-[22px] leading-[30px] text-center mb-5">PLAI</div>
                    <p className="text-center text-[14px] leading-[18px] mb-5">Are you sure you want to delete this facility?</p>
                    <div className="flex justify-center">
                        <button className="button button-blue mx-1" onClick={onClose}>No</button>
                        <button className="button mx-1"
                        onClick={() => { 
                            deleteFacilityAction();                       
                            onClose();
                        }}
                        >
                        Yes
                        </button>
                    </div>
                </div>
                );
            }
        });
    }

    const confirmEdit=()=>{
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                <div className='confirm-ui'>
                    <div className="font-semibold uppercase text-[22px] leading-[30px] text-center mb-5">PLAI</div>
                    <p className="text-center text-[14px] leading-[18px] mb-5">YIKES! As you’ve started collecting bookings, you’re unable to EDIT this facility. Consider setting up a new booking page before taking down this one!</p>
                    <div className="flex justify-center">
                        <button className="button button-blue mx-1" onClick={onClose}>Ok</button>                        
                    </div>
                </div>
                );
            }
        });
    }

    return(
        <>
        <div className="facility-card bg-white p-3 relative">
            <div className="flex flex-col sm:flex-row gap-6.5 mb-4.5">
                <div className='w-35'>
                    {facility.image!=="" ?
                        <img src={facility.image} alt="" width={140} height={90} className="w-35 h-22.5 rounded-xl object-cover" />
                    :
                        <img src={placeholderImg} alt="" width={140} height={90} className="w-35 h-22.5 rounded-xl object-cover"/>
                    }
                </div>
                <div className='flex-1'>
                    <div className='text-xl/6 font-bold mb-3 relative pr-5'>
                        {facility.name}
                        <button type='button' className='absolute right-0 top-0' onClick={()=>{deleteFacility()}}>
                            <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.1 1.21333H12.1333V2.42667H10.92V11.5267C10.92 11.6876 10.8561 11.8419 10.7423 11.9556C10.6285 12.0694 10.4742 12.1333 10.3133 12.1333H1.82C1.6591 12.1333 1.50479 12.0694 1.39102 11.9556C1.27725 11.8419 1.21333 11.6876 1.21333 11.5267V2.42667H0V1.21333H3.03333V0H9.1V1.21333ZM4.24667 4.24667V9.1H5.46V4.24667H4.24667ZM6.67333 4.24667V9.1H7.88667V4.24667H6.67333Z" fill="#363F6C"/>
                            </svg>
                        </button>
                    </div>
                    <div className='flex gap-2 items-center mb-4'>
                        <img src={iconTimeTiny} width={12} height={12} alt="time" />
                        <div className='text-xs'>{dayjs('1/1/1 '+facility.start_time).format('hh:mm A')} to {dayjs('1/1/1 '+facility.end_time).format('hh:mm A')}</div>
                    </div>
                    <div className='flex gap-3'>
                        {facility.booking_days_display.map((bday)=>{
                            return(
                                <span className='rounded-full w-6.5 h-6.5 bg-[rgba(42,48,82,0.05)] font-light text-[9px] leading-[10px] flex items-center justify-center' key={bday}>{bday}</span>
                            )
                        })}
                    </div>
                </div>
            </div>
            <div className='flex justify-end gap-2.5 items-center'>
                <div className="flex bg-white h-full items-center">
                    <span className={`text-[9px] leading-[10px] mr-1.5 max-w-[65px] ${bookingStatus!==null && bookingStatus.toLowerCase()==='yes' ? 'text-green-600' : 'text-red-600'}`}>{bookingStatus!==null && bookingStatus.toLowerCase()==='yes' ? 'Accepting bookings' : 'Not Accepting bookings'}</span>
                    {!loading ?
                        <label className="relative inline-flex items-center cursor-pointer mb-0">
                            <input type="checkbox" value="" className="sr-only peer" onChange={()=>toggleBookingStatus()} checked={bookingStatus!==null && bookingStatus.toLowerCase()==='yes' ? true : false} />
                            <div className="w-10 h-5.5 bg-[rgba(54,63,108,0.1)] peer-focus:outline-none dark:peer-focus:ring-blue-800 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:bg-[var(--color-yellow)] after:content-[''] after:absolute after:top-[3px] after:left-[3px] after:bg-[var(--color-darkblue)] after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-[var(--color-darkblue)]"></div>
                        </label>
                    :
                        <img src="data:image/svg+xml,%3Csvg version='1.1' id='loader-1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='20px' height='20px' viewBox='0 0 40 40' enable-background='new 0 0 40 40' xml:space='preserve'%3E%3Cpath opacity='0.2' fill='%23000' d='M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z'%3E%3C/path%3E%3Cpath fill='%23000' d='M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 20 20' to='360 20 20' dur='0.5s' repeatCount='indefinite'%3E%3C/animateTransform%3E%3C/path%3E%3C/svg%3E" width={12} height={12} alt="" />
                    }
                </div>
                {facility.can_update_facility ?
                    <div><a href={`/team/${facility.team_id}/edit-facility/${facility._id}`} className="block button-tiny edit rounded-full"><span className="hidden">Edit</span></a></div>
                    :
                    <div><button type='button' className="block button-tiny edit rounded-full" onClick={()=>confirmEdit()}><span className="hidden">Edit</span></button></div>
                }
                <button type="button" onClick={()=>{if(facility.share_message!=="" ? setShowShare(true) : null);}}>
                    <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M15.2922 10.6707C15.2922 9.29065 16.4266 8.17091 17.8243 8.17091C18.1561 8.17012 18.4848 8.23415 18.7917 8.35935C19.0986 8.48454 19.3776 8.66845 19.6128 8.90058C19.848 9.1327 20.0348 9.40849 20.1625 9.7122C20.2902 10.0159 20.3563 10.3416 20.3571 10.6707C20.3571 12.0514 19.2227 13.1712 17.8243 13.1712C17.4889 13.1716 17.1568 13.1059 16.8473 12.9779C16.5378 12.8499 16.257 12.6622 16.0213 12.4257L12.5158 14.7927C12.6134 15.2749 12.5655 15.775 12.3782 16.2304L16.2217 18.7354C16.6744 18.3693 17.2409 18.1697 17.825 18.1706C18.1569 18.17 18.4856 18.2341 18.7924 18.3594C19.0992 18.4847 19.3782 18.6687 19.6133 18.9008C19.8484 19.133 20.0351 19.4089 20.1628 19.7126C20.2904 20.0164 20.3564 20.3421 20.3571 20.6711C20.3571 22.0511 19.2227 23.1709 17.8243 23.1709C17.1543 23.1723 16.5111 22.9097 16.0363 22.441C15.5614 21.9722 15.2938 21.3356 15.2922 20.6711C15.2916 20.3206 15.3661 19.974 15.5108 19.6541L11.6975 17.1709C11.2357 17.5691 10.644 17.7878 10.0321 17.7866C9.70025 17.7874 9.37149 17.7234 9.06461 17.5981C8.75774 17.4729 8.47875 17.2889 8.24361 17.0567C8.00846 16.8245 7.82175 16.5486 7.69416 16.2448C7.56657 15.941 7.5006 15.6152 7.5 15.2861C7.50069 14.9571 7.56675 14.6315 7.69438 14.3278C7.82202 14.024 8.00873 13.7483 8.24387 13.5161C8.47901 13.284 8.75796 13.1001 9.06479 12.9749C9.37162 12.8497 9.70031 12.7856 10.0321 12.7864C10.8368 12.7864 11.5523 13.1562 12.0159 13.7322L15.4139 11.4379C15.3331 11.1901 15.292 10.9311 15.2922 10.6707Z" fill="#363F6C"/>
                    </svg>
                </button>
            </div>
            {facility.is_payment_required.toLowerCase()==="no" ?
                <div className="absolute bottom-0 left-0 text-sm px-6 py-2 font-semibold bg-[var(--color-darkblue)] text-[var(--color-yellow)] rounded-tr-xl rounded-bl-xl">
                    No Charge
                </div>
            :
                <div className="absolute bottom-0 left-0 text-sm px-6 py-2 bg-[var(--color-darkblue)] text-white rounded-tr-xl rounded-bl-xl">
                    <span className='text-[var(--color-yellow)] font-semibold'>{facility.currency_symbol}</span>
                    <span className='font-light'>
                        <span className='font-semibold'>{facility.price}/</span>{facility.fees_management==='pay_per_hour' ? 'per hour' : 'per booking' }
                    </span>
                </div>
            }
        </div>
        {showShare &&
            <ShareFacility setShowShare={setShowShare}/>
        }
        </>
    )
}

export default FacilityCard;