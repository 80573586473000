import { NavLink } from 'react-router-dom';
import ThankYouImg from '../../../assets/images/thankyou.png';
import Sidebar from '../../../components/Sidebar/Sidebar';
import AdminNavbar from '../../../components/AdminNavbar/AdminNavbar';
const Thankyou = () =>{
    return(
        <>
        <Sidebar type="thin"/>
        <div id="page" className="page-thankyou">
            <AdminNavbar heading='Thank you'/>
            <div className='breadcrumb flex item-center pt-1.5 pb-1 pl-5 pr-7 w-full'>
                <NavLink to="/" className='home'>
                    <span>Dashboard</span>
                </NavLink>
                <span className='separator'>&lt;</span>
                Thank you              
            </div>
            <div className="empty text-center">
                <div className="image"><img src={ThankYouImg} alt="Plaisport" width={500} /></div>
                <div className="title mb-5">Thank you for your purchase</div>
                <div className="description mb-8">
                    Your purchase was successfull, please return to the PLAI and Press <NavLink className='text-[#363F6C] underline hover:text-[#FBDB09]' to="/">HOME</NavLink>
                </div>
                <div className='flex justify-center'>
                    <NavLink className='button' to="/">HOME</NavLink>
                </div>
            </div>
        </div>        
        </>
    )
}

export default Thankyou;