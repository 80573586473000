import { useState, useEffect } from 'react';

function IsTablet(wind) {
    const [width, setWidth] = useState(wind.innerWidth);
    
    useEffect(() => {
        function handlewindSizeChange() {
            setWidth(wind.innerWidth);
        }

        wind.addEventListener('resize', handlewindSizeChange);
        return () => {
            wind.removeEventListener('resize', handlewindSizeChange);
        }
    }, [wind, setWidth]);

    const isTablet = (width >= 768 && width < 1024) ? true : false;
    return isTablet
}

export default IsTablet;