import { useState, useEffect } from "react";
import Spinner from "../../../components/Spinner/Spinner";
import { getMyTeams } from "../../../libs/dataFunctions";
import MyTeamRow from "./MyTeamRow";

const MyTeams=()=>{   
    const [loading, setLoading] = useState(false);    
    const [myTeamCreatedList, setMyCreatedTeamList] = useState([]);
    const [myTeamJoinedList, setMyJoinedTeamList] = useState([]);

    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
    ? localStorage.getItem('auth_token')
    : '';

    async function refreshMyTeams(){
        setLoading(true);
        let myTeamsData = await getMyTeams(token);
        if(myTeamsData.status===1){
            setMyCreatedTeamList(myTeamsData.data?.user_created_team);
            setMyJoinedTeamList(myTeamsData.data?.user_joined_team);
        }
        setLoading(false);
    }
    useEffect(()=>{
        refreshMyTeams();
    },[]);
    
    return(
        !loading ?
        <>
            <div className="myteams_section">
            {myTeamCreatedList.length > 0 &&
                <>
                <div className="myteams_top">
                    <div className="ln-header flex justify-between items-center w-full pb-5 relative">
                        <h2 className="title mr-5">TEAMS CREATED</h2>
                        <div className="sep mr-5 sm:mr-11"></div> 
                    </div>
                </div>
                <div className="my-teams flex flex-wrap">
                    {myTeamCreatedList.map((myTeam,index)=>{
                        return(
                            <div className="w-full mb-5 md:w-1/2 lg:w-full xl:w-1/2 px-2.5" key={myTeam._id}>
                                <MyTeamRow myteam={myTeam} refreshMyTeams={refreshMyTeams} setLoading={setLoading} isDelete={true} />
                            </div>
                        )
                        })
                    }
                </div>
                </>
            }
            {myTeamJoinedList.length > 0 &&
                <>
                <div className="myteams_top">
                    <div className="ln-header flex justify-between items-center w-full pb-5 relative">
                        <h2 className="title mr-5">TEAMS JOINED</h2>
                        <div className="sep mr-5 sm:mr-11"></div> 
                    </div>
                </div>
                <div className="my-teams flex flex-wrap">
                    {myTeamJoinedList.map((myTeam,index)=>{
                        return(
                            <div className="w-full mb-5 md:w-1/2 lg:w-full xl:w-1/2 px-2.5" key={myTeam._id}>
                                <MyTeamRow myteam={myTeam} refreshMyTeams={refreshMyTeams} setLoading={setLoading} isDelete={false} />
                            </div>
                        )
                        })
                    }
                </div>
                </>
            }
            </div>
        </>
        :
        <>
           <div className="py-5 text-center"><Spinner /></div>
        </>
    );
}

export default MyTeams;