import React,{ useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useLocation } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { jwtDecode } from 'jwt-decode';
import * as yup from 'yup';
import { useGoogleLogin } from '@react-oauth/google';
import {
    //LoginSocialGoogle,
    //LoginSocialFacebook,
    LoginSocialApple,
} from 'reactjs-social-login';

import { browserName, browserVersion } from "react-device-detect";
import { loginUser,socialLoginUser, useAuthState, useAuthDispatch } from '../../contexts/AuthProvider';

import HeaderBlank from "../../components/HeaderBlank/HeaderBlank";
import signinBG from "../../assets/images/signin-bg.png";
import logoShort from "../../assets/images/logo-short.png";
import logoMedium from '../../assets/images/logo-main.svg';
import googleSigninIco from "../../assets/images/google-sign.svg";
//import facebookSignIco from '../../assets/images/facebook-signin.svg';
import appleSignIco from '../../assets/images/apple-signin.svg';
import './auth.scss';

const schema = yup.object({
    email: yup.string().email().required('Please Enter your email'),
    /*password: yup.string().required('Please Enter your password')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
      "Password must contain at least 8 characters, including one upper case and one number."
    ),*/
}).required();

export default function SignIn({redirect}) {
    const navigate = useNavigate();
    const { state } = useLocation();
    const [showPassword, setShowPassword] = useState(false);
    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });
    const [err, setErr] = useState(null);
    
    const dispatch = useAuthDispatch();
    const { loading, errorMessage } = useAuthState();

    const onSubmit = async (data) => {
        let formdata = new FormData();
        formdata.append("email", data.email);
        formdata.append("password", data.password);
        formdata.append("device_type", "W");
        formdata.append("device_token", "1234");
        formdata.append("device_unique_id", "123");
        formdata.append("device_name", browserName);
        formdata.append("app_version", "2.1.1");
        formdata.append("os", browserVersion);

        try {
			let response = await loginUser(dispatch, formdata);            
			if (response.status === 0){
                return;
            }
            else{
                if(state!==null && state.prev!=='/signin'){
                    window.location.href=state.prev;
                }
                else{
                    window.location.href='/';
                }
            }
		} catch (error) {
			console.log(error);
		}
    }

    /*const responseFacebook = async(response) => {
        if(response.accessToken!==undefined){
            console.log(response);
            const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
            let formdata = new FormData();
            formdata.append("email", response.email);
            formdata.append("device_type", "W");
            formdata.append("device_token", "1234");
            formdata.append("device_unique_id", "123");
            formdata.append("device_name", browserName);
            formdata.append("app_version", "2.1.1");
            formdata.append("registered_type",'facebook');
            formdata.append("social_id",response.id);
            formdata.append("os", browserVersion);
            formdata.append('timezone',timeZone);

            try {
                let logresponse = await socialLoginUser(dispatch, formdata);            
                if (logresponse.status !== 1){
                    let socialData = {
                        platform:'facebook',
                        social_id:response.id,
                        id_token:response.accessToken,
                        name:response.name,
                        email:response.email
                    };
                    navigate('/signup',{
                        state:{
                            social_data:JSON.stringify(socialData)
                        }
                    });
                }
                else{
                    window.location.href = '/';
                }
            } catch (error) {
                console.log(error);
            }
        }
    }*/

    const appleResponseHandle = async(res) =>{
        setErr(null);
        if(res.authorization!==undefined){
            const id_token = res.authorization.id_token;
            const appleUserDetail = jwtDecode(id_token);
            const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
            let formdata = new FormData();
            formdata.append("email", appleUserDetail.email);
            formdata.append("device_type", "W");
            formdata.append("device_token", "1234");
            formdata.append("device_unique_id", "123");
            formdata.append("device_name", browserName);
            formdata.append("app_version", "2.1.1");
            formdata.append("registered_type",'apple');
            formdata.append("social_id",appleUserDetail.sub);
            formdata.append("os", browserVersion);
            formdata.append('timezone',timeZone);

            try {
                let response = await socialLoginUser(dispatch, formdata);            
                if (response.status !== 1){
                    let socialData = {
                        platform:'apple',
                        social_id:appleUserDetail.sub,
                        id_token:res.authorization.id_token,
                        name:res.user!==undefined ? res.user.name : {firstName:'', lastName:''},
                        email:appleUserDetail.email
                    };                    
                    
                    navigate('/signup',{
                        state:{
                            social_data:JSON.stringify(socialData)
                        }
                    });
                }
                else{
                    window.location.href = '/';
                }
            } catch (error) {
                console.log(error);
                setErr(error);
            }
        }
    }
    
    const googleResponseHandler=async(response, accessToken)=>{
        if(response.email!==undefined){
            const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
            let formdata = new FormData();
            formdata.append("email", response.email);
            formdata.append("device_type", "W");
            formdata.append("device_token", "1234");
            formdata.append("device_unique_id", "123");
            formdata.append("device_name", browserName);
            formdata.append("app_version", "2.1.1");
            formdata.append("registered_type",'google');
            formdata.append("social_id",response.sub);
            formdata.append("os", browserVersion);
            formdata.append('timezone',timeZone);

            try {
                let res = await socialLoginUser(dispatch, formdata);  
                if (res.status !== 1){
                    let socialData = {
                        platform:'google',
                        social_id:response.sub,
                        id_token:accessToken,
                        name:{firstName:response.given_name, lastName:response.family_name},
                        email:response.email!==undefined ? response.email: ''
                    };                    
                    
                    navigate('/signup',{
                        state:{
                            social_data:JSON.stringify(socialData)
                        }
                    });
                }
                else{
                    window.location.href = '/';
                }
            } catch (error) {
                console.log(error);
                setErr(error);
            }
        }
        else{
            setErr("User not found from Google account");
        }
    }

    const googleLoginBtnAction=useGoogleLogin({
        prompt: 'select_account',
        onSuccess:(response) =>{
            if(response.access_token!==undefined){
                const myHeaders = new Headers();
                myHeaders.append("Authorization", "Bearer "+response.access_token);

                const formdata = new FormData();
                formdata.append("access_token", response.access_token);

                const requestOptions = {
                    method: "POST",
                    headers: myHeaders,
                    body: formdata,
                    redirect: "follow"
                };

                fetch("https://www.googleapis.com/oauth2/v3/userinfo?alt=json", requestOptions)
                .then((response) => response.text())
                .then((result) =>{
                    var res=JSON.parse(result);
                    googleResponseHandler(res, response.access_token);
                })
                .catch((error) => {setErr(error); console.error(error)});
            }
        },
        onError: (error) => console.log('Login Failed:', error)
    })

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return(
        <>
        
            <HeaderBlank/>
            <div className='auth-page bg-no-repeat bg-center bg-cover'>
                <div className='auth-wrapper flex justify-between'>
                    <div className='image-col hidden md:relative md:flex md:justify-center md:flex-1 md:items-center'>
                        <div className='image-bgwrapper absolute w-full h-full'>
                            <img src={signinBG} alt="Sign In" className='w-full h-full absolute' width={791} height={948}/>
                        </div>
                        <div className='logo-short relative z-10'>
                            <img src={logoShort} alt="Plaisport" width={129} height={141} />
                        </div>
                    </div>
                    <div className='auth-form-col flex justify-center items-center'>
                        <div className='auth-form-wrapper bg-white rounded-xl pt-11 pb-7'>
                            <div className='auth-form-head'>
                                <img src={logoMedium} alt="Plaisport" className='mx-auto' width={126} height={47} />
                            </div>
                            <div className='auth-form-content text-center mb-[50px]'>
                                <h2>Welcome back!</h2>
                                <p>Login to your account</p>
                            </div>
                            <div className='auth-login-form'>
                            {!loading && errorMessage ? <p className='error text-center mb-2.5'>{errorMessage}</p> : null}
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className='auth-form-field-wrap mb-5 email-field-wrap relative'>
                                    <input 
                                        type="email" id="login-user" disabled={loading} placeholder="Email Address" className='email-field'
                                        {...register("email")}
                                        aria-invalid={errors.email ? "true" : "false"} 
                                    />                                
                                </div>
                                {errors.email && <p className="error mb-2.5" role="alert">{errors.email?.message}</p>}
                                <div className='auth-form-field-wrap mb-3 password-field-wrap relative'>
                                    <input 
                                        type={showPassword ? "text" : "password"} id="login-password" disabled={loading} placeholder="Password" className='password-field'
                                        {...register("password")} 
                                        aria-invalid={errors.password ? "true" : "false"}
                                    />
                                    <span className={`passwordTrigger bg-no-repeat bg-center bg-contain ${showPassword ? 'toggled' : ''}`} onClick={handleClickShowPassword} id="passwordTrigger"></span>
                                </div>
                                {errors.password && <p className="error mb-2.5" role="alert">{errors.password?.message}</p>}
                                <div className='auth-form-field-wrap flex justify-center md:justify-end mb-12'>
                                    <a href="/forgotpassword" className='auth-form-link'>Forgot Password</a>
                                </div>
                                <div className='auth-form-field-wrap flex justify-center mb-5 md:mb-10'>
                                    <input className='button button--large' type="submit" value={loading ? 'Loading' : 'Log in'} disabled={loading}/>
                                </div>
                                
                                <div className='pb-10 text-[20px] text-center leading-[20px]'>LOGIN WITH</div>
                                <div className='flex flex-row justify-center mb-5 gap-10'>
                                    <div>
                                        <button type="button" onClick={()=>googleLoginBtnAction()} 
                                            className='w-[50px] sm:w-[70px] p-3 sm:p-0 h-[51px] sm:h-[71px] flex justify-center items-center bg-[#363F6C] rounded-full cursor-pointer'>
                                            <img src={googleSigninIco} alt="Login with google" width={36} height={37} />
                                        </button>
                                    </div>
                                    <div>
                                        <LoginSocialApple
                                            client_id={process.env.REACT_APP_APPLE_ID || ''}
                                            onLoginStart={()=>{}}
                                            redirect_uri="https://login.plaisport.com/"
                                            scope='name email'
                                            onResolve={({ provider, data }) => {
                                                appleResponseHandle(data);
                                            }}
                                            onReject={(err) => {
                                                console.log(err)
                                            }}
                                        >
                                            <span className='w-[50px] sm:w-[70px] p-3 sm:p-0 h-[51px] sm:h-[71px] flex justify-center items-center bg-[#363F6C] rounded-full cursor-pointer'><img src={appleSignIco} alt="Login with Apple" width={32} height={40} /></span>
                                        </LoginSocialApple>
                                    </div>
                                    {/*
                                    <div>
                                        <LoginSocialFacebook
                                            appId={process.env.REACT_APP_FB_APP_ID || ''}
                                            onLoginStart={()=>{}}
                                            fields='id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender'
                                            onResolve={({ provider, data }) => {
                                                responseFacebook(data);
                                            }}
                                            onReject={(err) => {
                                                console.log(err)
                                            }}
                                        >
                                            <span className='w-[50px] sm:w-[70px] p-3 sm:p-0 h-[51px] sm:h-[71px] flex justify-center items-center bg-[#363F6C] rounded-full cursor-pointer'><img src={facebookSignIco} alt="Login with facebook" width={40} height={41} /></span>
                                        </LoginSocialFacebook>                                        
                                    </div>
                                    */}
                                </div>
                                
                                <div className='auth-form-field-wrap flex justify-center text-center'>
                                    <span>Don’t have an account? <a href="/signup" className='auth-form-link'>Sign Up</a></span>
                                </div>
                            </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}