import {useState, useEffect} from 'react';
import { useForm } from 'react-hook-form';
import { useFormData } from '../../contexts/FormContext';

const SignUpStepThree =({ formStep, nextFormStep }) => {
    const { setFormValues, sports, data } = useFormData();
    const [sportInfo, setSportInfo] = useState([]);
    const [q, setSearchQuery] = useState("");
    const [searchParam] = useState(["name"]);
    const { register, handleSubmit, formState: { errors } } = useForm({
        mode: "all"
    });
    
    const search=(items)=>{
        
        return items.filter((item) => {
            return searchParam.some((newItem) => {
                return (
                    item[newItem]
                        .toString()
                        .toLowerCase()
                        .indexOf(q.toLowerCase()) > -1
                );
            });
        });
    }

    const onSubmit = (values) => {
        setFormValues({sports:sportInfo});
        nextFormStep();
    };
    
    return (
        <div className={formStep === 2 ? '' : 'hidden'}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='auth-form-field-wrap justify-center mb-5 relative'>
                    <div className="search-bar !max-w-full flex justify-center mx-auto py-2.5 mb-10">
                        <span className="search-icon"></span>
                        <input placeholder="Search..." onChange={(e) => setSearchQuery(e.target.value)} className="search-input w-full focus:outline-none focus:ring-0" />
                    </div>
                    <div className='sports-btn-group'>
                        <div className='flex flex-wrap mb-8'>
                            {search(sports).map((sport,index)=>{
                                return(
                                    <div key={sport._id} className='w-1/2 px-[7.5px] mb-[10px]'>
                                        <label htmlFor={`id-${sport._id}`} className="block relative">
                                            <input type="checkbox" id={`id-${sport._id}`} value={sport._id} 
                                            onChange={(e) => {
                                                // add to list
                                                if (e.target.checked) {
                                                setSportInfo([
                                                    ...sportInfo,
                                                    sport._id,
                                                ]);
                                                } else {
                                                // remove from list
                                                setSportInfo(
                                                    sportInfo.filter((sp) => sp !== sport._id),
                                                );
                                                }
                                            }}
                                            checked={sportInfo.includes(sport._id)}
                                            />
                                            <span className="flex items-center">
                                                <img src={sport.image} width={20} alt="" className="mr-2.5" />{sport.name}
                                            </span>
                                        </label>
                                    </div>
                                )
                            })}
                        </div>                        
                    </div>
                    <div className='auth-form-field-wrap flex justify-center mb-0 !absolute bottom-0 left-0 w-full bg-gradient-to-b from-transparent to-white'>
                        <input className='button button--large' type="submit" value={'Continue'}/>
                    </div>
                </div>
                <div className='auth-form-field-wrap flex justify-center'>
                    <span>Already have an account? <a href="/signin" className='auth-form-link'>Login</a></span>
                </div>
            </form>
        </div>
    );
}

export default SignUpStepThree;