import { useState, useEffect, useContext, useCallback, useRef } from "react";
import { NavLink } from "react-router-dom";
import { TeamContext } from "../../../contexts/TeamContext";
import { getMembersForChat, getGroupsV2 } from "../../../libs/dataFunctions";
import Spinner from "../../../components/Spinner/Spinner";
import placeholderImg from '../../../assets/images/placeholder.png';
import PlaiImageCropper from "../../../components/ImageCropper/ImageCropper";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { withTimeout } from "../../../libs/utils";
import { generateRandomName } from "../../../components/ImageCropper/cropUtils";
import * as yup from 'yup';
import './chatpage.scss';

const ChatMemberRow = ({member, selectedMembers, setSelectedMembers}) =>{
    var selectedMemberArray = [...selectedMembers];
    const handleAddMember=()=>{
        if (selectedMemberArray.indexOf(member.sub) !== -1) {
            setSelectedMembers(selectedMembers.filter((item) => {return !item.includes(member.sub)}));
        }
        else{
            setSelectedMembers([...selectedMembers, member.sub]);
        }
    }
    
    return(
        <div className={`chat-member-row flex items-center flex-1 cursor-pointer ${selectedMemberArray.indexOf(member.sub) !== -1 ? 'selected' : ''}`} onClick={()=>handleAddMember()}>
            <div className='w-[24px] lg:w-[44px] mr-2.5'>
            {member.profilePicture !== '' ?  
                <img src={member.profilePicture} alt="" width={44} height={44} className="rounded-full w-[24px] lg:w-[44px] h-[24px] lg:h-[44px] cursor-pointer" />
            :
                <img src={placeholderImg} alt="" width={44} height={44} className="rounded-full w-[24px] lg:w-[44px] h-[24px] lg:h-[44px] cursor-pointer" />
            }
            </div>
            <div className='member-info flex-1'>
                <div className='pr-2.5'>{member.name}</div>
            </div>
            <div className="w-[20px] lg:w-[40px] lg:pl-2.5">
                {selectedMemberArray.indexOf(member.sub) !== -1 ?
                    <svg className="w-[18px] h-[17px] lg:w-[31px] lg:h-[30px]" width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="15.8486" cy="15" r="15" fill="white"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M23.8486 15C23.8486 15.5523 23.4009 16 22.8486 16L8.84863 16C8.29635 16 7.84863 15.5523 7.84863 15C7.84863 14.4477 8.29635 14 8.84863 14L22.8486 14C23.4009 14 23.8486 14.4477 23.8486 15Z" fill="#363F6C"/>
                    </svg>                
                :
                    <svg className="w-[18px] h-[17px] lg:w-[31px] lg:h-[30px]" width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="15.8486" cy="15" r="15" fill="#363F6C"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M16.001 6.60413C16.5533 6.60413 17.001 7.05184 17.001 7.60413V21.6041C17.001 22.1564 16.5533 22.6041 16.001 22.6041C15.4487 22.6041 15.001 22.1564 15.001 21.6041V7.60413C15.001 7.05184 15.4487 6.60413 16.001 6.60413Z" fill="#FBDB09"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M24.001 14.6041C24.001 15.1564 23.5533 15.6041 23.001 15.6041L9.00098 15.6041C8.44869 15.6041 8.00098 15.1564 8.00098 14.6041C8.00098 14.0518 8.44869 13.6041 9.00098 13.6041L23.001 13.6041C23.5533 13.6041 24.001 14.0518 24.001 14.6041Z" fill="#FBDB09"/>
                    </svg>
                }
            </div>
        </div>
    )
}

const schema = yup.object({
    group_name: yup.string().required('Group name is required.'),
}).required();

const ChatCreateGroupComponent = ({socket, setCurrentChatPage})=>{
    const { team, token, teamUpdating } = useContext(TeamContext);
    const [membersList, setMembersList] = useState([]);
    const [allMembersList, setAllMembersList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchParam] = useState(["name"]);
    const [q, setSearchQuery] = useState("");
    const [selectedMembers, setSelectedMembers] = useState([]);
    const [content, setContent] = useState('');
    const limitTextarea = 100;
    const [groupImage, setGroupImage] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [teamGroups, setTeamGroups] = useState([]);
    const [openFilterMenu,setFilterMenu] = useState(false);
    const ref = useRef();

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });

    const fetchMembers = async() =>{
        setLoading(true);
        let [membersListdata, teamGroupsData] = await Promise.all([
            getMembersForChat(token, team._id),
            getGroupsV2(team._id, token)
        ]);
        let tmpmemberlist = [];
        membersListdata.data.forEach((member)=>{
            let groups = [];
            member.groups_new.forEach((tgrp)=>{
                groups.push(tgrp._id);
            });
            member.groups=groups;
            tmpmemberlist.push(member);
        })
        setAllMembersList(tmpmemberlist);
        setMembersList(tmpmemberlist);
        if(teamGroupsData.length>0){
            let teamGroupsList = [];
            teamGroupsData.forEach(element => {
                teamGroupsList.push(element);
                if(element.nest_groups.length > 0){                    
                    element.nest_groups.forEach(celm =>{
                        teamGroupsList.push(celm);
                    });                    
                }                
            });
            setTeamGroups(teamGroupsList);
        }
        setLoading(false);
    }

    const onSubmit=async(data)=>{        
        if(selectedMembers.length<=0){
            setErrorMessage("Please select member for this group.");
            return;
        }
        setLoading(true);
        var formdata = new FormData();
        formdata.append("type", "create_group");
        formdata.append("team_id", team._id);
        formdata.append("user_sub_ids", selectedMembers.join('@#'));
        formdata.append("name", data.group_name);
        formdata.append("description", data.group_desc);
        formdata.append("user_sub_ids", selectedMembers.join('@#'));
        formdata.append('separate_by', '@#');

        if (groupImage !== '' && groupImage!==null) {
            var formdatas3 = new FormData();
            let tmpFilename = generateRandomName();
            const imgFileObj = new File([groupImage], tmpFilename+'.webp', { lastModified: new Date().getTime(), type: groupImage.type })
            formdatas3.append("files[]", imgFileObj);
            var requestOptionss3 = {
                method: 'PUT',
                body: formdatas3,
                redirect: 'follow'
            };
            const responsesApp = await fetch("https://dashboard.plaisport.com/upload_content", requestOptionss3);
            const resultsApp = await responsesApp.text();
            const rdatasApp = JSON.parse(resultsApp);
            formdata.append('content',rdatasApp.data[0]);
        }

        var secureHeader = new Headers();
        secureHeader.append('Authorization', 'Bearer ' + token);
        secureHeader.append('device_type', 'W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };
        await fetch(process.env.REACT_APP_APIURL + '/chat', requestOptions)
            .then(response => response.text())
            .then(result => {
                setLoading(false);
                let rdata = JSON.parse(result);
                if (rdata.status !== 1) {
                    setErrorMessage(rdata.message);
                }
                else {
                    socket.emit('create_group', rdata.conversation_details, withTimeout((response) => {
                        console.log("success!" + response);
                      }, (response) => {
                        console.log(response);
                      }, 1500));
                    setCurrentChatPage('');
                }
            })
            .catch(error => console.log('error', error));
    }

    const search=(items)=>{
        return items.filter((item) => {
            return searchParam.some((newItem) => {
                return (
                    item[newItem]?
                        item[newItem]
                        .toString()
                        .toLowerCase()
                        .indexOf(q.toLowerCase()) > -1
                    :
                    null
                );
            });
        });
    }

    const filterMembers=(group)=>{
        if(group==='all'){
            setMembersList(allMembersList);
        }
        else{
            let filteredList = allMembersList.filter((item) => {
                return (item.groups.includes(group));
            });  
            setMembersList(filteredList);
        }        
    }

    const setFormattedContent = useCallback(
        (text) => {
          setContent(text.slice(0, limitTextarea));
        },
        [limitTextarea, setContent]
    );

    useEffect(()=>{
        const checkIfClickedOutside = e => {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            if (ref.current && !ref.current.contains(e.target)) {
                setFilterMenu(false);
            }
        }
        document.addEventListener("mousedown", checkIfClickedOutside);      
        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    },[setFilterMenu]);

    useEffect(()=>{ 
        if(!teamUpdating){
            fetchMembers();
        }
    },[teamUpdating]);
    
    return(
        <>
        {!loading && !teamUpdating ?
            <>
                <div className='breadcrumb flex item-center pt-1.5 pb-1 pl-5 pr-7 w-full'>
                    <NavLink to="/" className='home'>
                        <span>Dashboard</span>
                    </NavLink>
                    <span className='separator'>&lt;</span>
                    <NavLink to={`/team/${team._id}`}>
                        <span>{team.name}</span>
                    </NavLink>
                    <span className='separator'>&lt;</span>
                    <NavLink onClick={()=>setCurrentChatPage('')}>
                        <span>Chat</span>
                    </NavLink>
                    <span className='separator'>&lt;</span>
                    Create Group
                </div>
                <div className="body px-5 lg:px-8 py-10">
                    <div className="ln-header flex justify-between items-center w-full flex-wrap pb-5 relative">
                        <h2 className="title mr-5 uppercase">Create Group</h2>
                        <div className="sep mr-4"></div>
                        <button className="button button--yellow button-medium" onClick={()=>setCurrentChatPage('')}>Back</button>
                    </div>
                    <div className="w-full rounded-xl bg-white px-3 lg:px-8 py-5 lg:py-10">
                        <div className="flex flex-col-reverse lg:flex-row">
                            <div className="lg:w-1/2 lg:pr-9">
                                <form onSubmit={handleSubmit(onSubmit)} className="form-wrapper">
                                    <div className="form-field-container mb-5">
                                        <div className='form-field-wrap no-field-wrap relative max-w-[300px] mx-auto'>
                                            <PlaiImageCropper 
                                                default_label={'Upload Photo'} 
                                                default_message={'Optional*<br/>Maximum upload file size: 2MB<br/>(1000 X 1000)'}
                                                onFinalChange={(newImage)=>{setGroupImage(newImage)}} 
                                                aspect={1 / 1}/>                            
                                        </div>
                                    </div>
                                    <div className="form-field-container mb-5">
                                        <div className="label !text-[#6E7079] !px-0">Group Name*</div>
                                        <div className='form-field-wrap no-field-wrap relative'>
                                            <input 
                                                type="text" 
                                                id="group-name" 
                                                disabled={loading} 
                                                placeholder="Group Name *" 
                                                className='text-field'
                                                {...register("group_name")}
                                                aria-invalid={errors.group_name ? "true" : "false"}
                                            />
                                        </div>
                                        {errors.group_name && <p className="error mb-2.5" role="alert">{errors.group_name?.message}</p>}
                                    </div>
                                    <div className="form-field-container mb-5">
                                        <div className="label !text-[#6E7079] !px-0">Group Description</div>
                                        <div className='form-field-wrap relative'>
                                            <textarea
                                                id="group-desc" 
                                                disabled={loading} 
                                                className='textarea-field'
                                                {...register("group_desc")}
                                                onChange={e => setFormattedContent(e.target.value)}
                                                value={content}
                                            />
                                            <p className="text-right text-[12px] leading-[12px]">
                                                {content.length}/{limitTextarea}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="w-full px-2.5">
                                        <div className='flex justify-center'>
                                            <input className='button' type="submit" value='Submit' disabled={loading}/>
                                        </div>
                                    </div>
                                    {errorMessage!=="" && <p className="text-red-500 mb-2.5 text-center" role="alert">{errorMessage}</p> }
                                </form>
                            </div>
                            <div className="lg:w-1/2 mb-5 lg:mb-0">
                                <div className="chat-members-list-wrap">                                    
                                    <div className="chat-members-list">
                                        <div className="ln-header flex justify-between items-center w-full flex-wrap pb-5 relative">
                                            <h2 className="title mr-5 uppercase">Add Member</h2>
                                            <div className="sep mr-4"></div>
                                            <button type="button" className="button button--yellow button-medium">Select All</button>                        
                                        </div>
                                        <div className="search-form-wrap flex mb-5 relative" ref={ref}>
                                            <label htmlFor="search-form" className="search-field relative mr-3.5 flex-1">
                                                <input
                                                    type="search"
                                                    name="search-field"
                                                    id="search-form"
                                                    className="search-input lg:!py-[11px]"
                                                    placeholder="Search Now"
                                                    value={q}
                                                    onChange={(e) => {
                                                        setSearchQuery(e.target.value);                                                            
                                                    }}
                                                />
                                            </label>
                                            {teamGroups.length > 0 &&
                                            <>
                                                <div>
                                                    <button type="button" className="w-[30px] lg:w-[40px] h-[30px] lg:h-[40px]" onClick={()=>setFilterMenu(!openFilterMenu)}>
                                                        <svg className="w-full h-full" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <circle cx="20" cy="20" r="20" fill="#363F6C"/>
                                                            <path d="M24.9938 21.7284H21.1152C20.5033 21.7284 20.0071 22.2246 20.0071 22.8365C20.0071 23.4483 20.5033 23.9446 21.1152 23.9446H24.9938C25.6057 23.9446 26.1019 23.4483 26.1019 22.8365C26.1019 22.2246 25.6068 21.7284 24.9938 21.7284ZM29.4262 12.8636H21.1152C20.5033 12.8636 20.0071 13.3599 20.0071 13.9717C20.0071 14.5836 20.5033 15.0798 21.1152 15.0798H29.4262C30.0381 15.0798 30.5343 14.5836 30.5343 13.9717C30.5343 13.3599 30.0391 12.8636 29.4262 12.8636ZM22.7777 26.1607H21.084C20.4722 26.1607 19.9759 26.657 19.9759 27.2688C19.9759 27.8807 20.4722 28.3769 21.084 28.3769H22.7777C23.3895 28.3769 23.8546 27.8807 23.8546 27.2688C23.8546 26.657 23.3906 26.1607 22.7777 26.1607ZM27.21 17.296H21.1152C20.5033 17.296 20.0071 17.7922 20.0071 18.4041C20.0071 19.016 20.5033 19.5122 21.1152 19.5122H27.21C27.8219 19.5122 28.3181 19.016 28.3181 18.4041C28.3181 17.7922 27.8229 17.296 27.21 17.296ZM16.6645 23.207L15.5426 24.4328V13.9728C15.5426 13.3599 15.0474 12.8636 14.4345 12.8636C13.8216 12.8636 13.3264 13.3599 13.3264 13.9728V24.4304L12.2044 23.207C11.9859 22.9686 11.6871 22.8475 11.3869 22.8475C11.1097 22.8473 10.8426 22.9513 10.6386 23.1389C10.1874 23.5527 10.1572 24.2546 10.5704 24.7065L13.5865 28.0339C14.0062 28.4931 14.8005 28.4931 15.2206 28.0339L18.2366 24.7065C18.6501 24.2546 18.6196 23.5531 18.1685 23.1389C17.7795 22.7257 17.08 22.7568 16.6645 23.207Z" fill="#FBDB09"/>
                                                        </svg>
                                                    </button>                                                
                                                </div>
                                                <div className={`chat-conversation-menu !w-[175px] max-h-[300px] overflow-y-auto ${openFilterMenu ? 'flex flex-col' : 'hidden'}`}>
                                                    <button className='chat-conversation-menu-item' onClick={()=>{filterMembers('all')}}>View All</button>
                                                    {teamGroups.map((tgroup)=>{
                                                        return(
                                                            <button key={tgroup._id} className='chat-conversation-menu-item' onClick={()=>{filterMembers(tgroup._id)}}>{tgroup.name}</button>
                                                        )
                                                    })}
                                                </div>
                                            </>
                                            }
                                        </div>
                                        {search(membersList).length>0 ?
                                        <>
                                            {search(membersList).map((member,index)=>{
                                                return(
                                                    <ChatMemberRow member={member} key={index} selectedMembers={selectedMembers} setSelectedMembers={setSelectedMembers} />
                                                )
                                            })}
                                        </>
                                        :
                                        <div>Member not found.</div>
                                        }
                                    </div>                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        :
            <div className="text-center py-5"><Spinner /></div>
        }
        </>
    )
}

export default ChatCreateGroupComponent;