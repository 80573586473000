import { useState, useEffect } from "react";
const StarRating = ({onChange ,ratingValue}) => {
    const [rating, setRating] = useState(0);
    const [hover, setHover] = useState(0);
    const changeRating = (index) =>{
      if(index!==ratingValue){
        setRating(index);
        onChange?.(index);
      }
    }
    useEffect(()=>{
      setRating(ratingValue);
    },[ratingValue]);
    
    return (
      <div className="star-rating">
        {[...Array(3)].map((star, index) => {
          index += 1;
          return (
            <button
              type="button"
              key={index}
              className={`startbtn ${index <= (hover || rating) ? "on" : "off"}`}
              onClick={() => changeRating(index)}
              onMouseEnter={() => setHover(index)}
              onMouseLeave={() => setHover(rating)}
            >
              <span className="star">&#9733;</span>
            </button>
          );
        })}
      </div>
    );
}

export default StarRating;