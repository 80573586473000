import { useContext, useState, useEffect, useRef } from "react";
import * as DOMPurify from 'dompurify';
import Spinner from "../../../components/Spinner/Spinner";
import { TeamContext } from "../../../contexts/TeamContext";
import { getDiscountsCatg, getDiscounts } from "../../../libs/dataFunctions";
import rgroups from '../../../assets/images/noemptyother.png';
import "./Discount.scss";
const TabDiscounts = () =>{
    const [loading, setLoading] = useState(false);
    const [q, setSearchQuery] = useState("");
    const [showSortingOpt, setShowSortingOpt] = useState(false);
    const [sortedBy, setSortedBy] = useState('all');
    const [filterCatg, setFilterCatg] = useState('all');
    const [errorMessage, setErrorMessage] = useState('');
    const [discountCatg, setDiscountCatgs] = useState([]);
    const [discounts, setDiscounts] = useState([]);
    const [discountsList, setDiscountsList] = useState([]);
    const [discountdetail,setCurrentDiscountDetails] = useState(null);
    const [searchParam] = useState(["name"]);

    const ref = useRef();

    const { team} = useContext(TeamContext);
    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
	? localStorage.getItem('auth_token')
	: '';

    const filterBy = (fby) =>{
        let filteredDiscounts;
        if(fby==='all'){
            filteredDiscounts = discounts;
        }
        else{
            filteredDiscounts = discounts.filter((item)=>(item.category_id===fby));            
        }
        
        setDiscountsList(filteredDiscounts);
        setCurrentDiscountDetails(filteredDiscounts[0]);
        setShowSortingOpt(false);
        setFilterCatg(fby);
    }

    const sortBy = (orderby) =>{
        let sorted_list;
        if(orderby==='highest-discount'){
            sorted_list = discountsList.sort((a,b) => {
                return parseInt(a.discount) - parseInt(b.discount);
            }).reverse();
        }
        if(orderby==='a-z'){
            sorted_list = discountsList.sort((a,b) => {
                return a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1;
            });
        }
        if(orderby==='newest'){
            sorted_list = discountsList.sort((a,b) => {
                return new Date(a.created_at).getTime() - 
                    new Date(b.created_at).getTime()
            }).reverse();
        }
        setDiscountsList(sorted_list);
        setShowSortingOpt(false);
        setSortedBy(orderby);
    }

    const search=(items)=>{
        let sres = items.filter((item) => {
            return searchParam.some((newItem) => {
                if(q!==''){
                    return (
                        item[newItem] &&
                            item[newItem]
                                .toString()
                                .toLowerCase()
                                .indexOf(q.toLowerCase()) > -1                        
                    );
                }
                else{
                    return item;
                }
            });
        });
        
        return sres;
    }

    async function refreshDiscounts() {
        setErrorMessage(null);
        setLoading(true);
        const [discountCatgData, discountData] = await Promise.all([
            getDiscountsCatg(token, team._id),
            getDiscounts(token, team._id)
        ]);

        if(discountData.status===1 && discountCatgData.status===1){            
            setDiscountCatgs(discountCatgData.data);
            setDiscounts(discountData.data);
            setDiscountsList(discountData.data);
            setCurrentDiscountDetails(discountData.data[0]);
        }
        else{
            setErrorMessage(discountData.message);
        }
        setLoading(false);
    }
    
    useEffect(()=>{
        refreshDiscounts();
    },[]);

    useEffect(()=>{
        const checkIfClickedOutside = e => {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            if (showSortingOpt && ref.current && !ref.current.contains(e.target)) {
                setShowSortingOpt(false);
            }
        }      
        document.addEventListener("mousedown", checkIfClickedOutside);      
        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    },[showSortingOpt]);
    
    return(
        !loading ?
        <>
            <div className="tab-actionbar px-2.5 sm:px-7.5 py-[18px] flex flex-col md:flex-row justify-between items-center">
                <div className="title-col max-w-[320px] md:max-w-full mx-auto md:mx-0 mb-2.5 md:mb-0">Discounts</div>
                <div className="cta-col max-w-[320px] md:max-w-full mx-auto md:mx-0 flex justify-center md:justify-end flex-wrap items-center relative" ref={ref}>
                    <label htmlFor="search-form" className="search-field relative mr-auto ml-auto md:mr-3.5 mb-2.5 md:mb-0">
                        <input
                            type="search"
                            name="search-field"
                            id="search-form"
                            className="search-input"
                            placeholder="Search"
                            value={q}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />                        
                    </label>
                    <button type="button" className={`button-icon button-icon-sort`} onClick={(e)=>{setShowSortingOpt(!showSortingOpt)}}><span>Sort</span></button>
                    <ul className={`sort-actions absolute rounded-lg bg-white overflow-hidden top-full right-0 ${!showSortingOpt ? 'hidden' : ''}`}>
                        <li className={sortedBy==='highest-discount' ? 'active' : ''}><div className='cursor-pointer' onClick={()=>{sortBy('highest-discount')}}>Highest Discount</div></li>
                        <li className={sortedBy==='a-z' ? 'active' : ''}><div className='cursor-pointer' onClick={()=>{sortBy('a-z')}}>A - Z</div></li>
                        <li className={sortedBy==='newest' ? 'active' : ''}><div className='cursor-pointer' onClick={()=>{sortBy('newest')}}>Newest First</div></li>
                    </ul>                    
                </div>
            </div>
            <div className="rounded-xl bg-white">
                {discountCatg.length > 0 &&
                    <ul className="tabs-menu flex">
                        <li onClick={()=>{filterBy('all')}} className={filterCatg==='all' ? 'active' :''}>All</li>
                        {discountCatg.map((dcatg)=>{
                            return(
                                <li key={dcatg._id} className={`uppercase ${filterCatg===dcatg._id ? 'active' :''}`} onClick={()=>{filterBy(dcatg._id)}}>{dcatg.name}</li>
                            )
                        })}                            
                    </ul>
                }
                <div className="tabs-content pr-2.5 sm:pr-[30px]">
                    {search(discountsList).length > 0 ?
                    <div className="flex mt-10">
                        <div className="pr-[10px] w-[429px]">
                                {search(discountsList).map((ddata)=>{
                                    return(
                                    <div className={`h-[195px] w-[419px] discount-coupon flex items-center pt-[25px] px-7.5 pb-[35px] cursor-pointer ${discountdetail!==null && discountdetail._id===ddata._id ? 'active' : ''}`} key={ddata._id} onClick={()=>{setCurrentDiscountDetails(ddata)}}>
                                        <div className="w-[200px] col-1">
                                            <div className="text-[#FBDB03] text-[30px] leading-[45px] font-bold">{ddata.discount}% Off</div>
                                            <div className="text-[#363F6C] text-[16px] leading-[24px] font-bold truncate overflow-hidden">{ddata.name}</div>
                                        </div>
                                        <div className="line"></div>
                                        <div className="flex-1 col-2">
                                            <div className="image-wrapper text-center">
                                                <img src={ddata.image} alt="" className="max-w-[100px] mx-auto" />
                                            </div>
                                        </div>
                                    </div>
                                    )
                                })}
                        </div>
                        {discountdetail!==null &&
                            <div className="flex-1">
                                {discountdetail.image && discountdetail.image!=="" &&
                                    <div className="image-wrapper text-center pt-10">
                                        <img src={discountdetail.image} alt="" className="max-w-[200px] mx-auto" />
                                    </div>
                                }
                                <div className="ln-header flex justify-between items-center w-full pt-5 mb-10 relative">
                                    <h2 className="title mr-2.5">{discountdetail.name}</h2>
                                    <div className='sep mr-2.5'></div>
                                    <div>
                                        <a href={discountdetail.redirect_url} className='block button button-blue' target="_blank" rel="noreferrer">{discountdetail.discount}% Off</a>
                                    </div>                                
                                </div>
                                { discountdetail.description !== undefined ?
                                    <div className='mb-8' dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(discountdetail.description)}} />
                                : null}
                                <div className="py-5 text-center">
                                    <a href={discountdetail.redirect_url} className='button button--large' target="_blank" rel="noreferrer">Let’s go</a>
                                </div>
                            </div>
                        }
                    </div>
                    :
                    <>
                        <div className="empty text-center">
                            <div className="image"><img src={rgroups} alt="Plaisport" width={228} /></div>
                            <div className="title mb-5">No Discounts Yet?</div>                                                       
                        </div>
                    </>
                    }
                </div>
            </div>
        </>
        :
        <>
            <div className="py-5 text-center"><Spinner /></div>
        </>
    )
}
export default TabDiscounts;