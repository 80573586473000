import { createSlice } from "@reduxjs/toolkit";
const initialState = [];
const conversationListSlice = createSlice({
    // The name of the slice
    name : 'conversationList',
    // The initial state of the slice
    initialState,
    // The reducers of the slice
    reducers : {
        fetchConversationListAction : (state,action) => {
            return action.payload;
        },
        addConversationListAction : (state,action) => {
            return [ 
                action.payload,
                ...state
            ]
        },
        updateConversationPinAction : (state,action) => {
            const newState = state.map(obj => {
                if (obj.conversation_id === action.payload.conversation.conversation_id) {
                  return {...obj, is_pin: action.payload.flag};
                }          
                return obj;
            }).sort((a, b) => new Date(a.last_message_date) - new Date(b.last_message_date)).sort((a, b) => a.is_pin < b.is_pin ? 1 : -1);

            return newState;
        },
        leaveConversationAction : (state,action) => {
            const newState = state.map(obj => {
                if (obj.conversation_id === action.payload.conversation_id) {
                    return {...obj, is_leave_from_group: true};
                }          
                return obj;
            });

            return newState;
        },
        updateConversationAction:(state,action)=>{
            const newState = state.map(obj => {
                if (obj.conversation_id === action.payload.conversation_id) {
                    return action.payload;
                }          
                return obj;
            });
            return newState.sort((a, b) => new Date(a.last_message_date) - new Date(b.last_message_date)).sort((a, b) => a.is_pin < b.is_pin ? 1 : -1);
        },
        updateConversationLastReadAction:(state,action)=>{
            const newState = state.map(obj => {
                if (obj.conversation_id === action.payload.conversation_id) {
                    return {...obj, last_message_read:true};
                }          
                return obj;
            });
            return newState;
        },
        deleteConversationAction : (state,action) => {
            const newState = state.filter(obj => {
                return obj.conversation_id !== action.payload;
            });
            return newState;
        }
    }
});

// Export the actions of the slice
export const {fetchConversationListAction, addConversationListAction , deleteConversationAction, leaveConversationAction, updateConversationPinAction, updateConversationAction, updateConversationLastReadAction } = conversationListSlice.actions;
// Export the reducer of the slice
export default conversationListSlice.reducer;