import Payment from 'payment';
import moment from "moment";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(tz);

const truncate = (str, max, suffix) => str.length < max ? str : `${str.substr(0, str.substr(0, max - suffix.length).lastIndexOf(' '))}${suffix}`;
export default truncate;

function clearNumber(value = '') {
    return value.replace(/\D+/g, '')
}

export function getIsUser(value) {
    if (!value) {
        return null;
    }
    const issuer = Payment.fns.cardType(value);
    return issuer;
}
export function formatCreditCardNumber(value) {
    if (!value) {
        return value
    }

    const issuer = Payment.fns.cardType(value)
    const clearValue = clearNumber(value)
    let nextValue

    switch (issuer) {
        case 'amex':
            nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 10)} ${clearValue.slice(10, 15)}`
            break
        case 'dinersclub':
            nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 10)} ${clearValue.slice(10, 14)}`
            break
        default:
            nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 8)} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`
            break
    }
    return nextValue.trim();
}

export function formatCVC(value, allValues = {}) {
    const clearValue = clearNumber(value)
    let maxLength = 4;
    if (allValues.number) {
        const issuer = Payment.fns.cardType(allValues.number);
        switch (issuer) {
            case 'amex':
                maxLength = 4;
                break;
            default:
                maxLength = 3
                break;
        }
    }
    return clearValue.slice(0, maxLength)
}

export function formatExpirationDate(value) {
    const clearValue = clearNumber(value)

    if (clearValue.length >= 3) {
        return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`
    }
    return clearValue;
}

export const calculate_age = (dob1) => {
    var today = new Date();
    var birthDate = new Date(dob1);  // create a date object directly from `dob1` argument
    var age_now = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age_now--;
    }
    return age_now;
}

export function daysInMonth(month, year) {
    return moment(`${year}-${month}-01`).daysInMonth();
}

export const years = function (startYear) {
    const currentYear = new Date().getFullYear(), years = [];
    startYear = startYear || 1980;
    while (startYear <= currentYear) {
        years.push(startYear++);
    }
    return years;
}

export function TimeSlotList(){
    var x = 1; //minutes interval
    var timeslist = []; // time array
    var tt = 0; // start time
    
    //loop to increment the time and push results in array
    for (var i=0;tt<24*60; i++) {
        var hh = Math.floor(tt/60); // getting hours of day in 0-24 format
        var mm = (tt%60); // getting minutes of the hour in 0-55 format
        timeslist[i] = ("0" + (hh % 24)).slice(-2) + ':' + ("0" + mm).slice(-2); // pushing data in array in [00:00 - 12:00 AM/PM format]
        tt = tt + x;
    }
    return timeslist;
}

export function toCurrentOffset(date) {
    // initialize
    let tmpDate = date+' 12:00:00';
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();    
    let result = dayjs(tmpDate).tz(timeZone);

    // remove date offset
    //result = result.subtract(result.utcOffset(), 'minutes')

    // add current offset of today
    //result = result.add(dayjs().utcOffset(), 'minutes')

    return result.toDate()
}

export const withTimeout = (onSuccess, onTimeout, timeout) => {
    let called = false;
  
    const timer = setTimeout(() => {
      if (called) return;
      called = true;
      onTimeout();
    }, timeout);
  
    return (...args) => {
      if (called) return;
      called = true;
      clearTimeout(timer);
      onSuccess.apply(this, args);
    }
  }