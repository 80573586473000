import { useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Spinner from "../../../components/Spinner/Spinner";

const schema = yup.object({
    firstName: yup.string().required('Enter First Name.'),
    lastName: yup.string().required('Enter Last Name.'),
    email: yup.string().email().required('Email address is required.'),
}).required();

const RequestGiftPopup = ({setShowForm, product, teamID, token, size, color, sleeve, gender, shirtName, shirtNumber})=>{
    const [loading, setLoading]=useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });
    const onSubmit=async(data)=>{
        setErrorMessage(null);
        setLoading(true);
        var formdata=new FormData();
        formdata.append("type", "request_gift");
        formdata.append("team_id", teamID);
        formdata.append("product_id", product._id);
        
        if(product.size!==undefined && product.size.length > 0 && size!==''){
            formdata.append('size',size);
        }
        else{
            formdata.append('size',"");
        }
        if(product.sleeve!==undefined && product.sleeve.length > 0 && sleeve!==''){
            formdata.append('sleeve',sleeve);
        }
        else{
            formdata.append('sleeve',"");
        }
        if(product.color!==undefined && product.color.length > 0 && color!==''){
            formdata.append('color',color);
        }
        else{
            formdata.append('color',"");
        }
        if(product.gender!==undefined && product.gender.length > 0 && gender!==''){
            formdata.append('gender',gender);
        }
        else{
            formdata.append('gender',"");
        }
        if(product.is_personalize){
            if(shirtNumber!==''){
                formdata.append('shirt_no', shirtNumber);
            }
            if(shirtName!==''){
                formdata.append('shirt_name', shirtName);
            }
        }
        formdata.append('firstName',data.firstName);
        formdata.append('lastName',data.lastName);
        formdata.append('email',data.email);
        formdata.append('phoneNumber',data.phone);

        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };
        await fetch(process.env.REACT_APP_APIURL + '/app_shop' , requestOptions)
        .then(response => response.text())
        .then(result => {
            setLoading(false);
            let rdata = JSON.parse(result);
            if(rdata.status===1){
                setShowForm(false);
            }
            else{
                setErrorMessage(rdata.message);
            }
        })
        .catch(error => setErrorMessage(error));
    };
    return(
        <>
        <div className="popup-form">
            <div className="popup-form-wrap popup-form-memberdetails !max-w-[670px] relative !px-2.5 sm:!px-8">                    
                <button type="button" className="close-popup" onClick={(e)=>{setShowForm(false);}}><span>close</span></button>
                <div className="form-heading text-center mb-[30px] uppercase">Request As A Gift</div>
                <div className="relative">
                {!loading ?
                <>
                    <form onSubmit={handleSubmit(onSubmit)} className="form-wrapper ads-form-wrapper">                
                        <div className="flex flex-wrap">
                            <div className="w-full">
                                <div className='form-field-wrap mb-5 user-field-wrap relative'>
                                    <input 
                                        type="text" disabled={loading} placeholder="First name*" className='text-field'
                                        {...register("firstName")}
                                        aria-invalid={errors.firstName ? "true" : "false"} 
                                    />                                
                                </div>
                                {errors.firstName && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.firstName?.message}</p>}
                                <div className='form-field-wrap mb-5 user-field-wrap relative'>
                                    <input 
                                        type="text" disabled={loading} placeholder="Last name*" className='text-field'
                                        {...register("lastName")}
                                        aria-invalid={errors.lastName ? "true" : "false"} 
                                    />                                
                                </div>
                                {errors.lastName && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.lastName?.message}</p>}
                                <div className='form-field-wrap mb-5 email-field-wrap relative'>
                                    <input 
                                        type="text" disabled={loading} placeholder="Email*" className='text-field'
                                        {...register("email")}
                                        aria-invalid={errors.email ? "true" : "false"} 
                                    />                                
                                </div>
                                {errors.email && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.email?.message}</p>}
                                <div className='form-field-wrap mb-5 phone-field-wrap relative'>
                                    <input 
                                        type="text" disabled={loading} placeholder="Mobile Number" className='text-field'
                                        {...register("phone")}
                                        aria-invalid={errors.phone ? "true" : "false"} 
                                    />                                
                                </div>
                                {errors.phone && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.phone?.message}</p>}
                                
                            </div>                            
                        </div>
                        {!loading && errorMessage!==null ? <p className='w-full error text-center mb-2.5'>{errorMessage}</p> : null}
                        <div className="w-full px-2.5">
                            <div className='flex justify-center'>
                                <input className='button button--large' type="submit" value="Submit" />
                            </div>
                        </div>                        
                    </form>
                </>
                :
                <>
                    <div className="text-center py-5"><Spinner /></div>
                </>
                }
                </div>
            </div>
        </div>
        </>
    )
}
export default RequestGiftPopup;