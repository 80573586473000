import { useState, useEffect } from "react";
import { useForm, Controller } from 'react-hook-form';
import Select, {components} from "react-select";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { getTeams, getProfile } from "../../../libs/dataFunctions";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import 'filepond/dist/filepond.min.css';
import DownArrowSvg from "../../../assets/images/icon-down.svg";
import Spinner from "../../../components/Spinner/Spinner";

registerPlugin(FilePondPluginFileValidateType);

const schema = yup.object().shape({
    name: yup.string().required('Document name is required.'),
    teams: yup
    .array()
    .min(1, "Select at least one team")
    .nullable()
    .required("Select at least one team")
});

const AddDocumentsForm = ({setShowForm,refreshMyDocs}) =>{
    const [loading,setLoading] = useState(false);
    const [teams,setTeams] = useState([]);
    const [userList,setUserList] = useState([]);
    const [errorMessage,setErrorMessage] = useState('');
    const [selectedTeams, setSelectedTeams] = useState([]);
    const [selectedTeamsOut, setSelectedTeamsOut] = useState(selectedTeams);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [selectedUsersOut, setSelectedUsersOut] = useState(selectedUsers);
    const [pdfFiles, setPdfFile] = useState([]);
    const { register, handleSubmit, setValue, control, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });
    const [userDetail,setUserDetail] = useState([]);

    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
    ? localStorage.getItem('auth_token')
    : '';
    const DropdownIndicator = (props) => {
        return (
          <components.DropdownIndicator {...props}>
            <img src={DownArrowSvg} width={24} height={24} alt="" />
          </components.DropdownIndicator>
        );
    };

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            const base64String = reader.result
                    .replace('data:', '')
                    .replace(/^.+,/, '');
            resolve(base64String);
        }
        reader.onerror = error => reject(error);
    });

    const onSubmit = async(data) => {
        setErrorMessage('');
        if(pdfFiles.length > 0){
            setLoading(true);
            var formdata = new FormData();
            formdata.append("type", "add_document");
            formdata.append("teams_id", data.teams.join('@#'));
            if(data.user_sub_ids !== undefined && data.user_sub_ids.length>0){
                formdata.append('user_sub_ids', data.user_sub_ids.join('@#'));
            }
            formdata.append("name", data.name);
            let pdfcodes = [];
            let exts = [];
            for(let i=0; i<pdfFiles.length; i++){
                pdfcodes[i] = await toBase64(pdfFiles[i].file);
                exts[i] = pdfFiles[i].fileExtension;
            };
            formdata.append("document_content", pdfcodes.join('@#'));
            formdata.append("document_extension", exts.join('@#'));            
            formdata.append('separate_by','@#');

            var secureHeader = new Headers();
            secureHeader.append('Authorization','Bearer ' + token);
            secureHeader.append('device_type','W');
            const requestOptions = {
                method: 'POST',
                redirect: 'follow',
                body: formdata,
                headers: secureHeader
            };

            await fetch(process.env.REACT_APP_APIURL + '/app_documents' , requestOptions)
            .then(response => response.text())
            .then(result => {
                setLoading(false);
                let rdata = JSON.parse(result);
                if(rdata.status !== 1){
                    setErrorMessage(rdata.message);
                }
                else{
                    setShowForm(false);
                    refreshMyDocs();
                }
            })
            .catch(error => console.log('error', error));
        }
        else{
            setErrorMessage('Upload minimum one pdf file');
        }
    }

    async function refreshTeam(){
        setLoading(true);
        const [teamsData, profileData] = await Promise.all([
          getTeams(token),
          getProfile(token)
        ]);
        let teamsOptions = [];
        if(teamsData.status===1){
            teamsData.data.forEach(element => {
                teamsOptions.push({'value':element._id, 'label':element.name});
            });
        }
        setTeams(teamsOptions);
        setUserDetail(profileData);
        let chOptions = [];
        chOptions.push({'value':profileData.sub, 'label':profileData.firstName+' '+profileData.lastName});
        if(profileData.childrens.length>0){
            profileData.childrens.forEach(element => {
                chOptions.push({'value':element.sub, 'label':element.name});
            });            
        }
        setUserList( chOptions );
        setLoading(false);
    }
    function handleSelectChangeUser(event) {
        const current = [...event];
        var current_child = current.map((child) => {
            return child.value
        });
        setSelectedUsers(current_child);
        setSelectedUsersOut(event);        
    }

    function handleSelectChange(event) {
        const current = [...event];
        var current_teams = current.map((team) => {
            return team.value
        });    
        setSelectedTeams(current_teams);
        setSelectedTeamsOut(event);
    }

    useEffect(()=>{
        refreshTeam();
    },[]);

    return(
        !loading ?
        <>
        <button type="button" className="close-popup" onClick={(e)=>{setShowForm(false)}}><span>close</span></button>
        <div className="bg-[#363F6C] py-7.5 px-5 rounded-xl">
            <div>
                <div className="form-heading text-center mb-[30px] !text-white">Upload Documents</div>
                <form onSubmit={handleSubmit(onSubmit)} className="form-wrapper">
                    <div className="flex flex-col">
                        <div className="form-field-container mb-12">
                            <div className="label">Document Name*</div>
                            <div className='form-field-wrap no-field-wrap relative'>
                                <input 
                                    type="text" id="event-name" disabled={loading} placeholder="Document Name *" className='text-field'
                                    {...register("name")}
                                    aria-invalid={errors.name ? "true" : "false"} 
                                />                    
                            </div>
                            {errors.name && <p className="error mb-2.5" role="alert">{errors.name?.message}</p>}
                        </div>
                        <div className="form-field-container mb-12">
                            <div className="flex justify-between">
                                <div className="label">Select Users</div>
                                <p className="mb-1 font-medium text-[12px] leading-[18px] text-white">
                                    {selectedUsersOut.length} SELECTED
                                </p>
                            </div>
                            <div className='form-field-wrap select-field-wrap groups-field-wrap relative'>                            
                                <Controller
                                    control={control}
                                    name='user_sub_ids'
                                    render={({ field: { onChange, value, ref }}) => (
                                        <Select
                                            inputRef={ref}
                                            value={selectedUsersOut}
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            components={{ DropdownIndicator }}
                                            placeholder={<div>Select Users</div>}
                                            onChange={val => {
                                                onChange(val.map(c => c.value));
                                                handleSelectChangeUser(val);
                                            }}
                                            options={userList}
                                            isMulti
                                            styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    borderWidth: 0,
                                                    backgroundColor: '#EFF1F9',
                                                    padding: '6px 7px 6px 46px'
                                                }),
                                                menu: (baseStyles) => ({
                                                    ...baseStyles,
                                                    zIndex:1000,
                                                    backgroundColor: '#ffffff'
                                                })
                                            }}
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 8,                                                
                                            })}
                                        />
                                    )}
                                />                            
                            </div>
                        </div>
                        <div className="form-field-container mb-12">
                            <div className="flex justify-between">
                                <div className="label">Select Multiple Teams*</div>
                                <p className="mb-1 font-medium text-[12px] leading-[18px] text-white">
                                    {selectedTeamsOut.length} SELECTED
                                </p>
                            </div>
                            <div className='form-field-wrap select-field-wrap groups-field-wrap relative'>                            
                                <Controller
                                    control={control}
                                    name='teams'
                                    render={({ field: { onChange, value, ref }}) => (
                                        <Select
                                            inputRef={ref}
                                            value={selectedTeamsOut}
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            components={{ DropdownIndicator }}
                                            placeholder={<div>Select Multiple Teams*</div>}
                                            onChange={val => {
                                                onChange(val.map(c => c.value));
                                                handleSelectChange(val);
                                            }}
                                            options={teams}
                                            isMulti
                                            styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    borderWidth: 0,
                                                    backgroundColor: '#EFF1F9',
                                                    padding: '6px 7px 6px 46px'
                                                }),
                                                menu: (baseStyles) => ({
                                                    ...baseStyles,
                                                    zIndex:1000,
                                                    backgroundColor: '#ffffff'
                                                })
                                            }}
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 8,                                                
                                            })}
                                        />
                                    )}
                                />                                
                            </div>
                            {errors.teams && <p className="error mb-2.5" role="alert">{errors.teams?.message}</p>}
                        </div>   
                        <div className="form-field-container mb-5">                                     
                            <div className='form-field-wrap relative'>
                                <FilePond
                                    files={pdfFiles}
                                    onupdatefiles={setPdfFile}                                    
                                    allowMultiple={true}
                                    credits={false}
                                    allowFileTypeValidation={true}
                                    acceptedFileTypes={['application/pdf','image/jpeg', 'image/png']}
                                    labelFileTypeNotAllowed='File of invalid type'
                                    //maxFiles={1}                                    
                                    labelIdle='
                                        <div class="upload-text">
                                            <h4>Upload PDF, PNG or JPEG files</h4>
                                            <p class="text-center">Maximum upload file size: 2MB</p>
                                        </div>'
                                    {...register("pdfdocuments")}
                                />
                            </div>
                        </div>
                        {!loading && errorMessage ?                
                            <div className='flex justify-center mb-10'>
                                <p className='error text-center mb-2.5 text-[#ff0000]'>{errorMessage}</p>
                            </div>                
                            : null
                        }
                        <div className="w-full px-2.5 pt-5">
                            <div className='flex justify-center mb-10'>
                                <input className='button' type="submit" value='Upload' disabled={loading}/>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        </>
        :
        <>
            <div className="py-5 text-center"><Spinner /></div>
        </>
    );
}
export default AddDocumentsForm;