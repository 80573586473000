import { useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const schema = yup.object({
    live_stream_link: yup.string().required('Enter Live Stream URL.'),
}).required();

const LiveStreamFormPopup=({setShowForm, teamID, eventID, token, refresh})=>{
    const [loading,setLoading] = useState(false);
    const [errorMessage,setErrorMessage] = useState(false);

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });

    const onSubmit = async(data) => {
        setLoading(true);
        var formdata = new FormData();
        formdata.append("type", "add_live_stream_link");
        formdata.append("team_id", teamID);
        formdata.append("event_id", eventID);
        formdata.append("live_stream_link", data.live_stream_link);
        
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        await fetch(process.env.REACT_APP_APIURL + '/app_event' , requestOptions)
        .then(response => response.text())
        .then(result => {
            setLoading(false);
            let rdata = JSON.parse(result);
            if(rdata.status !== 1){
                setErrorMessage(rdata.message);
            }
            else{
                setShowForm(false);
                refresh();
            }
        })
        .catch(error => console.log('error', error));
    }
    return (
        <>
        {!loading ?
        <>
            <button type="button" className="close-popup" onClick={(e)=>{setShowForm(false)}}><span>close</span></button>
            <div className="form-heading text-center mb-[30px] !text-white">Live Stream Link</div>
            {errorMessage ? <p className='error text-center mb-2.5 text-white text-base'>{errorMessage}</p> : null}
            <form onSubmit={handleSubmit(onSubmit)} className="form-wrapper link-form-wrapper">
                <div className="w-full mb-5">
                    <div className="form-field-container">
                        <div className="label">Add Live Stream Link*</div>
                        <div className='form-field-wrap no-field-wrap relative mb-2.5'>
                            <input 
                                type="text" disabled={loading} placeholder="http://" className='text-field'
                                {...register("live_stream_link")}
                                aria-invalid={errors.live_stream_link ? "true" : "false"} 
                            />                        
                        </div>
                        {errors.live_stream_link && <p className="error mb-2.5" role="alert">{errors.live_stream_link?.message}</p>}
                    </div>
                </div>
                <div className="w-full px-2.5">
                    <div className='flex justify-center'>
                        <input className='button' type="submit" value="Submit" disabled={loading}/>
                    </div>
                </div>
            </form>
        </>
        :
        <></>
        }
        </>
    )
}

export default LiveStreamFormPopup;