import dayjs from "dayjs";
import placeholderImg from '../../../../assets/images/placeholder.png';

const TeamMembershipCardBlock=({cardDetail})=>{
    return(<>
        <div className="membership-card rounded-[30px] bg-white shadow-[0_12px_40px_0_rgba(54,63,108,0.1)] p-5 md:p-[36px]">
            <div className="flex w-[208px] mx-auto mb-4">
                {cardDetail.logo !=="" ?
                    <img src={cardDetail.logo} alt={cardDetail.team_name} className="border border-[#363F6C] rounded-full w-[41px] h-[41px] mr-5" />
                :
                    <img src={placeholderImg} alt={cardDetail.team_name} className="border border-[#363F6C] rounded-full w-[41px] h-[41px] mr-5" />                    
                }
                <svg className="mr-[25px]" width="20" height="34" viewBox="0 0 20 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.673087 33.1223H3.16619L19.4591 0.606634H16.966L0.673087 33.1223Z" fill="#363F6C"/>
                </svg>
                <svg width="102" height="36" viewBox="0 0 102 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M68.4372 11.8845C68.3304 12.12 68.254 12.2708 68.1911 12.4278C65.6547 18.6763 63.1192 24.9249 60.5828 31.1724C60.276 31.9272 60.0175 32.7081 59.6427 33.4283C59.1297 34.4113 58.3268 35.0949 57.1931 35.2823C55.8385 35.5063 54.7121 35.0269 53.8307 34.024C52.9848 33.063 52.8288 31.8874 53.2821 30.7432C55.8291 24.3125 58.4263 17.9006 61.0015 11.4814C61.9981 8.99835 62.9538 6.49851 63.9912 4.03217C64.344 3.19365 64.7816 2.34362 65.3689 1.66108C67.3244 -0.611595 71.0469 -0.0285082 72.188 2.74246C75.14 9.9091 78.0366 17.0988 80.9479 24.2832C81.8346 26.4732 82.6741 28.682 83.5744 30.8657C84.3627 32.7772 82.5506 35.5744 80.0665 35.343C78.737 35.2195 77.6671 34.6071 77.1531 33.3634C74.8323 27.7409 72.5565 22.0995 70.2691 16.4633C69.663 14.9695 69.0705 13.4694 68.4361 11.8845H68.4372Z" fill="#FBDB03"/>
                    <path d="M7.39258 23.0606C7.39258 23.3401 7.39258 23.5463 7.39258 23.7526C7.39258 26.4324 7.35071 29.1134 7.4041 31.7933C7.45539 34.3926 4.86029 35.7294 3.03984 35.2573C1.67163 34.9024 0.526389 33.6137 0.415424 32.1524C0.380879 31.7022 0.377738 31.2479 0.377738 30.7957C0.376691 22.0776 0.376691 13.3575 0.377738 4.63839C0.377738 4.29084 0.382972 3.94224 0.40705 3.59469C0.533717 1.81298 1.77945 0.49606 3.56326 0.337988C4.49808 0.255288 5.44337 0.272037 6.38343 0.274131C8.70008 0.278318 11.0282 0.16526 13.3323 0.343222C18.0232 0.70438 22.0786 4.24164 23.2574 8.79851C24.7261 14.4765 21.5898 20.4728 16.1734 22.3362C14.5226 22.9036 12.811 23.0606 11.0785 23.0606C9.87881 23.0606 8.67914 23.0606 7.39258 23.0606ZM7.39258 16.0039C8.9733 16.0039 10.4692 15.9599 11.961 16.0143C14.1101 16.0929 15.5087 15.0743 16.2813 13.1533C17.4254 10.308 15.4302 7.8574 13.1868 7.48368C11.4878 7.20104 9.71865 7.34132 7.98195 7.27118C7.52972 7.25338 7.37897 7.39366 7.38316 7.85845C7.40305 10.396 7.39258 12.9346 7.39258 15.4721C7.39258 15.626 7.39258 15.7799 7.39258 16.0039Z" fill="#363F6C"/>
                    <path d="M36.364 28.3472C38.7434 28.3472 41.0412 28.3461 43.3401 28.3482C43.6709 28.3482 44.0059 28.3336 44.3314 28.3796C45.7687 28.5817 47.0511 29.8494 47.2751 31.2626C47.5243 32.8371 46.8219 34.2524 45.3605 34.9098C44.8077 35.1579 44.166 35.3201 43.5631 35.3275C40.0426 35.3662 36.521 35.3495 33.0005 35.3442C31.4135 35.3421 30.2348 34.6408 29.637 33.1626C29.4161 32.6172 29.3606 31.9745 29.3596 31.3757C29.3429 22.2536 29.3554 13.1304 29.3418 4.00827C29.3387 2.32705 30.1091 1.20798 31.6124 0.536961C32.9639 -0.0660164 34.6796 0.372608 35.5391 1.5817C36.0719 2.33124 36.3734 3.15719 36.3724 4.10981C36.3577 11.9443 36.364 19.7778 36.364 27.6123C36.364 27.8353 36.364 28.0593 36.364 28.3472Z" fill="#363F6C"/>
                    <path d="M96.6628 17.7951C96.6628 22.2327 96.667 26.6702 96.6576 31.1077C96.6565 31.6092 96.6346 32.1221 96.5309 32.6099C96.1635 34.3393 94.5849 35.4741 92.7927 35.338C91.1512 35.2134 89.7631 33.8002 89.6344 32.1033C89.5967 31.6008 89.5946 31.0952 89.5946 30.5906C89.5925 22.0463 89.5862 13.501 89.604 4.95675C89.6051 4.19884 89.6647 3.41686 89.8605 2.68931C90.275 1.14941 91.4621 0.448034 92.9549 0.306712C94.3221 0.176904 95.3794 0.788256 96.085 1.97955C96.4953 2.67256 96.6607 3.41686 96.6586 4.22083C96.6492 8.74525 96.6545 13.2697 96.6545 17.7951C96.6576 17.7951 96.6607 17.7951 96.6639 17.7951H96.6628Z" fill="#363F6C"/>
                    <path d="M99.4511 33.8221H99.0219V35.1505H98.8481V33.8221H98.4199V33.665H99.4511V33.8221Z" fill="#363F6C"/>
                    <path d="M101.2 35.1495H101.028V34.1529C101.028 34.0744 101.033 33.978 101.042 33.8639H101.038C101.021 33.9309 101.007 33.9791 100.993 34.0084L100.485 35.1505H100.401L99.8939 34.0168C99.8792 33.9833 99.8646 33.933 99.8489 33.865H99.8447C99.8499 33.9247 99.853 34.021 99.853 34.156V35.1505H99.6855V33.665H99.9159L100.371 34.7014C100.407 34.781 100.429 34.8406 100.439 34.8794H100.446C100.475 34.7977 100.499 34.737 100.517 34.6972L100.982 33.665H101.199V35.1505L101.2 35.1495Z" fill="#363F6C"/>
                </svg>
            </div>
            <div className="text-center text-[16px] leading-[24px] text-black font-semibold mb-14">{cardDetail.team_name}</div>
            <div className="text-center mb-1">
                {cardDetail.profilePicture !=="" ?
                    <img src={cardDetail.profilePicture} alt={cardDetail.name} className="border-4 border-white rounded-full w-[105px] h-[105px] mx-auto" />
                :
                    <img src={placeholderImg} alt={cardDetail.name} className="border-4 border-white rounded-full w-[105px] h-[105px] mx-auto" />
                }
            </div>
            <div className="text-center text-[26px] leading-[32px] text-black font-bold mb-4">{cardDetail.name}</div>
            <div className="text-center text-[16px] leading-[24px] text-black mb-[30px]">{cardDetail.product_name}</div>
            <div className="text-center text-[16px] leading-[24px] text-black mb-6"><strong>ID No:</strong> {cardDetail.membership_id}</div>
            <div className="text-center text-[16px] leading-[24px] text-black mb-6"><strong className="uppercase">Instated On:</strong> {dayjs(cardDetail.instated_on).format("DD-MM-YYYY")}</div>
            <div className="text-center text-[16px] leading-[24px] text-black"><strong className="uppercase">Expire:</strong> 
                {cardDetail.expire_on==='LIFETIME' ?
                    'LIFETIME'
                :
                <>
                    {cardDetail.expire_on!=="" ? dayjs(cardDetail.expire_on).format("DD-MM-YYYY"):' - '}
                </>
                }
            </div>
        </div>
    </>)
}

export default TeamMembershipCardBlock;