import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc'
import tz from 'dayjs/plugin/timezone'
import * as yup from 'yup';
import ReactDatePicker from "react-datepicker";
import Select, {components} from "react-select";

import Spinner from "../../components/Spinner/Spinner";
import PhoneCodeList from '../../libs/phonecodes';
import PlaiImageCropper from "../../components/ImageCropper/ImageCropper";
import iconMale from "../../assets/images/icon-male.svg";
import iconFemale from "../../assets/images/icon-female.svg";
import DownArrowSvg from "../../assets/images/icon-down.svg";
import { generateRandomName } from "../../components/ImageCropper/cropUtils";

dayjs.extend(utc);
dayjs.extend(tz);

const schema = yup.object({
    firstName: yup.string().required('First name is required.'),
    lastName: yup.string().required('Last name is required.'),
    birthDate: yup.string().required('Birthdate is required'),
    phonecode: yup.string().required('Phone Code is required.'),
    phoneNumber: yup.string().required('Emergancy number is required.'),    
}).required();

const SignUpChildernForm = ({setShowForm, childrenList, setChildrenList, isEditChild, childitem, index}) =>{
    const [loading,setLoading] = useState(false);
    const [profileImage, setProfileImage] = useState(null);
    const [errorMessage,setErrorMessage] = useState(false);
    const [genderErr, setGenderErr] = useState(false);
    const [showMedicalDetails, setShowMedicalDetails] = useState(false);
    const [phoneCodeOptions,setPhoneCodeOptions]=useState([]);
    const [selectedPhoneCode, setSelectedPhoneCode] = useState([]);
    const [selectedPhoneCodeOut, setSelectedPhoneCodeOut] = useState(selectedPhoneCode);
    
    const { register, handleSubmit, setValue, control, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });
    
    const onSubmit = async(data) => {
        setLoading(true);
        if(data.gender!==null || data.nottosay){
            if(isEditChild){                
                var newArray = [...childrenList];
                newArray[index] = {...newArray[index], 
                    firstName: data.firstName,
                    lastName: data.lastName,
                    dateOfBirth: dayjs(data.birthDate).format('YYYY-MM-DD'),
                    phoneCode: data.phonecode,
                    phoneNumber: data.phoneNumber,
                    medical_emergency: data.medicaldetails,
                    gender: !data.nottosay ? data.gender : 'prefer_not_to_say',
                }
                if(profileImage!==null){
                    var formdatas3 = new FormData();
                    var tmpFilename = generateRandomName();
                    const imgFileObj = new File([profileImage], tmpFilename+'.webp', { lastModified: new Date().getTime(), type: profileImage.type })
                    formdatas3.append("files[]", imgFileObj);
                    var requestOptionss3 = {
                        method: 'PUT',
                        body: formdatas3,
                        redirect: 'follow'
                    };
                    const responsesApp = await fetch("https://dashboard.plaisport.com/upload_content", requestOptionss3);
                    const resultsApp = await responsesApp.text();
                    const rdatasApp = JSON.parse(resultsApp);
                    newArray[index] = {...newArray[index], 
                        content: rdatasApp.data[0],
                    }                    
                }
                setChildrenList(newArray);                
            }
            else{
                var childArr = {};
                childArr.firstName = data.firstName;
                childArr.lastName = data.lastName;
                childArr.dateOfBirth = dayjs(data.birthDate).format('YYYY-MM-DD');
                childArr.phoneCode = data.phonecode;
                childArr.phoneNumber = data.phoneNumber;
                childArr.medical_emergency = data.medicaldetails;
                childArr.gender = !data.nottosay ? data.gender : 'prefer_not_to_say';

                if(profileImage!==null){
                    var formdatas4 = new FormData();
                    var tmpFilename4 = generateRandomName();
                    const imgFileObj = new File([profileImage], tmpFilename4+'.png', { lastModified: new Date().getTime(), type: profileImage.type })
                    formdatas4.append("files[]", imgFileObj);
                    var requestOptionss4 = {
                        method: 'PUT',
                        body: formdatas4,
                        redirect: 'follow'
                    };
                    const responsesApp = await fetch("https://dashboard.plaisport.com/upload_content", requestOptionss4);
                    const resultsApp = await responsesApp.text();
                    const rdatasApp = JSON.parse(resultsApp);
                    childArr.content = rdatasApp.data[0];                    
                }
                
                setChildrenList(oldArray => [...oldArray,childArr] );            
            }
            setShowForm(false);
        }
        else{
            if(data.gender===null && data.nottosay===false){
                setGenderErr(true);
            }
            else{
                setGenderErr(false);
            }
        }
    };
    const DropdownIndicator = (props) => {
        return (
          <components.DropdownIndicator {...props}>
            <img src={DownArrowSvg} width={24} height={24} alt="" />
          </components.DropdownIndicator>
        );
    };

    function handleSelectChange(selectedOpt) {        
        setSelectedPhoneCode(selectedOpt.value);
        setSelectedPhoneCodeOut(selectedOpt);
    }

    useEffect(()=>{
        let phoneCodeOpt = [];
        PhoneCodeList.forEach(element => {
            phoneCodeOpt.push({'value':element.code, 'label':'('+element.code+') ' + element.name, 'country':element.name});
        });
        setPhoneCodeOptions(phoneCodeOpt);
        if(isEditChild){
            setValue('firstName',childitem.firstName);
            setValue('lastName',childitem.lastName);
            if(childitem.gender.toLowerCase()==='male' || childitem.gender.toLowerCase()==='female'){
                setValue('gender',childitem.gender.toLowerCase());
            }
            else{
                setValue('nottosay',true);
            }
            setValue('birthDate',new Date(childitem.dateOfBirth));
            setValue('phonecode',childitem.phoneCode);
            setValue('phoneNumber',childitem.phoneNumber);
            let ePhoneOptions = [];
            PhoneCodeList.forEach(element => {
                if(element.code===childitem.phoneCode){
                    ePhoneOptions.push({'value':element.code, 'label':'('+element.code+') ' + element.name, 'country':element.name});
                }
            });
            setSelectedPhoneCodeOut(ePhoneOptions);
            if(childitem.medical_emergency && childitem.medical_emergency!==""){
                setValue('medicaldetails',childitem.medical_emergency);
                setShowMedicalDetails(true);
                setValue('isMedicalDetail','Yes');
            }
            else{
                setShowMedicalDetails(false);
                setValue('isMedicalDetail','No');
            }
        }
        else{
            setValue('isMedicalDetail','No');
            setValue('gender','male');
        }        
    },[])

    return(
        !loading ?
        <>
            <button type="button" className="close-popup" onClick={(e)=>{setShowForm(false)}}><span>close</span></button>
            <div className="form-heading text-center mb-[30px]">{isEditChild ? 'Update your Child': 'Add your Child'}</div>
            {!loading && errorMessage ? <p className='error text-center mb-2.5'>{errorMessage}</p> : null}
            <form onSubmit={handleSubmit(onSubmit)} className="form-wrapper product-form-wrapper">
                <div className="lg:grid lg:grid-cols-2 lg:gap-4">
                    <div>
                        <div className='form-field-wrap mb-5 user-field-wrap relative'>
                            <input 
                                type="text" id="first-name" disabled={loading} placeholder="First name *" className='text-field'
                                {...register("firstName")}
                                aria-invalid={errors.firstName ? "true" : "false"} 
                            />                                
                        </div>
                        {errors.firstName && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.firstName?.message}</p>}
                    </div>  
                    <div>
                        <div className='form-field-wrap mb-5 user-field-wrap relative'>
                            <input 
                                type="text" id="last-name" disabled={loading} placeholder="Last name *" className='text-field'
                                {...register("lastName")}
                                aria-invalid={errors.lastName ? "true" : "false"} 
                            />                                
                        </div>
                        {errors.lastName && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.lastName?.message}</p>}
                    </div>
                    <div>
                        <div className="form-field-wrap button-group-wrap flex flex-wrap justify-between items-center">
                            <div className="flex-1 button-grop-label gender-field-label">Select Gender</div>
                            <div className="r-button-group flex">
                                <div className="mr-4">
                                    <input type="radio" {...register("gender")} value="male" id="gender-male" className="peer hidden" onChange={(e)=>{setValue('nottosay',false); setValue('gender','male')}} />
                                    <label
                                        htmlFor="gender-male"
                                        className="gender-label block py-[5px] flex items-center cursor-pointer select-none rounded-[42px] text-center bg-[#EFF1F9] peer-checked:bg-[#FBDB03] peer-checked:text-black"
                                        ><img src={iconMale} width={18} height={18} alt="" className='mr-2'/>Male</label>
                                </div>
                                <div>
                                    <input type="radio" {...register("gender")} value="female" id="gender-female" className="peer hidden" onChange={(e)=>{setValue('nottosay',false); setValue('gender','female')}} />
                                    <label
                                        htmlFor="gender-female"
                                        className="gender-label block py-[5px] flex items-center cursor-pointer select-none rounded-[42px] text-center bg-[#EFF1F9] peer-checked:bg-[#FBDB03] peer-checked:text-black"
                                        ><img src={iconFemale} width={18} height={18} alt="" className='mr-2'/>Female</label>
                                </div>
                            </div>
                        </div>
                        <div className='auth-form-field-wrap mb-5 check-field-wrap relative !p-0'>
                            <label htmlFor="nottosay" className='!text-[12px] font-normal leading-[12px] uppercase justify-end items-center'>
                                <input type="checkbox" id="nottosay" className='checkbox-field appearance-none h-3 w-3 rounded-sm bg-white align-top float-left mr-1 cursor-pointer'
                                    {...register("nottosay")}
                                    onChange={(e)=>{
                                        if(e.target.checked){setValue('gender','')}
                                    }}
                                />Prefer Not to say
                            </label>
                        </div>
                        {genderErr && <p className="error mb-2.5 -mt-2.5" role="alert">Please either gender or prefer not to say.</p>}
                    </div>
                    <div>
                        <div className='form-field-wrap mb-5 select-field-wrap date-field-wrap relative'>
                                <Controller
                                    control={control}
                                    name='birthDate'
                                    errors={errors}
                                    render={({ field }) => (
                                    <ReactDatePicker
                                        placeholderText='DD/MMM/YYYY'
                                        onChange={(date) => field.onChange(date)}
                                        selected={field.value}
                                        maxDate={new Date()}
                                        mask="__-___-____"
                                        dateFormat="dd/MMM/yyyy"
                                        showYearDropdown
                                    />
                                )}
                                />
                        </div>
                        {errors.birthDate && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.birthDate?.message}</p>}
                    </div>
                    <div className="col-span-2">
                        <div className='flex flex-wrap'>
                            <div className="w-full lg:w-1/2 lg:pr-2.5">
                                <div className='form-field-wrap w-full mb-5 no-field-wrap relative'>
                                    <Controller
                                        control={control}
                                        name='phonecode'
                                        render={({ field: { onChange, value, ref }}) => (
                                            <Select
                                                inputRef={ref}
                                                value={selectedPhoneCodeOut}
                                                className="react-select-container"
                                                classNamePrefix="react-select"
                                                components={{ DropdownIndicator }}
                                                placeholder={<div>Select Code*</div>}
                                                isSearchable
                                                onChange={val => {
                                                    onChange(val.value);
                                                    handleSelectChange(val);
                                                }}
                                                options={phoneCodeOptions}
                                                styles={{
                                                    control: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        borderWidth: 0,
                                                        backgroundColor: 'rgba(239, 241, 249, 0.6)',
                                                        padding: '6px 7px 6px 7px'
                                                    }),
                                                    menu: (baseStyles) => ({
                                                        ...baseStyles,
                                                        zIndex:1000,
                                                        backgroundColor: '#ffffff'
                                                    })
                                                }}
                                                theme={(theme) => ({
                                                    ...theme,
                                                    borderRadius: 8,                                                
                                                })}
                                            />
                                        )}
                                    />
                                </div>
                                {errors.phonecode && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.phonecode?.message}</p>}
                            </div>
                            <div className="w-full lg:w-1/2 lg:pl-2.5">
                                <div className='form-field-wrap w-full mb-5 phone-field-wrap relative'>
                                    <input 
                                        type="tel" id="phone" placeholder="Emergency Contact Number* *" className='text-field'
                                        {...register("phoneNumber")}
                                    />                                
                                </div>
                                {errors.phoneNumber && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.phoneNumber?.message}</p>}
                            </div>
                        </div>
                    </div>
                    <div className='form-field-wrap lg:col-start-2 lg:row-start-1 lg:row-span-4 lg:row-end-5 mb-5 relative'>
                        <PlaiImageCropper default_label={'Child Profile Photo'} default_message={'Maximum upload file size: 2MB<br/>(1000 X 1000)'} editImage={isEditChild && childitem.content ? 'data:image/'+childitem.extension+';base64,'+childitem.content :''} onFinalChange={(newImage)=>{setProfileImage(newImage)}} aspect={1 / 1}/>
                    </div>         
                    <div className="col-span-2">
                        <div className="form-field-wrap button-group-wrap flex mb-5 flex-wrap justify-between items-center">
                            <div className="flex-1 text-[14px] leading-[21px] text-black">Does your Child have any medical condition that their sports team might need to be aware of?</div>
                            <div className="r-button-group flex ml-2.5">
                                <div className="mr-4">
                                    <input type="radio" {...register("isMedicalDetail")} value="Yes" id="medical-yes" className="peer hidden" onChange={(e)=>{setValue('isMedicalDetail','Yes'); setShowMedicalDetails(true);}} />
                                    <label
                                        htmlFor="medical-yes"
                                        className="block w-[58px] py-[5px] cursor-pointer select-none rounded-[42px] text-center bg-[#EFF1F9] peer-checked:bg-[#FBDB03] peer-checked:text-black"
                                        >Yes</label>
                                </div>
                                <div>
                                    <input type="radio" {...register("isMedicalDetail")} value="No" id="medical-no" className="peer hidden" onChange={(e)=>{setValue('isMedicalDetail','No'); setShowMedicalDetails(false);}} />
                                    <label
                                        htmlFor="medical-no"
                                        className="block w-[58px] py-[5px] cursor-pointer select-none rounded-[42px] text-center bg-[#EFF1F9] peer-checked:bg-[#FBDB03] peer-checked:text-black"
                                        >No</label>
                                </div>
                            </div>
                        </div>
                        {showMedicalDetails &&
                            <div className='form-field-wrap mb-5 relative'>
                                <textarea 
                                    type="text" id="medicaldetails" disabled={loading} placeholder="Medical Emergency Details" className='textarea-field'
                                    {...register("medicaldetails")}
                                ></textarea>
                            </div>
                        }
                    </div>
                    <div className="col-span-2">
                        <div className='flex justify-center'>
                            <input className='button button--large' type="submit" value={`${isEditChild ? 'Update': "Continue"}`} disabled={loading}/>
                        </div>
                    </div>
                </div>
            </form>
        </>
        :
        <>
            <div className="py-5 text-center"><Spinner /></div>
        </>
    );
}
export default SignUpChildernForm;