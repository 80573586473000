import { useContext, useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";
import dayjs from "dayjs";
import { TeamContext } from "../../../contexts/TeamContext";
import redirect from '../../../assets/images/nomember.png';
import MembersRow from "./MembersRow";
import Spinner from "../../../components/Spinner/Spinner";
import { getMembers, getInvitedMembers, getRemainingJoinMembers } from "../../../libs/dataFunctions";
import MemberNewForm from "./MemberNewForm";
import IsMobile from "../../../components/isMobile";
import JoinMembersForm from "./JoinMemberForm";
import AskUpdateMember from "./AskUpdateMember";
import MergeMembersPopup from "./MergeMembersPopup";

const TabMembers=()=>{
    const { team, token } = useContext(TeamContext);
    const [teamMembers, setMembers] = useState([]);
    const [dispMembers, setDispMembers] = useState([]);
    const [invitedMembers, setInvitedMembers] = useState([]);
    const [membersLoading, setMembersLoading] = useState(false);
    const [openForm, setOpenForm] = useState(false);
    const [openJoinChildForm, setOpenJoinChildForm] = useState(false);
    const [q, setSearchQuery] = useState("");    
    const [searchParam] = useState(["firstName","lastName","name"]);
    const [showInvitedMembers,setShowInvitedMembers] = useState(false);
    const [showExportOptions,setShowExportOptions] = useState(false);
    const [showForceUpdatePopup,setForceUpdatePopup] = useState(false);
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [isCheck, setIsCheck] = useState([]);
    const [isCheckAllMembers, setIsCheckAllMembers] = useState(false);
    const [isCheckAllToDelInvites, setIsCheckAllToDelInvites] = useState(false);
    const [isCheckMembers, setIsCheckMembers] = useState([]);
    const [isCheckDelInvites, setIsCheckDelInvites] = useState([]);
    const [remainingMembers, setRemainingMembers] = useState([]);
    const [showSortingOpt, setShowSortingOpt] = useState(false);
    const [filterBy, setFilterBy] = useState('all');
    const [showMergePopup,setShowMergePopup] = useState(false);
    const isMobile = IsMobile(window);
    const navigate = useNavigate();

    const sortBy = (orderby) =>{
        let sorted_members;
        if(orderby==='player'){
            sorted_members = teamMembers.filter((latestitem)=>(latestitem.accountType.toLowerCase()==='player'));
        }
        if(orderby==='child'){
            sorted_members = teamMembers.filter((latestitem)=>(latestitem.accountType.toLowerCase()==='children'));
        }
        if(orderby==='parent'){
            sorted_members = teamMembers.filter((latestitem)=>(latestitem.accountType.toLowerCase()==='parent'));
        }
        if(orderby==='admin' || orderby==='group_admin'){
            sorted_members = teamMembers.filter((latestitem)=>(latestitem.user_role.toLowerCase()===orderby));
        }
        if(orderby==='a-z'){
            sorted_members = teamMembers.sort((a,b) => {
                return a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1;
            });
        }
        if(orderby==='z-a'){
            sorted_members = teamMembers.sort((a,b) => {
                return b.name.toUpperCase() > a.name.toUpperCase() ? 1 : -1;
            });
        }

        if(orderby==='all'){
            sorted_members = teamMembers;
        }
        setDispMembers(sorted_members);
        setShowSortingOpt(false);
        setFilterBy(orderby);
    }

    async function refreshTeamMembers(){
        setMembersLoading(true);
        const [teamMemberData, invitedMemberData, remainingMembersData] = await Promise.all([
            getMembers(team._id, token),
            getInvitedMembers(team._id, token),
            getRemainingJoinMembers(token, team._id)
        ]);        
        setMembers(teamMemberData);
        setInvitedMembers(invitedMemberData);
        setRemainingMembers(remainingMembersData);
        if(!showInvitedMembers){
            setDispMembers(teamMemberData);
        }
        else{
            setDispMembers(invitedMemberData);
        }
        setMembersLoading(false);
    }

    const deleteInviteMembers = async ()=>{
        if(window.confirm("Are you sure you want to delete this invited members?")){
          var formdata = new FormData();
          formdata.append("type", "delete_invite_v2");
          formdata.append("invite_ids",isCheckDelInvites.join('@#'));
          formdata.append("team_id", team._id);
          formdata.append("separate_by", '@#');
          
          var secureHeader = new Headers();
          secureHeader.append('Authorization','Bearer ' + token);
          secureHeader.append('device_type','W');
          const requestOptions = {
              method: 'POST',
              redirect: 'follow',
              body: formdata,
              headers: secureHeader
          };
  
          await fetch(process.env.REACT_APP_APIURL + '/app_members' , requestOptions)
          .then(response => response.text())
          .then(result => {
            refreshTeamMembers();
          })
          .catch(error => console.log('error', error));          
        }
    }

    const search=(items)=>{
        return items.filter((item) => {
            return searchParam.some((newItem) => {
                return (
                    item[newItem]?
                        item[newItem]
                        .toString()
                        .toLowerCase()
                        .indexOf(q.toLowerCase()) > -1
                    :
                    null
                );
            });
        });
    }
    
    const exportFields = [
        {
            key: "firstName",
            label: "First Name"
        },
        {
            key: "lastName",
            label: "Last Name"
        },
        {
            key: "name",
            label: "Full Name"
        },
        {
            key: "email",
            label: "Email"
        },
        {
            key: "phoneNumber",
            label: "Phone"
        },
        
        {
            key: "groups",
            label: "Groups"
        },
        {
            key: "customFields",
            label: "Custom Fields"
        },
        {
            key: "dateOfBirth",
            label: "Date of Birth"
        },
        {
            key: "role",
            label: "Role"
        }
    ];

    const handleSelectAll = e => {
        setIsCheckAll(!isCheckAll);
        setIsCheck(exportFields.map(li => li));
        if (isCheckAll) {
            setIsCheck([]);
        }
    };

    const handleClick = e => {
        const { id, checked, value } = e.target;
        let tmpObj = {};
        Object.assign(tmpObj, JSON.parse(value));        
        setIsCheck([...isCheck, tmpObj]);
        if (!checked) {
            setIsCheck(isCheck.filter((item) => {return !item.key.includes(id)}));
        }
    };

    const handleSelectAllMembers = e => {
        setIsCheckAllMembers(!isCheckAllMembers);
        setIsCheckMembers(search(dispMembers).map(item => item));
        if (isCheckAllMembers) {
            setIsCheckMembers([]);
        }
    };

    const handleSelectAllToDel = e => {
        setIsCheckAllToDelInvites(!isCheckAllToDelInvites);
        setIsCheckDelInvites(search(dispMembers).map(item => item._id));
        if (isCheckAllToDelInvites) {
            setIsCheckDelInvites([]);
        }
    };
    
    const fieldLists = exportFields.map((field) => {
        return (              
            <label key={field.key} className='text-[12px] mr-3 text-black font-light cursor-pointer'>
                <input                    
                    type="checkbox"
                    name={field.label}
                    id={field.key}
                    value={JSON.stringify(field)}
                    className='checkbox-field appearance-none h-4 w-4 rounded-sm bg-white mt-1 align-top float-left mr-2 cursor-pointer'
                    onChange={handleClick}
                    checked={isCheck.some((item)=>{ return item.key===field.key })}
                />
                {field.label}
            </label>
        );
    });

    useEffect(()=>{
        refreshTeamMembers();
    },[]);

    useEffect(()=>{
        if(showInvitedMembers){
            setDispMembers(invitedMembers);
            setSearchQuery('');
        }
        else{
            setDispMembers(teamMembers);
            setSearchQuery('');
        }
    },[showInvitedMembers]);

    const NewMemberForm = ({setShowForm}) =>{        
        return(
            <div className="popup-form">
                <div className="popup-form-wrap popup-form-memberform relative">                    
                    <MemberNewForm isNew={true} team={team} setShowForm={setShowForm} refreshMembers={refreshTeamMembers}/>
                </div>
            </div>
        )
    }
    
    const JoinChildFormPopup = ({setShowForm}) =>{        
        return(
            <div className="popup-form">
                <div className="popup-form-wrap relative !w-full !max-w-[450px]">
                    <JoinMembersForm team={team} setShowForm={setShowForm} refreshMembers={refreshTeamMembers} remainingMembers={remainingMembers}/>
                </div>
            </div>
        )
    }
    
    const ForceUpdatePopup = ({setShowForm}) => {
        return(
            <div className="popup-form">
                <div className="popup-form-wrap relative !w-full !max-w-[850px]">
                    <AskUpdateMember team={team} setShowForm={setShowForm} />
                </div>
            </div>
        )
    }
    return(
        !membersLoading ?
        <>
            <div className="tab-actionbar mb-3 md:mb-0 px-2.5 sm:px-7.5 py-[18px] flex flex-col md:flex-row justify-between items-center flex-wrap">
                <div className="title-col mb-2.5 md:mb-0">
                    {showInvitedMembers ? 'Invitations sent' : 'Members List'}
                    {(isCheckDelInvites.length > 0 && team.role === 'admin') &&
                        <button type="button" className="button button-action button-red ml-5" onClick={()=>{deleteInviteMembers()}}>Delete</button>
                    }
                </div>
                <div className="cta-col flex flex-col md:flex-row items-center relative">
                    <label htmlFor="search-form" className="search-field relative mr-3.5 mb-2.5 md:mb-0">
                        <input
                            type="search"
                            name="search-field"
                            id="search-form"
                            className="search-input"
                            placeholder="Search Members"
                            value={q}
                            onChange={(e) => {
                                setSearchQuery(e.target.value);
                                setIsCheck([]);
                                setIsCheckAll(false);
                                setIsCheckMembers([]);
                                setIsCheckAllMembers(false);                                
                            }}
                        />
                    </label>
                    {remainingMembers.length > 0 &&
                        <button type="button" className="button button-action button-new mr-1.5 mb-2.5 md:mb-0" onClick={(e)=>{setOpenJoinChildForm(true)}}>Add child</button>
                    }
                    {team.role==='admin' &&
                        <>  
                            <button type="button" className="button button-action button-new mr-1.5 mb-2.5 md:mb-0" onClick={(e)=>{setOpenForm(true)}}>Invite Member</button>
                            <div className="flex items-center">
                                <button type="button" className="button button-action button-import mr-1.5" onClick={()=>{navigate('/team/'+team._id+'/import-members');}}>Import</button>
                                {!showInvitedMembers &&
                                    <button type="button" className="button button-action button-export mr-1.5" onClick={()=>{
                                        setShowExportOptions(!showExportOptions); 
                                        setIsCheckMembers([]);
                                        setIsCheck([]);
                                        setIsCheckAll(false);
                                        setIsCheckMembers([]);
                                        setIsCheckAllMembers(false);
                                        setForceUpdatePopup(false);
                                    }}>Export</button>
                                }
                                {!showInvitedMembers &&
                                <button type="button" className={`button button-action mr-1.5 ${showForceUpdatePopup ? 'active' : ''}`} onClick={(e)=>{
                                    setForceUpdatePopup(!showForceUpdatePopup); 
                                    setIsCheck([]);
                                    setIsCheckAll(false);
                                    setIsCheckMembers([]);
                                    setIsCheckAllMembers(false);
                                    setShowExportOptions(false);
                                }}>Push for update</button>
                                }
                                <button type="button" className={`button-icon button-icon-email mr-1.5 ${showInvitedMembers ? 'active' : ''}`} onClick={()=>{setShowInvitedMembers(!showInvitedMembers);}}></button>
                                <button type="button" className="button-icon button-icon-merge" onClick={()=>{setShowMergePopup(true)}}></button>
                            </div>
                        </>
                    }
                    {!showInvitedMembers &&
                    <>
                        <button type="button" className={`button-icon button-icon-sort ml-1.5`} onClick={(e)=>{setShowSortingOpt(!showSortingOpt)}}><span>Sort</span></button>
                        <ul className={`sort-actions absolute rounded-lg bg-white overflow-hidden top-full right-0 ${!showSortingOpt ? 'hidden' : ''}`}>
                            <li className={filterBy==='admin' ? 'active' : ''}><div className='cursor-pointer' onClick={()=>{sortBy('admin');}}>Full Admin</div></li>
                            <li className={filterBy==='group_admin' ? 'active' : ''}><div className='cursor-pointer' onClick={()=>{sortBy('group_admin');}}>Group Admin</div></li>
                            <li className={filterBy==='player' ? 'active' : ''}><div className='cursor-pointer' onClick={()=>{sortBy('player');}}>Member</div></li>
                            <li className={filterBy==='child' ? 'active' : ''}><div className='cursor-pointer' onClick={()=>{sortBy('child');}}>Child</div></li>
                            <li className={filterBy==='parent' ? 'active' : ''}><div className='cursor-pointer' onClick={()=>{sortBy('parent');}}>Parent</div></li>
                            <li className={filterBy==='a-z' ? 'active' : ''}><div className='cursor-pointer' onClick={()=>{sortBy('a-z');}}>A - Z</div></li>
                            <li className={filterBy==='z-a' ? 'active' : ''}><div className='cursor-pointer' onClick={()=>{sortBy('z-a');}}>Z - A</div></li>
                        </ul>
                    </>
                    }
                </div>            
                {openForm &&
                    <NewMemberForm setShowForm={setOpenForm}/>
                }
                {openJoinChildForm && 
                    <JoinChildFormPopup setShowForm={setOpenJoinChildForm} />
                }
                {showForceUpdatePopup &&
                    <ForceUpdatePopup setShowForm={setForceUpdatePopup} />
                }
                {showMergePopup &&
                    <MergeMembersPopup team={team} token={token} setShowForm={setShowMergePopup} />
                }
            </div>            
            {dispMembers && search(dispMembers).length > 0 ?
                <div className="team-members-list pb-[77px]">
                    {!isMobile ?
                    <table className="table-auto w-full">
                        {!showInvitedMembers ?
                            <thead>
                                <tr>
                                    <th></th>
                                    <th colSpan={5}>
                                    {showExportOptions && 
                                        <div className="flex py-3.5">
                                            <label className='text-[12px] mr-5 text-[#363F6C] font-semibold cursor-pointer'>
                                                <input 
                                                    type="checkbox"
                                                    name="selectAll"
                                                    id="selectAll"
                                                    className='checkbox-field appearance-none h-4 w-4 rounded-sm bg-white mt-1 align-top float-left mr-2 cursor-pointer'
                                                    onChange={handleSelectAll}
                                                    checked={isCheckAll} />
                                                    All Fields
                                            </label>
                                            {fieldLists}
                                            {(isCheckMembers.length>0 && isCheck.length>0) &&
                                                <CSVLink 
                                                    className="button button-action button-blue mr-3.5"
                                                    data={isCheckMembers.map((memberObj, index) => {
                                                        let rowData={}, columnData;                                                        
                                                        columnData = isCheck.map((isc)=>{
                                                            let colData={};
                                                            if(isc.key === 'dateOfBirth'){
                                                                colData.dateOfBirth=memberObj[isc.key]!=="" ? dayjs(memberObj[isc.key]).format('DD-MM-YYYY') : (memberObj['is_above_18'] ? 'Over 18 years' : 'Under 18 years');
                                                            }
                                                            if(isc.key === 'firstName'){
                                                                colData.firstName=memberObj[isc.key];
                                                            }
                                                            if(isc.key === 'lastName'){
                                                                colData.lastName=memberObj[isc.key];
                                                            }
                                                            if(isc.key === 'name'){
                                                                colData.name=memberObj[isc.key];
                                                            }
                                                            if(isc.key === 'email'){                                                                
                                                                if(memberObj.accountType==='children' && memberObj.email.trim()===""){
                                                                    if(memberObj.parents.length > 0){
                                                                        let parentsEmail = [];
                                                                        memberObj.parents.forEach(pItem=>{
                                                                            parentsEmail.push(pItem.email);
                                                                        });
                                                                        colData.email=parentsEmail.join(',');
                                                                    }
                                                                    else{
                                                                        colData.email="";
                                                                    }
                                                                }
                                                                else{
                                                                    colData.email=memberObj[isc.key];
                                                                }
                                                            }
                                                            if(isc.key === 'groups'){
                                                                colData.groups=memberObj.groups.join();
                                                            }
                                                            if(isc.key === 'phoneNumber'){
                                                                if(memberObj.accountType==='children' && memberObj.phoneNumber.trim()===""){
                                                                    if(memberObj.parents.length>0){
                                                                        let parentsPhone = [];
                                                                        memberObj.parents.forEach(pItem=>{
                                                                            parentsPhone.push(pItem.phoneNumber);
                                                                        });
                                                                        colData.phoneNumber=parentsPhone.join(',');
                                                                    }
                                                                    else{
                                                                        colData.phoneNumber="";
                                                                    }
                                                                }
                                                                else{
                                                                    colData.phoneNumber=memberObj.phoneNumber.toString();
                                                                }
                                                            }
                                                            if(isc.key === 'role'){
                                                                colData.role=memberObj.user_role==="group_admin" ? 'GROUP ADMIN' : memberObj.user_role.toUpperCase();
                                                            }
                                                            if(isc.key === 'customFields'){
                                                                let csf=[];
                                                                if(memberObj.customFields.length>0){
                                                                 csf = memberObj.customFields.map((cf)=>{
                                                                    return cf.name+':'+cf.value;
                                                                 })
                                                                }
                                                                if(csf.length>0){
                                                                    colData.customFields=csf.join();
                                                                }
                                                                else{
                                                                    colData.customFields=null;
                                                                }
                                                            }
                                                            return colData;
                                                        });

                                                        for(let i = 0; i < columnData.length; i++ ) {
                                                            Object.assign(rowData, columnData[i]);
                                                        }                                                        
                                                        return rowData;
                                                    })}
                                                    filename={'members-' + team._id + '.csv'}
                                                    headers={isCheck}>
                                                        Export
                                                </CSVLink>
                                            }
                                        </div>
                                    }
                                    </th>
                                </tr>
                                <tr className='border-b border-black-100/10'>
                                    <th className='pl-2.5 md:pl-[30px] w-[120px]'>
                                        {(showExportOptions) &&
                                            <label className='text-[12px] text-[#363F6C] font-semibold cursor-pointer flex'>
                                                <input 
                                                    type="checkbox"
                                                    name="selectAll"
                                                    id="selectAll"
                                                    className='checkbox-field appearance-none h-4 w-4 rounded-sm bg-white mt-1 align-top float-left mr-2 cursor-pointer'
                                                    onChange={handleSelectAllMembers}
                                                    checked={isCheckAllMembers} />
                                                    All
                                            </label>                                            
                                        }
                                    </th>
                                    <th className="text-left py-[18px]">Name</th>
                                    {team.role === 'admin' &&
                                    <>
                                        <th className="text-left py-[18px] hidden lg:table-cell">Email</th>
                                        <th className="text-left py-[18px] hidden lg:table-cell">Contact</th>
                                        <th className="text-left py-[18px] hidden md:table-cell">Date of Birth</th>                                    
                                        <th className="text-right py-[18px] pr-[30px]">Actions</th>
                                    </>
                                    }
                                </tr>
                            </thead>
                            :
                            <thead>
                                <tr className='border-b border-black-100/10'>
                                    {team.role === 'admin' &&
                                        <th className='pl-2.5 md:pl-[30px] w-[120px]'>
                                            <label className='text-[12px] text-[#363F6C] font-semibold cursor-pointer flex'>
                                            <input 
                                                type="checkbox"
                                                name="selectAll"
                                                id="selectAllToDel"
                                                className='checkbox-field appearance-none h-4 w-4 rounded-sm bg-white mt-1 align-top float-left mr-2 cursor-pointer'
                                                onChange={handleSelectAllToDel}
                                                checked={isCheckAllToDelInvites} />
                                                All
                                        </label>
                                        </th>
                                    }
                                    <th className='text-left pl-2.5 md:pl-[30px]'>Name</th>
                                    <th className="text-left py-[18px]">Email</th>
                                    <th className="text-left py-[18px]">Date sent</th>
                                    {team.role === 'admin' &&
                                        <th className="text-right py-[18px] pr-[30px]">Actions</th>
                                    }
                                </tr>
                            </thead>
                        }
                        <tbody>
                        {search(dispMembers).map((teamMember,index) => {                        
                            return (
                                <MembersRow isInvitedMember={showInvitedMembers} isMobile={isMobile} showExportOptions={showExportOptions} isCheckMembers={isCheckMembers} setIsCheckMembers={setIsCheckMembers} isCheckDelInvites={isCheckDelInvites} setIsCheckDelInvites={setIsCheckDelInvites} teamMember={teamMember} refreshMembers={refreshTeamMembers} team={team} key={index} />
                            )
                            })
                        }
                        </tbody>
                        {(showInvitedMembers && isCheckDelInvites.length > 0 && team.role === 'admin') &&
                            <tfoot>
                                <tr><td className="border-b border-black-100/10 py-2.5 pl-2.5 md:pl-[30px]"><button type="button" className="button button-action button-red" onClick={()=>{deleteInviteMembers()}}>Delete</button></td><td colSpan={5} className="border-b border-black-100/10 py-2.5"></td></tr>
                            </tfoot>
                        }                        
                    </table>
                    :
                    <div>
                        {showExportOptions && 
                            <div className="flex flex-col py-3.5 px-3">
                                <label className='text-[12px] mb-2 text-[#363F6C] font-semibold cursor-pointer'>
                                    <input 
                                        type="checkbox"
                                        name="selectAll"
                                        id="selectAll"
                                        className='checkbox-field appearance-none h-4 w-4 rounded-sm bg-white mt-1 align-top float-left mr-2 cursor-pointer'
                                        onChange={handleSelectAll}
                                        checked={isCheckAll} />
                                        All Fields
                                </label>
                                {fieldLists}
                                {(isCheckMembers.length>0 && isCheck.length>0) &&
                                    <CSVLink 
                                        className="button button-action button-blue mb-2"
                                        data={isCheckMembers.map((memberObj, index) => {
                                            let rowData={}, columnData;                                                        
                                            columnData = isCheck.map((isc)=>{
                                                let colData={};
                                                if(isc.key === 'dateOfBirth'){
                                                    colData.dateOfBirth=memberObj[isc.key]!=="" ? dayjs(memberObj[isc.key]).format('DD-MM-YYYY') : (memberObj['is_above_18'] ? 'Over 18 years' : 'Under 18 years');
                                                }
                                                if(isc.key === 'firstName'){
                                                    colData.firstName=memberObj[isc.key];
                                                }
                                                if(isc.key === 'lastName'){
                                                    colData.lastName=memberObj[isc.key];
                                                }
                                                if(isc.key === 'name'){
                                                    colData.name=memberObj[isc.key];
                                                }
                                                if(isc.key === 'email'){                                                                
                                                    if(memberObj.accountType==='children' && memberObj.email.trim()===""){
                                                        if(memberObj.parents.length > 0){
                                                            let parentsEmail = [];
                                                            memberObj.parents.forEach(pItem=>{
                                                                parentsEmail.push(pItem.email);
                                                            });
                                                            colData.email=parentsEmail.join(',');
                                                        }
                                                        else{
                                                            colData.email="";
                                                        }
                                                    }
                                                    else{
                                                        colData.email=memberObj[isc.key];
                                                    }
                                                }
                                                if(isc.key === 'groups'){
                                                    colData.groups=memberObj.groups.join();
                                                }
                                                if(isc.key === 'phoneNumber'){
                                                    if(memberObj.accountType==='children' && memberObj.phoneNumber.trim()===""){
                                                        if(memberObj.parents.length>0){
                                                            let parentsPhone = [];
                                                            memberObj.parents.forEach(pItem=>{
                                                                parentsPhone.push(pItem.phoneNumber);
                                                            });
                                                            colData.phoneNumber=parentsPhone.join(',');
                                                        }
                                                        else{
                                                            colData.phoneNumber="";
                                                        }
                                                    }
                                                    else{
                                                        colData.phoneNumber=memberObj.phoneNumber.toString();
                                                    }
                                                }
                                                if(isc.key === 'role'){
                                                    colData.role=memberObj.user_role==="group_admin" ? 'GROUP ADMIN' : memberObj.user_role.toUpperCase();
                                                }
                                                if(isc.key === 'customFields'){
                                                    let csf=[];
                                                    if(memberObj.customFields.length>0){
                                                     csf = memberObj.customFields.map((cf)=>{
                                                        return cf.name+':'+cf.value;
                                                     })
                                                    }
                                                    if(csf.length>0){
                                                        colData.customFields=csf.join();
                                                    }
                                                    else{
                                                        colData.customFields=null;
                                                    }
                                                }
                                                return colData;
                                            });

                                            for(let i = 0; i < columnData.length; i++ ) {
                                                Object.assign(rowData, columnData[i]);
                                            }                                                        
                                            return rowData;
                                        })}
                                        filename={'members-' + team._id + '.csv'}
                                        headers={isCheck}>
                                            Export
                                    </CSVLink>
                                }
                            </div>
                        }
                        {search(dispMembers).map((teamMember,index) => {                        
                            return (
                                <MembersRow isInvitedMember={showInvitedMembers} isMobile={isMobile} showExportOptions={showExportOptions} isCheckMembers={isCheckMembers} setIsCheckMembers={setIsCheckMembers} teamMember={teamMember} refreshMembers={refreshTeamMembers} team={team} key={index} />
                            )
                            })
                        }
                    </div>
                    }
                </div>
            :
                <div className="empty text-center">
                    <div className="image"><img src={redirect} alt="Plaisport" width={228} /></div>
                    <div className="title mb-5">No Members Yet?</div>
                    <div className="description mb-8">You’re all alone</div>
                    {team.role==='admin' &&
                        <button type="button" className='button button-yellow button-yellow-add' onClick={(e)=>{setOpenForm(true)}}>Invite Member</button>
                    }
                </div>
            }
        </>
        :
        <>
            <div className="py-5 text-center"><Spinner /></div>
        </>
    );
}

export default TabMembers;