import { useContext, useState, useEffect, useRef } from "react";
import { TeamContext, TeamContextProvider } from "../../../contexts/TeamContext";
import { useParams, NavLink } from "react-router-dom";
import { CSVLink } from "react-csv";
import Sidebar from "../../../components/Sidebar/Sidebar";
import AdminNavbar from "../../../components/AdminNavbar/AdminNavbar";
import { getShopOrders, getGroupsV2 } from "../../../libs/dataFunctions";
import Spinner from "../../../components/Spinner/Spinner";
import OrderRow from "./OrderRow";
import PaymentRequestForm from "./PaymentRequestForm";
import AddPaymentForm from "./AddPaymentForm";

function ShopOrdersOverview(){
    const { team, token, teamUpdating } = useContext(TeamContext);
    const [shopOrdersList, setShopOrders] = useState([]);
    const [shopLoading, setShopLoading] = useState(false);
    const [showSortingOpt, setShowSortingOpt] = useState(false);
    const [orderlist, setOrderlist] = useState([]);
    const [prform, setShowPrForm] = useState(false);
    const [isAddPaymentform, setShowisAddPaymentForm] = useState(false);
    const [q, setSearchQuery] = useState("");
    const [searchParam] = useState(["product_name","firstName","lastName"]);
    const [filterBy, setFilterBy] = useState('all');
    const [isInitial, setIsInitail] = useState(true);
    const [ordersType, setOrderType] = useState("get_orders");
    const [ordersTotalAmount, setOrdersTotalAmount] = useState((shopOrdersList.total_amount !== undefined && shopOrdersList.total_amount !== '') ? shopOrdersList.total_amount: 0);
    const [parentGroups,setParentGroups] = useState([]);

    const search=(items)=>{
        return items.filter((item) => {
            return searchParam.some((newItem) => {
                return (
                    item[newItem]
                        .toString()
                        .toLowerCase()
                        .indexOf(q.toLowerCase()) > -1
                );
            });
        });
    }

    const sortBy = (orderby) =>{
        let sorted_orders;
        var tmpTotal = 0;
        if(orderby==='Succeeded'){
            sorted_orders = shopOrdersList?.data.filter((orderitem)=>(orderitem.status.toLowerCase()==='succeeded'));
            tmpTotal = 0;
            console.log(sorted_orders);
            sorted_orders.forEach((item)=>{
                tmpTotal+=parseFloat(item.amount);
            })
        }
        if(orderby==='Requested'){
            sorted_orders = shopOrdersList?.data.filter((orderitem)=>(orderitem.status.toLowerCase()==='requested'));
            tmpTotal = 0;
            sorted_orders.forEach((item)=>{
                tmpTotal+=parseFloat(item.amount);
            })
        }
        if(orderby==='Incomplete'){
            sorted_orders = shopOrdersList?.data.filter((orderitem)=>(orderitem.status.toLowerCase()==='incomplete'));
            tmpTotal = 0;
            sorted_orders.forEach((item)=>{
                tmpTotal+=parseFloat(item.amount);
            })
        }
        if(orderby==='Scheduled'){
            sorted_orders = shopOrdersList?.data.filter((orderitem)=>(orderitem.status.toLowerCase()==='schedule') || orderitem.status.toLowerCase()==='cash');
            tmpTotal = 0;
            sorted_orders.forEach((item)=>{
                tmpTotal+=parseFloat(item.amount);
            })
        }
        if(orderby==='Tips'){
            sorted_orders = shopOrdersList?.data.filter((orderitem)=>(orderitem.order_type!==undefined && orderitem.order_type.toLowerCase()==='tips'));
            tmpTotal = 0;
            sorted_orders.forEach((item)=>{
                tmpTotal+=parseFloat(item.amount);
            })
        }
        if(orderby==='Cancelled'){
            sorted_orders = shopOrdersList?.data.filter((orderitem)=>(orderitem.status.toLowerCase()==='cancelled'));
            tmpTotal = 0;
            sorted_orders.forEach((item)=>{
                tmpTotal+=parseFloat(item.amount);
            })
        }
        if(orderby==='Overdue'){
            sorted_orders = shopOrdersList?.data.filter((orderitem)=>(orderitem.status.toLowerCase()==='overdue'));
            tmpTotal = 0;
            sorted_orders.forEach((item)=>{
                tmpTotal+=parseFloat(item.amount);
            })
        }
        /*if(orderby==='Cash'){
            sorted_orders = shopOrdersList?.data.filter((orderitem)=>(orderitem.status.toLowerCase()==='cash'));
        }*/
        
        if(orderby==='all'){
            sorted_orders = shopOrdersList?.data;
            tmpTotal=(shopOrdersList.total_amount !== undefined && shopOrdersList.total_amount !== '') ? shopOrdersList.total_amount: 0;
        }

        setOrdersTotalAmount(tmpTotal);
        setOrderlist(sorted_orders);
        setShowSortingOpt(false);
        setFilterBy(orderby);
    }

    const fetchOrders = async(ordersFrom)=>{
        setShopLoading(true);
        const [shopOrderData, groupsData] = await Promise.all([
            getShopOrders(team._id,ordersFrom,token),          
            getGroupsV2(team._id, token)
        ]);
        setShopOrders(shopOrderData);
        setOrderlist(shopOrderData.data);

        let parentGroupOptions = [];
        groupsData.forEach(element => {
            let childOptions = [];
            if(element.nest_groups.length > 0){
                element.nest_groups.forEach(celm =>{
                    childOptions.push({'value':celm._id, 'label':celm.name});
                });
                parentGroupOptions.push({'value':element._id, 'label':element.name, 'children':childOptions});
            }
            else{
                parentGroupOptions.push({'value':element._id, 'label':element.name});
            }
        });
        setParentGroups(parentGroupOptions);

        setShopLoading(false);
        setIsInitail(false);        
        setOrdersTotalAmount((shopOrderData.total_amount !== undefined && shopOrderData.total_amount !== '') ? shopOrderData.total_amount: 0);        
        return true;
    }

    const refreshShopOrders= (ordersFrom) =>{
        fetchOrders(ordersFrom).then(()=>{
            if(!isInitial){
                sortBy(filterBy);
            }
        })
    }

    const ref = useRef();
    
    useEffect(()=>{
        setShopLoading(true);
        if(!teamUpdating && team.role==='admin'){
            refreshShopOrders(ordersType);
        }
    },[team]);

    useEffect(()=>{
        const checkIfClickedOutside = e => {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            if (showSortingOpt && ref.current && !ref.current.contains(e.target)) {
                setShowSortingOpt(false)
            }
        }      
        document.addEventListener("mousedown", checkIfClickedOutside);      
        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    },[showSortingOpt]);

    return(
        !shopLoading ?
        <>
            <div className='breadcrumb flex item-center pt-1.5 pb-1 pl-5 pr-7 w-full'>
                <NavLink to="/" className='home'>
                    <span>Dashboard</span>
                </NavLink>
                <span className='separator'>&lt;</span>
                <NavLink to={`/team/${team._id}/shop`}>
                    Shop
                </NavLink>
                <span className='separator'>&lt;</span>
                Orders
            </div>
            
            <div className='body shop-orders px-5 lg:px-8 py-10'>
                <div className='ordersums flex flex-wrap flex-col md:flex-row mb-5'>
                    {(ordersTotalAmount!==0) &&
                        <div className='ordercube rounded-xl py-[9px] px-[60px] mb-5 md:mb-0 md:mr-5 team-header text-center'>
                            <h4>{team.currency_symbol} {ordersTotalAmount.toFixed(2)}</h4>
                            <p>TOTAL INCOME</p>
                        </div>
                    }
                    {(shopOrdersList.data !== undefined) &&
                        <div className='ordercube rounded-xl py-[9px] px-[60px]  bg-white text-center'>
                            <h4>{search(orderlist).length}</h4>
                            <p>TOTAL ORDERS</p>
                        </div>
                    }
                    <div className="flex w-full mt-5">
                        <button type="button" className={`button ${ordersType==="get_orders" ? 'button-blue' : ''} mr-2.5`} onClick={()=>{setFilterBy('all'); setIsInitail(false); setOrderType("get_orders"); fetchOrders("get_orders");}}>ON PLAI</button>
                        <button type="button" className={`button ${ordersType==="get_orders_others" ? 'button-blue' : ''} ml-2.5`} onClick={()=>{setFilterBy('all'); setIsInitail(false); setOrderType("get_orders_others"); fetchOrders("get_orders_others");}}>OTHER</button>
                    </div>
                </div>
                <div className="pb-[77px] bg-white rounded-xl">
                    <div className="tab-actionbar px-2.5 sm:px-7.5 py-[18px] flex flex-col md:flex-row justify-between items-center">
                        <div className="title-col mb-2.5 md:mb-0">Orders List</div>
                        <div className="cta-col max-w-[320px] md:max-w-full mx-auto md:mx-0 flex justify-center md:justify-end flex-wrap items-center relative" ref={ref}>
                            <label htmlFor="search-form" className="search-field relative mr-3.5 mb-2.5 md:mb-0">
                                <input
                                    type="search"
                                    name="search-field"
                                    id="search-form"
                                    className="search-input"
                                    placeholder="Search Purchases"
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                />                        
                            </label>
                            {team.role==='admin' &&
                                <>
                                {ordersType==="get_orders" ?
                                <>
                                    {shopOrdersList.stripeEnabled &&
                                        <button type="button" className="button button-action button-new mr-3.5" onClick={()=>{setShowPrForm(true)}}>Request Payment</button>
                                    }
                                </>
                                :
                                <button type="button" className="button button-action button-new mr-3.5" onClick={()=>{setShowisAddPaymentForm(true)}}>Add A Payment</button>
                                }
                                </>
                            }
                            {prform &&
                                <PaymentRequestForm team={team} setShowForm={setShowPrForm} refreshShopOrders={refreshShopOrders} parentGroups={parentGroups} />
                            }
                            {isAddPaymentform &&
                                <AddPaymentForm team={team} setShowForm={setShowisAddPaymentForm} refreshShopOrders={refreshShopOrders} />
                            }
                            {team.role==='admin' &&
                                <CSVLink 
                                    className="button button-action button-blue mr-3.5"
                                    data={search(orderlist).map((order, index) => {
                                        return {
                                          name: order.product_name,
                                          order_reference: (order.notes!=="" && order.notes!==undefined) ? order.notes : '',
                                          amount: order.status!=='Requested' ? order.amount : 'Pending',
                                          status: order.status,
                                          member: (order.firstName+' '+order.lastName),
                                          address: order.address,
                                          email: order.email,
                                          stripe_id: order.status==='Succeeded' ? (order.stripe_id!==undefined && order.stripe_id!=="" ? order.stripe_id : null) : null,
                                          date: order.date,
                                        }
                                      })}
                                      filename={'orders-' + team._id + '.csv'}
                                      headers={[
                                        { label: 'Item', key: 'name' },
                                        { label: 'Order Reference', key: 'order_reference' },
                                        { label: 'Amount Received', key: 'amount' },
                                        { label: 'Status', key: 'status' },
                                        { label: 'Member', key: 'member' },
                                        { label: 'Address', key: 'address' },
                                        { label: 'Email', key: 'email' },
                                        { label: 'Stripe Payment ID', key: 'stripe_id' },
                                        { label: 'Purchase', key: 'date' },
                                      ]}>
                                        Export
                                    </CSVLink>
                            }
                            {ordersType==="get_orders" &&
                                <>
                                <button type="button" className={`button-icon button-icon-sort`} onClick={(e)=>{setShowSortingOpt(!showSortingOpt)}}><span>Sort</span></button>
                                <ul className={`sort-actions absolute rounded-lg bg-white overflow-hidden top-full right-0 ${!showSortingOpt ? 'hidden' : ''}`}>
                                    <li className={filterBy==='all' ? 'active' : ''}><div className='cursor-pointer' onClick={()=>{sortBy('all')}}>All</div></li>
                                    <li className={filterBy==='Succeeded' ? 'active' : ''}><div className='cursor-pointer' onClick={()=>{sortBy('Succeeded')}}>Succeeded</div></li>                                
                                    <li className={filterBy==='Incomplete' ? 'active' : ''}><div className='cursor-pointer' onClick={()=>{sortBy('Incomplete')}}>Incomplete</div></li>
                                    <li className={filterBy==='Requested' ? 'active' : ''}><div className='cursor-pointer' onClick={()=>{sortBy('Requested')}}>Requested</div></li>
                                    <li className={filterBy==='Scheduled' ? 'active' : ''}><div className='cursor-pointer' onClick={()=>{sortBy('Scheduled')}}>Scheduled</div></li>
                                    <li className={filterBy==='Overdue' ? 'active' : ''}><div className='cursor-pointer' onClick={()=>{sortBy('Overdue')}}>Overdue</div></li>
                                    <li className={filterBy==='Cancelled' ? 'active' : ''}><div className='cursor-pointer' onClick={()=>{sortBy('Cancelled')}}>Cancelled</div></li>
                                    <li className={filterBy==='Tips' ? 'active' : ''}><div className='cursor-pointer' onClick={()=>{sortBy('Tips')}}>Club Tips</div></li>
                                </ul>
                                </>
                            }
                        </div>               
                    </div>
                    <div className="shop-orders-list">                        
                        <div className="lg:table w-full">
                            <div className="hidden lg:table-header-group table-head">
                                <div className="table-row">
                                    <div className="table-cell pl-[30px] border-b border-black-100/10">Products</div>
                                    <div className="table-cell py-[18px] border-b border-black-100/10">Member</div>
                                    <div className="table-cell py-[18px] border-b border-black-100/10">Date</div>
                                    <div className="table-cell py-[18px] w-[120px] border-b border-black-100/10">Amount</div>
                                    <div className="table-cell py-[18px] border-b border-black-100/10">{ordersType==="get_orders" ? 'Stripe ID' : 'Collected by'}</div>
                                    <div className="table-cell py-[18px] w-[180px] border-b border-black-100/10">Status</div>
                                </div>
                            </div>
                            <div className="lg:table-row-group table-body">                            
                                {search(orderlist) !== undefined ? (
                                    <>
                                        {search(orderlist).map((orders,index) => {                        
                                            return (                                            
                                                <OrderRow team={team} orders={orders} key={index} refreshShopOrders={refreshShopOrders} ordersType={ordersType} />
                                            )
                                        }
                                        )}
                                    </>
                                )
                                :null}
                            </div>
                        </div>              
                    </div>
                </div>
            </div>
        </>
        :
        <>
            <div className="py-5 text-center"><Spinner /></div>
        </>
    );
}
function ShopOrders(){
    const { id } = useParams();

    return(
        <>
            <Sidebar type="thin"/>          
            <TeamContextProvider id={id}>  
            <div id="page" className="page-shoporders">
                <AdminNavbar heading="Orders"/>
                <ShopOrdersOverview/>
            </div>            
            </TeamContextProvider>
        </>
    )
}

export default ShopOrders;