import { useState, useEffect } from "react";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import Spinner from "../../../components/Spinner/Spinner";
import { getMessageHistory } from "../../../libs/dataFunctions";
import placeholderImg from '../../../assets/images/placeholder.png';
import messageReadTick from '../../../assets/images/message-readtick.svg';
import messageUnReadTick from '../../../assets/images/message-unreadtick.svg';

dayjs.extend(utc);
dayjs.extend(tz);

const MessageDetailUsers = ({users}) =>{
    return(
    users.length > 0 &&
    <div className="rounded-lg p-4">
    {users.map((user)=>{
        return(
            <div key={user._id} className="flex justify-between items-center w-full text-[#363F6C] mb-2.5 pb-2.5 border-b last:border-0 last:mb-0">
                <div className='w-[44px] mr-2.5'>
                {user.profilePicture !== '' ?  
                    <img src={user.profilePicture} alt="" width={44} height={44} className="rounded-full w-[44px] h-[44px] cursor-pointer" />
                :
                    <img src={placeholderImg} alt="" width={44} height={44} className="rounded-full w-[44px] h-[44px] cursor-pointer" />
                }
                </div>
                <div className='member-info flex-1'>
                    <div className='pr-2.5 font-medium'>{user.name}</div>
                </div>
                <div className="text-[10px]">
                    {dayjs(user.read_at).format('DD MMM YYYY || hh:mm A')}
                </div>
            </div>
        )
    })}
    </div>
    )
}
const MessageDetail=({setShowForm, activeChatSource, detailMsgID})=>{
    const [loading,setLoading] = useState(false);
    const [messageDetailData, setMessageDetailData] = useState([]);
    const [messageSeenHistroy, setMessageSeenHistory] = useState({});

    const fetchMessageDetail=async()=>{
        setLoading(true);

        let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token') !== undefined
        ? localStorage.getItem('auth_token')
        : '';

        let [messageDataResponse] = await Promise.all([
            getMessageHistory(token, activeChatSource.conversation_id, detailMsgID)
        ]);

        setMessageDetailData(messageDataResponse.data);
        setMessageSeenHistory(messageDataResponse.data.message_seen_history);
        setLoading(false);
    }

    const AudioMessage = ({audioUrl}) =>{
        const extension = audioUrl.split(';')[0].split('/')[1];
        var audioType = 'audio/mp3';
        if(extension==='ogg'){
            audioType = 'audio/ogg';
        }
        else if(audioType==='wav'){
            audioType = 'audio/wav';
        }
        return(
            <audio controls controlsList={'nodownload noplaybackrate'}>
                <source src={audioUrl} type={audioType} />
                Your browser does not support the audio element.
            </audio>
        )
    }

    useEffect(()=>{
        fetchMessageDetail();
    },[]);
    return(
        <div className="popup-form">
            <div className="popup-form-wrap !w-full !max-w-[600px] relative !px-2.5 sm:!px-8 !bg-[#363F6C]">                    
                <button type="button" className="close-popup" onClick={(e)=>{setShowForm(false);}}><span>close</span></button>
                <div className="form-heading text-center mb-[30px] uppercase !text-white">Message Seen Info</div>
                <div className="relative chat-message-detail bg-white py-4 px-3.5 rounded-xl">
                    {!loading ?
                    <>
                    {Object.keys(messageDetailData).length !==0 ?
                        <>
                            <div className="chatboard-message__chats border-b border-[#6E7079] pb-4 mb-5">
                                <div className="chatboard-message__recipient flex flex-col items-end">
                                    <div className={`chatboard-message__recipient_msg type-${messageDetailData.message_type}`}>                                        
                                        {messageDetailData.message_type === 'text' &&
                                        <>
                                            <p>{messageDetailData.message}</p>
                                        </>
                                        }
                                        {messageDetailData.message_type === 'image' &&
                                        <>
                                            <div className='w-full'>
                                                <img src={messageDetailData.message} className='w-full object-fit rounded-xl' alt=''/>
                                            </div>
                                        </>
                                        }
                                        {messageDetailData.message_type === 'audio' &&
                                        <>
                                            <div className='w-full'>
                                                <AudioMessage audioUrl={messageDetailData.message} />                                                
                                            </div>
                                        </>
                                        }                                        
                                    </div>
                                    <p className='timestamp flex'>
                                        <span>{dayjs(messageDetailData.created_at).format('MMM DD, hh:mm A')}</span>
                                        {messageDetailData.is_read ?
                                            <img src={messageReadTick} width="14" height="14" alt="" className='ml-2.5' />
                                            :
                                            <img src={messageUnReadTick} width="14" height="14" alt="" className='ml-2.5' />
                                        }
                                    </p>
                                </div>
                            </div>
                            {activeChatSource.is_group ?
                                <>
                                {messageSeenHistroy.length > 0 &&
                                    <div className="chat-message-history">
                                        {messageSeenHistroy.map((msh,index)=>{
                                            return(
                                                <div key={index}>
                                                    <div className="ln-header flex justify-between items-center w-full flex-wrap pb-5 relative">
                                                        <h4 className="title mr-5 uppercase text-[#363F6C] font-semibold">{msh.title}</h4>
                                                        <div className="sep"></div>
                                                    </div>
                                                    <MessageDetailUsers users={msh.users} />
                                                </div>
                                            )
                                        })}
                                    </div>
                                }
                                </>
                            :
                                <div>
                                    <div className="chat-message-history">
                                    {messageSeenHistroy.length > 0 &&
                                        <div className="chat-message-history">
                                            {messageSeenHistroy.map((msh,index)=>{
                                                return(
                                                    <div key={index}>
                                                        {msh.users.length > 0 &&
                                                            <>
                                                            {msh.users.map((user,index2)=>{
                                                                return(
                                                                    <>
                                                                    <div className="flex justify-between items-center mb-2.5" key={index2}>
                                                                        <div className="font-medium text-[#363F6C]">Read</div>
                                                                        {user.read_at!=='' &&
                                                                            <div className="text-[12px]">{dayjs(user.read_at).format('DD MMM YYYY || hh:mm A')}</div>
                                                                        }
                                                                    </div>
                                                                    <div className="flex justify-between items-center" key={index2}>
                                                                        <div className="font-medium text-[#363F6C]">Delivered</div>
                                                                        <div className="text-[12px]">{dayjs(messageDetailData.created_at).format('DD MMM YYYY || hh:mm A')}</div>                                                                        
                                                                    </div>
                                                                    </>
                                                                )
                                                            })}
                                                            </>
                                                        }
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    }
                                    </div>
                                </div>
                            }
                        </>
                    :
                        <div>Details not available.</div>
                    }
                    </>
                    :
                    <>
                        <div className="text-center py-5"><Spinner /></div>
                    </>
                    }
                </div>
            </div>
        </div>
    )
}
export default MessageDetail;
