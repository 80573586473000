import { useEffect, useState } from "react";
import * as DOMPurify from 'dompurify';
import { getGameSubscriptionPlans, getMyPaymentCards } from "../../../libs/dataFunctions";
import Spinner from "../../../components/Spinner/Spinner";
import { formatCreditCardNumber, formatExpirationDate, formatCVC, getIsUser } from '../../../libs/utils';
import CountriesList from '../../../libs/countries';
import Amex from '../../../assets/images/amex.svg';
import Discover from '../../../assets/images/discover.svg';
import DinnersClub from '../../../assets/images/dinersclub.svg';
import Jcb from '../../../assets/images/jcb.svg';
import Mastercard from '../../../assets/images/mastercard.svg';
import UnionPay from '../../../assets/images/unionpay.svg';
import Visa from '../../../assets/images/visa.svg';

const CardIcon=({value})=>{
    const icons = {
        'amex':Amex,
        'mastercard':Mastercard,
        'visa':Visa,
        'discover':Discover,
        'jcb':Jcb,
        'dinersclub': DinnersClub,
        'uninpay': UnionPay,
        'elo':null,
        'hipercard':null,
        'troy':null,
        'maestro':null,
        'laser':null
    }
    if (!value) {
        return null;
    }
    
    return(
        icons[value]!==null ? <img src={icons[value]} alt="" width={24} /> : null
    )
}

const CFGSubscriptionForm=({setShowForm, teamID})=>{
    const [loading, setLoading] = useState(false);
    const [planPackages, setPlanPackages] = useState([]);
    const [currentCardID,setCurrentCardID]  = useState('');
    const [paymentCards,setPaymentCards] = useState([]);
    const [cardNum, setCardNum] = useState('');
    const [cardExpiry, setCardExpiry] = useState('');
    const [cardCVV, setCardCVV] = useState('');
    const [country, setCountry] = useState('');
    const [cardicon, setCardIcon] = useState(null);
    const [cardHolderName, setCardName] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccessMsg] = useState('');

    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
    ? localStorage.getItem('auth_token')
    : '';

    const refreshPlan = async()=>{
        setLoading(true);
        const [palandata, mycardsData] = await Promise.all([
            getGameSubscriptionPlans(token),
            getMyPaymentCards(token),
        ]);
        setPlanPackages(palandata.data);
        if(mycardsData.data && mycardsData.data.length > 0){
            setPaymentCards(mycardsData.data);
            mycardsData.data.forEach(element => {
                if(element.default_source){
                    setCurrentCardID(element.id);
                }
            });
        }
        setLoading(false);
    }

    const handleOnSubmit=async(e)=>{
        e.preventDefault();
        setSuccessMsg('');
        setError('');
        if(currentCardID !== ''){
            setLoading(true);
            let formdata = new FormData();
            formdata.append('type','purchase_fantasy_league');
            formdata.append('team_id',teamID);
            formdata.append('card_id',currentCardID);
            formdata.append('redirect_url', 'Yes');

            var secureHeader = new Headers();
            secureHeader.append('Authorization','Bearer ' + token);
            secureHeader.append('device_type','W');

            const requestOptions = {
                method: 'POST',
                redirect: 'follow',
                body: formdata,
                headers: secureHeader
            }

            await fetch(process.env.REACT_APP_APIURL + '/app_fantasy' , requestOptions)
            .then(response => response.text())
            .then(result => {
                let rdata = JSON.parse(result);
                if(rdata.status !== 1){
                    setLoading(false);
                    setError(rdata.message);
                }
                else{
                    if(rdata.redirct_url!==''){
                        window.location.href = rdata.redirct_url;
                    }
                    else{
                        window.location.href = '/team/'+teamID;
                    }
                }
            })
            .catch(error => console.log('error', error));
        }
        else{
            if(cardNum!=="" && cardExpiry!=="" && cardCVV!=="" && country!==""){
                setLoading(true);
                setError('');
                let formdata = new FormData();
                let expiry = cardExpiry.split("/");
                let today, cardday;
                today = new Date();
                cardday = new Date();
                cardday.setFullYear(parseInt(expiry[1])+2000,expiry[0]-1,0);
                if(cardicon==='amex'){
                    if(cardNum.replace(/\D+/g, '').length<15){
                        setError("Invalid card number");
                        if(cardCVV.length<4){
                            setError("Invalid card number, cvc number");
                        }
                        return;
                    }
                    if(cardCVV.length<4){
                        setError("Invalid CVC number");
                        return;
                    }
                }
                else if(cardicon==='dinersclub'){
                    if(cardNum.replace(/\D+/g, '').length<14){
                        setError("Invalid card number");
                        return;
                    }
                }
                else{
                    if(cardNum.replace(/\D+/g, '').length<16){
                        setError("Invalid card number");
                        return;
                    }
                }
                if (cardday < today) {
                    setError("Invalid expiry date");
                }
                else{
                    formdata.append('type','add_card');                    
                    formdata.append('card_number',cardNum);
                    formdata.append('name',cardHolderName);
                    formdata.append('exp_year',expiry[1]);
                    formdata.append('exp_month',expiry[0]);
                    formdata.append('country',country);
                    formdata.append('cvc',cardCVV);
                    formdata.append('is_default_card','Yes');

                    var secureHeader2 = new Headers();
                    secureHeader2.append('Authorization','Bearer ' + token);
                    secureHeader2.append('device_type','W');
                    const requestOptions = {
                        method: 'POST',
                        redirect: 'follow',
                        body: formdata,
                        headers: secureHeader2
                    }
                    
                    await fetch(process.env.REACT_APP_APIURL + '/app_stripe_card' , requestOptions)
                    .then(response => response.text())
                    .then(result => {
                        let rdata = JSON.parse(result);
                        if(rdata.status !== 1){
                            setLoading(false);
                            setError(rdata.message);
                        }
                        else{
                            let formdata2 = new FormData();
                            formdata2.append('type','purchase_fantasy_league');
                            formdata2.append('team_id',teamID);
                            formdata2.append('card_id',rdata.data[0].id);
                            formdata2.append('redirect_url', 'Yes');

                            var secureHeader2 = new Headers();
                            secureHeader2.append('Authorization','Bearer ' + token);
                            secureHeader2.append('device_type','W');

                            const requestOptions2 = {
                                method: 'POST',
                                redirect: 'follow',
                                body: formdata,
                                headers: secureHeader2
                            }

                            fetch(process.env.REACT_APP_APIURL + '/app_fantasy' , requestOptions2)
                            .then(response2 => response2.text())
                            .then(result2 => {
                                let rdata2 = JSON.parse(result2);
                                if(rdata2.status !== 1){
                                    setLoading(false);
                                    setError(rdata2.message);
                                }
                                else{
                                    if(rdata2.redirct_url!==''){
                                        window.location.href = rdata2.redirct_url;
                                    }
                                    else{
                                        window.location.href = '/team/'+teamID;
                                    }
                                }
                            })
                            .catch(error => console.log('error', error));
                        }
                    })
                    .catch(error => console.log('error', error));
                }            
            }
            else{
                setError("Please fill all fields above.");
            }
        }
    }

    useEffect(()=>{
        refreshPlan();
    },[]);

    return(
        !loading ?
        <>
            <button type="button" className="close-popup" onClick={(e)=>{setShowForm(false)}}><span>close</span></button>
            <div className="form-heading text-center mb-[30px]">Subscriptions</div>
            <form onSubmit={handleOnSubmit} className="form-wrapper link-form-wrapper">
                <div className="flex mb-5 justify-center">
                    <div className="rounded-[10px] px-2 w-1/2 relative">
                        <label className="relative rounded-[10px] bg-[#363F6C] p-3.5 flex flex-col text-center cursor-pointer text-[#FBDB09] botder-[#363F6C] peer-checked:border-[#FBDB09] border-[2px]">
                            <div className="text-white text-[14px] leading-[21px]">Pay</div>
                            <div className="text-[38px] leading-[38px] font-semibold">{planPackages.amount}</div>
                            <div className="text-white text-[14px] leading-[21px]">{planPackages.duration}</div>
                            <span className="absolute top-[10px] right-[10px]">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.6 13.8L8.425 11.625C8.24167 11.4417 8.01267 11.3543 7.738 11.363C7.46267 11.371 7.23333 11.4667 7.05 11.65C6.86667 11.8333 6.775 12.0667 6.775 12.35C6.775 12.6333 6.86667 12.8667 7.05 13.05L9.9 15.9C10.0833 16.0833 10.3167 16.175 10.6 16.175C10.8833 16.175 11.1167 16.0833 11.3 15.9L14.1375 13.0625L16.975 10.225C17.1583 10.0417 17.246 9.81233 17.238 9.537C17.2293 9.26233 17.1333 9.03333 16.95 8.85C16.7667 8.66667 16.5333 8.575 16.25 8.575C15.9667 8.575 15.7333 8.66667 15.55 8.85L13.075 11.325L10.6 13.8ZM12 22C10.6167 22 9.31667 21.7373 8.1 21.212C6.88333 20.6873 5.825 19.975 4.925 19.075C4.025 18.175 3.31267 17.1167 2.788 15.9C2.26267 14.6833 2 13.3833 2 12C2 10.6167 2.26267 9.31667 2.788 8.1C3.31267 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.31233 8.1 2.787C9.31667 2.26233 10.6167 2 12 2C13.3833 2 14.6833 2.26233 15.9 2.787C17.1167 3.31233 18.175 4.025 19.075 4.925C19.975 5.825 20.6873 6.88333 21.212 8.1C21.7373 9.31667 22 10.6167 22 12C22 13.3833 21.7373 14.6833 21.212 15.9C20.6873 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6873 15.9 21.212C14.6833 21.7373 13.3833 22 12 22ZM12 20C14.2167 20 16.1043 19.221 17.663 17.663C19.221 16.1043 20 14.2167 20 12C20 9.78333 19.221 7.89567 17.663 6.337C16.1043 4.779 14.2167 4 12 4C9.78333 4 7.896 4.779 6.338 6.337C4.77933 7.89567 4 9.78333 4 12C4 14.2167 4.77933 16.1043 6.338 17.663C7.896 19.221 9.78333 20 12 20Z" fill="#FBDB09"/>
                                </svg>
                            </span>                            
                        </label>
                    </div>
                </div>
                <div className="text-left text-black text-[16px] leading-[21px] mb-5 subscription-plan-description" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(planPackages.description)}} />
                {paymentCards.length > 0 ? 
                    <>  
                        <label>Select card</label>
                        <div className='form-field-wrap w-full  mb-5 no-field-wrap relative'>
                            <select onChange={(e)=>{setCurrentCardID(e.target.value)}}>
                                {paymentCards.map((card,index) => {
                                    return(
                                        <option key={card.id} value={card.id}>****{card.last4} ({card.name})</option>
                                    )
                                })}
                            </select>
                        </div>
                        {error!=="" && <p className='text-red-700 error mb-5'>{error}</p>}
                        {success!=="" && <p className='text-green-600 error mb-5'>{success}</p>}
                        <div className='form-field-wrap mb-5 w-full no-field-wrap relative text-center'>                    
                            <button type="submit" className='button button--large'>Subscribe</button>                            
                        </div>
                    </>
                :
                    <div className='flex flex-wrap'>
                        <div className='form-field-wrap w-full mb-5 no-field-wrap relative'>
                            <input 
                                placeholder="0000 0000 0000 0000" className='text-field' 
                                type='tel'
                                name='number'
                                maxLength='19'
                                required
                                autoComplete="off"
                                onChange={(e)=>{
                                    e.target.value = formatCreditCardNumber(e.target.value);
                                    setCardNum(e.target.value);
                                    let icon = getIsUser(e.target.value);
                                    setCardIcon(icon);
                                    setError('');
                                }}
                            />
                            <div className='absolute top-[18px] right-[20px]'>
                                <CardIcon value={cardicon}/>
                            </div>
                        </div>
                        <div className='form-field-wrap w-full sm:w-1/2 mb-5 no-field-wrap sm:pr-1 sm:pl-1 relative'>
                            <input 
                                placeholder="MM/YY" className='text-field' 
                                type='tel'
                                name='expiry'
                                pattern='\d\d/\d\d'
                                required
                                autoComplete="off"
                                onChange={(e)=>{
                                    e.target.value = formatExpirationDate(e.target.value);
                                    setCardExpiry(e.target.value);
                                    setError('');
                                }}
                            />                                
                        </div>
                        <div className='form-field-wrap w-full sm:w-1/2 mb-5 no-field-wrap sm:pl-1 relative'>
                            <input 
                                type='tel'
                                name='cvc'
                                className='text-field'
                                placeholder='CVC'
                                required
                                autoComplete="off"
                                onChange={(e)=>{
                                    e.target.value = formatCVC(e.target.value, {number:cardNum});
                                    setCardCVV(e.target.value);
                                    setError('');
                                }}
                            />                                
                        </div>
                        <div className='form-field-wrap w-full mb-5 no-field-wrap relative'>
                            <input 
                                type='text'
                                name='name'
                                className='text-field'
                                placeholder='Card Holder Name'
                                required
                                autoComplete="off"
                                onChange={(e)=>{
                                    setCardName(e.target.value);
                                    setError('');
                                }}
                            />                                
                        </div>
                        <div className='form-field-wrap w-full mb-5 select-field-wrap country-field-wrap relative'>
                            <select name="country" onChange={(e)=>{setCountry(e.target.value)}}>
                                <option value="">Select Country *</option>
                                {CountriesList.map((c,index)=>{
                                    return(
                                        <option key={index} value={c.code}>{c.name}</option>
                                    );
                                })}
                            </select>
                        </div>
                        {error!=="" && <p className='text-red-700 error mb-5'>{error}</p>}
                        {success!=="" && <p className='text-green-600 error mb-5'>{success}</p>}
                        <div className='form-field-wrap mb-5 w-full text-center no-field-wrap relative'>                    
                            <button type="submit" className='button button--large'>Subscribe</button>                            
                        </div>
                    </div>
                }
            </form>
        </>
        :
        <>
            <div className="py-5 text-center"><Spinner /></div>
        </>
    )
}

export default CFGSubscriptionForm;