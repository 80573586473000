import { useState, useRef, useEffect } from "react";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import placeholderImg from '../../../assets/images/placeholder.png';
import messageReadTick from '../../../assets/images/message-readtick.svg';
import messageUnReadTick from '../../../assets/images/message-unreadtick.svg';
import chatDotMenu from '../../../assets/images/chat-dotmenu.svg';
import chatPinImg from '../../../assets/images/chat-pin.svg';
import { getConversationsDetails } from "../../../libs/dataFunctions";
import { useDispatch, useSelector } from "react-redux";
import { deleteConversationAction, updateConversationPinAction, leaveConversationAction, updateConversationAction } from "../../../Redux/conversationListSlice";
import { fetchActiveChatAction, deleteActiveChatAction } from "../../../Redux/activeChatSlice";
import { withTimeout } from "../../../libs/utils";

dayjs.extend(utc);
dayjs.extend(tz);

const ChatConvesationRow=({conversation, team, setActiveChatLoading,socket, enableArchiveMode, selectedMembers, setSelectedMembers, setmobShowChatboard})=>{
    const [openMenu, setOpenMenu] = useState(false);
    const [loading, setLoading] = useState(false);
    const ref = useRef();
    const dispatch = useDispatch();
    const activeChat = useSelector(state=>state.activeChat);
    const activeChatSource = activeChat.activeChatSource;

    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token') !== undefined
        ? localStorage.getItem('auth_token')
        : '';

    var selectedMemberArray = [...selectedMembers];
    const handleSelectMember=()=>{
        if (selectedMemberArray.indexOf(conversation.conversation_id) !== -1) {
            setSelectedMembers(selectedMembers.filter((item) => {return !item.includes(conversation.conversation_id)}));
        }
        else{
            setSelectedMembers([...selectedMembers, conversation.conversation_id]);
        }
    }

    const handleChatConversation = async(e) => {
        setLoading(true);
        setActiveChatLoading(true);
        setmobShowChatboard(true);
        let [tmpData] = await Promise.all([
            getConversationsDetails(token, team._id, conversation.conversation_id)
        ]);

        if(tmpData.status===1){
            dispatch(fetchActiveChatAction({chatSource:conversation,messages:tmpData.data}));
        }
        else{
            dispatch(fetchActiveChatAction({chatSource:{},messages:[]}));
        }

        let updateConversation = {...conversation};
            updateConversation.unread_count = 0;
            dispatch(updateConversationAction(updateConversation));
            
        if(tmpData.all_read_by_conversation!==""){            
            socket.emit('read_message',tmpData.all_read_by_conversation);
        }
        setLoading(false);
        setActiveChatLoading(false);
    }

    const handlePin=async(flag)=>{
        setOpenMenu(false);
        dispatch(updateConversationPinAction({conversation:conversation, flag: flag}));
        
        var formdata = new FormData();
        formdata.append("type", "pin_conversation");
        formdata.append("conversation_id", conversation.conversation_id);
        
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        await fetch(process.env.REACT_APP_APIURL + '/chat' , requestOptions)
            .then(response => response.text())
            .then(result => {
                let rdata = JSON.parse(result);                
            })
            .catch(error => console.log('error', error));
    }

    const handleLeaveGroup = async() =>{
        setOpenMenu(false);
        if(window.confirm("Are you sure to leave this group?")){
            dispatch(leaveConversationAction(conversation));
            var formdata = new FormData();
            formdata.append("type", "leave_conversation");
            formdata.append("conversation_id", conversation.conversation_id);
            
            var secureHeader = new Headers();
            secureHeader.append('Authorization','Bearer ' + token);
            secureHeader.append('device_type','W');
            const requestOptions = {
                method: 'POST',
                redirect: 'follow',
                body: formdata,
                headers: secureHeader
            };

            await fetch(process.env.REACT_APP_APIURL + '/chat' , requestOptions)
                .then(response => response.text())
                .then(result => {
                    let rdata = JSON.parse(result);
                    if(rdata.status === 1){
                        socket.emit('leave_conversion',JSON.stringify({"conversation_id":conversation.conversation_id}), withTimeout((response) => {
                            console.log("success!" + response);
                        }, (response) => {
                            console.log(response);
                        }, 1500));
                    }                    
                })
                .catch(error => console.log('error', error));
        }
    }

    const handleDeleteConversation = async() =>{
        setOpenMenu(false);
        if(window.confirm("Are you sure to delete this chat?")){            
            dispatch(deleteConversationAction(conversation.conversation_id));
            if(activeChatSource.conversation_id === conversation.conversation_id){
                dispatch(deleteActiveChatAction(conversation));
            }
            var formdata = new FormData();
            formdata.append("type", "delete_conversation");
            formdata.append("conversation_id", conversation.conversation_id);
            formdata.append("team_id",team._id);
            
            var secureHeader = new Headers();
            secureHeader.append('Authorization','Bearer ' + token);
            secureHeader.append('device_type','W');
            const requestOptions = {
                method: 'POST',
                redirect: 'follow',
                body: formdata,
                headers: secureHeader
            };

            await fetch(process.env.REACT_APP_APIURL + '/chat' , requestOptions)
                .then(response => response.text())
                .then(result => {
                    let rdata = JSON.parse(result);
                })
                .catch(error => console.log('error', error));
        }
    }

    const handleArchieveSingle=async()=>{
        setOpenMenu(false);
        if(window.confirm(!conversation.is_archive ? "Are you sure to archive this chat?" : "Are you sure to Unarchive this chat?")){            
            
            dispatch(deleteConversationAction(conversation.conversation_id)); //Remove from state
            if(activeChatSource.conversation_id === conversation.conversation_id){
                dispatch(deleteActiveChatAction(conversation));
            }
            var formdata = new FormData();
            formdata.append("type", "archive_conversation");
            formdata.append("conversation_ids", conversation.conversation_id);
            formdata.append("team_id",team._id);
            formdata.append("separate_by","@#");
            
            var secureHeader = new Headers();
            secureHeader.append('Authorization','Bearer ' + token);
            secureHeader.append('device_type','W');
            const requestOptions = {
                method: 'POST',
                redirect: 'follow',
                body: formdata,
                headers: secureHeader
            };

            await fetch(process.env.REACT_APP_APIURL + '/chat' , requestOptions)
                .then(response => response.text())
                .then(result => {
                    let rdata = JSON.parse(result);                
                })
                .catch(error => console.log('error', error));
        }
    }

    useEffect(()=>{
        const checkIfClickedOutside = e => {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            if (ref.current && !ref.current.contains(e.target)) {
                setOpenMenu(false);
            }
        }
        document.addEventListener("mousedown", checkIfClickedOutside);      
        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    },[setOpenMenu]);

    return(
        <>
            <div className="chat-conversation">
                {!enableArchiveMode ?
                <>
                    <div className='flex items-center flex-1' onClick={(e)=>{handleChatConversation(e)}}>                    
                        <div className='w-[40px] xl:w-[50px] mr-1 xl:mr-2.5'>
                        {conversation.profilePicture !== '' ?  
                            <img src={conversation.profilePicture} alt="" width={50} height={50} className="rounded-full w-[40px] xl:w-[50px] h-[40px] xl:h-[50px] cursor-pointer" />
                        :
                            <img src={placeholderImg} alt="" width={50} height={50} className="rounded-full w-[40px] xl:w-[50px] h-[40px] xl:h-[50px] cursor-pointer" />
                        }
                        </div>
                        <div className='conversation-info flex-1 w-[100px]'>
                            <div className='flex w-full justify-between items-center'><span className='text-[#363F6C] font-semibold text-[12px] xl:text-[16px] pr-2.5 flex-1'>{conversation.name}</span><span className="w-[65px] xl:w-auto text-[7px] xl:text-[10px] font-light">{dayjs(conversation.last_message_date).format('DD MMM YYYY | hh:mm A')}</span></div>
                            <div className='flex w-full'>
                                {conversation.last_message_send_by_me &&
                                <>
                                {conversation.last_message_read ?
                                    <img src={messageReadTick} width="14" height="14" alt="" className='mr-[5px]' />
                                :
                                    <img src={messageUnReadTick} width="14" height="14" alt="" className='mr-[5px]' />
                                }
                                </>
                                }
                                <div className='text-[9px] xl:text-[11px] truncate overflow-hidden'>
                                    {conversation.last_message_type==='text' &&
                                    <>
                                    {conversation.last_message}
                                    </>
                                    }
                                    {conversation.last_message_type==='image' &&
                                        "Image"
                                    }
                                    {conversation.last_message_type==='audio' &&
                                        "Audio"
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex w-[20px] xl:w-[30px] items-center relative' ref={ref}>
                        <img src={chatDotMenu} width={30} height={30} className="w-[20px] xl:w-[30px] h-[20px] xl:h-[30px] cursor-pointer" alt="Options" title="Options" onClick={()=>{setOpenMenu(!openMenu)}} />
                        <div className={`chat-conversation-menu ${openMenu ? 'flex flex-col' : 'hidden'}`}>
                            {!conversation.is_archive &&
                            <>
                                {!conversation.is_pin ? 
                                    <button className="chat-conversation-menu-item" type="button" onClick={()=>{handlePin(true)}}>Pin</button>
                                :
                                    <button className="chat-conversation-menu-item" type="button" onClick={()=>{handlePin(false)}}>Unpin</button>
                                }
                            </>
                            }
                            
                            {!conversation.is_archive ?
                                <button className="chat-conversation-menu-item" type="button" onClick={()=>{handleArchieveSingle()}}>Archive</button>
                                :
                                <button className="chat-conversation-menu-item" type="button" onClick={()=>{handleArchieveSingle()}}>Unarchive</button>
                            }
                            {conversation.is_group ?
                            <>
                                {!conversation.is_leave_from_group ?
                                    <button className="chat-conversation-menu-item" type="button" onClick={()=>{handleLeaveGroup()}}>Leave Chat</button>
                                :
                                    <button className="chat-conversation-menu-item" type="button" onClick={()=>{handleDeleteConversation()}}>Delete Chat</button>
                                }
                            </>
                            :
                            <>
                                <button className="chat-conversation-menu-item" type="button" onClick={()=>{handleDeleteConversation()}}>Delete Chat</button>
                            </>
                            }
                        </div>
                        <div className="absolute bottom-[-5px] left-0 right-0 mx-auto flex justify-end items-center">
                        {conversation.is_pin &&
                            <div className="chat-conversation-pin w-[12px] xl:w-[16px] h-[12px] xl:h-[16px]">
                                <img src={chatPinImg} width={16} height={16} className="w-[12px] xl:w-[16px] h-[12px] xl:h-[16px]" alt="Pin" title="Pin" />
                            </div>
                        }
                        {conversation.unread_count > 0 &&
                            <div className="w-[16px] h-[16px] rounded-full bg-[#363F6C] text-white text-[10px] leading-[10px] flex justify-center items-center">
                                <span>{conversation.unread_count}</span>
                            </div>
                        }
                        </div>
                    </div>
                </>
                :
                <>
                    <div className='flex items-center flex-1'>                    
                        <div className="w-[20px] xl:w-[30px]">
                            <button className="w-[18px] h-[18px]" onClick={()=>{handleSelectMember();}}>
                                {selectedMemberArray.indexOf(conversation.conversation_id) !== -1 ?
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="18" height="18" rx="9" fill="#363F6C"/>
                                        <path d="M6 9L8.25 11L12 7" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>                                    
                                :
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g opacity="0.5">
                                        <rect x="0.5" y="0.5" width="17" height="17" rx="8.5" stroke="#363F6C"/>
                                        </g>
                                    </svg>
                                }                                
                            </button>
                        </div>
                        <div className='w-[50px] mr-2.5'>
                        {conversation.profilePicture !== '' ?  
                            <img src={conversation.profilePicture} alt="" width={50} height={50} className="rounded-full w-[40px] xl:w-[50px] h-[40px] xl:h-[50px]" />
                        :
                            <img src={placeholderImg} alt="" width={50} height={50} className="rounded-full w-[40px] xl:w-[50px] h-[40px] xl:h-[50px]" />
                        }
                        </div>
                        <div className='conversation-info flex-1'>
                            <div className='flex w-full justify-between items-center'><span className='text-[#363F6C] font-semibold text-[14px] xl:text-[16px] pr-2.5'>{conversation.name}</span></div>
                        </div>
                    </div>                    
                </>
                }
            </div>
        </>
    )
}

export default ChatConvesationRow;