import { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc'
import tz from 'dayjs/plugin/timezone'
import * as yup from 'yup';
import ReactDatePicker from "react-datepicker";
import PhoneCodeList from '../../../libs/phonecodes';
dayjs.extend(utc);
dayjs.extend(tz);
const schema = yup.object({
    phonecode: yup.string().required('Phone Code is required.'),
    phone: yup.number().typeError('Mobile number is required.'),
    birthDate: yup.string().when('bdatenottosay',{
        is:false,
        then:yup.string().required('Birthdate is required').nullable()
    }).nullable(),            
}).required();

const BasicInfoForm=({setShowForm, member, team, refreshMembers})=>{
    const [loading,setLoading] = useState(false);
    const [isBdateNotPreffer, setBdateNotPreffer] = useState(false);
    const [message, setMessage] = useState(null);
    const { register, handleSubmit, setValue, control, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });

    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
    ? localStorage.getItem('auth_token')
    : '';

    const onSubmit = async(data) => {
        setLoading(true);
        setMessage(null);
        var formdata = new FormData();
        
        formdata.append("type", "admin_update_member_information");
        formdata.append("team_id", team._id);
        formdata.append("user_sub", member.sub);
        if(member.same_child_subs.length > 0){
            formdata.append("same_child_subs", member.same_child_subs.join('@#'));
        }
        formdata.append("phoneNumber", data.phone);
        formdata.append("phoneCode", data.phonecode);
        if(!data.bdatenottosay){
            formdata.append("dateOfBirth",dayjs(data.birthDate).format('YYYY-MM-DD'));
        }
        else{
            formdata.append("is_above_18",data.is_above_18==='Yes' ? "Yes" : "No");
        }
        formdata.append('separate_by','@#');
        
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');  
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };          
        await fetch(process.env.REACT_APP_APIURL + '/app_members' , requestOptions)
        .then(response => response.text())
        .then(result => {
            setLoading(false);
            let rdata = JSON.parse(result);
            if(rdata.status!==1){
                setMessage(rdata.message);
            }
            else{
                setMessage(rdata.message);                
            }
        })
        .catch(error => console.log('error', error));
    }

    useEffect(() => {
        if(member.dateOfBirth!==""){
            if(member.dateOfBirth!=='over_18'){
                setValue('birthDate',new Date(member.dateOfBirth));
                setBdateNotPreffer(false);
            }
        }
        else{
            setValue('bdatenottosay',true);
            setBdateNotPreffer(true);
            if(member.is_above_18){
                setValue('is_above_18','Yes');                
            }
            else{
                setValue('is_above_18','No');
            }
        }
        setValue('phonecode',member.phoneCode);
        setValue('phone',member.phoneNumber);
    },[]);

    return(
        <>
        <div className="popup-form">
            <div className="popup-form-wrap popup-form-memberdetails relative !px-2.5 md:!px-8">
                <button type="button" className="close-popup" onClick={(e)=>{setShowForm(false);}}><span>close</span></button>
                <div className="form-heading text-center mb-[30px]">Edit Member Basic Info</div>
                <form onSubmit={handleSubmit(onSubmit)} className="form-wrapper link-form-wrapper">
                    <div className='flex flex-wrap'>
                        <label className="w-full text-[#363F6C] font-medium">Mobile Number</label>
                        <div className='form-field-wrap w-full sm:w-1/3 mb-5 no-field-wrap relative sm:pr-2.5'>
                            <select {...register("phonecode")} onChange={(e)=>setValue('phonecode', e.target.value, { shouldValidate: true })}>
                                <option value="">Code *</option>
                                {PhoneCodeList.map((c,index)=>{
                                    return(
                                        <option key={index} value={c.code}>{c.code}</option>
                                    );
                                })}
                            </select>
                        </div>
                        <div className='form-field-wrap w-full sm:w-2/3 mb-5 no-field-wrap relative'>
                            <input 
                                type="tel" id="phone" placeholder="Mobile Number *" className='text-field'
                                {...register("phone")}
                            />                                
                        </div>
                    </div>
                    {errors.phonecode && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.phonecode?.message}</p>}
                    {errors.phone && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.phone?.message}</p>}
                    {!isBdateNotPreffer &&
                        <>
                            <label className="w-full text-[#363F6C] font-medium">Date Of Birth</label>
                            <div className='form-field-wrap mb-5 select-field-wrap date-field-wrap relative'>
                                <Controller
                                    control={control}
                                    name='birthDate'
                                    errors={errors}
                                    render={({ field, ref }) => (
                                    <ReactDatePicker
                                        placeholderText='DD/MMM/YYYY'
                                        onChange={(date) => field.onChange(date)}
                                        selected={field.value}
                                        maxDate={new Date()}
                                        mask="__-___-____"
                                        dateFormat="dd/MMM/yyyy"
                                        inputRef={ref}
                                        showYearDropdown
                                    />
                                )}
                                />
                            </div>
                            {errors.birthDate && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.birthDate?.message}</p>} 
                        </>
                    }               
                    <div className='form-field-wrap mb-5 check-field-wrap relative !p-0'>
                        <label htmlFor="bdatenottosay" className='!text-[12px] font-normal leading-[12px] uppercase justify-end items-center'>
                            <input 
                                type="checkbox" id="bdatenottosay" className='checkbox-field appearance-none h-3 w-3 rounded-sm bg-white align-top float-left mr-1 cursor-pointer'
                                {...register("bdatenottosay")}
                                onChange={(e)=>{
                                    if(e.target.checked){setBdateNotPreffer(true); setValue('is_above_18','Yes'); }else{setBdateNotPreffer(false);}
                                }}
                            />PREFER NOT TO SHARE DOB
                        </label>
                    </div>
                    {isBdateNotPreffer &&
                        <div className="form-field-wrap button-group-wrap mb-5 flex justify-between items-center">
                            <div className="flex-1 button-grop-label">Are you over the age of 18?</div>
                            <div className="r-button-group w-[148px] flex">
                                <div className="mr-4">
                                <input type="radio" {...register("is_above_18")} value="Yes" id="is_above_18-yes" className="peer hidden" />
                                <label
                                    htmlFor="is_above_18-yes"
                                    className="block py-[5px] w-[66px] cursor-pointer select-none rounded-[42px] text-center bg-[#EFF1F9] peer-checked:bg-[#FBDB03] peer-checked:text-black"
                                    >Yes</label>
                                </div>
                                <div>
                                <input type="radio" {...register("is_above_18")} value="No" id="is_above_18-no" className="peer hidden" />
                                <label
                                    htmlFor="is_above_18-no"
                                    className="block py-[5px] w-[66px] cursor-pointer select-none rounded-[42px] text-center bg-[#EFF1F9] peer-checked:bg-[#FBDB03] peer-checked:text-black"
                                    >No</label>
                                </div>
                            </div>
                        </div>
                    }
                    {!loading ?
                        <div className="w-full px-2.5">
                            <div className='flex justify-center'>
                                <input className='button button--large' type="submit" value="Save" disabled={loading}/>
                            </div>
                        </div>
                    :
                        <div className="text-center"><img className="mx-auto" src="data:image/svg+xml,%3Csvg version='1.1' id='loader-1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='40px' height='40px' viewBox='0 0 40 40' enable-background='new 0 0 40 40' xml:space='preserve'%3E%3Cpath opacity='0.2' fill='%23000' d='M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z'%3E%3C/path%3E%3Cpath fill='%23000' d='M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 20 20' to='360 20 20' dur='0.5s' repeatCount='indefinite'%3E%3C/animateTransform%3E%3C/path%3E%3C/svg%3E" width={40} height={40} alt="" /></div>
                    }
                    {message!==null &&
                        <div className="text-center pt-2.5">{message}</div>
                    }
                </form>
            </div>
        </div>
        </>
    )
}

export default BasicInfoForm;