import { useEffect, useState, useContext } from "react";
import { useParams, NavLink } from "react-router-dom";
import DOMPurify from "dompurify";
import Spinner from "../../../components/Spinner/Spinner";
import Sidebar from "../../../components/Sidebar/Sidebar";
import AdminNavbar from "../../../components/AdminNavbar/AdminNavbar";
import CSVReader from 'react-csv-reader'
import { TeamContext, TeamContextProvider } from "../../../contexts/TeamContext";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { getGroupArray } from "../../../libs/dataFunctions";

const MemberImportForm = () =>{
    const [loading,setLoading] = useState(false);
    const [errorMessage,setErrorMessage] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [data, setData] = useState(null);
    const [originalFile, setOriginalFile] = useState(null);
    const {team, teamUpdating } = useContext(TeamContext);
    const [grousArray, setGroupsArray] = useState([]);

    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
    ? localStorage.getItem('auth_token')
    : '';

    const handleForce = (data, fileInfo, originalFile) => {
        setData(null);
        setOriginalFile(null);
        setErrorMessage(null);
        setOriginalFile(originalFile);
        var keysArray = ["Email","First Name","Last Name","Phone Code", "Phone Number","Date Of Birth","Country","Groups","Gender(male/female)","accountType"];
        var keyError = false;
        var erField='';
        
        /*if (keysArray.length !== Object.keys(data[0]).length){
            erField = 'Numbers of fields not match.';
            keyError = true;
        }
        else {
            // Comparing each element of your array
            for (var i = 0; i < keysArray.length; i++) {
                if (keysArray[i] !== Object.keys(data[0])[i]) {
                    erField += keysArray[i]+',';
                    keyError = true;
                }
            }
            if(keyError){
                erField = erField.replace(/,*$/, '');
                erField = 'Field Names '+erField+' Not Match.';
            }
        }*/

        // Comparing each element of your array
        for (var i = 0; i < keysArray.length; i++) {
            if (keysArray[i] !== Object.keys(data[0])[i]) {
                erField += keysArray[i]+', ';
                keyError = true;
            }
        }
        if(keyError){
            erField = erField.replace(/,*$/, '');
            erField = 'Field Names '+erField+' Not Match.';
        }

        data = data.map((row) => {
            const { valid, missingGroups, groupsArr } = verifyRow(row);
            row.valid = valid;
            row.missingGroups=missingGroups;
            row.groupsArr=groupsArr;

            const regexyyyymmdd = /^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/gm;
            if(row["Date Of Birth"]==='over_18' || row["Date Of Birth"]==='below_18'){
                row.invaliddate= false;
            }
            else{
                if (!regexyyyymmdd.test(row["Date Of Birth"])) {
                    row.invaliddate= true;
                }
                else{
                    row.invaliddate= false;
                }
            }            
            return row;
        });        

        if (keyError) {
            //setErrorMessage("Fields <span class=\"font-bold\">"+erField+"</span> are invalid. Download template and compare your field names.");
            setHasError(true);
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                    <div className='confirm-ui'>
                        <div className="font-semibold uppercase text-[22px] leading-[30px] text-center mb-5">PLAI</div>
                        <div className="mb-2.5 text-center text-[14px]" role="alert">
                            <p className="mb-2 font-bold itallic">Swing and a Miss...</p>
                            <p className="mb-2">
                                <span className="font-bold">{erField}</span> Download template and compare your field names.
                            </p>
                        </div>
                        <div className="flex justify-center">
                            <button className="button button-blue mx-1" onClick={onClose}>Close</button>                                                
                        </div>
                    </div>
                    );
                }
            });
        } else {
            setHasError(false);
        }

        var dateerrors = data.filter((row) => row.invaliddate);
        if (!keyError && dateerrors.length > 0) {
            //setErrorMessage('<p class="mb-2 font-bold itallic">Swing and a Miss...</p><p class="mb-2">The DATES entered are in the incorrect format - Revise the dates on the sheet to a <strong>YYYY-MM-DD</strong> format (with dashes, not slashes!) and resubmit!</p><p class="mb-2">Need support? Simply reach out to <a href="mailto:support@plaisport.com">support@plaisport.com</a>.</p>');
            setHasError(true);
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                    <div className='confirm-ui'>
                        <div className="font-semibold uppercase text-[22px] leading-[30px] text-center mb-5">PLAI</div>
                        <div className="mb-2.5 text-center text-[14px]" role="alert">
                            <p className="mb-2 font-bold itallic">Swing and a Miss...</p><p className="mb-2">The DATES entered are in the incorrect format - Revise the dates on the sheet to a <strong>YYYY-MM-DD</strong> format (with dashes, not slashes!) and resubmit!</p><p className="mb-2">Need support? Simply reach out to <a href="mailto:support@plaisport.com" className="underline">support@plaisport.com</a>.</p>
                        </div>
                        <div className="flex justify-center">
                            <button className="button button-blue mx-1" onClick={onClose}>Close</button>                                                
                        </div>
                    </div>
                    );
                }
            });
        } else {
            if(!keyError && !dateerrors.length>0){
                setHasError(false);
            }
        }
        var errorsGroup = data.filter((row) => row.missingGroups.length>0);
        if (!keyError && !dateerrors.length > 0 && errorsGroup.length > 0) {
            //setErrorGroupMissing('We noticed that the some groups do not yet exist on your PLAI team page. Please check below list where groups which are display in <strong>Red</strong> are missing. Add those missing groups and submit')
            setHasError(true);
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                    <div className='confirm-ui'>
                        <div className="font-semibold uppercase text-[22px] leading-[30px] text-center mb-5">PLAI</div>
                        <div className="mb-2.5 text-center text-[14px]" role="alert">
                            <p className="mb-2 font-bold itallic">Swing and a Miss...</p><p className="mb-2">We noticed that the some groups do not yet exist on your PLAI team page. Please check below list where groups which are display in <strong>Red</strong> are missing. Add those missing groups and resubmit!</p><p className="mb-2">Need support? Simply reach out to <a href="mailto:support@plaisport.com" className="underline">support@plaisport.com</a>.</p>
                        </div>
                        <div className="flex justify-center">
                            <button className="button button-blue mx-1" onClick={onClose}>Close</button>                                                
                        </div>
                    </div>
                    );
                }
            });
        }
        else{
            if(!keyError && !errorsGroup.length>0 && !dateerrors.length > 0){
                setHasError(false);
            }
        }

        var errors = data.filter((row) => !row.valid);
        if (!keyError && !dateerrors.length > 0 && !errorsGroup.length > 0 && errors.length > 0) {
            //setErrorMessage("Found "+ (errors.length > 1 ? errors.length + " errors" : errors.length + " error") +" in your file. Download template and compare your data.");
            setHasError(true);
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                    <div className='confirm-ui'>
                        <div className="font-semibold uppercase text-[22px] leading-[30px] text-center mb-5">PLAI</div>
                        <div className="mb-2.5 text-center text-[14px]" role="alert">
                            <p className="mb-2 font-bold itallic">
                                Found {errors.length > 1 ? errors.length + " errors" : errors.length + " error"} in your file. Download template and compare your data.
                            </p>
                        </div>
                        <div className="flex justify-center">
                            <button className="button button-blue mx-1" onClick={onClose}>Close</button>                                                
                        </div>
                    </div>
                    );
                }
            });
        } else {
            if(!keyError && !errorsGroup.length>0 && !dateerrors.length > 0 && !errors.length > 0){
                setHasError(false);
            }
        }
        setData(data);
    }

    const requiredKeys = ['Email'];
    function verifyRow(data) {
        var missing = [];
        var missinggroups=[];
        var groupsArr=[];
        for (var i = 0; i < requiredKeys.length; i++) {
            if (!data[requiredKeys[i]]) {
                missing.push(requiredKeys[i])
            }
        }

        for (var key in data) {
            if(key === 'Groups'){
                const fileGroups=data[key].split(',');                
                for(var k = 0; k < fileGroups.length; k++){ 
                    groupsArr.push(fileGroups[k].trim());
                    if(!grousArray.includes(fileGroups[k].trim())){
                        missinggroups.push(fileGroups[k].trim());
                        missing.push(key);
                    }
                }
            }
        }
        //return missing.length<1;  
        return { valid: missing.length < 1, groupsArr:groupsArr, missingGroups: missinggroups }      
    }
    
    function cleanVariableName(variableName) {
        return variableName.replace(/_/g, ' ').replace('yyyy mm dd', 'yyyy-mm-dd')
    }
    const parseOptions = {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
        //transformHeader: (header) => header.toLowerCase().replace(/\W/g, '_'),
        transformHeader: (header) => header.trim(),
    }

    const convert2base64 = file =>new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    const handleSubmit = async(data) => {
        setLoading(true);
        const file = originalFile;
        setErrorMessage(null);
        if(file.type === 'text/csv'){
            let bs64code = await convert2base64(file);
            let bs64codeData = bs64code.replace('data:', '').replace(/^.+,/, '');
            var formdata = new FormData();
            formdata.append("type", "import_members");
            formdata.append("team_id", team._id);
            formdata.append("document_content", bs64codeData);
            formdata.append("document_extension", 'csv');

            var secureHeader = new Headers();
            secureHeader.append('Authorization','Bearer ' + token);
            secureHeader.append('device_type','W');
            const requestOptions = {
                method: 'POST',
                redirect: 'follow',
                body: formdata,
                headers: secureHeader
            };

            await fetch(process.env.REACT_APP_APIURL + '/app_members' , requestOptions)
            .then(response => response.text())
            .then(result => {
                let rdata = JSON.parse(result);
                if(rdata.status !== 1){
                    setLoading(false);
                    setErrorMessage(rdata.message);
                }
                else{
                    setLoading(false);
                    setData(null);
                    setOriginalFile(null);
                    setErrorMessage(null);
                    window.location.href='/team/'+team._id+'/members';
                }
            })
            .catch(error => console.log('error', error));
        }
        else{
            setErrorMessage('Invalid file type, please upload CSV file.');
        }
    }

    const handleReset=()=>{
        setData(null); 
        setErrorMessage(null);
        setHasError(false);
    }

    const fetchGrouArray=async()=>{
        const [groupArraydata]=await Promise.all([
            getGroupArray(token,team._id)
        ]);
        if(groupArraydata.status===1){
            setGroupsArray(groupArraydata.data);
        }
    }

    useEffect(()=>{
        if(teamUpdating){
            setLoading(true);
        }
        else{
            setLoading(false);
            fetchGrouArray();
        }
    },[teamUpdating]);

    return (
        !loading ?
        <>
            <div className='breadcrumb flex item-center pt-1.5 pb-1 pl-5 pr-7 w-full'>
                <NavLink to="/" className='home'>
                    <span>Dashboard</span>
                </NavLink>
                <span className='separator'>&lt;</span>
                <NavLink to={`/team/${team._id}`}>
                    {team.name}
                </NavLink>
                <span className='separator'>&lt;</span>
                Import Members
            </div>
            <div className="body px-5 lg:px-8 py-10">
                <div className="team-contents w-full rounded-xl bg-white py-6 px-6">
                    <div className="form-heading mb-[30px]">Import Members</div>
                    <div className="mb-5">
                        <p className="mb-5">
                            Here you can import your members via a spreadsheet file quickly and easily. Start by downloading{' '}
                            <a href="/plai-members-template-new.csv" className="button button--medium" download>
                                Download Template
                            </a>
                        </p>
                        <p className="mb-5">
                            Data imported such as Phone Number and Country will become part of the member's custom attributes, allowing
                            you to also view their PLAI profile data which may be more up to date.
                        </p>
                        <button className="button button--medium button-gray" onClick={() => {
                            window.open('https://calendly.com/plaisport_ceo/plai-import-support', '_blank')
                        }}>Book a demo</button>
                    </div>
                    <div className="w-full flex items-center justify-start mb-2.5">
                        {data===null &&
                            <label className="button button--medium button-blue cursor-pointer" style={{ height: 'fit-content' }} >
                                <span>Choose a file</span>
                                <CSVReader cssClass="react-csv-input" inputStyle={{ display: 'none' }} label="" onFileLoaded={handleForce} parserOptions={parseOptions} />
                            </label>
                        }
                        {(!hasError && data!==null) ? 
                            <>
                                <input className='button button--medium' type="button" onClick={()=>{handleSubmit()}} value="Confirm & Import" disabled={loading}/>
                            </>
                            :
                            <>
                                {data!==null &&
                                    <input className='button button--medium' type="button" onClick={()=>{handleReset()}} value="Clear" disabled={loading}/>
                                }
                            </>
                        }
                    </div>
                    {data && (
                        <div className="events-import">
                            {errorMessage !== null && 
                                <div className="text-red-500 mb-2.5 text-left text-[14px]" role="alert" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(errorMessage)}} />
                            }
                            <table className="table-auto w-full">
                                <thead>
                                <tr className="row">
                                    {data &&
                                    Object.keys(data[0]).map((key) => {
                                        if (key !== 'valid' && key !== 'invaliddate' && key!=='missingGroups' && key!=='groupsArr') {
                                            return (
                                                <th className="text-[12px] text-left px-2.5 py-2.5 border-b" key={key}>
                                                    {cleanVariableName(key)}
                                                </th>
                                            )
                                        } else {
                                            return null
                                        }
                                    })}
                                </tr>
                                </thead>
                                <tbody>
                                {data &&
                                    data.map((row, index) => (
                                    <tr className={`row`} key={index}>
                                        {Object.keys(row).map((key) => {
                                        if (key !== 'valid' && key !== 'invaliddate' && key!=='missingGroups' && key!=='groupsArr') {
                                            return (
                                            <td className={`text-[12px] text-left px-2.5 py-2.5 border-b ${key!=='Groups' && row.keys && row.keys.includes(key) ? 'data-error' : ''} ${row.invaliddate && key==='Date Of Birth' ? 'text-red-500': ''}`}
                                                key={key}>
                                                    {key!=='Groups' ?
                                                    <>{row[key]}</>
                                                    :
                                                    <ul>
                                                        {row['groupsArr'].map((acg,index)=>{                                                    
                                                                if(row['missingGroups'].includes(acg)){
                                                                    return(
                                                                        <li key={index} className="text-red-500 mr-1">{acg}</li>
                                                                    )
                                                                }
                                                                else{
                                                                    return(
                                                                        <li key={index}>{acg}</li>
                                                                    )
                                                                }                                                    
                                                        })}
                                                    </ul>
                                                    }
                                            </td>
                                            )
                                        } else {
                                            return null
                                        }
                                        })}
                                    </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
            </div>
        </>
        :
        <>
            <div className="py-5 text-center"><Spinner /></div>
        </>
    );
}


function MemberImport(){
    const { id } = useParams();

    return(
        <>
            <Sidebar type="thin"/>
            <TeamContextProvider id={id}>
                <div id="page" className="page-team">
                    <AdminNavbar heading="Import Members"/>
                    <MemberImportForm/>                    
                </div>
            </TeamContextProvider>
        </>
    )
}

export default MemberImport;