import { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import Slider from "react-slick";
import { GoogleMap, Marker, MarkerClusterer,  useJsApiLoader } from '@react-google-maps/api';
import IconMapMarker from "../../assets/images/icon-mapmarkerv2.svg";
import './SearchPage.scss';
import Sidebar from '../../components/Sidebar/Sidebar';
import Spinner from '../../components/Spinner/Spinner';
import { getAllTeams, getProfile } from '../../libs/dataFunctions';
import TeamCard from './TeamCard';
import ClubNotFoundPopup from './ClubNotFoundPopup';
const styles = require('./MapStyle.json');

const SearchMapComponent = ({containerStyle, activeMarker, setActiveMarker, zoom, setActiveZoom, currentLocation, clusterOptions, teams, setSearchTeams}) =>{  
    return(        
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={activeMarker}
            zoom={zoom}
            options={{ styles }}
        >
            <Marker
                position={{
                    lat: currentLocation.lat,
                    lng: currentLocation.lng
                }}
            />
            <MarkerClusterer options={clusterOptions}>
            {(clusterer) =>
                <>
                {teams.map((item, index) => {
                    if(item.latitude!==undefined && item.latitude!=="" && item.longitude!==undefined && item.longitude!==""){
                        return(
                            <Marker key={index}
                                icon={IconMapMarker}
                                clusterer={clusterer}
                                position={{
                                    lat: parseFloat(item.latitude),
                                    lng: parseFloat(item.longitude)
                                }}
                                onClick={() => {
                                    setSearchTeams([item]);
                                    let firstLoc = [];
                                    firstLoc['lat']= parseFloat(item.latitude);
                                    firstLoc['lng']= parseFloat(item.longitude);
                                    setActiveZoom(18);
                                    setActiveMarker(firstLoc);                                    
                                }}
                            />
                        )
                    }
                    return null;
                })}
                </>
            }
            </MarkerClusterer>
        </GoogleMap>
    )
}

const SearchMapPage = ()=>{
    const [loading,setLoading] = useState(false);
    const [searchLoading,setSearchLoading] = useState(false);
    const [teams,setAllTeams] = useState([]);
    const [error,setError] = useState('');
    const [searchedTeams,setSearchTeams] = useState([]);
    const [currentLocation, setCurrentLocation] = useState(null);
    const [isLocationPermission, setLocationPermission] = useState(false);
    const [permissionError, setPermissionError] = useState(null);
    const [defaultProps, setDefaultProps] = useState(null);
    const [activeMarker, setActiveMarker] = useState(null);
    const [activeZoom, setActiveZoom] = useState(null);
    const [userDetail, setUserDetail] = useState([]);
    const [searchKeyword , setSearchKeyWord] = useState('');
    const [showClubNotFoundPopup, setShowClubNotFoundPopup] = useState(false);

    const { register, handleSubmit, formState: { errors } } = useForm({});    

    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
    ? localStorage.getItem('auth_token')
    : '';

    const search=(q)=>{
        if(q!=='' && q!==null){
            return teams.filter((item) => {                
                return item.name.toString().toLowerCase().includes(q.toLowerCase());
            });
        }
        return [];
    }

    const onSubmit = (data) => {
        setSearchKeyWord(data.searchkey);

        if(data.searchkey!==''){
            setSearchLoading(true);
            let resultData = search(data.searchkey);
            //refreshMapteams(data.searchkey);
            if(resultData.length>0){
                if(resultData[0].latitude!=="" && resultData[0].longitude!==""){
                    let firstLoc = [];
                    firstLoc['lat']= parseFloat(resultData[0].latitude);
                    firstLoc['lng']= parseFloat(resultData[0].longitude);
                    setActiveMarker(firstLoc);
                    setActiveZoom(18);
                }
                setSearchTeams(resultData);
                setSearchLoading(false);
            }
            else{
                //setActiveMarker(currentLocation);
                //setActiveZoom(18);
                setSearchTeams([]);
                setSearchLoading(false);
            }
        }
    }
    
    async function refreshMapteams(lat,lng){
        setError('');
        setSearchLoading(true);
        const [allTeams] = await Promise.all([          
            //getSearchKeyTeams(token,searchkey,defaultProps.lat,defaultProps.lng),
            getAllTeams(token,lat,lng),
        ]);         
        if(allTeams.status===1){
            if(allTeams.data.length > 0){
                setAllTeams(allTeams.data);
                //setSearchTeams(allTeams.data);
                let ifirstLoc = [];
                ifirstLoc['lat']= parseFloat(allTeams.data[0].latitude);
                ifirstLoc['lng']= parseFloat(allTeams.data[0].longitude);
                /*setActiveMarker(ifirstLoc);
                setActiveZoom(20);*/
            }
        }
        else{
            setError(allTeams.message);
        }
        setSearchLoading(false);
    }
    const containerStyle = {
        width: '100%',
        height: '100%'
    };
    const clusterOptions = {
        imagePath:'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'
    }
    
    const settings = {
        className: "center",
        centerMode: true,
        infinite: searchedTeams.length > 1 ? true : false,
        centerPadding: 0,
        variableWidth: true,
        slidesToShow: searchedTeams.length > 3 ? 3 : 1,
        speed: 500,
        arrows: true,
        useTransform: false,
        focusOnSelect: false,
        afterChange: function(currentSlide) {            
            if(searchedTeams[currentSlide].latitude!=="" && searchedTeams[currentSlide].longitude!==""){
                let markerLoc = [];
                markerLoc['lat']= parseFloat(searchedTeams[currentSlide].latitude);
                markerLoc['lng']= parseFloat(searchedTeams[currentSlide].longitude);
                setActiveMarker(markerLoc);
                setActiveZoom(20);
            }
        },
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: searchedTeams.length > 3 ? 3 : 1,
                    arrows:false,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    arrows:false,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    arrows:false,
                }
            }
          ]
    };
    
    function getLocationPromise(){
        if ('geolocation' in navigator) {
            setLocationPermission(true);
            navigator.geolocation.getCurrentPosition(function (position) {
                let lat = parseFloat(position.coords.latitude)
                let lng = parseFloat(position.coords.longitude)
                // Resolving the values which I need
                setCurrentLocation({'lat':lng, 'lng': lat});
                setDefaultProps({center: {
                    lat: lat,
                    lng: lng
                    },
                    zoom: 7
                });
                
                refreshMapteams(lat,lng);
                refreshUserData();
            },
            function(error){
                switch(error.code) {
                    case error.PERMISSION_DENIED:
                        console.log("User denied the request for Geolocation.");
                        setPermissionError("Please allow location permisson to search teams and reload page.");
                        setLocationPermission(false);
                        break;
                    case error.POSITION_UNAVAILABLE:
                        console.log("Location information is unavailable.");
                        setPermissionError("Location information is unavailable.");
                        setLocationPermission(true);
                        break;
                    case error.TIMEOUT:
                        console.log("The request to get user location timed out.");
                        setPermissionError("The request to get user location timed out. Please try again by hard refresh");
                        setLocationPermission(true);
                        break;
                    case error.UNKNOWN_ERROR:
                    default:
                        console.log("An unknown error occurred.");
                        setPermissionError("An unknown error occurred. Please check your location permission settings.");
                        setLocationPermission(false);
                        break;
                    
                }
            })
    
        } else {
            setLocationPermission(false);
            console.log("your browser doesn't support geolocation API");
            setPermissionError("Your browser doesn't support geolocation API.");
        }
    }

    const refreshUserData = async() =>{
        setLoading(true);            
        const [profileData] = await Promise.all([
            getProfile(token),
        ]);
        setUserDetail(profileData);
        setLoading(false);
    }

    const [ libraries ] = useState(['places']);

    const {isLoaded} = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GAPIKEY,
        libraries: libraries
    }); 
    
    useEffect(()=>{        
        getLocationPromise();
    },[]);
    
    return(
        <>
            <Sidebar type="thin"/>
            <div id="page" className="page-search search-result">
                <div className="body">
                    {(!loading && currentLocation!==null) ?
                        <>
                            <div className="search-field-wrap w-full flex justify-center py-2.5">
                                <form onSubmit={handleSubmit(onSubmit)} className='flex'>
                                    <div className="search-bar !bg-white flex justify-center mx-auto py-2.5 mr-2.5">
                                        <span className="search-icon"></span>
                                        <input placeholder="Search for a team..." autoFocus {...register("searchkey")} className="search-input w-full focus:outline-none focus:ring-0" />                                    
                                    </div>
                                    {!searchLoading ?
                                        <button type="submit" className='button'>GO</button>
                                    :
                                        <img src="data:image/svg+xml,%3Csvg version='1.1' id='loader-1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='20px' height='20px' viewBox='0 0 40 40' enable-background='new 0 0 40 40' xml:space='preserve'%3E%3Cpath opacity='0.2' fill='%23000' d='M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z'%3E%3C/path%3E%3Cpath fill='%23000' d='M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 20 20' to='360 20 20' dur='0.5s' repeatCount='indefinite'%3E%3C/animateTransform%3E%3C/path%3E%3C/svg%3E" width={40} height={40} alt="" />
                                    }
                                </form>
                            </div>
                            <div className='plaisearchmap relative z-0'>
                                {isLoaded &&
                                    <SearchMapComponent
                                        containerStyle={containerStyle} 
                                        activeMarker={activeMarker!==null ? activeMarker : defaultProps.center} 
                                        zoom={activeZoom!==null ? activeZoom : defaultProps.zoom} 
                                        currentLocation={currentLocation} 
                                        clusterOptions={clusterOptions} 
                                        teams = {teams}
                                        setSearchTeams = {setSearchTeams}
                                        setActiveMarker={setActiveMarker}
                                        setActiveZoom={setActiveZoom}
                                    />
                                }
                                <div className='absolute left-0 right-0 mx-auto bottom-0 w-full max-w-[1061px] z-0'>
                                    <div className={`search-result-slider ${searchedTeams.length<=2 ? 'has-two' : 'has-more'} ${searchedTeams.length===1 ? 'has-one' : ''}`}>
                                        {!searchLoading ?
                                        <>
                                        {searchedTeams.length>0 ?                                            
                                            <Slider {...settings}>
                                                {searchedTeams.map((item) => {                                            
                                                        return( 
                                                            <div className='w-full h-full' key={item._id}>
                                                                <TeamCard team={item} userDetail={userDetail} />
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </Slider>
                                            :
                                            <>
                                                {searchKeyword!=='' &&
                                                    <div className='flex justify-center'>
                                                        <button className='button' onClick={()=>{setShowClubNotFoundPopup(!showClubNotFoundPopup)}}>TELL YOUR CLUB ABOUT PLAI</button>
                                                    </div>
                                                }
                                            </>
                                        }
                                        </>
                                        :
                                            <Spinner />
                                        }
                                    </div>
                                </div>
                            </div>
                            {showClubNotFoundPopup &&
                                <ClubNotFoundPopup setShowForm={setShowClubNotFoundPopup} />
                            }
                        </>
                    :
                    <>
                    {loading &&
                        <div className="py-5 text-center"><Spinner /></div>
                    }
                    {!loading &&
                        <>
                            {!isLocationPermission ?
                                <p className='text-center p-10'>{permissionError}</p>
                            :
                                <p className='text-center p-10'>Please wait we are fetching nearby clubs based on your location.</p>
                            }
                        </>
                    }
                    </>
                    }
                </div>
            </div>
        </>
    );
}

export default SearchMapPage;