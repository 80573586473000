import GoogleMapReact from 'google-map-react';
import IconMapMarker from "../../assets/images/icon-mapmarker.svg";
import './Map.scss';

const LocationPin = ({ text }) => (
    <div className="pin">
      <img src={IconMapMarker} width={24} height={31} alt=""/>      
    </div>
);

const PlaiMap = ({ location, zoomLevel }) =>{
    const defaultProps = {
        center: {
          lat: parseFloat(location?.lat),
          lng: parseFloat(location?.lng)
        },
        zoom: zoomLevel
      };
    return(
        <div className="plaimap">
            <div className="google-map" onClick={() => {
                    window.open('https://www.google.co.uk/maps/search/' + location.address)
                }}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: process.env.REACT_APP_GAPIKEY, libraries:['places'] }}
                    defaultCenter={defaultProps.center}
                    defaultZoom={defaultProps.zoom}>
                    <LocationPin
                        lat={location?.lat}
                        lng={location?.lng}
                        text={location?.address}
                    />
                </GoogleMapReact>
            </div>
        </div>  
    );
}

export default PlaiMap;