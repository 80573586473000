import { useState, useEffect } from "react";
import { getManageNotification } from "../../../libs/dataFunctions";
import Spinner from "../../../components/Spinner/Spinner";

const NotificationForm = ({setShowFrom, teamID}) =>{
    const [loading, setLoading] = useState(false);
    const [notificationList, setNotificationList] = useState([]);

    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
        ? localStorage.getItem('auth_token')
        : '';

    const getNotificationList = async() =>{
        setLoading(true);            
        const [data_notifications] = await Promise.all([
            getManageNotification(token,teamID),
        ]);            
        setNotificationList(data_notifications.data);
        setLoading(false);
    }
    useEffect(()=>{
        getNotificationList();
    },[]);

    const NotificationSettingRow = ({setting})=>{
        const [checkStatus, setCheckStatus] = useState(setting.email_status==='Yes' ? true : false);
        const [checkAppStatus, setCheckAppStatus] = useState(setting.app_notification_status==='Yes' ? true : false);
        const toggleEmailHandler=async(e)=>{
            setCheckStatus(e.currentTarget.checked);
            let updateStatus = e.currentTarget.checked ? 'Yes' : 'No';

            var formdata = new FormData();
            formdata.append("type", "set_manage_notification");
            formdata.append("team_id", teamID);
            formdata.append("status", updateStatus);
            formdata.append("notification_type",setting.email_type);
            var secureHeader = new Headers();
            secureHeader.append('Authorization','Bearer ' + token);
            secureHeader.append('device_type','W');
            const requestOptions = {
                method: 'POST',
                redirect: 'follow',
                body: formdata,
                headers: secureHeader
            };
            await fetch(process.env.REACT_APP_APIURL + '/manage_notification' , requestOptions)
            .then(response => response.text())
            .then(result => {
                let rdata = JSON.parse(result);
                if(rdata.status !== 1){
                    
                }
                else{
                    setLoading(false);                    
                }
            })
            .catch(error => console.log('error', error));
        }
        const toggleAppHandler=async(e)=>{
            setCheckAppStatus(e.currentTarget.checked);
            let updateStatus = e.currentTarget.checked ? 'Yes' : 'No';

            var formdata = new FormData();
            formdata.append("type", "set_manage_notification");
            formdata.append("team_id", teamID);
            formdata.append("status", updateStatus);
            formdata.append("notification_type",setting.app_notification_type);
            var secureHeader = new Headers();
            secureHeader.append('Authorization','Bearer ' + token);
            secureHeader.append('device_type','W');
            const requestOptions = {
                method: 'POST',
                redirect: 'follow',
                body: formdata,
                headers: secureHeader
            };
            await fetch(process.env.REACT_APP_APIURL + '/manage_notification' , requestOptions)
            .then(response => response.text())
            .then(result => {
                let rdata = JSON.parse(result);
                if(rdata.status !== 1){
                    
                }
                else{
                    setLoading(false);                    
                }
            })
            .catch(error => console.log('error', error));
        }
        return(
            <div className="flex justify-between my-5">
                <div className="text-[14px] text-white mr-2.5">{setting.title}</div>
                <div className="flex">
                    {setting.email_type!=="" ?
                        <label className="relative inline-flex items-center cursor-pointer mr-2.5">
                            <input type="checkbox" value="" className="sr-only peer" onChange={(event)=>{toggleEmailHandler(event)}} checked={checkStatus ? true : false} />
                            <div className="w-11 h-6 bg-[rgba(255,255,255,0.1)] peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white peer-checked:after:bg-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#FBDB09]"></div>                        
                        </label>
                    :
                        <label className="relative invisible inline-flex items-center cursor-pointer mr-2.5">
                            <input type="checkbox" value="" className="sr-only peer" />
                            <div className="w-11 h-6 bg-[rgba(255,255,255,0.1)] peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white peer-checked:after:bg-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#FBDB09]"></div>                        
                        </label>
                    }
                    {setting.app_notification_type!=="" ?
                        <label className="relative inline-flex items-center cursor-pointer">
                            <input type="checkbox" value="" className="sr-only peer" onChange={(event)=>{toggleAppHandler(event)}} checked={checkAppStatus ? true : false} />
                            <div className="w-11 h-6 bg-[rgba(255,255,255,0.1)] peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white peer-checked:after:bg-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#FBDB09]"></div>                        
                        </label>
                    :
                        <label className="relative invisible items-center cursor-pointer">
                            <input type="checkbox" value="" className="sr-only peer" />
                            <div className="w-11 h-6 bg-[rgba(255,255,255,0.1)] peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white peer-checked:after:bg-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#FBDB09]"></div>                        
                        </label>
                    }
                </div>
            </div>
        )
    }

    return(
        <div className="popup-form">
            <div className="popup-form-wrap !w-full !max-w-[600px] relative">
                <button type="button" className="close-popup" onClick={(e)=>{setShowFrom(false)}}><span>close</span></button>
                {!loading ?
                    <div className='rounded-xl bg-[#363F6C] py-7.5 px-5'>
                        <div className="form-heading text-center mb-[30px] !text-white">Manage Notifications</div>
                        {notificationList.length > 0 &&
                        <>
                            {notificationList.map((nitem,index)=>{
                                return(
                                    <div key={index} className="mb-5">
                                        <div className="flex justify-between items-center w-full pb-5 relative">
                                            <span className="text-white text-[18px] font-semibold mr-5">{nitem.title}</span>
                                            <div className='flex-1 h-[1px] bg-[#FBDB09]'></div>
                                            <div className="flex justify-between ml-2.5 mr-5">
                                                <span className="text-white text-sm mr-2.5 w-11">Email</span>
                                                <span className="text-white text-sm w-11">App</span>
                                            </div>
                                        </div>
                                        <div className="bg-[#2d3459] rounded-lg py-2.5 px-5">
                                            {nitem.notifications.length > 0 &&
                                            <>
                                            {nitem.notifications.map((nsettingitem, index2)=>{
                                                return(
                                                    <NotificationSettingRow key={index2} setting={nsettingitem} />
                                                )
                                            })}
                                            </>
                                            }
                                        </div>
                                    </div>
                                )
                            })}
                        </>
                        }
                    </div>
                    :
                    <div className="py-5 text-center"><Spinner /></div>
                }
            </div>
        </div>
    )
}

export default NotificationForm;