import Amex from '../../assets/images/amex.svg';
import Discover from '../../assets/images/cardDiscover@3x.png';
import DinnersClub from '../../assets/images/cardDiners@3x.png';
import Jcb from '../../assets/images/cardJCB@3x.png';
import Mastercard from '../../assets/images/cardMaster@3x.png';
import UnionPay from '../../assets/images/cardUnionPay@3x.png';
import Visa from '../../assets/images/cardVisa@3x.png';
import { useState } from 'react';

const SavedCard=({setShowForm, paymentcard, refreshMyCards})=>{
    const [checked,setChecked] = useState(paymentcard.default_source);
    const [updateLoading, setUpdateLoading] = useState(false);
    
    let companyLogo = Visa;
    if(paymentcard.brand.toLowerCase() === "visa") {
        companyLogo = Visa;
    }
    else if(paymentcard.brand.toLowerCase() === "mastercard") {
        companyLogo = Mastercard;
    }
    else if(paymentcard.brand.toLowerCase() === "american express") {
        companyLogo = Amex;
    }
    else if(paymentcard.brand.toLowerCase() === "diners club") {
        companyLogo = DinnersClub;
    }
    else if(paymentcard.brand.toLowerCase() === "discover") {
        companyLogo = Discover;
    }
    else if(paymentcard.brand.toLowerCase() === "jcb") {
        companyLogo = Jcb;
    }
    else if(paymentcard.brand.toLowerCase() === "unionpay") {
        companyLogo = UnionPay;
    }
    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
    ? localStorage.getItem('auth_token')
    : '';
    const updateDefaultStatus=async(e)=>{
        if(e.target.checked){
            setUpdateLoading(true);
            let formdata = new FormData();
            formdata.append("type", "set_default_card");
            formdata.append("card_id", paymentcard.id);
            var secureHeader = new Headers();
            secureHeader.append('Authorization','Bearer ' + token);
            secureHeader.append('device_type','W');
            const requestOptions = {
                method: 'POST',
                redirect: 'follow',
                body: formdata,
                headers: secureHeader
            };

            await fetch(process.env.REACT_APP_APIURL + '/app_stripe_card' , requestOptions)
            .then(response => response.text())
            .then(result => {
                let rdata = JSON.parse(result);
                setUpdateLoading(false);
                refreshMyCards();
                setShowForm(false);
            })
            .catch(error => console.log('error', error));
        }
    }

    return(
        <>
        <div className="payment-card relative rounded-[14px] relative">
            <div className='logo absolute top-[5px] left-[5px]'>
                <img src={companyLogo} alt={paymentcard.brand} width={70} />
            </div>
            <div className="py-1 w-full top-0 left-0 absolute flex flex-col justify-between h-full">                
                <div className='actions flex justify-end items-center w-full p-2'>
                    <div className='check-field-wrap relative px-2.5'>
                        {!updateLoading ?
                        <label htmlFor={`usedefault-${paymentcard.id}`} className='!text-[12px] justify-center text-[#000000] cursor-pointer'>
                            <input 
                                type="checkbox" id={`usedefault-${paymentcard.id}`} defaultChecked={checked} className='checkbox-field appearance-none h-4 w-4 rounded-sm bg-transparent mt-1 align-top float-left mr-2 cursor-pointer'
                                onChange={(e)=>{
                                    updateDefaultStatus(e);
                                }}
                            />Default Card
                        </label>
                        :
                        <span className='text-[12px] text-[#000000]'>Updating...</span>
                        }
                    </div>
                </div>
                <div className='flex justify-end w-full px-2.5'>
                    <div>
                        <div className='text-[10px] text-[#000000]'>EXPIRY DATE</div>
                        <div className='text-[12px] font-bold text-[#000000] text-right'>{('0' + paymentcard.exp_month).slice(-2)}/{paymentcard.exp_year}</div>
                    </div>
                </div>
                <div className='flex justify-between items-end w-full px-2.5 pb-2.5'>
                    <div>
                        <div className='text-[10px] text-[#000000]'>CARD HOLDER NAME</div>
                        <div className='text-[12px] font-bold text-[#000000]'>{paymentcard.name}</div>
                    </div>
                    <div className='text-[12px] font-bold text-[#000000]'>****{paymentcard.last4}</div>
                </div>
            </div>
        </div>
        </>
    );
}
export default SavedCard;