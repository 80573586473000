import { useState, useEffect } from "react";
import { getRemainingGroupMembers } from "../../../libs/dataFunctions";
import Spinner from "../../../components/Spinner/Spinner";
import AskedMemberRow from "./AddGroupMemberRow";

const AddGroupMember = ({team, group, setShowForm, refreshGroupMembers})=>{
    const [loading, setLoading] = useState(false);
    const [Members, setMembers] = useState([]);
    const [q, setSearchQuery] = useState("");    
    const [searchParam] = useState(["name"]);
    const [isCheckAllMembers, setIsCheckAllMembers] = useState(false);
    const [isCheckMembers, setIsCheckMembers] = useState([]);
    const [responseMsg, setResponseMessage] = useState(null);

    const search=(items)=>{
        return items.filter((item) => {
            return searchParam.some((newItem) => {
                return (
                    item[newItem]?
                        item[newItem]
                        .toString()
                        .toLowerCase()
                        .indexOf(q.toLowerCase()) > -1
                    :
                    null
                );
            });
        });
    }

    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
        ? localStorage.getItem('auth_token')
        : '';

    async function refreshRemainingMembers(){
        setLoading(true);
        const [teamMemberData] = await Promise.all([
            getRemainingGroupMembers(token, team._id, group._id),
        ]);
        if(teamMemberData.status===1){
            setMembers(teamMemberData.data);
        }
        setLoading(false);
    }
    const handleSelectAllMembers = e => {
        setIsCheckAllMembers(!isCheckAllMembers);
        setIsCheckMembers(search(Members).map(item => item));
        if (isCheckAllMembers) {
            setIsCheckMembers([]);
        }
    };

    const addMemberInGroup = async ()=>{
        setResponseMessage(null);
        setLoading(true);
        var formdata = new FormData();
        var memberSub = [];
        isCheckMembers.forEach(element => {
            memberSub.push(element.sub);
            if(element.same_child_subs.length>0){
                element.same_child_subs.forEach((samechilditem)=>{
                    memberSub.push(samechilditem);
                });
            }            
        });
        formdata.append("type", "add_members_in_group");
        formdata.append("member_sub",memberSub.join('@#'));
        formdata.append("team_id", team._id);
        formdata.append("group_id", group._id);
        formdata.append("separate_by", '@#');
        
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };
        
        await fetch(process.env.REACT_APP_APIURL + '/app_groups' , requestOptions)
        .then(response => response.text())
        .then(result => {
            refreshGroupMembers();
            let rdata = JSON.parse(result);
            setResponseMessage(rdata.message);
            setIsCheckMembers([]);            
            setIsCheckAllMembers(false);
        })
        .catch(error => console.log('error', error));
        setLoading(false);
    }

    useEffect(()=>{
        refreshRemainingMembers();
    },[]);
    return(
        !loading ?
        <>
            <button type="button" className="close-popup" onClick={(e)=>{setShowForm(false)}}><span>close</span></button>
            {responseMsg!==null ?
            <>
                <div className="form-heading text-center mb-2.5">Success!</div>
                <div className="py-5 text-[14px] leading-[16px] text-center text-green-600 font-bold">
                    {responseMsg}
                </div>
            </>
            :
            <>
                <div className="form-heading text-center mb-2.5">Add Members</div>
                <div className="mb-5">
                    <label htmlFor="search-form" className="!w-full mx-auto !max-w-[300px] block search-field relative">
                        <input
                            type="search"
                            name="search-field"
                            id="search-form"
                            className="search-input !py-2.5"
                            placeholder="Search Members"
                            value={q}
                            onChange={(e) => {
                                setSearchQuery(e.target.value);                            
                            }}
                        />
                    </label>
                </div>
                <div className="mb-5 flex justify-end">
                    <label className='block text-[14px] text-[#363F6C] font-semibold cursor-pointer flex'>
                        <input 
                            type="checkbox"
                            name="selectAll"
                            id="selectAll"
                            className='checkbox-field appearance-none h-4 w-4 rounded-sm bg-white mt-1 align-top float-left mr-2 cursor-pointer'
                            onChange={handleSelectAllMembers}
                            checked={isCheckAllMembers} />
                            Select All
                    </label> 
                </div>
                <div className="mb-5 md:mb-20 flex flex-wrap ask-update-member-list">
                    {search(Members).map((teamMember,index) => {                        
                        return (
                            <AskedMemberRow isCheckMembers={isCheckMembers} setIsCheckMembers={setIsCheckMembers} setIsCheckAllMembers={setIsCheckAllMembers} teamMember={teamMember} Members={Members} team={team} key={index} />
                        )
                        })
                    }
                </div>
                {(isCheckMembers.length > 0 && team.role === 'admin') &&
                    <div className="py-5 md:py-10 text-center absolute left-0 w-full bottom-0"><button type="button" className="button" onClick={()=>{addMemberInGroup()}}>Add</button></div>
                }
            </>
            }
        </>
        :
        <>
            <div className="py-5 text-center"><Spinner /></div>
        </>
    );
}

export default AddGroupMember;