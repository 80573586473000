import { useEffect, useRef, useState } from "react";
import 'react-tooltip/dist/react-tooltip.css';
import { useAuthState } from '../../../contexts/AuthContext';
import { useSelector, useDispatch } from "react-redux";
import { getConversations, getSingleConversation } from "../../../libs/dataFunctions";
import { fetchConversationListAction, updateConversationAction, updateConversationLastReadAction } from "../../../Redux/conversationListSlice";
import { newMessageAction, readMessageAction, updateActiveSourceAction, deleteActiveMessageAction } from "../../../Redux/activeChatSlice";
import { fetchNewCounterAction } from "../../../Redux/counterSlice";
import ChatMessageComponent from "./ChatMessageComponent";
import FarwardMessage from "./FarwardMessage";
import MessageDetail from "./MessageDetail";


const ChatBoard = ({team, lastMessageRef, socket, setCurrentChatPage, setGroupID, isArchive}) =>{
    const userDetail = useAuthState();
    const conversationList = useSelector(state=>state.conversationList);
    const activeChat = useSelector(state=>state.activeChat);
    const activeChatSource = activeChat.activeChatSource;
    const messages = activeChat.activeChatMessages;
    const [showFarwardPop ,setShowFarwardPop] = useState(false);
    const [showMessageDetail, setShowMessageDetail] = useState(false);
    const [detailMsgID, setdetailMsgID] = useState(null);
    const [ffconvID, setffconvID] = useState(null);
    const dispatch = useDispatch();

    const conversationListRef = useRef(conversationList);
    const activeChatRef = useRef(activeChat);    

    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
        ? localStorage.getItem('auth_token')
        : '';

    useEffect(()=>{
        activeChatRef.current = activeChat;
    },[activeChat] );

    useEffect(()=>{
        conversationListRef.current = conversationList;
    },[conversationList] );

    useEffect(()=>{
        socket.on('new_message',async(message) => {
            console.log(message);
            let messageObj = JSON.parse(message);
            console.log(message);
            if(Object.keys(activeChatSource).length !==0 && messageObj.conversation_id === activeChatSource.conversation_id){
                dispatch(newMessageAction(messageObj));
                var formdata = new FormData();
                formdata.append("type", "read_message");
                formdata.append("conversation_id", messageObj.conversation_id);
                formdata.append("conversation_details_id", messageObj.conversation_details_id);

                var secureHeader = new Headers();
                secureHeader.append('Authorization','Bearer ' + token);
                secureHeader.append('device_type','W');
                const requestOptions = {
                    method: 'POST',
                    redirect: 'follow',
                    body: formdata,
                    headers: secureHeader
                };

                await fetch(process.env.REACT_APP_APIURL + '/chat' , requestOptions)
                    .then(response => response.text())
                    .then(result => {
                        let rdata = JSON.parse(result);
                        if(rdata.all_read_by_conversation!==""){
                            socket.emit('read_message',rdata.all_read_by_conversation);
                        }
                    })
                    .catch(error => console.log('error', error));
            }

            var foundflag = false;
            foundflag = conversationListRef.current.some(obj=>obj.conversation_id === messageObj.conversation_id);
            
            if(foundflag){
                let updateConversationList = [...conversationListRef.current];
                let foundConversation = updateConversationList.filter(obj => {
                    return obj.conversation_id === messageObj.conversation_id;
                })[0];

                let updateConversation = {...foundConversation};
                updateConversation.last_message_type = messageObj.message_type;
                updateConversation.last_message_conversation_details_id = messageObj.conversation_details_id;
                updateConversation.last_message_date = messageObj.created_at;
                updateConversation.last_message_read = false;
                updateConversation.last_message_send_by_me = false;
                updateConversation.last_message = messageObj.message;
                if(messageObj.conversation_id !== activeChatSource.conversation_id){
                    updateConversation.unread_count = foundConversation.unread_count+1;
                }
                
                dispatch(updateConversationAction(updateConversation));
            }
            else{
                console.log("not found");
                let [conversationListdata] = await Promise.all([
                    getConversations(token, team._id)
                ]);
                dispatch(fetchConversationListAction(conversationListdata.data));
                dispatch(fetchNewCounterAction({'unread_chat_msg_count':conversationListdata.unread_chat_msg_count,'archive_unread_message_count':conversationListdata.archive_unread_message_count}));
            }
        });

        socket.on('group_created',async(message) => {
            let [conversationListdata] = await Promise.all([
                getConversations(token, team._id)
            ]);
            dispatch(fetchConversationListAction(conversationListdata.data));
            dispatch(fetchNewCounterAction({'unread_chat_msg_count':conversationListdata.unread_chat_msg_count,'archive_unread_message_count':conversationListdata.archive_unread_message_count}));
        });

        socket.on('group_updated',async(message) => {
            let messageObj = JSON.parse(message);
            var foundflag = false;
            foundflag = conversationListRef.current.some(obj=>obj.conversation_id === messageObj.conversation_id);

            if(foundflag){
                let updateConversationList = [...conversationListRef.current];
                let foundConversation = updateConversationList.filter(obj => {
                    return obj.conversation_id === messageObj.conversation_id;
                })[0];

                let updateConversation = {...foundConversation};
                updateConversation.profilePicture = messageObj.profilePicture;
                updateConversation.name = messageObj.name;
                updateConversation.description = messageObj.description;
                dispatch(updateConversationAction(updateConversation));
            }
            else{
                let [conversationListdata] = await Promise.all([
                    getConversations(token, team._id)
                ]);
                dispatch(fetchConversationListAction(conversationListdata.data));
                dispatch(fetchNewCounterAction({'unread_chat_msg_count':conversationListdata.unread_chat_msg_count,'archive_unread_message_count':conversationListdata.archive_unread_message_count}));
            }
        });

        socket.on('removed_from_group', (message)=>{
            let messageObj = JSON.parse(message);
            var foundflag = false;
            foundflag = conversationListRef.current.some(obj=>obj.conversation_id === messageObj.conversation_id);

            if(foundflag){
                if(messageObj.user_subs.includes(userDetail.user.sub)){
                    let updateConversationList = [...conversationListRef.current];
                    let foundConversation = updateConversationList.filter(obj => {
                        return obj.conversation_id === messageObj.conversation_id;
                    })[0];

                    let updateConversation = {...foundConversation};
                    updateConversation.is_leave_from_group = true;
                    dispatch(updateConversationAction(updateConversation));
                }
            }
            if(Object.keys(activeChatSource).length !==0 && messageObj.conversation_id === activeChatSource.conversation_id){
                let updateActiveConversation = {...activeChatSource};
                updateActiveConversation.is_leave_from_group = true;
                dispatch(updateActiveSourceAction(updateActiveConversation));
            }
        });

        socket.on('message_read',async (message) => {
            let messageObj = JSON.parse(message);
            if(Object.keys(activeChatSource).length !==0 && messageObj.conversation_id === activeChatSource.conversation_id){
                dispatch(readMessageAction(messageObj.conversation_detail_ids));
            }

            let updateConversationList = [...conversationListRef.current];
            let foundConversation = updateConversationList.filter(obj => {
                return obj.conversation_id === messageObj.conversation_id;
            })[0];

            let updatedConversation = {...foundConversation};
            dispatch(updateConversationLastReadAction(updatedConversation));
        });

        socket.on('message_deleted',async (message) => {
            console.log(message);
            let messageObj = JSON.parse(message);
            if(Object.keys(activeChatSource).length !==0 && messageObj.conversation_id === activeChatSource.conversation_id){
                console.log('found 1');
                if(messages.some(obj=>obj.conversation_details_id === messageObj.conversation_details_id)){
                    console.log('found 2');
                    dispatch(deleteActiveMessageAction(messageObj.conversation_details_id));
                }                
            }

            var foundflag = false;
            foundflag = conversationListRef.current.some(obj=>obj.conversation_id === messageObj.conversation_id);

            if(foundflag){
                let [conversationSingledata] = await Promise.all([
                    getSingleConversation(token, messageObj.conversation_id)
                ]);

                dispatch(updateConversationAction(conversationSingledata.data));
            }
            else{
                let [conversationListdata] = await Promise.all([
                    getConversations(token, team._id)
                ]);
                dispatch(fetchConversationListAction(conversationListdata.data));
                dispatch(fetchNewCounterAction({'unread_chat_msg_count':conversationListdata.unread_chat_msg_count,'archive_unread_message_count':conversationListdata.archive_unread_message_count}));
            }
        });

        return () => {
            socket.off("new_message");
            socket.off("group_created");
            socket.off("group_updated");
            socket.off("removed_from_group");
            socket.off("message_read");
            socket.off("message_deleted");
        };
    },[socket]);

    useEffect(() => {
        //scroll to bottom every time messages change
        lastMessageRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });        
    }, [messages]);
    
    return(
        Object.keys(activeChatSource).length !==0 ?
        <>
            <div className="chatboard-heading flex justify-between py-2.5 lg:py-6 px-2.5 lg:px-8 lg:mb-5">
                <div className="text-white text-[16px] lg:text-[20px] leading-[24px] lg:leading-[30px] font-semibold uppercase flex-1">
                    {activeChatSource.name}
                </div>
                {activeChatSource.is_group &&
                    <button onClick={()=>{setGroupID(activeChatSource.conversation_id); setCurrentChatPage('group-detail')}} className="w-[20px] lg:w-[30px]">
                        <svg width="30" height="30" className="w-full" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" id="chat_info">
                            <path d="M0 15C0 6.71573 6.71573 0 15 0C23.2843 0 30 6.71573 30 15C30 23.2843 23.2843 30 15 30C6.71573 30 0 23.2843 0 15Z" fill="#FBDB03"/>
                            <path d="M16.9398 12.6015L13.1752 13.0733L13.0404 13.698L13.7802 13.8344C14.2635 13.9495 14.3588 14.1238 14.2536 14.6054L13.0404 20.3066C12.7215 21.7812 13.213 22.4749 14.3687 22.4749C15.2646 22.4749 16.3052 22.0607 16.7771 21.4919L16.9217 20.808C16.5929 21.0973 16.1129 21.2124 15.794 21.2124C15.3419 21.2124 15.1775 20.8951 15.2942 20.3362L16.9398 12.6015ZM17.0549 9.16895C17.0549 9.60495 16.8817 10.0231 16.5734 10.3314C16.2651 10.6397 15.8469 10.8129 15.4109 10.8129C14.9749 10.8129 14.5568 10.6397 14.2485 10.3314C13.9402 10.0231 13.767 9.60495 13.767 9.16895C13.767 8.73296 13.9402 8.31482 14.2485 8.00652C14.5568 7.69822 14.9749 7.52502 15.4109 7.52502C15.8469 7.52502 16.2651 7.69822 16.5734 8.00652C16.8817 8.31482 17.0549 8.73296 17.0549 9.16895Z" fill="#363F6C"/>
                        </svg>                
                    </button>
                }
            </div>
            <div className='chatboard-message__container'>
                {messages.length > 0 ?
                <>
                    {messages.map((chatItem)=>{
                        return(
                            <ChatMessageComponent 
                                key={chatItem._id} 
                                chatItem={chatItem} 
                                socket={socket} 
                                userDetail={userDetail} 
                                setShowFarwardPop={setShowFarwardPop} 
                                setffconvID={setffconvID}
                                setShowMessageDetail={setShowMessageDetail}
                                setdetailMsgID = {setdetailMsgID}
                            />
                        )
                    })}
                </>
                :
                <></>
                }
                <div ref={lastMessageRef} />
            </div>
            {showFarwardPop &&
                <FarwardMessage team={team} setShowForm={setShowFarwardPop} ffconvID={ffconvID} setffconvID={setffconvID} socket={socket} isArchive={isArchive} />
            }
            {showMessageDetail &&
                <MessageDetail activeChatSource={activeChatSource} setShowForm={setShowMessageDetail} detailMsgID={detailMsgID} />
            }
        </>
        :
        <>
            <div className="chatboard-heading flex justify-between py-6 px-8 mb-5">
                <div className="text-white text-[20px] leading-[30px] font-semibold uppercase flex-1">PLAI Sport Chat</div>                
            </div>
            <div className='chatboard-message__container'>
                <div className="text-center">Welcome to PLAI Sport Chat</div>
                <div ref={lastMessageRef} />
            </div>
        </>
    );
}
export default ChatBoard;