import { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import Select, { components } from "react-select";
import DownArrowSvg from "../../assets/images/icon-down.svg";
import placeholderImg from '../../assets/images/placeholder.png';

const TeamCard = ({team, refreshRequests, userDetail}) =>{
    const [error,setErrorMessage] = useState(null);
    const [joinpopup, setShowjoinPopup] = useState(false);
    const [loadingCancel, setLoadingCancel] = useState(false);

    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
    ? localStorage.getItem('auth_token')
    : '';
    
    const AcceptRequestForm = ({setShowForm}) =>{
        const [selectedChilds, setSelectedChilds] = useState([]);
        const [selectedChildsOut, setSelectedChildsOut] = useState(selectedChilds);
        const [childOptions , setChildOptions] = useState([]);
        const [loading, setLoading] = useState(false);
        const { register, handleSubmit, control, formState: { errors } } = useForm({});
        const DropdownIndicator = (props) => {
            return (
              <components.DropdownIndicator {...props}>
                <img src={DownArrowSvg} width={24} height={24} alt="" />
              </components.DropdownIndicator>
            );
        };
        function handleSelectChange(event) {
            const current = [...event];
            var current_child = current.map((child) => {
                return child.value
            });
            setSelectedChilds(current_child);
            setSelectedChildsOut(event);        
        }
        const handleTeamJoin= async(data)=>{
            setErrorMessage(null);
            let formdata = new FormData();
            formdata.append('type', 'accept_invitation');
            formdata.append('team_id', team._id);
            formdata.append('invite_id', team.invite_id);
            if(data.user_sub_ids !== undefined && data.user_sub_ids.length>0){
                formdata.append('user_sub_ids', data.user_sub_ids.join('@#'));
            }
            if(data.fieldname){
                if(data.fieldname.length>0){
                    var questions=[];
                    var values=[]
                    data.fieldname.forEach(element => {
                        questions.push(element.name);
                        values.push(element.value);
                    });
                    
                    formdata.append('custom_fields_name',questions.join('@#'));
                    formdata.append('custom_fields_value',values.join('@#'));                    
                }
            }
            formdata.append('separate_by','@#');
            var secureHeader = new Headers();
            secureHeader.append('Authorization','Bearer ' + token);
            secureHeader.append('device_type','W');
            const requestOptions = {
                method: 'POST',
                redirect: 'follow',
                body: formdata,
                headers: secureHeader
            };
            await fetch(process.env.REACT_APP_APIURL + '/app_teams' , requestOptions)
            .then(response => response.text())
            .then(result => {
                setLoading(false);
                let rdata = JSON.parse(result);
                if(rdata.status !== 1){
                    setErrorMessage(rdata.message);
                }
                else{
                    setLoading(false);
                    refreshRequests();
                }
            })
            .catch(error => console.log('error', error));
        }
        const onSubmit=(data)=>{            
            handleTeamJoin(data);
        }

        useEffect(()=>{
            if(userDetail.childrens.length>0){
                let chOptions = [];            
                chOptions.push({'value':userDetail.sub, 'label':userDetail.firstName+' '+userDetail.lastName});
                userDetail.childrens.forEach(element => {
                    chOptions.push({'value':element.sub, 'label':element.name});
                });
                setChildOptions( chOptions ); 
            }
        },[userDetail]);

        return(            
            <div className="popup-form">
                <div className="popup-form-wrap relative !w-[400px] !px-7.5">
                    <button type="button" className="close-popup" onClick={(e)=>{setShowForm(false)}}><span>close</span></button>
                    <div className='flex flex-col align-center justify-center'>
                        <div className='mb-10'>Accept Request from <strong className='text-[#363F6C]'>{team.name}?</strong></div>
                        {error!=='' && <p className='error text-red-500'>{error}</p>}
                        <form onSubmit={handleSubmit(onSubmit)} className="form-wrapper flex justify-center flex-col">
                            {childOptions.length>0 &&
                                <>
                                <div className='text-[16px] leading-[24px] text-black'>Select Child</div>
                                <div className='form-field-wrap mb-5 select-field-wrap groups-field-wrap relative'>
                                    <Controller
                                        control={control}
                                        name='user_sub_ids'
                                        render={({ field: { onChange, value, ref }}) => (
                                            <Select
                                                inputRef={ref}
                                                value={selectedChildsOut}
                                                className="react-select-container"
                                                classNamePrefix="react-select"
                                                components={{ DropdownIndicator }}
                                                placeholder={<div>Select Joiners</div>}
                                                onChange={val => {
                                                    onChange(val.map(c => c.value));
                                                    handleSelectChange(val);
                                                }}
                                                options={childOptions}
                                                isMulti
                                                styles={{
                                                    control: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        borderWidth: 0,
                                                        backgroundColor: 'rgba(239, 241, 249, 0.6)',
                                                        padding: '6px 7px 6px 46px'
                                                    }),
                                                    menu: (baseStyles) => ({
                                                        ...baseStyles,
                                                        zIndex:1000,
                                                        backgroundColor: '#ffffff'
                                                    })
                                                }}
                                                theme={(theme) => ({
                                                    ...theme,
                                                    borderRadius: 8,                                                
                                                })}
                                            />
                                        )}
                                    />                                    
                                </div>
                                </>
                            }
                            {team.customFields && team.customFields.length > 0 &&
                                team.customFields.map((customField,i)=>{
                                    return(
                                        <div className='form-field-wrap small-field-wrap mb-5' key={customField._id}>
                                            <label>{customField.name}{customField.required ? '*' : ''}</label>
                                            <input type="hidden" {...register(`fieldname[${i}].name`)} value={customField.name} className='text-field' />
                                            <input type="text" {...register(`fieldname[${i}].value`, {required:customField.required ? "This field is required.": false})} className='text-field' />
                                            {errors.fieldname?.[i]?.value && <p className='error mb-2.5 mt-1'>{errors.fieldname?.[i]?.value?.message}</p>}
                                        </div>
                                    );
                                })                            
                            }
                            {!loading ?
                                <div className='text-center'><button type="submit" className='button button-large'>Confirm</button></div>
                            :
                                <img src="data:image/svg+xml,%3Csvg version='1.1' id='loader-1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='20px' height='20px' viewBox='0 0 40 40' enable-background='new 0 0 40 40' xml:space='preserve'%3E%3Cpath opacity='0.2' fill='%23000' d='M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z'%3E%3C/path%3E%3Cpath fill='%23000' d='M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 20 20' to='360 20 20' dur='0.5s' repeatCount='indefinite'%3E%3C/animateTransform%3E%3C/path%3E%3C/svg%3E" width={40} height={40} alt="" />
                            }
                        </form>
                    </div>
                </div>
            </div>
        )
    }
    
    const cancelRequest = async() =>{
        if(window.confirm("Are you sure you want to cancel this request?")){        
            setLoadingCancel(true);
            let formdata = new FormData();
            formdata.append('type', 'cancel_join_request');
            formdata.append('team_id', team._id);
            var secureHeader = new Headers();
            secureHeader.append('Authorization','Bearer ' + token);
            secureHeader.append('device_type','W');
            const requestOptions = {
                method: 'POST',
                redirect: 'follow',
                body: formdata,
                headers: secureHeader
            };
            await fetch(process.env.REACT_APP_APIURL + '/app_teams' , requestOptions)
            .then(response => response.text())
            .then(result => {
                setLoadingCancel(false);
                let rdata = JSON.parse(result);
                if(rdata.status === 1){
                    refreshRequests();
                }
            })
            .catch(error => console.log('error', error));
        }
    }

    return(
    <>
        <div className="teamcard p-3.5">
            <div className='logo-wrapper relative w-[100px] h-[100px] mb-2.5 mx-auto rounded-full overflow-hidden'>
                {team.logo && team.logo!=="" ?
                    <img src={team.logo} alt={team.name} className="absolute object-cover w-full h-full"/>
                :
                    <img src={placeholderImg} alt={team.name} className="absolute object-cover w-full h-full"/>
                }
            </div>
            <div className='info text-center'>
                <h5 className="title mb-2.5">{team.name}</h5>
                {(team.sport && team.sport.image && team.sport.image!=="") &&
                    <div className='bg-[#363F6C] w-[40px] h-[40px] flex items-center justify-center rounded-full p-1.5 mx-auto mb-2.5'><img src={team.sport.image} alt="" width={30} className='max-auto' /></div>
                }                
                {team.type==='invite' ? 
                    <>
                        <button className='button button-blue button-medium mx-auto' onClick={()=>{setShowjoinPopup(true)}}>Accept</button>
                        {joinpopup && 
                            <AcceptRequestForm setShowForm={setShowjoinPopup} />
                        }
                    </>
                :
                    <>
                        <p className='uppercase'>A Team admin will soon approve your request.</p>
                        {!loadingCancel ?
                            <button className="button button-medium button-red mx-auto mt-2.5" onClick={()=>{cancelRequest();}}>Cancel</button>
                        :
                            <img src="data:image/svg+xml,%3Csvg version='1.1' id='loader-1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='20px' height='20px' viewBox='0 0 40 40' enable-background='new 0 0 40 40' xml:space='preserve'%3E%3Cpath opacity='0.2' fill='%23000' d='M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z'%3E%3C/path%3E%3Cpath fill='%23000' d='M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 20 20' to='360 20 20' dur='0.5s' repeatCount='indefinite'%3E%3C/animateTransform%3E%3C/path%3E%3C/svg%3E" width={20} height={20} alt="" />
                        }
                    </>
                }                
            </div>
        </div>        
    </>
    );
}

export default TeamCard;