import { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import { copyImageToClipboard } from 'copy-image-clipboard';
import { getSingleConversation } from "../../../libs/dataFunctions";
import placeholderImg from '../../../assets/images/placeholder.png';
import messageReadTick from '../../../assets/images/message-readtick.svg';
import messageUnReadTick from '../../../assets/images/message-unreadtick.svg';
import { deleteActiveMessageAction } from "../../../Redux/activeChatSlice";
import { updateConversationAction } from "../../../Redux/conversationListSlice";

dayjs.extend(utc);
dayjs.extend(tz);

const ChatMessageComponent=({socket, chatItem, userDetail, setShowFarwardPop, setffconvID, setShowMessageDetail, setdetailMsgID})=>{
    const [openMenu, setOpenMenu] = useState(false);
    const [messageLoading, setMessageLoading] = useState(false);
    const ref = useRef();
    const dispatch = useDispatch();    

    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
        ? localStorage.getItem('auth_token')
        : '';

    const AudioMessage = ({audioUrl}) =>{
        const extension = audioUrl.split(';')[0].split('/')[1];
        var audioType = 'audio/mp3';
        if(extension==='ogg'){
            audioType = 'audio/ogg';
        }
        else if(audioType==='wav'){
            audioType = 'audio/wav';
        }
        return(
            <audio controls controlsList={'nodownload noplaybackrate'}>
                <source src={audioUrl} type={audioType} />
                Your browser does not support the audio element.
            </audio>
        )
    }

    const deleteMessage=async()=>{
        if(window.confirm("Are you sure to delete this message?")){
            setMessageLoading(true);
            
            var formdata = new FormData();
            formdata.append("type", "delete_message");
            formdata.append("conversation_id", chatItem.conversation_id);
            formdata.append("conversation_details_id", chatItem.conversation_details_id);

            var secureHeader = new Headers();
            secureHeader.append('Authorization','Bearer ' + token);
            secureHeader.append('device_type','W');
            const requestOptions = {
                method: 'POST',
                redirect: 'follow',
                body: formdata,
                headers: secureHeader
            };

            await fetch(process.env.REACT_APP_APIURL + '/chat' , requestOptions)
                .then(response => response.text())
                .then(result => {
                    let rdata = JSON.parse(result);
                    if(rdata.status===1){  
                        socket.emit('delete_message',JSON.stringify({"conversation_id":chatItem.conversation_id,"conversation_details_id":chatItem.conversation_details_id}));                        
                    }
                })
                .catch(error => console.log('error', error));

            let [conversationSingledata] = await Promise.all([
                getSingleConversation(token, chatItem.conversation_id)
            ]);
            setMessageLoading(false);
            dispatch(deleteActiveMessageAction(chatItem.conversation_details_id));                        
            dispatch(updateConversationAction(conversationSingledata.data));
        }
    }

    useEffect(()=>{
        const checkIfClickedOutside = e => {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            if (ref.current && !ref.current.contains(e.target)) {
                setOpenMenu(false);
            }
        }
        document.addEventListener("mousedown", checkIfClickedOutside);      
        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    },[setOpenMenu]);

    return(
        <>
        <div className="chatboard-message__chats">
            {chatItem.sub === userDetail.user.sub ?
            <div className="chatboard-message__recipient flex flex-col items-end">
                    <div className={`chatboard-message__recipient_msg type-${chatItem.message_type}`} ref={ref}>                                        
                        {chatItem.message_type === 'text' &&
                        <>
                            <p>{chatItem.message}</p>
                        </>
                        }
                        {chatItem.message_type === 'image' &&
                        <>
                            <div className='w-full'>
                                <img src={chatItem.message} className='w-full object-fit rounded-xl' alt=''/>
                            </div>
                        </>
                        }
                        {chatItem.message_type === 'audio' &&
                        <>
                            <div className='w-full'>
                                <AudioMessage audioUrl={chatItem.message} />                                                
                            </div>
                        </>
                        }
                        <button type="button" className="absolute right-[7px] top-[7px]" onClick={()=>setOpenMenu(!openMenu)}>
                            <svg className="w-[12px] h-[12px] lg:w-[18px] lg:h-[18px]" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.39426 6.69702L9.00007 11.3028L13.6059 6.69702" stroke="#363F6C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </button>
                        <div className={`chat-conversation-menu !w-[135px] lg:!w-[175px] ${openMenu ? 'flex flex-col' : 'hidden'}`}>
                            <button className='chat-conversation-menu-item' onClick={()=>{setOpenMenu(false); setffconvID(chatItem); setShowFarwardPop(true);}}>Forward</button>
                            {chatItem.message_type !== 'audio' &&
                                <>
                                {chatItem.message_type === 'image' ?
                                    <button className='chat-conversation-menu-item' onClick={()=>{copyImageToClipboard(chatItem.message); setOpenMenu(false);}}>Copy</button>
                                    :
                                    <button className='chat-conversation-menu-item' onClick={()=>{navigator.clipboard.writeText(chatItem.message); setOpenMenu(false);}}>Copy</button>
                                }
                                </>
                            }
                            <button className='chat-conversation-menu-item' onClick={()=>{setOpenMenu(false); setShowMessageDetail(true); setdetailMsgID(chatItem.conversation_details_id)}}>Info</button>
                            <button className='chat-conversation-menu-item !text-[#ff0000]' onClick={()=>{deleteMessage();setOpenMenu(false);}}>Delete</button>
                        </div>
                    </div>
                    <p className='timestamp flex'>
                        <span>{dayjs(chatItem.created_at).format('MMM DD, hh:mm A')}</span>
                        {!messageLoading ?
                            <>
                            {chatItem.is_read ?
                                <img src={messageReadTick} width="14" height="14" alt="" className='ml-2.5' />
                                :
                                <img src={messageUnReadTick} width="14" height="14" alt="" className='ml-2.5' />
                            }
                            </>
                            :
                            <img src="data:image/svg+xml,%3Csvg version='1.1' id='loader-1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='20px' height='20px' viewBox='0 0 40 40' enable-background='new 0 0 40 40' xml:space='preserve'%3E%3Cpath opacity='0.2' fill='%23000000' d='M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z'%3E%3C/path%3E%3Cpath fill='%23FBDB03' d='M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 20 20' to='360 20 20' dur='0.5s' repeatCount='indefinite'%3E%3C/animateTransform%3E%3C/path%3E%3C/svg%3E" width={14} height={14} alt="" />
                        }
                    </p>
            </div>            
            :
            <div className="chatboard-message__sender">
                <div className="chatboard-message__sender_img w-[23px] lg:w-[44px] h-[23px] lg:h-[44px] mr-2.5">
                    {chatItem.profilePicture !== '' ?
                        <img src={chatItem.profilePicture} alt="" width={44} height={44} className="rounded-full w-[23px] lg:w-[44px] h-[23px] lg:h-[44px] object-fit" />
                    :
                        <img src={placeholderImg} alt="" width={44} height={44} className="rounded-full w-[23px] lg:w-[44px] h-[23px] lg:h-[44px] object-fit" />
                    }
                </div>
                <div className="chatboard-message__sender_body">
                    <p className="chatboard-message__sender_by">{chatItem.name}</p>
                    <div className={`chatboard-message__sender_msg type-${chatItem.message_type}`} ref={ref}>                                        
                        {chatItem.message_type === 'text' &&
                        <>
                            <p>{chatItem.message}</p>
                        </>
                        }
                        {chatItem.message_type === 'image' &&
                        <>
                            <div className='w-full'>
                                <img src={chatItem.message} className='w-full  object-fit rounded-xl' alt=''/>
                            </div>
                        </>
                        }
                        {chatItem.message_type === 'audio' &&
                        <>
                            <div className='w-full'>
                                <AudioMessage audioUrl={chatItem.message} />                                                
                            </div>
                        </>
                        }
                        <button type="button" className="absolute right-[7px] top-[7px]" onClick={()=>setOpenMenu(!openMenu)}>
                            <svg className="w-[12px] h-[12px] lg:w-[18px] lg:h-[18px]" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.39426 6.69702L9.00007 11.3028L13.6059 6.69702" stroke="#ffffff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </button>
                        <div className={`chat-conversation-menu !w-[135px] lg:!w-[175px] ${openMenu ? 'flex flex-col' : 'hidden'}`}>
                            <button className='chat-conversation-menu-item' onClick={()=>{setOpenMenu(false); setffconvID(chatItem); setShowFarwardPop(true);}}>Forward</button>
                            {chatItem.message_type !== 'audio' &&
                                <>
                                {chatItem.message_type === 'image' ?
                                    <button className='chat-conversation-menu-item' onClick={()=>{copyImageToClipboard(chatItem.message); setOpenMenu(false);}}>Copy</button>
                                    :
                                    <button className='chat-conversation-menu-item' onClick={()=>{navigator.clipboard.writeText(chatItem.message); setOpenMenu(false);}}>Copy</button>
                                }
                                </>
                            }
                        </div>
                    </div>
                    <p className='timestamp'>{dayjs(chatItem.created_at).format('MMM DD, hh:mm A')}</p>
                </div>
            </div>
            }                            
        </div>
        </>
    );
}

export default ChatMessageComponent;