import { useState, useEffect, useRef } from 'react';
import ad from '../../assets/images/PT_Ad_Placeholder.png';
import './LatestNews.scss';
import LatestNewsRow from './LatestNewsRow';
import { useAuthState } from '../../contexts/AuthContext';

export default function LatestNews({latests, refreshLatest}){
    const [latestitems, setLatestItems] = useState(latests);
    const [showSortingOpt, setShowSortingOpt] = useState(false);
    const [filterBy, setfilterby] = useState('all');
    const ref = useRef();
    const userDetails = useAuthState();

    useEffect(()=>{
        let sorted_news;
        if(filterBy==='bookmarked'){
            sorted_news = latests.filter((latestitem)=>(latestitem.bookmark==='yes'));
        }
        if(filterBy==='all'){
            sorted_news = latests;
        }
        setShowSortingOpt(false);
        setLatestItems(sorted_news);
    },[filterBy, latests]);

    useEffect(()=>{
        const checkIfClickedOutside = e => {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            if (showSortingOpt && ref.current && !ref.current.contains(e.target)) {
                setShowSortingOpt(false)
            }
        }      
        document.addEventListener("mousedown", checkIfClickedOutside);      
        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    },[showSortingOpt]);

    return(        
        <>
            <div className="latest-news pb-5">
                <div className="container max-w-full mx-auto pl-5 pr-5 md:pr-7"> 
                    <div className="ln-header flex justify-between items-center w-full pb-5 relative">
                        <h2 className="title mr-5">Latest</h2>
                        <div className='sep mr-5 sm:mr-11'></div>
                        <div className='relative' ref={ref}>
                            <button type='button' className='sortby py-1.5 pl-9 pr-3' onClick={(e)=>{setShowSortingOpt(!showSortingOpt)}}>Sort by</button>
                            <ul className={`sort-actions absolute rounded-lg bg-white overflow-hidden top-full right-0 ${!showSortingOpt ? 'hidden' : ''}`}>
                                <li className={filterBy==='all' ? 'active' : ''}><div className='cursor-pointer' onClick={()=>{setfilterby('all');}}>All</div></li>
                                <li className={filterBy==='bookmarked' ? 'active' : ''}><div className='cursor-pointer' onClick={()=>{setfilterby('bookmarked');}}>Favourites</div></li>                                
                            </ul>
                        </div>
                    </div>
                    {latestitems.length > 0 ?
                    <div className='ln-wrapper flex flex-col sm:flex-row'>
                        <div className="ln-list sm:mr-6 sm:flex-1">
                            {latestitems.map((news,index)=>{
                                return(
                                    <LatestNewsRow news={news} key={index} setfilterby={setfilterby} refreshLatest={refreshLatest} />
                                )
                            })}
                        </div>
                        {!userDetails?.user?.isSubscriptionActive &&
                            <div className='ads hidden md:block md:max-w-[370px] md:text-right'><img className="sm:ml-auto" src={ad} alt="" width={370} height={690} /></div>
                        }
                    </div>
                    :
                    <div className='text-center'>{filterBy==='bookmarked' ? 'Whoops! You haven\'t favourite anything yet, look out for the ⭐ & favourite your favourite team content!' : 'No latest found'}</div>
                    }
                </div>
            </div>
        </>
    );
}