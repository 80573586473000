import { useState, useEffect, useRef } from 'react';
import CheckTreePicker from 'rsuite/CheckTreePicker';
import { getGroupsV2Filter } from '../../../libs/dataFunctions';
import Spinner from '../../../components/Spinner/Spinner';

const GroupFilterPopup=({selectedGroup, setSelectedGroup, setShowForm, onChange, team, token})=>{
    const [loading, setLoading] = useState(false);
    const [groups, setGroups] = useState([]);
    const [selGroups, setSelGroups] = useState([]);
    const contentRef= useRef();
    const handleSubmit=()=>{
        setSelectedGroup(selGroups);
        setShowForm(false);
    }

    async function fetchGroups(){
        setLoading(true);        
        const [groupsData] = await Promise.all([
            getGroupsV2Filter(team._id, token)
        ]);
        
        let groupOptions = [];
        
        groupsData.forEach(element => {
            let childOptions = [];
            if(element.nest_groups.length > 0){
                element.nest_groups.forEach(celm =>{
                    childOptions.push({'value':celm._id, 'label':celm.name});
                });
                groupOptions.push({'value':element._id, 'label':element.name, 'children':childOptions});
            }
            else{
                groupOptions.push({'value':element._id, 'label':element.name});
            }
        });
        setGroups(groupOptions);        
        setLoading(false);      
    }

    useEffect(()=>{
        fetchGroups();
        if(selectedGroup!==''){
            setSelGroups(selectedGroup);
        }
    },[]);

    return(
        <>
        <div className="popup-form">
            <div className="popup-form-wrap relative !px-2.5 sm:!px-8 !max-w-[500px] !w-full">
            {!loading ?
            <>
                <button type="button" className="close-popup" onClick={(e) => { setShowForm(false) }}><span>close</span></button>
                <div className="form-heading text-center mb-[30px]">Select Groups</div>
                <div className="mb-5 relative" ref={contentRef}>
                <CheckTreePicker
                    defaultExpandAll
                    placeholder="Select Groups*"
                    data={groups}
                    onChange={val => {
                        setSelGroups(val);
                    }}
                    container={()=>contentRef.current}
                    menuStyle={{zIndex: 100000000}}
                    defaultValue={selGroups}
                    value={selGroups}
                    style={{
                        width:'100%',
                        maxWidth:'500px',
                        borderWidth:0,
                        padding: '8px 7px 8px 7px',
                        height: '52px',
                        borderRadius: 8
                    }}
                    onSelect={(item,val,event)=>{
                            if(item.label.toLowerCase()==="all members"){
                                if(item.check){
                                    let groupIds=[];
                                    groups.forEach((item)=>{
                                        groupIds.push(item.value);
                                    });
                                    setSelGroups(groupIds);
                                }
                                else{
                                    setSelGroups([]);
                                }
                            }
                        }
                    }
                />
                </div>                
                <div className="flex justify-center">
                    <button className="button button-medium !mx-auto" type="button" onClick={()=>handleSubmit()}>DONE</button>
                </div>            
            </>
            :
                <Spinner />
            }
            </div>
        </div>
        </>
    )
}
export default GroupFilterPopup;