import { createContext, useEffect, useState } from "react";
import { getTeamDetail, getProfile } from "../libs/dataFunctions";
export const TeamContext = createContext();

const getCache = (id) => {
    let isCacheGood = false
    let data = {}
    const currentTimeStamp = Math.floor(Date.now() / 1000)
    const threshold = 120 //2 minutes
    const cacheStr = localStorage.getItem(`id-${id}`)
  
    if (cacheStr) {
      data = JSON.parse(cacheStr)
      if (data.cacheSet) {
        const numSeconds = Math.abs(currentTimeStamp - data.cacheSet)
        if (numSeconds < threshold) {
          isCacheGood = true
        }
      }
    }
    return {
      isCacheGood: isCacheGood,
      data: data
    }
  }

export function TeamContextProvider(props){
    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
	? localStorage.getItem('auth_token')
	: '';
    const [team, setTeam] = useState([]);
    const [currentTeam, setCurrentTeam] = useState(props.id);
    const [updating, setUpdating] = useState(true);       
    
    async function refresh() {
        setUpdating(true);
        const [teamDetail] = await Promise.all([
            getTeamDetail(currentTeam,token)
        ]);
        setTeam(teamDetail);

        const currentTimeStamp = Math.floor(Date.now() / 1000);
        teamDetail.cacheSet = currentTimeStamp;
        localStorage.setItem(`id-${currentTeam}`, JSON.stringify(teamDetail));
        
        //Code to update already logged in user's new extra fields
        const cacheUser = JSON.parse(localStorage.getItem('currentUser'));
        if(cacheUser?.isAbove18 === undefined){
            const [userData] = await Promise.all([
                getProfile(token)
            ]);

            let sessionData = {
                user: userData?.email,
                FirstName: userData?.firstName,
                LastName: userData?.lastName,
                account_type: userData?.accountType,
                profilePic: userData?.profilePicture,
                userID: userData?.user_id,
                sub: userData?.sub,
                auth_key: userData?.user_device?.auth_key,
                refresh_token: userData?.user_device?.refresh_token,
                children: userData?.children,
                dateOfBirth: userData?.dateOfBirth,
                isAbove18: userData?.is_above_18,
                isSubscriptionActive: userData?.is_subscription_active,
                isVerify: userData?.isVerify,
                phoneCode: userData?.phoneCode,
                phoneNumber: userData?.phoneNumber,
                superAdmin: userData?.superAdmin ? userData?.superAdmin : false
            }
            localStorage.setItem('currentUser',JSON.stringify(sessionData));
        }

        setUpdating(false);
    }
    
    useEffect(() => {
        (async()=>{
            const dataFromCache = getCache(currentTeam);
            const cacheUser = JSON.parse(localStorage.getItem('currentUser'));
            if(dataFromCache.isCacheGood && cacheUser?.isAbove18 !== undefined && dataFromCache?.data?.site_id !== undefined && dataFromCache?.data?.fantasy_matrix_id!==undefined){
                setTeam(dataFromCache.data);
                setUpdating(false);
            }
            else{
                refresh(); 
            }
        })();
    }, []);
    
    return (
        <TeamContext.Provider
            value={{
                team,
                token,
                refreshTeam: refresh,
                setCurrentTeam,
                teamUpdating: updating,
        }}>
            {props.children}
        </TeamContext.Provider>
    )
}