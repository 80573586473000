import { useEffect, useState, useContext } from "react";
import { useParams, NavLink } from "react-router-dom";
import * as DOMPurify from 'dompurify';
import Spinner from "../../../components/Spinner/Spinner";
import Sidebar from "../../../components/Sidebar/Sidebar";
import AdminNavbar from "../../../components/AdminNavbar/AdminNavbar";
import CSVReader from 'react-csv-reader'
import { TeamContext, TeamContextProvider } from "../../../contexts/TeamContext";
import { getGroupArray } from "../../../libs/dataFunctions";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

const EventImportForm = () =>{
    const [loading,setLoading] = useState(false);
    const [errorMessage,setErrorMessage] = useState(false);
    const [errorMessageImport,setErrorMessageImport] = useState(null);
    const [hasError, setHasError] = useState(false);
    const [data, setData] = useState(null);
    const [originalFile, setOriginalFile] = useState(null);
    const {team, teamUpdating } = useContext(TeamContext);
    const [showErrorImport,setShowErrorImport] = useState(false);
    const [grousArray, setGroupsArray] = useState([]);
    const requiredKeysNames = ['event_name','start_date (yyyy-mm-dd)','end_date (yyyy-mm-dd)','start_time','end_time','access_groups','autoAccept (Yes/No)', 'Type (Competition,Game,Race,Social Event,Practise,Other)'];

    const handleForce = (data, fileInfo, originalFile) => {
        setData(null);
        setOriginalFile(null);
        setErrorMessage(null);
        setOriginalFile(originalFile);
        
        var availRowKeys;
        data = data.map((row) => {
            const { valid, keys, availKeys, missingGroups, access_groupsArr } = verifyRow(row)            
            row.valid = valid
            row.keys = keys
            row.availKeys=availKeys;
            row.missingGroups=missingGroups;
            row.access_groupsArr=access_groupsArr;
            availRowKeys=availKeys;
            return row;
        })

        var headerError=false;
        var errorHeader=[];
        for(var i=0; i<requiredKeysNames.length; i++){
            if(!availRowKeys.includes(requiredKeysNames[i])){
                headerError=true;
                errorHeader.push(requiredKeysNames[i]);
            }
        }
        if(headerError){
            //setErrorMessage("Found "+ (errorHeader.length > 1 ? errorHeader.length + " errors" : errorHeader.length + " error") +" in your file. <strong>"+errorHeader.join(', ')+"</strong> field name are missing or misspelled. Download template and compare your fields.");
            setHasError(true);
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                    <div className='confirm-ui'>
                        <div className="font-semibold uppercase text-[22px] leading-[30px] text-center mb-5">PLAI</div>
                        <div className="mb-2.5 text-center text-[14px]" role="alert">
                            <p className="mb-2 font-bold itallic">Swing and a Miss...</p>
                            <p className="mb-2">
                                Found {errorHeader.length > 1 ? errorHeader.length + " errors" : errorHeader.length + " error"} in your file. <strong>{errorHeader.join(', ')}</strong> field name are missing or misspelled. Download template and compare your fields.
                            </p>
                        </div>
                        <div className="flex justify-center">
                            <button className="button button-blue mx-1" onClick={onClose}>Close</button>                                                
                        </div>
                    </div>
                    );
                }
            });
        }
        else{
            var errorsGroup = data.filter((row) => row.missingGroups.length>0);
            if (errorsGroup.length > 0) {
                //setErrorGroupMissing('We noticed that the some groups do not yet exist on your PLAI team page. Please check below list where groups which are display in <strong>Red</strong> are missing.')
                setHasError(true);
                confirmAlert({
                    customUI: ({ onClose }) => {
                        return (
                        <div className='confirm-ui'>
                            <div className="font-semibold uppercase text-[22px] leading-[30px] text-center mb-5">PLAI</div>
                            <div className="mb-2.5 text-center text-[14px]" role="alert">
                                <p className="mb-2 font-bold itallic">Swing and a Miss...</p>
                                <p className="mb-2">We noticed that the some groups do not yet exist on your PLAI team page. Please check below list where groups which are display in <strong>Red</strong> are missing. Add those missing groups and resubmit!</p><p className="mb-2">Need support? Simply reach out to <a href="mailto:support@plaisport.com" className="underline">support@plaisport.com</a>.</p>
                            </div>
                            <div className="flex justify-center">
                                <button className="button button-blue mx-1" onClick={onClose}>Close</button>                                                
                            </div>
                        </div>
                        );
                    }
                });
            }
            else{
                //setErrorGroupMissing(null);
                setHasError(false);
            }

            var errors = data.filter((row) => !row.valid)
            if (!errorsGroup.length > 0 && errors.length > 0) {
                setHasError(true);
                //setErrorMessage("Found "+ (errors.length > 1 ? errors.length + " errors" : errors.length + " error") +" in your file. Download template and compare your data.");
                confirmAlert({
                    customUI: ({ onClose }) => {
                        return (
                        <div className='confirm-ui'>
                            <div className="font-semibold uppercase text-[22px] leading-[30px] text-center mb-5">PLAI</div>
                            <div className="mb-2.5 text-center text-[14px]" role="alert">
                                <p className="mb-2 font-bold itallic">Swing and a Miss...</p>
                                <p className="mb-2">Found {errors.length > 1 ? errors.length + " errors" : errors.length + " error"} in your file. Download template and compare your data.</p>
                            </div>
                            <div className="flex justify-center">
                                <button className="button button-blue mx-1" onClick={onClose}>Close</button>                                                
                            </div>
                        </div>
                        );
                    }
                });
            } else {
                if(!errorsGroup.length > 0 && !errors.length > 0){
                    setHasError(false)
                }
            }
        }
        setData(data);
    }
    const requiredKeys = ['event_name'];

    function verifyRow(data) {
        var missing = [];
        var availKeys=[];
        var missinggroups=[];
        var access_groupsArr=[];
        for (var i = 0; i < requiredKeys.length; i++) {
            if (!data[requiredKeys[i]]) {
                missing.push(requiredKeys[i])
            }
        }

        // for every item in object verifycell
        for (var key in data) {
            if (!verifyCell(key, data)) {
                //setErrorMessage('Errors found. Invalid date format')
                missing.push(key)
            }

            if(key === 'access_groups'){
                const fileGroups=data[key].split(',');                
                for(var k = 0; k < fileGroups.length; k++){ 
                    access_groupsArr.push(fileGroups[k].trim());
                    if(!grousArray.includes(fileGroups[k].trim())){
                        missinggroups.push(fileGroups[k].trim());
                        missing.push(key);
                    }
                }
            }

            availKeys.push(key);
        }
                    
        return { valid: missing.length < 1, keys: missing, availKeys: availKeys, access_groupsArr:access_groupsArr, missingGroups: missinggroups }
    }

    function verifyCell(key, data) {
        //check data["start_date (yyyy-mm-dd)"] format is yyyy-mm-dd regex
        if (key === 'start_date (yyyy-mm-dd)' && !data[key].match(/^\d{4}-\d{2}-\d{2}$/)) {
            return false
        }
    
        //check data["end_date (yyyy-mm-dd)"] format is yyyy-mm-dd regex
        if (key === 'end_date (yyyy-mm-dd)' && !data[key].match(/^\d{4}-\d{2}-\d{2}$/)) {
            return false
        }

        return true;
    }

    function cleanVariableName(variableName) {
        return variableName.replace(/_/g, ' ').replace('yyyy mm dd', 'yyyy-mm-dd')
    }
    const papaparseOptions = {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
        //transformHeader: (header) => header.toLowerCase().replace(/\W/g, '_'),
    }

    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
    ? localStorage.getItem('auth_token')
    : '';

    const convert2base64 = file =>new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    const ErrorMessagePopup = ({setShowForm}) =>{        
        return(
            <div className="popup-form">
                <div className="popup-form-wrap !w-full !max-w-[600px] relative">                    
                    <button type="button" className="close-popup" onClick={(e)=>{setShowForm(false)}}><span>close</span></button>
                    <div className="form-heading text-center mb-[30px]">Your upload is COMPLETE!</div>
                    <div className="p-5">
                        <p className="mb-5">However, we noticed that the following groups do not yet exist on your PLAI team page:</p>
                        <ul className="mb-5">{
                            errorMessageImport.split(',').map((grp,index)=>{
                                return(
                                    <li key={index}><strong>{grp}</strong></li>
                                )
                            })
                        }</ul>
                        <p>Simply head to your GROUPS tab and create these groups now to fix!</p>
                    </div>
                </div>
            </div>
        )
    }

    const handleReset=()=>{
        setData(null);
        setOriginalFile(null);
        setErrorMessage(null);
        setOriginalFile(null);
        setHasError(false);
    }

    const handleSubmit = async(data) => {
        setLoading(true);
        const file = originalFile;
        setErrorMessageImport(null);
        if(file.type === 'text/csv'){
            let bs64code = await convert2base64(file);
            let bs64codeData = bs64code.replace('data:', '').replace(/^.+,/, '');
            var formdata = new FormData();
            formdata.append("type", "import_events");
            formdata.append("team_id", team._id);
            formdata.append("document_content", bs64codeData);
            formdata.append("document_extension", 'csv');

            var secureHeader = new Headers();
            secureHeader.append('Authorization','Bearer ' + token);
            secureHeader.append('device_type','W');
            const requestOptions = {
                method: 'POST',
                redirect: 'follow',
                body: formdata,
                headers: secureHeader
            };

            await fetch(process.env.REACT_APP_APIURL + '/app_event' , requestOptions)
            .then(response => response.text())
            .then(result => {                
                let rdata = JSON.parse(result);
                if(rdata.status !== 1){
                    setLoading(false);
                    setErrorMessageImport(rdata.message);
                    setShowErrorImport(true);
                }
                else{
                    setLoading(false);
                    setData(null);
                    setOriginalFile(null);
                    setErrorMessageImport(null);
                    window.location.href='/team/'+team._id+'/events';
                }
            })
            .catch(error => console.log('error', error));
        }
        else{
            setErrorMessageImport('Invalid file type, please upload CSV file.');
        }
    }

    const fetchGrouArray=async()=>{
        const [groupArraydata]=await Promise.all([
            getGroupArray(token,team._id)
        ]);
        if(groupArraydata.status===1){
            setGroupsArray(groupArraydata.data);
        }
    }

    useEffect(()=>{
        if(teamUpdating){
            setLoading(true);
        }
        else{
            setLoading(false);
            fetchGrouArray();
        }
    },[teamUpdating]);

    return (
        !loading ?
        <>
            <div className='breadcrumb flex item-center pt-1.5 pb-1 pl-5 pr-7 w-full'>
                <NavLink to="/" className='home'>
                    <span>Dashboard</span>
                </NavLink>
                <span className='separator'>&lt;</span>
                <NavLink to={`/team/${team._id}`}>
                    {team.name}
                </NavLink>
                <span className='separator'>&lt;</span>
                Import Events
            </div>
            <div className="body px-5 lg:px-8 py-10">
                <div className="team-contents w-full rounded-xl bg-white py-6 px-6">
                    <div className="form-heading mb-[30px]">Import Events</div>
                    <div className="mb-5">
                        <p className="mb-5">
                            Here you can import your events via a spreadsheet file quickly and easily. Start by downloading{' '}
                            <a href="/plai-events-template-updated.csv" className="button button--medium" download>
                                Download Template
                            </a>
                        </p>
                        <button className="button button--medium button-gray" onClick={() => {
                            window.open('https://calendly.com/plaisport_ceo/plai-import-support', '_blank')
                        }}>Book a demo</button>
                    </div>
                    <div className="w-full flex items-center justify-start mb-2.5">
                        {data===null ?
                            <label className="button button--medium button-blue cursor-pointer" style={{ height: 'fit-content' }} >
                                <span>Choose a file</span>
                                <CSVReader key="csv-reader" inputId="csv-reader" cssClass="react-csv-input" inputStyle={{ display: 'none' }} label="" onFileLoaded={handleForce} parserOptions={papaparseOptions} />                            
                            </label>
                            :
                            <input className='button button--medium button-blue cursor-pointer' type="button" onClick={()=>{handleReset()}} value="Clear" />
                        }
                        {!hasError && data!==null &&
                            <>
                                <input className='button button--medium  ml-5' type="button" onClick={()=>{handleSubmit()}} value="Confirm & Import" disabled={loading}/>
                            </>                            
                        }                        
                    </div>
                    {data && (
                        <div className="events-import">
                            {errorMessage !== null && 
                                <><p className="text-red-500 text-sm mb-2.5 text-center" role="alert" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(errorMessage) }} /></>
                            }                            
                            <table className="table-auto w-full">
                                <thead>
                                <tr className="row">
                                    {data &&
                                    Object.keys(data[0]).map((key) => {
                                        if (key !== 'valid' && key !== 'keys' && key!=='availKeys' && key!=='missingGroups' && key!=='access_groupsArr') {
                                            return (
                                                <th className="text-[12px] uppercase text-left px-2.5 py-2.5 border-b" key={key}>
                                                    {cleanVariableName(key)}
                                                </th>
                                            )
                                        } else {
                                            return null
                                        }
                                    })}
                                </tr>
                                </thead>
                                <tbody>
                                {data &&
                                    data.map((row, index) => (
                                    <tr className={'row '} key={index}>
                                        {Object.keys(row).map((key) => {
                                        if (key !== 'valid' && key !== 'keys' && key!=='availKeys' && key!=='missingGroups' && key!=='access_groupsArr') {
                                            return (
                                            <td className={'text-[12px] text-left px-2.5 py-2.5 border-b ' + (key!=='access_groups' && row.keys && row.keys.includes(key) ? 'data-error text-red-500' : '')}
                                                key={key}>
                                                {key!=='access_groups' ?
                                                <>
                                                    {row[key]}
                                                </>
                                                :
                                                <>
                                                <ul>
                                                    {row['access_groupsArr'].map((acg,index)=>{                                                    
                                                            if(row['missingGroups'].includes(acg)){
                                                                return(
                                                                    <li key={index} className="text-red-500 mr-1">{acg}</li>
                                                                )
                                                            }
                                                            else{
                                                                return(
                                                                    <li key={index}>{acg}</li>
                                                                )
                                                            }                                                    
                                                    })}
                                                </ul>
                                                </>
                                                }
                                                
                                            </td>
                                            )
                                        } else {
                                            return null
                                        }
                                        })}
                                    </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
            </div>
            {showErrorImport &&
                <ErrorMessagePopup setShowForm={setShowErrorImport} />
            }
        </>
        :
        <>
            <div className="py-5 text-center"><Spinner /></div>
        </>
    );
}


function EventImport(){
    const { id } = useParams();

    return(
        <>
            <Sidebar type="thin"/>
            <TeamContextProvider id={id}>
                <div id="page" className="page-team">
                    <AdminNavbar heading="Import Events"/>
                    <EventImportForm/>                    
                </div>
            </TeamContextProvider>
        </>
    )
}

export default EventImport;