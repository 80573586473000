import { useState } from 'react';
import dayjs from 'dayjs';
import placeholderImg from '../../assets/images/placeholder.png';
import iconMale from "../../assets/images/icon-male.svg";
import iconFemale from "../../assets/images/icon-female.svg";
import { calculate_age } from '../../libs/utils';
import ChildernForm from './ChildernForm';

const ChildRow = ({childitem, index, refreshProfile}) =>{
    const [showChildEditForm, setShowChildEditForm] = useState(false);
    const [openDetail, setOpenDetail] = useState(false);

    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
    ? localStorage.getItem('auth_token')
    : '';

    const ChildEditForm = ({setShowForm}) =>{
        return(
            <div className="popup-form">
                <div className="popup-form-wrap !w-full !max-w-[870px] relative !px-7.5">                    
                    <ChildernForm setShowForm={setShowForm} isEdit={true} childitem={childitem} refreshProfile={refreshProfile} />
                </div>
            </div>
        )
    }
    const ChildDetail = ({setShowForm}) =>{
        return(
            <div className="popup-form">
                <div className="popup-form-wrap popup-form-memberdetails relative !px-2.5 sm:!px-8">                    
                    <button type="button" className="close-popup" onClick={(e)=>{setShowForm(false);}}><span>close</span></button>
                    <div className='member-card'>
                        <div className='flex flex-col sm:flex-row items-center w-full member-card-row pb-5 border-b border-[#53545C]/30'>
                            <div className='mr-5 w-[100px] h-[100px]'>
                                {childitem.profilePicture !== '' ?  
                                <img src={childitem.profilePicture} alt="" width={100} height={100} className="rounded-full cursor-pointer"/>
                                :
                                <img src={placeholderImg} alt="" width={100} height={100} className="rounded-full cursor-pointer"/>
                                }
                            </div>
                            <div className='grow flex flex-col sm:flex-row px-2.5 sm:px-0 py-2.5 sm:py-0 text-center sm:text-left sm:items-start'>
                                <div className='flex flex-col self-center grow'>
                                    <div className='text-[20px] leading-[30px] font-medium text-[#363F6C]'>{childitem.name}</div>
                                    <div className='flex'>
                                        <div className='text-[12px] leading-[24px] text-black font-light'>Age: <strong className='font-bold'>{calculate_age(childitem.dateOfBirth)}</strong></div>
                                        {childitem.gender!=='prefer_not_to_say' &&
                                            <div className='bg-[#FBDB03] rounded-[42px] py-0.5 px-2.5 text-[10px] leading-[15px] text-black font-medium flex items-center ml-2.5 tracking-[0.3px]'><img src={`${childitem.gender.toLowerCase()==='male' ? iconMale : iconFemale}`} alt="" width={10} height={10} className="mr-1" />{childitem.gender}</div>
                                        }
                                    </div>
                                </div>                        
                                <div className="flex items-center justify-center sm:justify-end pt-2.5 sm:pt-0 sm:flex-wrap sm:max-w-[100px]">
                                    <button type='button' className="button-tiny blue edit rounded-full mr-2.5 sm:mr-1.5 sm:ml-1.5" onClick={()=>{setShowForm(false); setShowChildEditForm(true);}}></button>
                                    <button type='button' className="button-tiny blue delete rounded-full sm:ml-1.5" onClick={()=>{deleteChild()}}></button>                              
                                </div>                        
                            </div>                      
                        </div>
                        <div className='flex flex-col sm:flex-row w-full justify-between member-card-row py-5'>
                            {childitem.phoneNumber!=="" &&
                                <div className='flex items-center mb-2.5 sm:mb-0'>
                                    <span className='button-tiny phone rounded-full mr-1.5'></span>
                                    <div>
                                        <div className='text-[10px] leading-[15px] font-medium text-black'>Emergency Contact</div>
                                        <p className='text-[14px] leading-[24px] font-bold text-[#363F6C]'>{childitem.phoneCode!=="" ? childitem.phoneCode+' '+childitem.phoneNumber : childitem.phoneNumber}</p>
                                    </div>
                                </div>
                            }
                            <div className='flex items-center'><span className='button-tiny date rounded-full mr-1.5'></span><p className='text-[14px] leading-[24px] font-bold text-[#363F6C]'>{dayjs(childitem.dateOfBirth).format('DD / MMM / YYYY')}</p></div>
                        </div>
                        <div className="flex justify-between items-center w-full pb-5 relative">
                            <div className="mr-2 text-[14px] leading-[21px] font-semibold text-black">Medical Information Submitted</div>
                            <div className="flex-1 h-[1px] bg-[#53545C]/30"></div> 
                        </div>
                        {(childitem.medical_emergency && childitem.medical_emergency!=="") ?
                            <div className='text-[12px] leading-[17px] font-light text-black'>{childitem.medical_emergency}</div>
                        :
                            <div className='text-[14px] leading-[17px] text-[#363F6C] text-center font-medium px-7.5 py-[17.5px] bg-[#EBECF0] rounded-[16px] shadow-[0_24px_20px_-16px_rgba(54,63,108,0.15)] max-w-[260px] mx-auto'>No known Medical concerns</div>
                        }
                    </div>
                </div>
            </div>
        )
    }
    const deleteChild = async() => {
        if(window.confirm("Are you sure you want to delete this child?")){
            var formdata = new FormData();
            formdata.append("type", "delete_children");
            formdata.append("user_id",childitem.user_id);
            
            var secureHeader = new Headers();
            secureHeader.append('Authorization','Bearer ' + token);
            secureHeader.append('device_type','W');
            const requestOptions = {
                method: 'POST',
                redirect: 'follow',
                body: formdata,
                headers: secureHeader
            };
    
            await fetch(process.env.REACT_APP_APIURL + '/app_children' , requestOptions)
            .then(response => response.text())
            .then(result => {
                refreshProfile();
            })
            .catch(error => console.log('error', error));
          }
    }
    return(
        <>
        <div className="w-full p-2.5 flex justify-between rounded-[16px] bg-white items-center shadow-[0_10px_20px_-0_rgba(54,63,108,0.1)] mb-5">
            <div className="flex items-center cursor-pointer" onClick={(e)=>{setOpenDetail(true)}}>
                {childitem.profilePicture!=="" ?
                    <img src={childitem.profilePicture} width="50" height="50" className="rounded-full cursor-pointer" alt=""/>
                :
                    <img src={placeholderImg} width="50" height="50" className="rounded-full cursor-pointer" alt="" />
                }
                <div className='pl-[16px] pr-2.5'>
                    <div className="text-[#363F6C] text-[16px] leading-[24px] font-medium">{childitem.firstName} {childitem.lastName}</div>
                    <div className='text-[12px] leading-[24px] text-black font-light'>Age: <strong className='font-bold'>{
                        childitem.dateOfBirth!=='' ? calculate_age(childitem.dateOfBirth) : (childitem.is_above_18 ? 'Over 18 years' : 'Under 18 years')
                    }</strong></div>                    
                </div>
            </div>
            <div className="flex items-center justify-end w-[70px]">
                <button type="button" className="button-tiny blue edit rounded-full mr-1.5" onClick={()=>{setShowChildEditForm(true);}}></button>
                <button type="button" className="button-tiny blue delete rounded-full" onClick={()=>{deleteChild()}}></button>
            </div>
        </div>
        {showChildEditForm &&
            <ChildEditForm setShowForm={setShowChildEditForm} />
        }
        {openDetail &&
            <ChildDetail setShowForm={setOpenDetail} />
        }
        </>
    )
}

export default ChildRow;