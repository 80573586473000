import { useState, useEffect } from "react";
import { getMembers } from "../../../libs/dataFunctions";

const DeleteMemberWithNewCreator = ({teamMember, onClose, teamID, token, setOpenDetail, refreshMembers}) =>{
    const [members,setMembers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedMember, setSelectedMember] = useState('');
    const [errorMsg, setErrorMsg] = useState('');

    const deleteMemberAction=async()=>{
        if(selectedMember!==''){
            setLoading(true);
            var formdata = new FormData();
                formdata.append("type", "delete_member");
                formdata.append("team_id", teamID);
                formdata.append("member_id",teamMember._id);
                formdata.append("assign_new_team_creator","Yes");
                formdata.append("new_team_creator_user_sub",selectedMember);
                var secureHeader = new Headers();
                secureHeader.append('Authorization','Bearer ' + token);
                secureHeader.append('device_type','W');
                const requestOptions = {
                    method: 'POST',
                    redirect: 'follow',
                    body: formdata,
                    headers: secureHeader
                };

                var apipoint=process.env.REACT_APP_APIURL + '/app_members';
                
                await fetch(apipoint , requestOptions)
                .then(response => response.text())
                .then(result => {
                    let rdata = JSON.parse(result);
                    onClose();
                    setOpenDetail(false);
                    refreshMembers();                    
                })
                .catch(error => console.log('error', error));
        }
        else{
            setErrorMsg("Please select one member as creator.");
        }
    }

    useEffect(()=>{
        const fetcMembers=async()=>{
            setLoading(true);
            const [teamMemberData] = await Promise.all([
                getMembers(teamID, token),
            ]);
            var tmpMembers = teamMemberData.filter((member)=>{return !member.is_club_creator && member.user_role.toLowerCase()==='admin'});
            setMembers(tmpMembers);
            setLoading(false);
        }
        fetcMembers();
    },[]);

    return(
        <div className='confirm-ui'>
            <div className="font-semibold uppercase text-[22px] leading-[30px] text-center mb-5">PLAI</div>
            <p className="text-center text-[14px] leading-[18px] mb-5">Select a user to reassign as the Team Creator {selectedMember}</p>
            {!loading ?
            <>                
                {members.length > 0 ?
                <div className="mb-5">
                    <select name="newteam_creator" className="default-field-style" onChange={(e)=>{setSelectedMember(e.target.value); setErrorMsg('');}}>
                        <option value=''>Select member *</option>
                        {members.map((elm)=>{
                            return(
                                <>
                                <option key={elm.sub} value={elm.sub}>{elm.name}</option>
                                </>                            
                            )
                        })}
                    </select>
                    {setErrorMsg!=='' && <p className="text-[12px] text-red-500">{errorMsg}</p>}
                </div>
                :
                <div className="mb-5">
                    <p className="text-[12px] text-red-500 text-center">No Admin Member Found</p>
                </div>
                }
                <div className="flex justify-center">
                    <button type="button" className="button button-blue mx-1" onClick={onClose}>Cancel</button>
                    {members.length > 0 &&
                        <>
                        <button type="submit" className="button"
                        onClick={() => {
                            deleteMemberAction();
                            onClose();
                        }}
                        >
                        Submit
                        </button>
                        </>
                    }
                </div>
                </>
                :
                <>
                    <div className="text-center"><img className="mx-auto" src="data:image/svg+xml,%3Csvg version='1.1' id='loader-1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='40px' height='40px' viewBox='0 0 40 40' enable-background='new 0 0 40 40' xml:space='preserve'%3E%3Cpath opacity='0.2' fill='%23000' d='M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z'%3E%3C/path%3E%3Cpath fill='%23000' d='M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 20 20' to='360 20 20' dur='0.5s' repeatCount='indefinite'%3E%3C/animateTransform%3E%3C/path%3E%3C/svg%3E" width={40} height={40} alt="" /></div>
                </>
            }
        </div>
    )
}
export default DeleteMemberWithNewCreator;