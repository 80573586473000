const ReferralCard = ({referralTeam}) =>{
    return(
        <>
            <div className="rounded-[30px] bg-white shadow-[0_12px_40px_0_rgba(54,63,108,0.1)] p-8">
                <div className="text-[10px] leading-[14px] text-gray-500">Reffered Club Name</div>
                <div className="mb-5 text-[16px] leading-[24px] font-semibold text-[#363F6C]">{referralTeam.name}</div>
                <div className="text-[10px] leading-[14px] text-gray-500">Reffered Club Address</div>
                <div className="mb-2.5 text-[16px] leading-[24px] font-semibold text-[#363F6C]">{referralTeam.location}</div>
                <hr/>
                <div className="flex flex-wrap py-1.5 justify-between">
                    <div className="text[12px] leading-[18px] font-medium">Team Created</div>
                    <div>
                        {referralTeam.is_10_member_joined ?
                            <svg width="18" height="19" viewBox="0 0 22 23" fill="#363F6C" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16.5556 1.92554H5.44444C2.98985 1.92554 1 3.91538 1 6.36998V17.4811C1 19.9357 2.98985 21.9255 5.44444 21.9255H16.5556C19.0102 21.9255 21 19.9357 21 17.4811V6.36998C21 3.91538 19.0102 1.92554 16.5556 1.92554Z" stroke="#363F6C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M7.66602 11.9256L10.166 14.1478L14.3327 9.70337" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        :
                            <svg width="18" height="19" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16.5556 1.92554H5.44444C2.98985 1.92554 1 3.91538 1 6.36998V17.4811C1 19.9357 2.98985 21.9255 5.44444 21.9255H16.5556C19.0102 21.9255 21 19.9357 21 17.4811V6.36998C21 3.91538 19.0102 1.92554 16.5556 1.92554Z" stroke="#363F6C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        }                        
                    </div>
                </div>
                <div className="flex flex-wrap py-1.5 justify-between">
                    <div className="text-[14px] leading-[18px] font-medium">Team Transacting</div>
                    <div>
                        {referralTeam.is_transaction_occure ?
                            <svg width="18" height="19" viewBox="0 0 22 23" fill="#363F6C" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16.5556 1.92554H5.44444C2.98985 1.92554 1 3.91538 1 6.36998V17.4811C1 19.9357 2.98985 21.9255 5.44444 21.9255H16.5556C19.0102 21.9255 21 19.9357 21 17.4811V6.36998C21 3.91538 19.0102 1.92554 16.5556 1.92554Z" stroke="#363F6C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M7.66602 11.9256L10.166 14.1478L14.3327 9.70337" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        :
                            <svg width="18" height="19" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16.5556 1.92554H5.44444C2.98985 1.92554 1 3.91538 1 6.36998V17.4811C1 19.9357 2.98985 21.9255 5.44444 21.9255H16.5556C19.0102 21.9255 21 19.9357 21 17.4811V6.36998C21 3.91538 19.0102 1.92554 16.5556 1.92554Z" stroke="#363F6C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        }
                    </div>
                </div>
                <div className="flex flex-wrap py-1.5 justify-between">
                    <div className="text-[14px] leading-[18px] font-medium">Your Next: <strong className="font-bold">{referralTeam.currency_symbol}125</strong></div>
                    <div>
                        {referralTeam.your_next ?
                            <svg width="18" height="19" viewBox="0 0 22 23" fill="#363F6C" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16.5556 1.92554H5.44444C2.98985 1.92554 1 3.91538 1 6.36998V17.4811C1 19.9357 2.98985 21.9255 5.44444 21.9255H16.5556C19.0102 21.9255 21 19.9357 21 17.4811V6.36998C21 3.91538 19.0102 1.92554 16.5556 1.92554Z" stroke="#363F6C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M7.66602 11.9256L10.166 14.1478L14.3327 9.70337" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        :
                            <svg width="18" height="19" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16.5556 1.92554H5.44444C2.98985 1.92554 1 3.91538 1 6.36998V17.4811C1 19.9357 2.98985 21.9255 5.44444 21.9255H16.5556C19.0102 21.9255 21 19.9357 21 17.4811V6.36998C21 3.91538 19.0102 1.92554 16.5556 1.92554Z" stroke="#363F6C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        }
                    </div>
                </div>
                <hr/>
                <div className="flex flex-wrap py-1.5 justify-between text-[14px] leading-[18px]">
                    <div className="font-light text-gray-500">Pay Out Received</div>
                    <div className="text-[#363F6C] font-semibold">{referralTeam.payouts_received}</div>
                </div>
                <div className="flex flex-wrap py-1.5 justify-between text-[14px] leading-[18px]">
                    <div className="font-light text-gray-500">Pay Out Pending</div>
                    <div className="text-[#363F6C] font-semibold">{referralTeam.payouts_pending}</div>
                </div>
            </div>
        </>
    );
}

export default ReferralCard;