import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import placeholderImg from '../../assets/images/placeholder.png';
import { useForm, Controller } from 'react-hook-form';
import Select, { components } from "react-select";
import DownArrowSvg from "../../assets/images/icon-down.svg";

const TeamCard = ({team, setActiveMarker, userDetail}) =>{
    const [joinpopup, setShowjoinPopup] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage,setErrorMessage] = useState('');
    
    const navigate = useNavigate();
    
    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
    ? localStorage.getItem('auth_token')
    : '';

    const handleTeamClick = () =>{
        navigate('/team/'+team._id);
    }
    
    const TeamJoinForm = ({setShowForm, userDetail}) =>{
        const [selectedChilds, setSelectedChilds] = useState([]);
        const [selectedChildsOut, setSelectedChildsOut] = useState(selectedChilds);
        const [childOptions , setChildOptions] = useState([]);
        
        const { register, handleSubmit, control, formState: { errors } } = useForm({});
        
        const DropdownIndicator = (props) => {
            return (
              <components.DropdownIndicator {...props}>
                <img src={DownArrowSvg} width={24} height={24} alt="" />
              </components.DropdownIndicator>
            );
        };
        function handleSelectChange(event) {
            const current = [...event];
            var current_child = current.map((child) => {
                return child.value
            });
            setSelectedChilds(current_child);
            setSelectedChildsOut(event);        
        }
        const handleTeamJoin= async(data)=>{
            setErrorMessage('');
            if(data.user_sub_ids !== undefined && data.user_sub_ids.length>0){
                let formdata = new FormData();
                formdata.append('type', 'join_teams');
                formdata.append('team_id', team._id);            
                formdata.append('user_sub_ids', data.user_sub_ids.join('@#'));
                
                if(data.fieldname){
                    if(data.fieldname.length>0){
                        var questions=[];
                        var values=[]
                        data.fieldname.forEach(element => {
                            questions.push(element.name);
                            values.push(element.value);
                        });
                        formdata.append('custom_fields_name',questions.join('@#'));
                        formdata.append('custom_fields_value',values.join('@#'));                    
                    }
                }
                formdata.append('separate_by','@#');
                var secureHeader = new Headers();
                secureHeader.append('Authorization','Bearer ' + token);
                secureHeader.append('device_type','W');
                const requestOptions = {
                    method: 'POST',
                    redirect: 'follow',
                    body: formdata,
                    headers: secureHeader
                };
                await fetch(process.env.REACT_APP_APIURL + '/app_teams' , requestOptions)
                .then(response => response.text())
                .then(result => {
                    setLoading(false);
                    let rdata = JSON.parse(result);
                    if(rdata.status !== 1){
                        setErrorMessage(rdata.message);
                    }
                    else{
                        setShowjoinPopup(false);
                        setLoading(false);
                        window.location.href = '/';
                    }
                })
                .catch(error => console.log('error', error));
            }
            else{
                setErrorMessage('Please select one joiners.');
            }
        }
        const onSubmit=(data)=>{            
            handleTeamJoin(data);
        }

        useEffect(()=>{
            if(userDetail.childrens.length>0){
                let chOptions = [];            
                chOptions.push({'value':userDetail.sub, 'label':userDetail.firstName+' '+userDetail.lastName});
                userDetail.childrens.forEach(element => {
                    chOptions.push({'value':element.sub, 'label':element.name});
                });
                setChildOptions( chOptions ); 
            }
            else{
                let chOptions = [];            
                chOptions.push({'value':userDetail.sub, 'label':userDetail.firstName+' '+userDetail.lastName});
                setChildOptions( chOptions ); 
            }
        },[userDetail]);
        
        return(            
            <div className="popup-form">
                <div className="popup-form-wrap relative !w-[400px] !px-7.5">
                    <button type="button" className="close-popup" onClick={(e)=>{setShowForm(false)}}><span>close</span></button>
                    <div className='flex flex-col align-center justify-center'>
                        <div className='mb-10'>Request to join <strong className='text-[#363F6C]'>{team.name}?</strong></div>
                        <form onSubmit={handleSubmit(onSubmit)} className="form-wrapper flex justify-center flex-col">
                            {childOptions.length>0 &&
                                <>
                                <div className='text-[16px] leading-[24px] text-black'>Who is joining this team?</div>
                                <div className='form-field-wrap mb-5 select-field-wrap groups-field-wrap relative'>
                                    <Controller
                                        control={control}
                                        name='user_sub_ids'
                                        rules={{ required: "Select minimum one user to join the team" }}
                                        render={({ 
                                            field: { onChange, value, ref },
                                            fieldState: { invalid, isTouched, isDirty, error},
                                            formState
                                        }) => (
                                            <Select
                                                inputRef={ref}
                                                value={selectedChildsOut}
                                                className="react-select-container"
                                                classNamePrefix="react-select"
                                                components={{ DropdownIndicator }}
                                                placeholder={<div>Select Joiners</div>}
                                                onChange={val => {
                                                    onChange(val.map(c => c.value));
                                                    handleSelectChange(val);
                                                }}
                                                options={childOptions}
                                                isMulti
                                                styles={{
                                                    control: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        borderWidth: 0,
                                                        backgroundColor: 'rgba(239, 241, 249, 0.6)',
                                                        padding: '6px 7px 6px 46px'
                                                    }),
                                                    menu: (baseStyles) => ({
                                                        ...baseStyles,
                                                        zIndex:1000,
                                                        backgroundColor: '#ffffff'
                                                    })
                                                }}
                                                theme={(theme) => ({
                                                    ...theme,
                                                    borderRadius: 8,                                                
                                                })}
                                            />
                                        )}
                                    />
                                    {errors.user_sub_ids && <p className='error mb-2.5 mt-1'>{errors.user_sub_ids.message}</p>}                                    
                                </div>
                                </>
                            }
                            {team.customFields && team.customFields.length > 0 &&
                                team.customFields.map((customField,i)=>{
                                    return(
                                        <div className='form-field-wrap small-field-wrap mb-5' key={customField._id}>
                                            <label>{customField.name}{customField.required ? '*' : ''}</label>
                                            <input type="hidden" {...register(`fieldname[${i}].name`)} value={customField.name} className='text-field' />
                                            <input type="text" {...register(`fieldname[${i}].value`, {required:customField.required ? "This field is required.": false})} className='text-field' />
                                            {errors.fieldname?.[i]?.value && <p className='error mb-2.5 mt-1'>{errors.fieldname?.[i]?.value?.message}</p>}
                                        </div>
                                    );
                                })
                            }
                            {errorMessage!=='' && <p className="text-red-500 mb-2.5 text-center" role="alert">{errorMessage}</p>}
                            {!loading ?
                                <div className='text-center'><button type="submit" className='button button-large'>Confirm</button></div>
                            :
                                <img src="data:image/svg+xml,%3Csvg version='1.1' id='loader-1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='20px' height='20px' viewBox='0 0 40 40' enable-background='new 0 0 40 40' xml:space='preserve'%3E%3Cpath opacity='0.2' fill='%23000' d='M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z'%3E%3C/path%3E%3Cpath fill='%23000' d='M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 20 20' to='360 20 20' dur='0.5s' repeatCount='indefinite'%3E%3C/animateTransform%3E%3C/path%3E%3C/svg%3E" width={40} height={40} alt="" />
                            }                            
                        </form>
                    </div>
                </div>
            </div>
        )
    }

    return(
        <>
            <div className="teamcard h-full pt-[114px] flex flex-column flex-end relative">                
                <div className='logo-wrapper absolute z-10 top-0 left-0 right-0 w-[180px] h-[180px] rounded-full overflow-hidden mx-auto'>
                    {team.logo && team.logo!=="" ?
                        <img src={team.logo} alt={team.name} className="absolute object-cover w-full h-full"/>
                    :
                        <img src={placeholderImg} alt={team.name} className="absolute object-cover w-full h-full"/>
                    }
                </div>
                <div className='teamcard-content relative px-[25px] pb-5 w-full'>
                    <div className='info text-center'>
                        <h5 className="title mb-1 hover:text-clip mb-2.5">{team.name}</h5>
                        <div className='mb-5 text-[10px] relative distance font-bold text-[#363F6C] flex justify-center items-center'>
                            <svg className='mr-2' width="12" height="16" viewBox="0 0 14 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.76718 18.2948C5.55045 17.257 4.42264 16.1192 3.39556 14.8934C1.85424 13.0524 0.0239266 10.3108 0.0239266 7.6983C0.0232582 6.36406 0.418415 5.05961 1.15939 3.95004C1.90036 2.84046 2.95384 1.97564 4.18651 1.46502C5.41917 0.954402 6.77561 0.820937 8.08416 1.08152C9.3927 1.3421 10.5945 1.98501 11.5376 2.92889C12.1654 3.55395 12.6631 4.29732 13.0017 5.11599C13.3403 5.93466 13.5133 6.81236 13.5104 7.6983C13.5104 10.3108 11.6801 13.0524 10.1388 14.8934C9.11172 16.1192 7.98392 17.257 6.76718 18.2948ZM6.76718 4.80833C6.00072 4.80833 5.26564 5.11281 4.72367 5.65478C4.18169 6.19676 3.87722 6.93183 3.87722 7.6983C3.87722 8.46477 4.18169 9.19984 4.72367 9.74182C5.26564 10.2838 6.00072 10.5883 6.76718 10.5883C7.53365 10.5883 8.26873 10.2838 8.8107 9.74182C9.35267 9.19984 9.65715 8.46477 9.65715 7.6983C9.65715 6.93183 9.35267 6.19676 8.8107 5.65478C8.26873 5.11281 7.53365 4.80833 6.76718 4.80833Z" fill="#363F6C"/></svg>
                            <span>{team.distance} KM</span>
                        </div>
                        {userDetail?.superAdmin ?
                        <>
                            <div className='flex justify-center'>
                                <button className='button button-yellow mr-1' onClick={()=>{handleTeamClick()}}>View Team</button>
                                <button className='button button-blue ml-1' onClick={()=>{setShowjoinPopup(true)}}>Join Team</button>
                            </div>
                        </>
                        :
                        <>
                            {team.is_joined ? 
                                <button className='button button-yellow' onClick={()=>{handleTeamClick()}}>View Team</button>
                            :
                                <button className='button button-blue' onClick={()=>{setShowjoinPopup(true)}}>Join Team</button>
                            }                        
                        </>
                        }                    
                    </div>
                    <div className='teamcard-footer flex w-full justify-between pt-5'>
                        {(team.sport && team.sport.image && team.sport.image!=="") &&
                            <div className='bg-[#363F6C] w-[28px] h-[28px] rounded-full p-1'><img src={team.sport.image} alt="" width={20} /></div>
                        }
                        {team.members.length > 0 &&
                        <div className="members">
                            {team?.members.map((teamMember,index)=>{
                                if(index > 4){return ''}
                                let eclass='';
                                let profilePic='';
                                profilePic=(teamMember.profilePicture && teamMember.profilePicture !== "") ? teamMember.profilePicture : placeholderImg;
                                if(index!==0){
                                    eclass = '-ml-2.5';
                                }                        
                                return <img src={profilePic} key={index} alt="" className={`inline-block relative object-cover object-center rounded-full w-7 h-7 ${eclass}`} />
                            })}
                            {team.members.length>5 &&
                                <span className='ml-2 text-[14px] leading-[22px] text-[#363F6C] font-medium'>+{team.members.length - 5}</span>
                            }
                        </div>
                        }
                    </div>
                </div>
            </div>
            {joinpopup &&
                <TeamJoinForm setShowForm={setShowjoinPopup} userDetail={userDetail}/>
            }
        </>
    );
}

export default TeamCard;