import { useState } from "react";
import Slider from "react-slick";
import CFGSTwoMemSelSlideItem from "./CFGTwoSelSlideItem";
import StarRating from "./StarRatings";
const CFGRookiePlayerSlider = ({iconplayers,setIconPlayers, setPlayerList, proplayers, setProPlayers, rookieplayers, setRookiePlayers, setPlayerMain}) =>{
    const [isCheckAll, setIsCheckAll] = useState(false);        
    const [isCheckplayers, setIsCheckPlayers] = useState([]);
    const [bulkRatingform,setShowBulkRatingForm] = useState(false);
    const handleSelectAll = e => {
        setIsCheckAll(!isCheckAll);
        setIsCheckPlayers(rookieplayers.map(memitem => memitem));
        if (isCheckAll) {
            setIsCheckPlayers([]);
        }
    };
    const SetBulkRatings = ({setShowForm}) =>{
        const [starRate, setStarRate] = useState(1);
        const updateRate = (rate) =>{
            setStarRate(rate);
        }
        const handleBulkRating = () =>{
            if(starRate===1){
                isCheckplayers.forEach((memberitem)=>{
                    if(memberitem.rating!==starRate){
                        memberitem.rating=starRate;
                        memberitem.price=0;
                        memberitem.updatedBefore=false;
                        setRookiePlayers(oldArray => [...oldArray,memberitem] );
                        setIconPlayers((current)=>current.filter((mitem) => mitem._id !== memberitem._id));
                        setProPlayers((current)=>current.filter((mitem) => mitem._id !== memberitem._id));
                        setPlayerList((current)=>current.filter((mitem) => mitem._id !== memberitem._id));
                        setPlayerMain((current)=>current.filter((mitem) => mitem._id !== memberitem._id));
                        setIsCheckPlayers((current)=>current.filter((mitem) => mitem._id !== memberitem._id));
                    }
                });                
            }
            else if(starRate===2){
                isCheckplayers.forEach((memberitem)=>{
                    if(memberitem.rating!==starRate){
                        memberitem.rating=starRate;
                        memberitem.price=0;
                        memberitem.updatedBefore=false;
                        setProPlayers(oldArray => [...oldArray,memberitem] );
                        setIconPlayers((current)=>current.filter((mitem) => mitem._id !== memberitem._id));
                        setRookiePlayers((current)=>current.filter((mitem) => mitem._id !== memberitem._id));
                        setPlayerList((current)=>current.filter((mitem) => mitem._id !== memberitem._id));
                        setPlayerMain((current)=>current.filter((mitem) => mitem._id !== memberitem._id));
                        setIsCheckPlayers((current)=>current.filter((mitem) => mitem._id !== memberitem._id));
                    }
                });
            }
            else if(starRate===3){
                isCheckplayers.forEach((memberitem)=>{
                    if(memberitem.rating!==starRate){
                        memberitem.rating=starRate;
                        memberitem.price=0;
                        memberitem.updatedBefore=false;
                        setIconPlayers(oldArray => [...oldArray,memberitem] );
                        setProPlayers((current)=>current.filter((mitem) => mitem._id !== memberitem._id));
                        setRookiePlayers((current)=>current.filter((mitem) => mitem._id !== memberitem._id));
                        setPlayerList((current)=>current.filter((mitem) => mitem._id !== memberitem._id));
                        setPlayerMain((current)=>current.filter((mitem) => mitem._id !== memberitem._id));
                        setIsCheckPlayers((current)=>current.filter((mitem) => mitem._id !== memberitem._id));
                    }
                });
            }
            setShowForm(false);
        }
        return(
            <div className="popup-form">
                <div className="popup-form-wrap relative !max-w-[550px] !px-7.5">
                    <button type="button" className="close-popup" onClick={(e)=>{setShowForm(false)}}><span>close</span></button>
                    <div className="w-full">
                        <h3 className="uppercase text-[22px] leading-[33px] font-semibold pb-[22px] border-b border-b-[#FBDB03] border-b-solid">RATING YOUR PLAYERS IN BULK</h3>
                        <div className="flex justify-center text-[28px] leading-[28px] mb-5"><StarRating onChange={updateRate} ratingValue={starRate}/></div>
                        <div className="flex justify-center">
                            <button className="button" type="button" onClick={()=>{handleBulkRating();}}>Apply now</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        centerMode: false,
        draggable:false,
        easing: 'linear',
        responsive: [                
            {
                breakpoint: 1200,
                settings: {
                  slidesToShow: 1,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 2,
                }
            },
            {
                breakpoint: 767,
                settings: {
                  slidesToShow: 1,
                }
            },
        ]
    };
    return(
        <div className="w-full lg:max-w-[830px] mb-6 bg-[#EFF1F9] rounded-[20px] pb-[18px]">
            <div className="pb-3 mb-3 border-b border-solid border-b-[#dfdfdf] flex justify-between pt-3 px-9">
                <h3 className="uppercase text-[#363F6C] text-[20px] leading-[30px] mb-0">Rookies</h3>
                <label className='italic text-[14px] leading-[21px] text-black cursor-pointer flex items-center'>
                    <input 
                        type="checkbox"
                        name="selectAll"
                        id="selectAll"
                        className='checkbox-field appearance-none h-4 w-4 rounded-sm bg-white align-top float-left mr-2 cursor-pointer'
                        onChange={handleSelectAll}
                        checked={isCheckAll} />
                        Select all
                </label>
            </div>
            <div className="relative px-5 player-catg-slider">
                <Slider {...settings}>
                    {rookieplayers.map((memberitem,index)=>{                                        
                        return (
                            <CFGSTwoMemSelSlideItem 
                                memberitem={memberitem} 
                                setPlayerList={setPlayerList}
                                key={memberitem._id}
                                playerType={'rookies'}
                                isCheckAll={isCheckAll}
                                setIsCheckAll={setIsCheckAll}
                                isCheckplayers={isCheckplayers}
                                setIsCheckPlayers={setIsCheckPlayers}
                                iconplayers={iconplayers}
                                setIconPlayers={setIconPlayers}
                                proplayers={proplayers}
                                setProPlayers={setProPlayers}
                                rookieplayers={rookieplayers}
                                setRookiePlayers={setRookiePlayers}
                                index={index} />
                        )
                    })}
                </Slider>
            </div>
            {isCheckplayers.length > 0 &&
                <>
                <div className="flex justify-center items-center pt-5 mb-5">
                    <button className="button button--medium" onClick={()=>{setShowBulkRatingForm(true)}}>Set ratings</button>
                </div>
                {bulkRatingform &&
                    <SetBulkRatings setShowForm={setShowBulkRatingForm} />
                }
                </>
            }
        </div>
    );
}

export default CFGRookiePlayerSlider;