import { useState, useEffect} from "react";
import { useParams, NavLink } from "react-router-dom";
import IsMobile from "../../components/isMobile";
import IsTablet from "../../components/isTablet";
import AdminNavbar from '../../components/AdminNavbar/AdminNavbar';
import Sidebar from '../../components/Sidebar/Sidebar';
import './Settings.scss';
import Changepassword from "./Changepass/Changepassword";
import Myorder from "./Myorder/Myorder";
import Logout from "./Logout/Logout";
import Deleteaccount from "./Deleteaccount/Deleteaccount";
import MyTeams from "./Myteams/Myteams";
import ReportIssue from "./ReportIssue/ReportIssue";
import FeatureRequest from "./ReportIssue/FeatureRequest";
import MyDocuments from "./MyDocuments/MyDocuments";
import MyCards from "./MyCards/MyCards";
import MySubscription from "./MySubscription/MySubscription";
import MembershipCards from "./MembershipCards/MembershipCards";
import { getProfile } from "../../libs/dataFunctions";
import Spinner from "../../components/Spinner/Spinner";

function Settings() {    
    const { tab } = useParams();
    const [userDetail, setUserDetail] = useState([]);
    const [tabActive, setTabActive] = useState('my_orders');    
    const [loading, setLoading] = useState(false);

    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
    ? localStorage.getItem('auth_token')
    : '';

    useEffect(() => {
        setTabActive(tab || 'saved_cards');
    }, [tab]);

    async function refreshProfile(){
        setLoading(true);
        const [profileData] = await Promise.all([
          getProfile(token)
        ]);
        
        setUserDetail(profileData);        
        setLoading(false);
    }
    useEffect(()=>{
        refreshProfile();
    },[]);

    const tabmenuItems = [
        {
            name: 'Saved Cards',
            slug: 'saved_cards',
            class: tabActive === 'saved_cards' ? 'active' : 'cursor-pointer',
            hide:false,
        },
        {
            name: 'My Purchases',
            slug: 'my_orders',
            class: tabActive === 'my_orders' ? 'active' : 'cursor-pointer',
            hide: false,
        },
        {
            name: 'Manage Subscription',
            slug: 'mysubscription',
            class: tabActive === 'mysubscription' ? 'active' : 'cursor-pointer',
            hide: userDetail.is_subscription_active ? false : true,
        },
        {
            name: 'My Documents',
            slug: 'my_documents',
            class: tabActive === 'my_documents' ? 'active' : 'cursor-pointer',
            hide: false,
        },
        {
            name: 'Membership Card',
            slug: 'membership_card',
            class: tabActive === 'membership_card' ? 'active' : 'cursor-pointer',
            hide: false,
        },
        {
            name: 'My Teams',
            slug: 'my_teams',
            class: tabActive === 'my_teams' ? 'active' : 'cursor-pointer',
            hide: false,
        },
        {
            name: 'Report an Issue',
            slug: 'report_issue',
            class: tabActive === 'report_issue' ? 'active' : 'cursor-pointer',
            hide: false,
        },
        {
            name: 'Submit a Feature Request',
            slug: 'feature_request',
            class: tabActive === 'feature_request' ? 'active' : 'cursor-pointer',
            hide:false
        },
        {
            name: 'Change Password',
            slug: 'changepassword',
            class: tabActive === 'changepassword' ? 'active' : 'cursor-pointer',
            hide: false,
        },
        {
            name: 'Delete Account',
            slug: 'delete_account',
            class: tabActive === 'delete_account' ? 'active' : 'cursor-pointer',
            hide: false,
        },        
        /*{
            name: 'LogOut',
            slug: 'logout',
            class: tabActive === 'logout' ? 'active' : 'cursor-pointer',
            hide: false,
        },*/
    ];
    const TabsMenu = ( { tabActive, setTabActive}) => {
        return(
        <>
            <ul className="tabs-menu flex flex-col w-full lg:w-1/2 xl:w-1/3 bg-inherit">
                {tabmenuItems.map((el,id) => {
                    return(
                        !el.hide &&
                            <li  key={id} className={`tabs-menu-item ${el.class}`} onClick={() => {
                                setTabActive(el.slug)
                            }}>{el.name}
                        </li>
                )
                })}                    
            </ul>
        </>
        );
    }
    const TabsMobileSettings = ({ tabActive, setTabActive})=>{
        return(
            <div className="tabs-menu flex w-full flex-col w-full bg-inherit">
                {tabmenuItems.map((el,index) => {
                    return(
                            !el.hide &&
                            <div key={index}>
                                <div className={`tabs-menu-item ${el.class}`} onClick={() => {
                                    setTabActive(el.slug)
                                }}>{el.name}
                                </div>
                                <div className={`tabs-contents w-full ${tabActive===el.slug ? 'flex' : 'hidden'}`}>
                                    <div className="tabs-content  lg:relative bg-white rounded-[14px] p-5">
                                        {tabActive === 'saved_cards' && <MyCards />}
                                        {tabActive === 'my_orders' && <Myorder/> }
                                        {tabActive === 'mysubscription' && <MySubscription/>}
                                        {tabActive === 'my_documents' && <MyDocuments />}
                                        {tabActive === 'membership_card' && <MembershipCards />}
                                        {tabActive === 'my_teams' && <MyTeams />}
                                        {tabActive === 'report_issue' && <ReportIssue />}
                                        {tabActive === 'feature_request' && <FeatureRequest />}
                                        {tabActive === 'changepassword' && <Changepassword/>}
                                        {tabActive === 'delete_account' && <Deleteaccount />}
                                        {tabActive === 'logout' && <Logout/>}                       
                                    </div>
                                </div>
                            </div>
                )
                })}                    
            </div>
        )
    }
    const ismobile = IsMobile(window);
    const istablet = IsTablet(window);
    return (
        <>
            <Sidebar type="thin" />
            <div id="page" className="page-settings">
                <AdminNavbar />
                <div className='breadcrumb flex item-center pt-1.5 pb-1 pl-5 pr-7 w-full'>
                    <NavLink to="/" className='home'>
                        <span>Dashboard</span>
                    </NavLink>
                </div>
                {!loading ?
                <div className='body'>
                    <div className='setting_tab_main w-full p-all'>
                        <div className='setting_tab_detail w-full'>
                            <div className="team-contents flex w-full rounded-xl ">
                                {(!ismobile && !istablet) ?
                                <>
                                    <TabsMenu
                                        tabActive={tabActive}
                                        setTabActive={setTabActive}
                                    />                          
                                    <div className="tabs-contents w-full flex ">
                                        <div className="tabs-content lg:relative bg-white rounded-[14px] p-[32px]">
                                            {tabActive === 'saved_cards' && <MyCards />}
                                            {tabActive === 'my_orders' && <Myorder/> }
                                            {tabActive === 'mysubscription' && <MySubscription/>}
                                            {tabActive === 'my_documents' && <MyDocuments />}
                                            {tabActive === 'membership_card' && <MembershipCards />}
                                            {tabActive === 'my_teams' && <MyTeams />}
                                            {tabActive === 'report_issue' && <ReportIssue />}
                                            {tabActive === 'feature_request' && <FeatureRequest />}
                                            {tabActive === 'changepassword' && <Changepassword/>}
                                            {tabActive === 'delete_account' && <Deleteaccount />}
                                            {tabActive === 'logout' && <Logout/>}
                                        </div>
                                    </div>
                                </>
                                :
                                <>
                                    <TabsMobileSettings
                                        tabActive={tabActive}
                                        setTabActive={setTabActive}
                                    />
                                </>
                                }                                        
                            </div>  
                        </div>              
                    </div>
                </div>
                :
                <>
                    <div className="py-5 text-center"><Spinner /></div>
                </>
                }
            </div>
        </>      
    );
}  
export default Settings;