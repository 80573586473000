import { useEffect, useState, useContext } from "react";
import { useParams, NavLink, useNavigate } from "react-router-dom";
import Sidebar from "../../../components/Sidebar/Sidebar";
import AdminNavbar from "../../../components/AdminNavbar/AdminNavbar";
import { TeamContext, TeamContextProvider } from "../../../contexts/TeamContext";
import placeholderImg from '../../../assets/images/placeholder.png';
import lineImg from '../../../assets/images/line.svg';
import fantacyLogo from '../../../assets/images/Fantasy-PLAI_LOGO.png';
import { getMembers, getGroups } from "../../../libs/dataFunctions";
import Spinner from "../../../components/Spinner/Spinner";

import "slick-carousel/slick/slick.css";
import './fantacygame.scss';
import CFGGameStepOne from "./CFGGameStepOne";
import CFGGameStepTwo from "./CFGGameStepTwo";
import CFGGameStepThree from "./CFGGameStepThree";
import CFGGameStepFour from "./CFGGameStepFour";
import CFGGameStepFive from "./CFGGameStepFive";
import CFGInviteMembers from "./CFGInviteMembers";
import FantacyMatrix from "./FantacyMatrix";

const CreateFantacyGameSteps = () =>{
    const { team, token, teamUpdating } = useContext(TeamContext);
    const [loading,setLoading] = useState(false);
    const [members, setMembers] = useState([]);
    const [groups, setGroups] = useState([]);
    const [selectedMembers, setSelectedMembers] = useState([]);
    const [currentStep, setCurrentStep] = useState(1);
    const [iconplayers,setIconPlayers ] = useState([]);
    const [proplayers,setProPlayers ] = useState([]);
    const [rookieplayers,setRookiePlayers ] = useState([]);
    const [errorMessage,setErrorMessage] = useState(null);
    const [showInvitedMembers, setShowInviteMembers] = useState(false);
    const [showMatrix, setShowMatrix] = useState(false);
    const [selectedMatrix, setSelectedMatrix] = useState(null);
    
    const navigate = useNavigate();
    const FGHeader = () =>{
        return(
            <>
                <div className='breadcrumb flex item-center pt-1.5 pb-1 pl-5 pr-7 w-full'>
                    <NavLink to="/" className='home'>
                        <span>Dashboard</span>
                    </NavLink>
                    <span className='separator'>&lt;</span>
                    <NavLink to={`/team/${team._id}`}>
                        <span>{team.name}</span>
                    </NavLink>
                    <span className='separator'>&lt;</span>
                    Create Fantasy Game
                </div>
                <div className="team-header px-5 py-[35px]">
                    <div className="flex flex-col sm:flex-row items-center justify-between">
                        <div className="w-full md:w-1/3">
                            <div className="text-white text-[18px] leading-[27px] font-medium mb-2.5">Create Fantasy Game</div>
                            <div className="flex items-center">
                                <div className="image-wrapper rounded-full w-[65px] h-[65px] border-2 border-white border-solid overflow-hidden relative mb-2.5 sm:mb-0">
                                {team.logo && team.logo !== "" ?                                
                                    <img src={team.logo} alt={team.name} className="absolute object-cover w-full h-full" />
                                    :
                                    <img src={placeholderImg} alt={team.name} className="absolute object-cover w-full h-full" />
                                }
                                </div>
                                <div className="mr-[16px] ml-[30px]"><img src={lineImg} alt="" /></div>
                                <div><img src={fantacyLogo} alt="" /></div>
                            </div>
                        </div>                        
                        <div className="text-[#FBDB03] text-[24px] leading-[36px] font-semibold flex-1 px-5 text-center">{team.name}</div>
                        
                        <div className="w-full md:w-1/3 text-right">
                            <button type="button" className="button button-action button-import mr-1.5" onClick={()=>{navigate('/team/'+team._id+'/import-game');}}>Upload</button>                            
                        </div>
                        
                    </div>
                </div>
            </>
        )
    }
    async function refreshTeamMembers(){
        setLoading(true);
        const [teamMemberData, groupdata] = await Promise.all([
            getMembers(team._id, token),
            getGroups(team._id, token)
        ]);
        setGroups(groupdata);
        if(selectedMembers.length<=0){
            setMembers(teamMemberData);
        }
        var tmpMembers = [];     
        var tmpMembers2 = [];
        var tmpIcons = [];
        var tmpPros = [];
        var tmpRookies = [];
        teamMemberData.forEach((member)=>{
            if(member.price!==0){
                tmpMembers2.push(member);
                if(member.price >=20 && member.price<=30){
                    tmpIcons.push(member);
                }
                if(member.price >=10 && member.price<=19.9){
                    tmpPros.push(member);
                }
                if(member.price >=1 && member.price<=9.9){
                    tmpRookies.push(member);
                }
            }
            else{
                tmpMembers.push(member);
            }
        });
        
        setSelectedMembers(tmpMembers2);
        setMembers(tmpMembers);
        setIconPlayers(tmpIcons);
        setProPlayers(tmpPros);
        setRookiePlayers(tmpRookies);        
        setLoading(false);
    }
    useEffect(() => {
        if(!teamUpdating){  
            if(team.is_fantasy_game_available && team.stripeEnabled){
                refreshTeamMembers();
                if(team.can_update_fantasy_matrix){
                    setShowMatrix(true);
                }
                else{
                    setSelectedMatrix(team.fantasy_matrix_id);
                }
            }
            else{
                window.location.href = '/team/'+team._id;
            }
        }
    },[teamUpdating]);

    const submitGame = async() =>{
        var formdata = new FormData();
        formdata.append("type", "admin_create_fantasy_game");
        formdata.append("team_id", team._id);
        let selectedGamePlayers = [];
        if(iconplayers.length > 0){
            iconplayers.forEach((member)=>{
                let players = {};
                players.sub=member.sub;
                players.price=member.price;
                players.rating = member.rating;
                //let playerJson = JSON.stringify(players);
                selectedGamePlayers.push(players);                
            });
        }
        if(proplayers.length > 0){
            proplayers.forEach((member)=>{
                let players = {};
                players.sub=member.sub;
                players.price=member.price;
                players.rating = member.rating;
                //let playerJson = JSON.stringify(players);
                selectedGamePlayers.push(players);                
            });
        }
        if(rookieplayers.length > 0){
            rookieplayers.forEach((member)=>{
                let players = {};
                players.sub=member.sub;
                players.price=member.price;
                players.rating = member.rating;
                //let playerJson = JSON.stringify(players);
                selectedGamePlayers.push(players);                
            });
        }
        formdata.append("members",JSON.stringify(selectedGamePlayers));
        formdata.append("fantasy_matrix_id",selectedMatrix);
        
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        }
        await fetch(process.env.REACT_APP_APIURL + '/app_fantasy' , requestOptions)
        .then(response => response.text())
        .then(result => {
            setLoading(false);
            let rdata = JSON.parse(result);
            if(rdata.status !== 1){
                setErrorMessage(rdata.message);
            }
            else{
                setShowInviteMembers(true);
                //window.location.href = '/team/'+team._id;
            }
        })
        .catch(error => console.log('error', error));
    }
    const CFGBack = () =>{
        if(currentStep===1){
            window.location.href = '/team/'+team._id;
        }
        else if(currentStep===2){
            setCurrentStep(1);
        }
        else if(currentStep===3){
            setCurrentStep(2);
        }
        else if(currentStep===4){
            setCurrentStep(3);
        }
        else if(currentStep===5){
            setCurrentStep(4);
        }
    }
    const CFGInviteMemberPopup = ({setShowForm}) => {
        return(
            <div className="popup-form">
                <div className="popup-form-wrap relative !w-full !max-w-[850px]">
                    <CFGInviteMembers team={team} setShowForm={setShowForm} />
                </div>
            </div>
        )
    }
    //Render Main
    return(
        !loading && !teamUpdating ?
        <>            
            <FGHeader />
            <div className="body px-5 lg:px-8 py-10">
                <button className="mb-5" onClick={()=>{CFGBack();}}>{'<< Back'}</button>
                <div className="team-contents w-full rounded-xl bg-white">
                    {currentStep===1 &&
                        <>
                            <CFGGameStepOne groups={groups} members={members} setMembers={setMembers} selectedMembers={selectedMembers} setSelectedMembers={setSelectedMembers} setCurrentStep={setCurrentStep} />
                            {showMatrix &&
                                <FantacyMatrix setShowForm={setShowMatrix} team={team} token={token} setSelectedMatrix={setSelectedMatrix} selectedMatrix={selectedMatrix} />
                            }
                        </>
                    }
                    {currentStep===2 &&
                        <CFGGameStepTwo 
                            groups={groups}
                            selectedMembers={selectedMembers} 
                            setSelectedMembers={setSelectedMembers}
                            iconplayers={iconplayers} 
                            setIconPlayers={setIconPlayers}
                            proplayers={proplayers} 
                            setProPlayers={setProPlayers}
                            rookieplayers={rookieplayers}
                            setRookiePlayers={setRookiePlayers} 
                            setCurrentStep={setCurrentStep} />
                    }
                    {currentStep===3 &&
                        <CFGGameStepThree 
                            selectedMembers={selectedMembers}
                            setSelectedMembers={setSelectedMembers} 
                            iconplayers={iconplayers} 
                            setIconPlayers={setIconPlayers}
                            setCurrentStep={setCurrentStep} />
                    }
                    {currentStep===4 &&
                        <CFGGameStepFour 
                            selectedMembers={selectedMembers}
                            setSelectedMembers={setSelectedMembers} 
                            proplayers={proplayers} 
                            setProPlayers={setProPlayers}
                            setCurrentStep={setCurrentStep}
                        />
                    }
                    {currentStep===5 &&
                        <CFGGameStepFive
                            selectedMembers={selectedMembers}
                            setSelectedMembers={setSelectedMembers} 
                            rookieplayers={rookieplayers} 
                            setRookiePlayers={setRookiePlayers}                            
                            submitGame={submitGame}
                        />
                    }
                </div>
            </div>
            {showInvitedMembers &&
                <CFGInviteMemberPopup setShowForm={setShowInviteMembers} />
            }
        </>
        :
        <>
            <div className="py-5 text-center"><Spinner /></div>
        </>
    )
}

const CreateFantacyGame = () =>{
    const { id } = useParams();
    return(
        <>
            <Sidebar type="thin"/>
            <TeamContextProvider id={id}>
                <div id="page" className="page-fantacy-game">
                    <AdminNavbar heading="Fantasy Game"/>
                    <CreateFantacyGameSteps />
                </div>
            </TeamContextProvider>
        </>
    )
}
export default CreateFantacyGame;
