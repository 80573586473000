import { useState } from 'react';
import placeholderImg from '../../../assets/images/placeholder.png';
import dayjs from 'dayjs';

const MemberChildrenItem = ({childData, teamId}) =>{
    const [showChild,setShowChild] = useState(false);    

    const ChildDetail = ({setShowChildPop}) =>{
        return(
            <div className="popup-form">
                <div className="popup-form-wrap popup-form-memberdetails relative !px-2.5 sm:!px-8">                    
                    <button type="button" className="close-popup" onClick={(e)=>{setShowChildPop(false)}}><span>close</span></button>
                    <div className='text-center text-[18px] leading-[24px] font-semibold mb-5'>Child Info</div>
                    <div className='member-card'>
                        <div className='flex flex-col sm:flex-row items-center w-full member-card-row pb-5 border-b border-[#53545C]/30'>
                            <div className='pr-5'>
                            {childData.profilePicture !== '' ?  
                              <img src={childData.profilePicture} alt="" width={100} height={100} className="rounded-full cursor-pointer"/>
                              :
                              <img src={placeholderImg} alt="" width={100} height={100} className="rounded-full cursor-pointer"/>
                            }
                            </div>
                            <div className='member-info grow flex flex-col sm:flex-row px-2.5 sm:px-0 py-2.5 sm:py-0 text-center sm:text-left sm:items-start'>
                                <div className='flex flex-col self-center grow'>
                                    <h3 className='member-name'>{childData.name}</h3>
                                    <div className='member-email'>{childData.email}</div>
                                </div>                            
                            </div>
                        </div>
                        <div className='flex flex-col sm:flex-row w-full justify-between member-card-row py-5'>
                            {childData.phoneNumber!=="" &&
                              <div className='member-phone flex items-center mb-2.5 sm:mb-0'><div className='button-tiny phone rounded-full mr-1.5'></div>{childData.phoneCode!=="" ? childData.phoneCode+' '+childData.phoneNumber : childData.phoneNumber}</div>
                            }
                            <div className='member-birthdate flex items-center'><div className='button-tiny date rounded-full mr-1.5'></div>{childData.dateOfBirth!=='' ? dayjs(childData.dateOfBirth).format('DD / MMM / YYYY'): (childData.is_above_18 ? 'Over 18 years' : 'Under 18 years')}</div>
                        </div>                        
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
        <li>
            <div className='member-cf mr-2 mb-3 py-1.5 px-5 bg-[#F5F5F8] hover:bg-[#d2d2df] rounded-[14px] cursor-pointer' onClick={()=>{setShowChild(true)}}>
                {childData.name}
            </div>
        </li>
        {showChild && 
            <ChildDetail setShowChildPop={setShowChild} />
        }
        </>
    )
}

export default MemberChildrenItem;