import Amex from '../../../assets/images/amex.svg';
import Discover from '../../../assets/images/cardDiscover@3x.png';
import DinnersClub from '../../../assets/images/cardDiners@3x.png';
import Jcb from '../../../assets/images/cardJCB@3x.png';
import Mastercard from '../../../assets/images/cardMaster@3x.png';
import UnionPay from '../../../assets/images/cardUnionPay@3x.png';
import Visa from '../../../assets/images/cardVisa@3x.png';
import { useState } from 'react';
import { isWindows } from 'react-device-detect';

const MyCard=({paymentcard, refreshMyCards})=>{
    const [checked,setChecked] = useState(paymentcard.default_source);
    const [updateLoading, setUpdateLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    let companyLogo = Visa;
    if(paymentcard.brand.toLowerCase() === "visa") {
        companyLogo = Visa;
    }
    else if(paymentcard.brand.toLowerCase() === "mastercard") {
        companyLogo = Mastercard;
    }
    else if(paymentcard.brand.toLowerCase() === "american express") {
        companyLogo = Amex;
    }
    else if(paymentcard.brand.toLowerCase() === "diners club") {
        companyLogo = DinnersClub;
    }
    else if(paymentcard.brand.toLowerCase() === "discover") {
        companyLogo = Discover;
    }
    else if(paymentcard.brand.toLowerCase() === "jcb") {
        companyLogo = Jcb;
    }
    else if(paymentcard.brand.toLowerCase() === "unionpay") {
        companyLogo = UnionPay;
    }
    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
    ? localStorage.getItem('auth_token')
    : '';
    const updateDefaultStatus=async(e)=>{
        if(e.target.checked){
            setUpdateLoading(true);
            let formdata = new FormData();
            formdata.append("type", "set_default_card");
            formdata.append("card_id", paymentcard.id);
            var secureHeader = new Headers();
            secureHeader.append('Authorization','Bearer ' + token);
            secureHeader.append('device_type','W');
            const requestOptions = {
                method: 'POST',
                redirect: 'follow',
                body: formdata,
                headers: secureHeader
            };

            await fetch(process.env.REACT_APP_APIURL + '/app_stripe_card' , requestOptions)
            .then(response => response.text())
            .then(result => {
                let rdata = JSON.parse(result);
                setUpdateLoading(false);
                refreshMyCards();
            })
            .catch(error => console.log('error', error));
        }
    }

    async function deleteCard(){
        if(window.confirm("Are you sure you want to delete this card?")){
            var formdata = new FormData();
            formdata.append("type", "delete_card");
            formdata.append("card_id", paymentcard.id);

            var secureHeader = new Headers();
            secureHeader.append('Authorization','Bearer ' + token);
            secureHeader.append('device_type','W');
            const requestOptions = {
                method: 'POST',
                redirect: 'follow',
                body: formdata,
                headers: secureHeader
            };

            await fetch(process.env.REACT_APP_APIURL + '/app_stripe_card' , requestOptions)
            .then(response => response.text())
            .then(result => {
                window.location.href="/settings/saved_cards";
            })
            .catch(error => console.log('error', error));
        }
    }

    return(
        <>
        <div className="payment-card relative rounded-[14px] relative">
            <div className='logo absolute top-[5px] left-[5px]'>
                <img src={companyLogo} alt={paymentcard.brand} width={70} />
            </div>
            <div className="py-1 w-full top-0 left-0 absolute flex flex-col justify-between h-full">                
                <div className='actions flex justify-end items-center w-full p-2'>
                    <div className='check-field-wrap relative px-2.5'>
                        {!updateLoading ?
                        <label htmlFor={`usedefault-${paymentcard.id}`} className='!text-[12px] justify-center text-[#000000] cursor-pointer'>
                            <input 
                                type="checkbox" id={`usedefault-${paymentcard.id}`} defaultChecked={checked} className='checkbox-field appearance-none h-4 w-4 rounded-sm bg-transparent mt-1 align-top float-left mr-2 cursor-pointer'
                                onChange={(e)=>{
                                    updateDefaultStatus(e);
                                }}
                            />Default Card
                        </label>
                        :
                        <span className='text-[12px] text-[#000000]'>Updating...</span>
                        }
                    </div>
                    {!deleteLoading ?
                        <button type="button" className='button-tiny delete rounded-full' onClick={()=>{deleteCard()}}></button>
                    :
                        <img src="data:image/svg+xml,%3Csvg version='1.1' id='loader-1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='20px' height='20px' viewBox='0 0 40 40' enable-background='new 0 0 40 40' xml:space='preserve'%3E%3Cpath opacity='0.2' fill='%23fff' d='M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z'%3E%3C/path%3E%3Cpath fill='%23fff' d='M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 20 20' to='360 20 20' dur='0.5s' repeatCount='indefinite'%3E%3C/animateTransform%3E%3C/path%3E%3C/svg%3E" width={20} height={20} alt="" />
                    }
                </div>
                <div className='flex justify-end w-full px-2.5'>
                    <div>
                        <div className='text-[10px] text-[#000000]'>EXPIRY DATE</div>
                        <div className='text-[12px] font-bold text-[#000000] text-right'>{('0' + paymentcard.exp_month).slice(-2)}/{paymentcard.exp_year}</div>
                    </div>
                </div>
                <div className='flex justify-between items-end w-full px-2.5 pb-2.5'>
                    <div>
                        <div className='text-[10px] text-[#000000]'>CARD HOLDER NAME</div>
                        <div className='text-[12px] font-bold text-[#000000]'>{paymentcard.name}</div>
                    </div>
                    <div className='text-[12px] font-bold text-[#000000]'>****{paymentcard.last4}</div>
                </div>
            </div>
        </div>
        </>
    );
}
export default MyCard;