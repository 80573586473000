import { useState, useEffect, useRef, useCallback } from "react";
import CFGSThreeMemRow from "./CFGThreeMemRow";
import placeholderImg from '../../../assets/images/placeholder.png';

const CFGGameStepFive = ({selectedMembers, setSelectedMembers, rookieplayers, setRookiePlayers, submitGame,}) =>{
    const [showInfoFive, setShowInfofiveForm] = useState(false);
    const [isCheckAll, setIsCheckAll] = useState(false);        
    const [isCheckplayers, setIsCheckPlayers] = useState([]);
    const [q, setSearchQuery] = useState("");    
    const [searchParam] = useState(["name"]);
    const [bulkPricingform, setShowBulkPricingForm] = useState(false);
    const handleSelectAll = e => {
        setIsCheckAll(!isCheckAll);
        setIsCheckPlayers(search(rookieplayers).map(memitem => memitem));
        if (isCheckAll) {
            setIsCheckPlayers([]);
        }
    }
    const SetBulkPriceRookie = ({setShowForm}) =>{
        const [value,onChange]=useState(1);        
        let minPrice = 1;
        let maxPrice = 9.5;
        const range = useRef(null);
        const maxValRef = useRef(null);
        const handleBulkPricing = () =>{
            let newArray = [...rookieplayers];
            isCheckplayers.forEach((memberitem,index)=>{
                const playerIndex = rookieplayers.findIndex(element => element._id === memberitem._id );                
                newArray[playerIndex] = {...newArray[playerIndex], price: parseFloat(value), updatedBefore: true}                        
            }); 
            setRookiePlayers(newArray);
            setShowForm(false);
            setIsCheckAll(false);
            setIsCheckPlayers([]);
        }
        const getPercent = useCallback(
            val => Math.round(((val - minPrice) / (maxPrice - minPrice)) * 100),
            [minPrice,maxPrice]
        )
        useEffect(() => {
            if (maxValRef.current) {
              const maxPercent = getPercent(value)
        
              if (range.current) {
                range.current.style.width = `${maxPercent}%`
              }
            }
        }, [value, getPercent]);
        return(
            <div className="popup-form">
                <div className="popup-form-wrap relative !max-w-[550px] !px-7.5">
                    <button type="button" className="close-popup" onClick={(e)=>{setShowForm(false)}}><span>close</span></button>
                    <div className="w-full">
                        <h3 className="uppercase text-[22px] leading-[33px] font-semibold pb-[22px] border-b border-b-[#FBDB03] border-b-solid">PRICING YOUR PLAYERS IN BULK</h3>
                        <div className="relative w-full">
                            <div className="single_rangeslider-container flex items-center h-[10px] mb-[15px]">
                                <input 
                                    type="range" 
                                    className='single-thumb'
                                    min={minPrice} 
                                    max={maxPrice} 
                                    value={value}
                                    step={0.5}
                                    ref={maxValRef}
                                    onChange={({ target: { value: radius } }) => {
                                        onChange(radius);
                                    }}
                                />
                                <div className="single_rangeslider relative w-full">
                                    <div className="single_rangeslider__track absolute rounded-[14px] h-[10px] bg-[#d3d6e4] w-full z-10"></div>
                                    <div ref={range} className="single_rangeslider__range bg-[#363F6C] z-20 absolute rounded-[14px] h-[10px]"></div>                                
                                </div>
                            </div>
                            <div className='flex justify-between'>
                                <div className="text-[12px] leading-[18px]">${parseFloat(minPrice).toFixed(1)}m</div>
                                <div className="text-[12px] leading-[18px] font-semibold text-[#363F6C]">${parseFloat(value).toFixed(1)}m</div>
                                <div className="text-[12px] leading-[18px]">${parseFloat(maxPrice).toFixed(1)}m</div>
                            </div>
                        </div>
                        <div className="flex justify-center">
                            <button className="button" type="button" onClick={()=>{handleBulkPricing();}}>Apply now</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    const search=(items)=>{
        return items.filter((item) => {
            return searchParam.some((newItem) => {
                return (
                    item[newItem]
                        .toString()
                        .toLowerCase()
                        .indexOf(q.toLowerCase()) > -1
                );
            });
        });
    }
    const StepFiveInfo = ({setShowForm}) =>{
        return(
            <div className="popup-form">
                <div className="popup-form-wrap relative !max-w-[550px] !px-7.5">
                    <button type="button" className="close-popup" onClick={(e)=>{setShowForm(false)}}><span>close</span></button>
                    <div className="w-full">
                        <h3 className="uppercase text-[22px] leading-[33px] font-semibold pb-[22px] border-b border-b-[#FBDB03] border-b-solid">FANTASY: BULK PRICING</h3>
                        <p>Use the checkbox to assign multiple players the same value</p>                            
                    </div>
                </div>
            </div>
        )
    }
    useEffect(()=>{        
        let newArray = [...rookieplayers];
        rookieplayers.forEach((player,playerIndex)=>{
            if(player.price===0){
                newArray[playerIndex] = {...newArray[playerIndex], price: 1, updatedbefore: false}
            }
            else{
                newArray[playerIndex] = {...newArray[playerIndex], updatedBefore: true}
            }
        });
        setRookiePlayers(newArray);
    },[]);
    return(
        <>
            <div className="px-2.5 sm:px-7.5 pt-[35px] mb-6">
                <div className="flex justify-between items-start">
                    <div className="flex-1 pr-5"><strong>Step 5:</strong> It's time to put a price on your ROOKIES! ROOKIES must be valued between $1.0m and $9.9m.</div>
                    <div className="flex items-center">
                        <label htmlFor="search-form" className="search-field relative mr-3.5 !mb-0">
                            <input
                                type="search"
                                name="search-field"
                                id="search-form"
                                className="search-input"
                                placeholder="Search Player"
                                value={q}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />                        
                        </label>
                        <button className="info-button" onClick={()=>{setShowInfofiveForm(true)}}>info</button>
                    </div>
                </div>
                {showInfoFive &&
                    <StepFiveInfo setShowForm={setShowInfofiveForm} />
                }
            </div>
            <div className="flex flex-wrap px-2.5 md:px-7 pb-2.5 md:pb-7">
                <div className="w-full lg:w-auto lg:flex-1 lg:pr-[34px]">
                    <div className="pb-3 mb-3 border-b border-solid border-b-[#dfdfdf]">
                        <h3 className="uppercase text-[#363F6C] text-[22px] leading-[33px] mb-0">Price up your Rookies!</h3>
                    </div>
                    {search(rookieplayers).length > 0 ?
                        <>
                            <div className="flex justify-end  mb-2.5">
                                <label className='italic text-[14px] leading-[21px] text-black cursor-pointer flex items-center'>
                                    <input 
                                        type="checkbox"
                                        name="selectAll"
                                        id="selectAll"
                                        className='checkbox-field appearance-none h-4 w-4 rounded-sm bg-white align-top float-left mr-2 cursor-pointer'
                                        onChange={handleSelectAll}
                                        checked={isCheckAll} />
                                        Select all
                                </label>
                            </div>
                            <div className="flex flex-wrap">
                                {search(rookieplayers).map((memberitem,index)=>{
                                    return (
                                        <CFGSThreeMemRow 
                                            memberitem={memberitem} 
                                            key={memberitem._id}
                                            isCheckplayers={isCheckplayers}
                                            setIsCheckPlayers={setIsCheckPlayers}
                                            players={rookieplayers}
                                            setPlayers={setRookiePlayers}                                                
                                            minPrice = {1}
                                            maxPrice = {9.9}
                                            price={memberitem.price!==0 ? memberitem.price : 1}
                                            index={index} />                                            
                                    )
                                })}                                    
                            </div>
                            {isCheckplayers.length > 0 &&
                                <>
                                <div className="flex justify-center items-center pt-5 mb-5">
                                    <button className="button button--medium" onClick={()=>{setShowBulkPricingForm(true)}}>Set Pricing</button>
                                </div>
                                {bulkPricingform &&
                                    <SetBulkPriceRookie setShowForm={setShowBulkPricingForm} />
                                }
                                </>
                            }
                        </>
                        :
                        <>
                            <div>
                                <div className="text-center text-[14px] leading-[21px] mb-2 font-medium mx-auto">You dont have any rookies selected.</div>
                                <div className="flex justify-center items-center mb-5">
                                    <button className="button button--medium" onClick={()=>{submitGame()}}>Confirm</button>
                                </div>
                            </div>
                        </>
                    }
                </div>
                <div className="w-full max-w-[446px] rounded-[20px] bg-[#EFF1F9]">
                    <div className="pb-3 pt-[18px] px-7 mb-3 border-b border-solid border-[#aeb2c7]">
                        <h3 className="uppercase text-[#363F6C] text-[20px] leading-[30px] text-center">Rookies values</h3>
                    </div>
                    
                    {rookieplayers.filter(function (memberitem) { return memberitem.updatedBefore }).length > 0 ?
                        <div className="py-12">
                            {rookieplayers.filter(function (memberitem) { return memberitem.updatedBefore }).map((memberitem,index)=>{
                                return (
                                    <div className="w-full mb-5 px-11" key={`rookie-${memberitem._id}`}>
                                        <div className="w-full px-2.5 py-5 flex justify-between rounded-[16px] bg-[#ffffff] items-center">
                                            <div className="flex items-center">
                                                {memberitem.profilePicture !=="" ?
                                                    <img src={memberitem.profilePicture} width="50" height="50" className="rounded-full w-[50px] mr-[18px]" alt="" />
                                                :
                                                    <img src={placeholderImg} width="50" height="50" className="rounded-full w-[50px] mr-[18px]" alt="" />
                                                }
                                                <div className="flex-1 pr-2.5">
                                                    <span className="block text-[#363F6C] text-[16px] leading-[24px] font-semibold">{memberitem.name}</span>
                                                    <span className="block text-[18px] leading-[27px] font-semibold">${memberitem.price}m</span>
                                                </div>
                                            </div>                                                
                                        </div>
                                    </div>                                    
                                )
                            })}
                            <div className="flex justify-center items-center mb-5">
                                <button className="button button--medium" onClick={()=>{submitGame()}}>Confirm</button>
                            </div>
                        </div>
                        :
                        <>
                            <div className="w-full min-h-[300px] py-12 flex flex-col justify-center items-center">
                                <div>
                                    <div className="rounded-full flex justify-center items-center mx-auto bg-[#F4F5FA] border border-[#E1E2E9] border-solid w-[110px] h-[110px]">
                                        <svg width="51" height="44" viewBox="0 0 51 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0.988281 30.0733C1.19765 29.3649 1.32181 28.6126 1.63342 27.9553C2.8324 25.4064 4.853 23.9385 7.67821 23.7388C9.09021 23.639 10.5168 23.7218 11.9775 23.7218C11.9446 23.8581 11.9069 23.9774 11.8886 24.0991C11.7255 25.1825 11.4383 26.2658 11.4261 27.3516C11.3737 32.0294 11.3981 36.7073 11.4115 41.3863C11.4115 41.945 11.521 42.5038 11.5843 43.1063H9.90698C8.48281 43.1063 7.05621 43.0686 5.63326 43.1148C3.28278 43.1903 1.35468 41.6176 1.04185 39.3852C1.04185 39.3572 1.00654 39.3329 0.988281 39.3061V30.0733Z" fill="#FBDB09"/>
                                            <path d="M25.8571 43.1063H16.0937C14.7912 43.1063 14.2946 42.6194 14.2934 41.3328C14.2934 36.6367 14.2837 31.9418 14.2934 27.2469C14.3031 23.7169 16.5282 20.9173 19.9401 20.1248C20.4298 20.0166 20.9298 19.9627 21.4313 19.9642C24.3782 19.9483 27.3251 19.9337 30.2708 19.9642C33.7095 19.997 36.4787 22.2368 37.259 25.5878C37.3787 26.1404 37.4366 26.7046 37.4319 27.27C37.444 31.9994 37.444 36.7276 37.4319 41.4545C37.4319 42.5756 36.8926 43.1063 35.7569 43.1075C32.4598 43.1083 29.1599 43.1079 25.8571 43.1063Z" fill="#363F6C"/>
                                            <path d="M25.8817 0.910767C30.6131 0.919287 34.5009 4.78645 34.5082 9.49107C34.506 11.7842 33.5985 13.9836 31.983 15.611C30.3674 17.2384 28.1747 18.162 25.8817 18.1809C21.1429 18.2065 17.1918 14.2261 17.2308 9.46429C17.2577 7.18725 18.1808 5.01253 19.8001 3.41144C21.4194 1.81035 23.6044 0.911889 25.8817 0.910767Z" fill="#363F6C"/>
                                            <path d="M40.2326 43.0808C40.2667 41.9549 40.3263 40.8593 40.3299 39.765C40.3409 35.7969 40.3299 31.8299 40.3299 27.8617C40.3299 26.5227 40.2314 25.2105 39.7895 23.9385C39.776 23.8806 39.7686 23.8215 39.7676 23.762C39.809 23.7316 39.8345 23.6987 39.8577 23.6987C41.5545 23.7426 43.2781 23.6123 44.9421 23.8704C48.2286 24.3804 50.6631 27.3212 50.7276 30.654C50.7775 33.3575 50.7665 36.0634 50.7349 38.7681C50.7069 41.0882 49.0612 42.9469 46.8105 43.0577C44.6049 43.166 42.3846 43.0808 40.2326 43.0808Z" fill="#FBDB09"/>
                                            <path d="M17.1485 15.4336C17.1071 19.0038 14.1857 21.9105 10.674 21.8752C7.10141 21.8387 4.21169 18.9271 4.23847 15.391C4.24194 14.5394 4.41397 13.697 4.74462 12.9123C5.07527 12.1275 5.55801 11.416 6.16499 10.8188C6.77197 10.2215 7.4912 9.75036 8.28117 9.43243C9.07115 9.11451 9.91624 8.95612 10.7677 8.96641C14.3233 9.01023 17.1887 11.9158 17.1485 15.4336Z" fill="#FBDB09"/>
                                            <path d="M47.4982 15.3995C47.5128 18.9429 44.6182 21.8509 41.0529 21.8752C37.5339 21.8984 34.621 18.9928 34.5881 15.4239C34.5565 11.8829 37.4584 8.97252 41.0261 8.964C42.7373 8.96076 44.3798 9.63659 45.5932 10.8431C46.8066 12.0497 47.4917 13.6884 47.4982 15.3995Z" fill="#FBDB09"/>
                                        </svg>
                                    </div>
                                    <div className="text-center text-[14px] leading-[21px] mb-2 font-medium w-[188px] mx-auto">Put a price on your Rookies!</div>
                                    <div className="text-center text-[10px] leading-[12px] w-[188px] mx-auto text-[#8B8D97]">Your Rookies will show here in PRICE ORDER once valued.</div>
                                </div>
                            </div>
                        </>
                    }
                </div>
            </div>
        </>
    )
}

export default CFGGameStepFive;