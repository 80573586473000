import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { formatCreditCardNumber, formatExpirationDate, formatCVC, getIsUser } from '../../libs/utils';
import { getMyPaymentCards } from '../../libs/dataFunctions';
import CountriesList from '../../libs/countries';
import Amex from '../../assets/images/amex.svg';
import Discover from '../../assets/images/discover.svg';
import DinnersClub from '../../assets/images/dinersclub.svg';
import Jcb from '../../assets/images/jcb.svg';
import Mastercard from '../../assets/images/mastercard.svg';
import UnionPay from '../../assets/images/unionpay.svg';
import Visa from '../../assets/images/visa.svg';

import Discover_card from '../../assets/images/cardDiscover@3x.png';
import DinnersClub_card from '../../assets/images/cardDiners@3x.png';
import Jcb_card from '../../assets/images/cardJCB@3x.png';
import Mastercard_card from '../../assets/images/cardMaster@3x.png';
import UnionPay_card from '../../assets/images/cardUnionPay@3x.png';
import Visa_card from '../../assets/images/cardVisa@3x.png';
import SavedCard from './SavedCard';

const CardIcon=({value})=>{
    const icons = {
        'amex':Amex,
        'mastercard':Mastercard,
        'visa':Visa,
        'discover':Discover,
        'jcb':Jcb,
        'dinersclub': DinnersClub,
        'uninpay': UnionPay,
        'elo':null,
        'hipercard':null,
        'troy':null,
        'maestro':null,
        'laser':null
    }
    if (!value) {
        return null;
    }
    
    return(
        icons[value]!==null ? <img src={icons[value]} alt="" width={24} /> : null
    )
}

const CheckoutForm = ({client_secret, intent_id, isSingle}) => {
    const [loading, setLoading] = useState(false)  ;
    const [cardNum, setCardNum] = useState('');
    const [cardExpiry, setCardExpiry] = useState('');
    const [cardCVV, setCardCVV] = useState('');
    const [country, setCountry] = useState('');
    const [error, setError] = useState('');
    const [cardicon, setCardIcon] = useState(null);
    const [cardHolderName, setCardName] = useState('');
    const [paymentCards, setPaymentCards] = useState([]);
    const [currentCardID, setCurrentCardID] = useState('');
    const [showChangeCardPop, setShowChangeCardPop] = useState(false);
    const navigate = useNavigate();
    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
    ? localStorage.getItem('auth_token')
    : '';

    const ChangeCardPop = ({setShowForm}) =>{
        return(
            <div className="popup-form">
                <div className="popup-form-wrap !w-full !max-w-[700px] relative">
                <button type="button" className="close-popup" onClick={(e)=>{setShowForm(false)}}><span>close</span></button>
                <div className="form-heading text-center mb-[30px]">Change Card</div>
                {paymentCards.length > 0 ?
                <>
                    <ul className='flex flex-wrap'>
                        {paymentCards.map((card,index) => {
                            return (
                                <li key={card.id} className={`px-2 mb-4`}>
                                    <SavedCard paymentcard={card} setShowForm={setShowForm} refreshMyCards={refreshMycards}/>
                                </li>
                            )
                        })}
                    </ul>
                </>
                :
                <></>
                }
                </div>
            </div>
        )
    }
    
    const handleSubmit = async (event) => {
        event.preventDefault();
        setError("");
        if(currentCardID !== ''){
            setLoading(true);
            setError('');
            let formdata = new FormData();
            formdata.append('type','stripe_payment_confirm');
            formdata.append('intent_id',intent_id);
            formdata.append('client_secret',client_secret);
            formdata.append('card_id',currentCardID);
            var secureHeader = new Headers();
            secureHeader.append('Authorization','Bearer ' + token);
            secureHeader.append('device_type','W');
            const requestOptions = {
                method: 'POST',
                redirect: 'follow',
                body: formdata,
                headers: secureHeader
            }

            let payApiUrl = process.env.REACT_APP_APIURL + '/app_stripe_payment_confirm_v2';
            if(isSingle){
                payApiUrl = process.env.REACT_APP_APIURL + '/app_stripe_payment_confirm';
            }
            
            await fetch(payApiUrl , requestOptions)
            .then(response => response.text())
            .then(result => {
                setLoading(false);
                let rdata = JSON.parse(result);
                if(rdata.status !== 1){
                    setError(rdata.message);
                }
                else{
                    if(rdata.data.redirct_url===''){
                        ///Go to thank you page
                        navigate('/thankyou');
                    }
                    else{
                        window.location.href=rdata.data.redirct_url;
                    }
                }
            })
            .catch(error => console.log('error', error));
        }
        else{
            if(cardNum!=="" && cardExpiry!=="" && cardCVV!=="" && country!==""){
                setLoading(true);
                setError('');
                let formdata = new FormData();
                let expiry = cardExpiry.split("/");
                let today, cardday;
                today = new Date();
                cardday = new Date();
                cardday.setFullYear(parseInt(expiry[1])+2000,expiry[0]-1,0);            
                if (cardday < today) {
                    setError("Invalid expiry date");
                }
                else{
                    formdata.append('type','add_card');                    
                    formdata.append('card_number',cardNum);
                    formdata.append('name',cardHolderName);
                    formdata.append('exp_year',expiry[1]);
                    formdata.append('exp_month',expiry[0]);
                    formdata.append('country',country);
                    formdata.append('cvc',cardCVV);
                    formdata.append('is_default_card','Yes');

                    var secureHeader2 = new Headers();
                    secureHeader2.append('Authorization','Bearer ' + token);
                    secureHeader2.append('device_type','W');
                    const requestOptions = {
                        method: 'POST',
                        redirect: 'follow',
                        body: formdata,
                        headers: secureHeader2
                    }
                    
                    await fetch(process.env.REACT_APP_APIURL + '/app_stripe_card' , requestOptions)
                    .then(response => response.text())
                    .then(result => {
                        let rdata = JSON.parse(result);
                        if(rdata.status !== 1){
                            setLoading(false);
                            setError(rdata.message);
                        }
                        else{
                            let formdata = new FormData();
                            formdata.append('type','stripe_payment_confirm');
                            formdata.append('intent_id',intent_id);
                            formdata.append('client_secret',client_secret);
                            formdata.append('card_id',rdata.data[0].id);
                            var secureHeader = new Headers();
                            secureHeader.append('Authorization','Bearer ' + token);
                            secureHeader.append('device_type','W');
                            const requestOptions = {
                                method: 'POST',
                                redirect: 'follow',
                                body: formdata,
                                headers: secureHeader
                            }
                            
                            fetch(process.env.REACT_APP_APIURL + '/app_stripe_payment_confirm_v2' , requestOptions)
                            .then(response => response.text())
                            .then(result => {
                                setLoading(false);
                                let rdata = JSON.parse(result);
                                if(rdata.status !== 1){
                                    setError(rdata.message);
                                }
                                else{
                                    if(rdata.data.redirct_url===''){
                                        ///Go to thank you page
                                        navigate('/thankyou');
                                    }
                                    else{
                                        window.location.href=rdata.data.redirct_url;
                                    }
                                }
                            })
                            .catch(error => console.log('error', error));
                        }
                    })
                    .catch(error => console.log('error', error));
                }            
            }
            else{
                setError("Please fill all fields above.");
            }
        }
    }

    const refreshMycards = async()=>{
        setLoading(true);
        let mycardsData = await getMyPaymentCards(token);
        if(mycardsData.data && mycardsData.data.length > 0){
            setPaymentCards(mycardsData.data);
            mycardsData.data.forEach(element => {
                if(element.default_source){
                    setCurrentCardID(element.id);
                }
            });
        }
        setLoading(false);
    }
    useEffect(()=>{
        refreshMycards();
    },[]);
    
    return (
        <form onSubmit={handleSubmit}>
            {!loading ?
            <>
                {paymentCards.length > 0 ?                
                <>
                    <ul className='flex justify-end'>
                        {paymentCards.map((card,index) => {
                            let companyLogo = Visa_card;
                            if(card.brand.toLowerCase() === "visa") {
                                companyLogo = Visa_card;
                            }
                            else if(card.brand.toLowerCase() === "mastercard") {
                                companyLogo = Mastercard_card;
                            }
                            else if(card.brand.toLowerCase() === "american express") {
                                companyLogo = Amex;
                            }
                            else if(card.brand.toLowerCase() === "diners club") {
                                companyLogo = DinnersClub_card;
                            }
                            else if(card.brand.toLowerCase() === "discover") {
                                companyLogo = Discover_card;
                            }
                            else if(card.brand.toLowerCase() === "jcb") {
                                companyLogo = Jcb_card;
                            }
                            else if(card.brand.toLowerCase() === "unionpay") {
                                companyLogo = UnionPay_card;
                            }
                            return (
                                card.default_source &&
                                <li key={card.id} className={`px-2 mb-4`}>
                                    <div className="payment-card relative rounded-[14px] relative">
                                        <div className='logo absolute top-[5px] left-[5px]'>
                                            <img src={companyLogo} alt={card.brand} width={70} />
                                        </div>
                                        <div className="py-1 w-full top-0 left-0 absolute flex flex-col justify-between h-full">                
                                            <div className='actions flex justify-end items-center w-full p-2'>
                                                <button type="button" className='button button-blue button-medium' onClick={()=>{setShowChangeCardPop(true)}}>Change Card</button>
                                            </div>
                                            <div className='flex justify-end w-full px-2.5'>
                                                <div>
                                                    <div className='text-[10px] text-[#000000]'>EXPIRY DATE</div>
                                                    <div className='text-[12px] font-bold text-[#000000] text-right'>{('0' + card.exp_month).slice(-2)}/{card.exp_year}</div>
                                                </div>
                                            </div>
                                            <div className='flex justify-between items-end w-full px-2.5 pb-2.5'>
                                                <div>
                                                    <div className='text-[10px] text-[#000000]'>CARD HOLDER NAME</div>
                                                    <div className='text-[12px] font-bold text-[#000000]'>{card.name}</div>
                                                </div>
                                                <div className='text-[12px] font-bold text-[#000000]'>****{card.last4}</div>
                                            </div>
                                        </div>
                                    </div>                                    
                                </li>
                            )
                        })}
                    </ul>
                    {showChangeCardPop &&
                        <ChangeCardPop setShowForm={setShowChangeCardPop} />
                    }
                    <div className='form-field-wrap mb-5 w-full no-field-wrap relative flex justify-end'>                    
                        {!loading ?
                            <button type="submit" className='button button--large'>CONFIRM PURCHASE</button>
                        :
                        <div className='flex justify-center items-center'>
                            <img src="data:image/svg+xml,%3Csvg version='1.1' id='loader-1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='20px' height='20px' viewBox='0 0 40 40' enable-background='new 0 0 40 40' xml:space='preserve'%3E%3Cpath opacity='0.2' fill='%23000' d='M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z'%3E%3C/path%3E%3Cpath fill='%23000' d='M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 20 20' to='360 20 20' dur='0.5s' repeatCount='indefinite'%3E%3C/animateTransform%3E%3C/path%3E%3C/svg%3E" width={40} height={40} alt="" /> TRANSACTION IS IN PROCESS...
                        </div>
                        }
                    </div>
                </>
                :
                    <div className='flex flex-wrap'>
                        <div className='form-field-wrap w-full sm:w-1/2 mb-5 no-field-wrap sm:pr-1 relative'>
                            <input 
                                placeholder="0000 0000 0000 0000" className='text-field' 
                                type='tel'
                                name='number'
                                pattern='[\d| ]{16,22}'
                                maxLength='19'
                                required
                                autoComplete="off"
                                onChange={(e)=>{
                                    e.target.value = formatCreditCardNumber(e.target.value);
                                    setCardNum(e.target.value);
                                    let icon = getIsUser(e.target.value);
                                    setCardIcon(icon);
                                    setError('');
                                }}
                            />
                            <div className='absolute top-[18px] right-[20px]'>
                                <CardIcon value={cardicon}/>
                            </div>
                        </div>
                        <div className='form-field-wrap w-full sm:w-1/4 mb-5 no-field-wrap sm:pr-1 sm:pl-1 relative'>
                            <input 
                                placeholder="MM/YY" className='text-field' 
                                type='tel'
                                name='expiry'
                                pattern='\d\d/\d\d'
                                required
                                autoComplete="off"
                                onChange={(e)=>{
                                    e.target.value = formatExpirationDate(e.target.value);
                                    setCardExpiry(e.target.value);
                                    setError('');
                                }}
                            />                                
                        </div>
                        <div className='form-field-wrap w-full sm:w-1/4 mb-5 no-field-wrap sm:pl-1 relative'>
                            <input 
                                type='tel'
                                name='cvc'
                                className='text-field'
                                placeholder='CVC'
                                pattern='\d{3}'
                                required
                                autoComplete="off"
                                onChange={(e)=>{
                                    e.target.value = formatCVC(e.target.value);
                                    setCardCVV(e.target.value);
                                    setError('');
                                }}
                            />                                
                        </div>
                        <div className='form-field-wrap w-full mb-5 no-field-wrap relative'>
                            <input 
                                type='text'
                                name='name'
                                className='text-field'
                                placeholder='Card Holder Name'
                                required
                                autoComplete="off"
                                onChange={(e)=>{
                                    setCardName(e.target.value);
                                    setError('');
                                }}
                            />                                
                        </div>
                        <div className='form-field-wrap w-full mb-5 select-field-wrap country-field-wrap relative'>
                            <select name="country" onChange={(e)=>{setCountry(e.target.value)}}>
                                <option value="">Select Country *</option>
                                {CountriesList.map((c,index)=>{
                                    return(
                                        <option key={index} value={c.code}>{c.name}</option>
                                    );
                                })}
                            </select>
                        </div>
                        {error!=="" && <p className='text-red-700 error mb-5'>{error}</p>}
                        <div className='form-field-wrap mb-5 w-full no-field-wrap relative flex justify-end'>                    
                            {!loading ?
                                <button type="submit" className='button button--large'>CONFIRM PURCHASE</button>
                            :
                            <div className='flex justify-center items-center'>
                                <img src="data:image/svg+xml,%3Csvg version='1.1' id='loader-1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='20px' height='20px' viewBox='0 0 40 40' enable-background='new 0 0 40 40' xml:space='preserve'%3E%3Cpath opacity='0.2' fill='%23000' d='M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z'%3E%3C/path%3E%3Cpath fill='%23000' d='M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 20 20' to='360 20 20' dur='0.5s' repeatCount='indefinite'%3E%3C/animateTransform%3E%3C/path%3E%3C/svg%3E" width={40} height={40} alt="" /> TRANSACTION IS IN PROCESS...
                            </div>
                            }
                        </div>
                    </div>
                }
            </>
            :
                <img src="data:image/svg+xml,%3Csvg version='1.1' id='loader-1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='20px' height='20px' viewBox='0 0 40 40' enable-background='new 0 0 40 40' xml:space='preserve'%3E%3Cpath opacity='0.2' fill='%23000' d='M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z'%3E%3C/path%3E%3Cpath fill='%23000' d='M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 20 20' to='360 20 20' dur='0.5s' repeatCount='indefinite'%3E%3C/animateTransform%3E%3C/path%3E%3C/svg%3E" width={30} height={30} alt="" className='mx-auto' />
            }
        </form>
    )
}

export default CheckoutForm;