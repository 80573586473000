import { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import Select, {components} from "react-select";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import DownArrowSvg from "../../assets/images/icon-down.svg";
import Spinner from "../../components/Spinner/Spinner";

const schema = yup.object({
    teamids: yup.array()
    .min(1, "At least one group must be selected")
    .nullable()
    .required("At least one group must be selected"),    
}).required();

const ChildJoinTeamForm = ({teams, childData, setShowChildJoinForm, setShowMainForm, refreshProfile}) =>{
    const [loadingThis, setLoadingThis] = useState(false);
    const [selectedTeams, setSelectedTeams] = useState([]);
    const [selectedTeamsOut, setSelectedTeamsOut] = useState(selectedTeams);
    const { register, handleSubmit, setValue, control,formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });
    
    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
    ? localStorage.getItem('auth_token')
    : '';

    const onSubmit = async(data) => {
        setLoadingThis(true);
        var formdata = new FormData();
        formdata.append("type", "child_join_teams");
        formdata.append("child_sub",childData.sub);
        formdata.append("teams_id", selectedTeams.join('@#'));
        formdata.append('separate_by','@#');
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        secureHeader.append('access-control-allow-origin','*');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata, 
            headers: secureHeader
        };

        await fetch(process.env.REACT_APP_APIURL + '/app_teams' , requestOptions)
        .then(response => response.text())
        .then(result => {
            let rdata = JSON.parse(result);
            setLoadingThis(false);
            setShowMainForm(false);
            refreshProfile();
        })
        .catch(error => console.log('error', error));
    }

    const DropdownIndicator = (props) => {
        return (
          <components.DropdownIndicator {...props}>
            <img src={DownArrowSvg} width={24} height={24} alt="" />
          </components.DropdownIndicator>
        );
    };

    function handleSelectChange(event) {
        const current = [...event];
        var current_teams = current.map((team) => {
            return team.value
        });
        setSelectedTeams(current_teams);
        setSelectedTeamsOut(event);        
    }

    return(
        <>
        <div className="popup-form">
            <div className="popup-form-wrap popup-form-memberdetails relative !px-2.5 sm:!px-8">
                {!loadingThis ?
                <>
                <button type="button" className="close-popup" onClick={(e)=>{setShowChildJoinForm(false)}}><span>close</span></button>
                <form onSubmit={handleSubmit(onSubmit)} className="form-wrapper product-form-wrapper">
                    <div className="uppercase text-center font-semibold text-[#363F6C] text-[18px] leading-[20px] mb-5">Which teams would you like to assign this child to</div>
                    <div className='form-field-wrap mb-5 select-field-wrap no-field-wrap relative'>                          
                        <Controller
                            control={control}
                            name='teamids'
                            render={({ field: { onChange, value, ref }}) => (
                                  <Select
                                      inputRef={ref}
                                      value={selectedTeamsOut}
                                      className="react-select-container"
                                      classNamePrefix="react-select"
                                      components={{ DropdownIndicator }}
                                      placeholder={<div>Select Teams*</div>}
                                      onChange={val => {
                                          onChange(val.map(c => c.value));
                                          handleSelectChange(val);
                                      }}
                                      options={teams}
                                      isMulti
                                      styles={{
                                          control: (baseStyles, state) => ({
                                              ...baseStyles,
                                              borderWidth: 0,
                                              backgroundColor: 'rgba(239, 241, 249, 0.6)',
                                              padding: '6px 7px 6px 6px'
                                          }),
                                          menu: (baseStyles) => ({
                                              ...baseStyles,
                                              zIndex:1000,
                                              backgroundColor: '#ffffff'
                                          })
                                      }}
                                      theme={(theme) => ({
                                          ...theme,
                                          borderRadius: 8,                                                
                                      })}
                                  />
                              )}
                        />                          
                        </div>
                        {errors.teamids?.message && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.teamids?.message}</p> }
                        <div className='flex justify-center'>
                            <input className='button button--large' type="submit" value="Add" disabled={loadingThis}/>
                        </div>
                </form>
                </>
                :
                <>
                    <div className="py-5 text-center"><Spinner /></div>
                </>
            }
            </div>
        </div>
        </>        
    )
}

export default ChildJoinTeamForm;