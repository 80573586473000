
import { useState, useEffect, useContext } from 'react';
import { useParams, NavLink } from "react-router-dom";
import Sidebar from "../../../components/Sidebar/Sidebar";
import AdminNavbar from "../../../components/AdminNavbar/AdminNavbar";
import { TeamContextProvider, TeamContext } from "../../../contexts/TeamContext";
import Spinner from '../../../components/Spinner/Spinner';
import { getFacilities } from '../../../libs/dataFunctions';
import Nofacilities from "../../../assets/images/noemptyother.png";
import FacilityCard from './FacilityCard';
import './managefacilities.scss';

const ManageFacilityList=()=>{
    const { team, teamUpdating } = useContext(TeamContext);
    const [loading,setLoading] = useState(false);
    const [facilitiesList, setFacilitiesList] = useState([]);
    const [q, setSearchQuery] = useState("");    
    const [searchParam] = useState(["name"]);

    const search=(items)=>{
        return items.filter((item) => {
            return searchParam.some((newItem) => {
                return (
                    item[newItem]
                        .toString()
                        .toLowerCase()
                        .indexOf(q.toLowerCase()) > -1
                );
            });
        });
    }

    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
    ? localStorage.getItem('auth_token')
    : '';

    const fetchAllFacilities=async()=>{
        setLoading(true);
        const [facilitiesData] = await Promise.all([
            getFacilities(token, team._id)
        ]);
        setFacilitiesList(facilitiesData.data);
        setLoading(false);
    }

    useEffect(()=>{
        setLoading(true);
        if(!teamUpdating){
            fetchAllFacilities();
        }
    },[teamUpdating]);
    return(
        <>
            {!loading && !teamUpdating ?
                <>
                <div className='breadcrumb flex item-center pt-1.5 pb-1 pl-5 pr-7 w-full'>
                    <NavLink to="/" className='home'>
                        <span>Dashboard</span>
                    </NavLink>
                    <span className='separator'>&lt;</span>
                    <NavLink to={`/team/${team._id}/events`}>
                        Events
                    </NavLink>
                    <span className='separator'>&lt;</span>
                    Manage Facilites
                </div>
                <div className='body px-5 lg:px-8 py-10'>
                    <div className='rounded-xl bg-white pb-10'>
                        <div className="tab-actionbar px-2.5 sm:px-7.5 py-4.5 mb-7.5 flex flex-col md:flex-row justify-between items-center">
                            <div className="title-col max-w-[320px] md:max-w-full mx-auto md:mx-0 mb-2.5 md:mb-0">Facilities</div>
                            <div className="cta-col max-w-[320px] md:max-w-full mx-auto md:mx-0 flex justify-center md:justify-end flex-wrap items-center relative">
                                <label htmlFor="search-form" className="search-field relative mr-auto ml-auto md:mr-3.5 mb-2.5 md:mb-0">
                                    <input
                                        type="search"
                                        name="search-field"
                                        id="search-form"
                                        className="search-input"
                                        placeholder="Search Facilities"
                                        value={q}
                                        onChange={(e) => {setSearchQuery(e.target.value)}}
                                    />                        
                                </label>                                        
                            </div>                    
                        </div>
                        <div className='px-5 lg:px-8'>
                            {search(facilitiesList).length > 0 ?
                                <>
                                    <div className='bg-[#EFF1F9] rounded-xl pb-10'>
                                        <div className='px-6 pt-9 pb-4 border-b border-[rgba(54,63,108,0.4)] mb-7.5'>
                                            <div className='flex gap-5 items-center'>
                                                <NavLink to={`/team/${team._id}/events`}>
                                                    <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <rect width="30" height="30" rx="8" transform="matrix(-1 0 0 1 30 0.670898)" fill="#FBDB03"/>
                                                        <path d="M12.2625 15.6166L16.6677 19.4705C17.186 19.9253 18 19.5557 18 18.8654L18 11.1576C18.0001 11.0031 17.9557 10.8519 17.8721 10.722C17.7885 10.5921 17.6692 10.489 17.5286 10.4252C17.3879 10.3613 17.2318 10.3394 17.079 10.362C16.9261 10.3845 16.7831 10.4507 16.6669 10.5525L12.2633 14.4064C12.177 14.4818 12.1078 14.5749 12.0604 14.6792C12.013 14.7836 11.9885 14.8969 11.9885 15.0115C11.9885 15.1261 12.013 15.2394 12.0604 15.3438C12.1078 15.4482 12.177 15.5412 12.2633 15.6166L12.2625 15.6166Z" fill="#363F6C"/>
                                                    </svg>
                                                </NavLink>
                                                <span className='text-xl font-bold uppercase'>Facilities</span>
                                            </div>
                                        </div>
                                        <div className='grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-7.5 px-6 pb-7 mb-10'>
                                            {search(facilitiesList).map((facilityItem)=>{
                                                return(
                                                    <FacilityCard facility={facilityItem} key={facilityItem._id} token={token} fetchAllFacilities={fetchAllFacilities} />
                                                )
                                            })}
                                        </div>
                                        <div className='flex items-center justify-center'>
                                            <a href={`/team/${team._id}/add-facilities`} className='button button--large flex gap-1.5 items-center'>
                                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M9 2.69971V15.2997" stroke="currentColor" strokeWidth="2"/>
                                                    <path d="M15.3003 8.99951L2.70029 8.99951" stroke="currentColor" strokeWidth="2"/>
                                                </svg>
                                                <span>Add Facilities</span>
                                            </a>
                                        </div>
                                    </div>
                                </>
                            :
                                <>
                                    <div className="empty text-center">
                                        <div className="image"><img src={Nofacilities} alt="Plaisport" width={228} /></div>                                        
                                        <div className="title mb-5">No Facilites Yet?</div>
                                        <div className="description mb-8">Facilites are not available yet.</div>
                                        <a href={`/team/${team._id}/add-facilities`} className='button button-yellow button-yellow-add' onClick={(e)=>{}}>Add Facilities</a>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
                </>
            :
                <>
                    <div className="py-5 text-center"><Spinner /></div>
                </>
            }
        </>
    )
}

const ManageFacilities=()=>{
    const { id } = useParams();
    return(
        <>
            <Sidebar type="thin" />
            <TeamContextProvider id={id}>
                <div id="page" className="">
                    <AdminNavbar heading="Manage Facilities" />  
                    <ManageFacilityList />              
                </div>
            </TeamContextProvider>
        </>
    )
}

export default ManageFacilities;