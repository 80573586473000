import { configureStore } from '@reduxjs/toolkit';
import conversationListReducer from './conversationListSlice';
import activeChatReducer from './activeChatSlice';
import counterReducer from './counterSlice';

export const store = configureStore({
  reducer: {
    conversationList:conversationListReducer,
    activeChat:activeChatReducer,
    newmsgCounter: counterReducer
  },
})