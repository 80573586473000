import { useEffect, useState } from "react";
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Select, { components } from "react-select";
import { getRemainingAttendees } from "../../../libs/dataFunctions";
import Spinner from "../../../components/Spinner/Spinner";
import DownArrowSvg from "../../../assets/images/icon-down.svg";

const schema = yup.object({
    user_sub_ids: yup
    .array()
    .min(1, "Select at least one member or add manually")
    .nullable()
    .required("Select at least one member or add manually"),
}).required();

const ManualAttendiForm=({setShowForm,team,eventItem, refreshEvent})=>{
    const [remainingAttendees, setRemainingAttendees] = useState([]);
    const [selectedMembers, setSelectedMembers] = useState([]);
    const [selectedMembersOut, setSelectedMembersOut] = useState(selectedMembers);
    const [errorMessage,setErrorMessage] = useState(false);
    const [successMessage,setSuccessMessage] = useState(false);

    const [loading, setLoading] = useState(false);
    const { register, handleSubmit, setValue, control, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema)
    });

    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token') !== undefined
        ? localStorage.getItem('auth_token')
        : '';

    const getMemberList = async() =>{
        setLoading(true);
        var memberData = await getRemainingAttendees(token, team._id, eventItem._id);
        let attendeesOptions = [];
        memberData.data.forEach(element => {
            attendeesOptions.push({'value':element.sub, 'label':element.name});
        });        
        attendeesOptions.push({'value':'manual', 'label':'Add Member Manually'});
        setRemainingAttendees(attendeesOptions);
        setLoading(false);
    }
    
    const onSubmit = async(data) => {
        setErrorMessage(false);
        setSuccessMessage(false);
        var formdata = new FormData();
        formdata.append('type','manually_add_attendance');
        formdata.append('team_id',team._id);
        formdata.append('event_id',eventItem._id);
        formdata.append('separate_by','@#');
        
        if(data.user_sub_ids.includes('manual')){
            if(data.firstName==="" || data.lastName==="" || data.email==="" ){
                setErrorMessage("Please enter all details for manual attendee");
                return;
            }
            else{
                if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)){
                    setErrorMessage("Invalid email address");
                    return;
                }
                else{
                    formdata.append("firstName",data.firstName);
                    formdata.append("lastName",data.lastName);
                    formdata.append("email",data.email);
                }                
            }            
        }
        let usersub = data.user_sub_ids;
        const usersub_index = usersub.indexOf('manual');
        if (usersub_index > -1) {
            usersub.splice(usersub_index, 1);
        }
        formdata.append('user_sub_ids',usersub.join('@#'));
        
        setLoading(true);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        await fetch(process.env.REACT_APP_APIURL + '/app_event' , requestOptions)
        .then(response => response.text())
        .then(result => {
            setLoading(false);
            let rdata = JSON.parse(result);
            if(rdata.status !== 1){
                setErrorMessage(rdata.message);
            }
            else{
                setSuccessMessage(rdata.message);
                reset();
                setShowForm(false);
                refreshEvent();
            }
        })
        .catch(error => console.log('error', error));
    }

    const DropdownIndicator = (props) => {
        return (
          <components.DropdownIndicator {...props}>
            <img src={DownArrowSvg} width={24} height={24} alt="" />
          </components.DropdownIndicator>
        );
    };
    function handleSelectChange(event) {
        const current = [...event];
        var current_members = current.map((member) => {
            return member.value
        });
        setSelectedMembers(current_members);
        setSelectedMembersOut(event);
    }

    useEffect(() => {
        getMemberList();
    }, []);

    return(
        !loading ?
        <>
        <div className="bg-[#363F6C] py-7.5 px-5 rounded-xl">
            <div>
                <div className="form-heading text-center mb-[30px] !text-white">Add Attendance</div>
                <form onSubmit={handleSubmit(onSubmit)} className="form-wrapper link-form-wrapper">                
                    <div>
                        <div className="form-field-container">
                            <div className="label">Select member</div>
                            <div className='form-field-wrap mb-5 select-field-wrap no-field-wrap relative'>
                                <Controller
                                    control={control}
                                    name='user_sub_ids'
                                    render={({ field: { onChange, value, ref }}) => (
                                        <Select
                                            inputRef={ref}
                                            value={selectedMembersOut}
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            components={{ DropdownIndicator }}
                                            placeholder={<div>Select*</div>}
                                            onChange={val => {
                                                onChange(val.map(c => c.value));
                                                handleSelectChange(val);
                                            }}
                                            options={remainingAttendees}
                                            isMulti
                                            styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    borderWidth: 0,
                                                    backgroundColor: '#EFF1F9',
                                                    padding: '6px 7px 6px 7px'
                                                }),
                                                menu: (baseStyles) => ({
                                                    ...baseStyles,
                                                    zIndex:1000,
                                                    backgroundColor: '#ffffff'
                                                })
                                            }}
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 8,                                                
                                            })}
                                        />
                                    )}
                                />                           
                            </div>
                            {errors.user_sub_ids?.message && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.user_sub_ids?.message}</p> }
                        </div>
                        {selectedMembers.includes("manual") &&
                            <>
                            <div className="form-field-container">
                                <div className="label">First name*</div>
                                <div className='form-field-wrap mb-5 no-field-wrap relative'>
                                    <input 
                                        type="text" id="firstname" disabled={loading} placeholder="First name" className='text-field'
                                        {...register("firstName")}
                                    />                                
                                </div>                                
                            </div>
                            <div className="form-field-container">
                                <div className="label">Last name*</div>
                                <div className='form-field-wrap mb-5 no-field-wrap relative'>
                                    <input 
                                        type="text" id="lastname" disabled={loading} placeholder="Last name" className='text-field'
                                        {...register("lastName")}
                                    />                                
                                </div>                                
                            </div>
                            <div className="form-field-container">
                                <div className="label">Email*</div>
                                <div className='form-field-wrap mb-5 no-field-wrap relative'>
                                    <input 
                                        type="email" id="email" disabled={loading} placeholder="Email" className='text-field'
                                        {...register("email")}
                                    />                                
                                </div>                                
                            </div>                            
                            </>
                        }
                    </div>
                    {errorMessage!=='' && <p className="text-red-500 mb-2.5 text-center" role="alert">{errorMessage}</p>}
                    {successMessage!=='' && <p className="text-green-600 mb-2.5 text-center" role="alert">{successMessage}</p>}
                    <div className="w-full px-2.5">
                        <div className='flex justify-center'>
                            <input className='button' type="submit" value="Add" disabled={loading}/>
                        </div>
                    </div>                
                </form>
            </div>
        </div>
        </>
        :
        <>
            <div className="py-5 text-center"><Spinner /></div>
        </>
    )
}
export default ManualAttendiForm;