import { useContext } from "react";
import { useParams, NavLink, useLocation } from "react-router-dom";
import Sidebar from "../../../components/Sidebar/Sidebar";
import AdminNavbar from "../../../components/AdminNavbar/AdminNavbar";
import { TeamContextProvider, TeamContext } from "../../../contexts/TeamContext";
import Spinner from "../../../components/Spinner/Spinner";
import NewsCloneForm from "./NewsCloneForm";

const DuplicateNewsForm=()=>{
    const { team, refreshTeam, teamUpdating } = useContext(TeamContext);
    const location  = useLocation();
    var news = location.state.news;
    
    return(
        !teamUpdating ?
        <>
        {(team.role==='admin' || (team.role==='member' && team.admin_groups_new !== undefined && team.admin_groups_new.length>0)) ?
            <NewsCloneForm news={news} team={team} refreshTeam={refreshTeam}/>
            :
            null
        }
        </>
        :
        <>
        <div className="text-center py-5"><Spinner /></div>
        </>
    );
}

function DuplicateNews(){
    const { id } = useParams();
    
    return(
        <>
            <Sidebar type="thin"/>
            <TeamContextProvider id={id}>
                <div id="page" className="create-news-page">
                    <AdminNavbar heading="Create News"/>
                    <div className='breadcrumb flex item-center pt-1.5 pb-1 pl-5 pr-7 w-full'>
                        <NavLink to="/" className='home'>
                            <span>Dashboard</span>
                        </NavLink>
                        <span className='separator'>&lt;</span>
                        <NavLink to={`/team/${id}/news`}>
                            <span>News</span>
                        </NavLink>
                        <span className='separator'>&lt;</span>
                        Create New Article
                    </div>
                    <DuplicateNewsForm  />
                </div>
            </TeamContextProvider>            
        </>
    )
}

export default DuplicateNews;