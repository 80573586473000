import { useEffect, useState, useCallback, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import CheckTreePicker from 'rsuite/CheckTreePicker';
import DatePicker from "rsuite/DatePicker";

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import ReactDatePicker from "react-datepicker";
import PlaiImageCropper from "../../../components/ImageCropper/ImageCropper";
import "react-datepicker/dist/react-datepicker.css";
import Spinner from "../../../components/Spinner/Spinner";
import TextEditor from "../../../components/TextEditor";
import { getGroupsV2 } from "../../../libs/dataFunctions";
import SMSChargePreviewForm from "./SmsChargeForm";
import DocumentView from "../../../components/DocumentView/DocumentView";
import "./NewsForm.scss";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import { generateRandomName } from "../../../components/ImageCropper/cropUtils";
import 'filepond/dist/filepond.min.css';

registerPlugin(FilePondPluginFileValidateType);
dayjs.extend(utc);
dayjs.extend(tz);

const schema = yup.object().shape({
    articletitle: yup.string().required('Title is required.'),
    groups: yup
    .array()
    .min(1, "At least one group must be selected")
    .nullable()
    .required("At least one group must be selected"),
    sms_details:yup.string().when('notify_sms',{
        is:'Yes',
        then:yup.string().required('Give SMS Notification Description')
    }),
    newsdate: yup.string().when('publish',{
        is:'false',
        then:yup.string().required('Please select publish date.')
    }),
    time: yup.string().when('publish',{
        is:'false',
        then:yup.string().required('Please select publish time.')
    }),
});

const NewsCloneForm = ({news, team, setShowForm}) =>{
    const [loading,setLoading] = useState(false);
    const [editorContent,setEditorContent] = useState('');
    const [publish,setPublishStatus] = useState('true');
    const [newsImage, setNewsImage] = useState(null);
    const [imageErrorMsg, setImageErrorMsg] = useState(false);
    const [groups, setGroups] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [pdfFiles, setPdfFile] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [is_notifySMS,setIsNotifysms] = useState('No');
    const [showSmsChargForm,setShowSmsChargeForm] = useState(false);
    const limitTextarea = 270;
    const [smscontent, setSmsContent] = useState('');
    const [fomrSData,setFormSData] = useState([]);
    const [newsDocuments,setNewsDocuments] = useState(news.documents);
    const [timepickerVal, setTimepickerVal] = useState(new Date());
    const navigate = useNavigate();
    const contentRef=useRef();

    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
    ? localStorage.getItem('auth_token')
    : '';
    
    const { register, handleSubmit, setValue, control, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });

    const setFormattedContent = useCallback(
        (text) => {
            setSmsContent(text.slice(0, limitTextarea));
        },
        [limitTextarea, setSmsContent]
    );

    const SMSChargePreview = ({setShowForm}) => {
        return(
            <div className="popup-form">
                <div className="popup-form-wrap relative !w-full !max-w-[500px]">
                    <SMSChargePreviewForm setShowForm={setShowForm} team={team} selectedGroups={selectedGroups} fomrSData={fomrSData} />
                </div>
            </div>
        )
    }
    
    const PreviewArticle = (data) => { 
        let previewData = [];
        previewData['title'] = data.articletitle;
        previewData['publish'] = data.publish;
        if(publish==='false'){
            previewData['hour'] = data.time;
            previewData['newsdate'] = dayjs(data.newsdate).format("YYYY-MM-DD");
        }
        else{
            previewData['hour'] = dayjs().format("HH:mm");
            previewData['newsdate'] = dayjs().format("YYYY-MM-DD");
        }
        previewData['group_ids'] = selectedGroups;
        previewData['details'] = editorContent;
        previewData['commentsEnabled'] = data.allowcomments ? 'true' : 'false';
        previewData['notify_email'] = data.notifymail ? 'Yes' : 'No';
        previewData['notify_notification'] = data.notify_notification ? 'Yes' : 'No';
        previewData['notify_sms'] = data.notify_sms ? 'Yes' : 'No';
        if(data.notify_sms){
            previewData['sms_details']=data.sms_details;
        }
        if(newsImage!=null){
            previewData["image"] = newsImage;
        }
        else{
            if(news.images!==undefined && news.images!==''){
                previewData["imageUrl"] = news.images;
                previewData["extension"]= news.images.split('.').pop();
            }
        }
        if(pdfFiles.length > 0 || newsDocuments.length>0){
            let pdfcodes = [];
            let pdfOldFiles = [];
            let exts = [];
            if(newsDocuments.length>0){
                for (let i = 0; i < newsDocuments.length; i++) {
                    pdfOldFiles.push(newsDocuments[i]);
                }
            }
            if(pdfFiles.length > 0){
                for(let i=0; i<pdfFiles.length; i++){
                    pdfcodes.push(pdfFiles[i].file);
                    exts.push(pdfFiles[i].fileExtension);
                }
            }
            previewData["pdfOldFiles"] = pdfOldFiles;
            previewData["pdfFiles"] = pdfcodes;
            previewData["pdfexts"] = exts;
        }
        
        if(is_notifySMS==='Yes'){
            setFormSData(previewData);
            setShowSmsChargeForm(true);            
        }
        else{
            navigate('/team/'+team._id+'/news/preview',{
                state:{
                    preViewData:previewData,
                    team:team,
                    preview:true
                }          
            });
        }
    }

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            const base64String = reader.result
                    .replace('data:', '')
                    .replace(/^.+,/, '');
            resolve(base64String);
        }
        reader.onerror = error => reject(error);
    });

    const saveAsDraft = async(data) => { 
        setErrorMessage('');
        if(is_notifySMS==='Yes'){
            let previewData = [];
            previewData['title'] = data.articletitle;
            previewData['publish'] = data.publish;
            if(publish==='false'){
                previewData['hour'] = data.time;
                previewData['newsdate'] = dayjs(data.newsdate).format("YYYY-MM-DD");
            }
            else{
                previewData['hour'] = dayjs().format("HH:mm");
                previewData['newsdate'] = dayjs().format("YYYY-MM-DD");
            }
            previewData['group_ids'] = selectedGroups;
            previewData['details'] = editorContent;
            previewData['commentsEnabled'] = data.allowcomments ? 'true' : 'false';
            previewData['notify_email'] = data.notifymail ? 'Yes' : 'No';
            previewData['notify_notification'] = data.notify_notification ? 'Yes' : 'No';
            previewData['notify_sms'] = data.notify_sms ? 'Yes' : 'No';
            if(data.notify_sms){
                previewData['sms_details']=data.sms_details;
            }
            if(newsImage!=null){
                previewData["image"] = newsImage;            
            }
            else{
                if(news.images!==undefined && news.images!==''){
                    previewData["imageUrl"] = news.images;
                    previewData["extension"]= news.images.split('.').pop();
                }
            }
            previewData["is_draft"] = 'Yes';

            if(pdfFiles.length > 0 || newsDocuments.length>0){
                let pdfcodes = [];
                let pdfOldFiles = [];
                let exts = [];
                if(newsDocuments.length>0){
                    for (let i = 0; i < newsDocuments.length; i++) {
                        pdfOldFiles.push(newsDocuments[i]);                        
                    }
                }
                if(pdfFiles.length > 0){
                    for(let i=0; i<pdfFiles.length; i++){
                        pdfcodes.push(pdfFiles[i].file);
                        exts.push(pdfFiles[i].fileExtension);
                    };
                }
                previewData["pdfFiles"] = pdfcodes;
                previewData["pdfOldFiles"] = pdfOldFiles;
                previewData["pdfexts"] = exts;
            }
            setFormSData(previewData);
            setShowSmsChargeForm(true);
        }
        else{
            var formdata = new FormData();
            formdata.append("type", "create_news");
            formdata.append("title", data.articletitle);
            formdata.append("published", data.publish);
            if(data.publish==='false'){
                formdata.append("hour", data.time);
                formdata.append("date", dayjs(data.newsdate).format("YYYY-MM-DD"));                
            }
            else{
                formdata.append("hour", dayjs().format("HH:mm"));
                formdata.append("date", dayjs().format("YYYY-MM-DD"));
            }
            
            formdata.append("group_ids", selectedGroups.join('@#'));
            formdata.append("details", editorContent);
            formdata.append("commentsEnabled", data.allowcomments ? 'true' : 'false');
            formdata.append("notify_email", data.notifymail ? 'Yes' : 'No');
            formdata.append("notify_notification",data.notify_notification ? 'Yes' : 'No');
            formdata.append("notify_sms",data.notify_sms ? 'Yes' : 'No');
            formdata.append("team_id", team._id);

            if(data.notify_sms){
                formdata.append("sms_details",data.sms_details);
            }
            if(newsImage!=null){
                var formdatas3 = new FormData();
                let tmpFilename = generateRandomName();
                const imgFileObj = new File([newsImage], tmpFilename+'.webp', { lastModified: new Date().getTime(), type: newsImage.type })
                formdatas3.append("files[]", imgFileObj);
                var requestOptionss3 = {
                    method: 'PUT',
                    body: formdatas3,
                    redirect: 'follow'
                };
                const responsesApp = await fetch("https://dashboard.plaisport.com/upload_content", requestOptionss3);
                const resultsApp = await responsesApp.text();
                const rdatasApp = JSON.parse(resultsApp);
                formdata.append('content',rdatasApp.data[0]);
            }
            else{
                if(news.images!==undefined && news.images!==''){
                    formdata.append("content", news.images);
                    formdata.append("extension", news.images.split('.').pop());
                }
            }

            if(pdfFiles.length > 0 || newsDocuments.length>0){
                let pdfcodes = [];
                let exts = [];
                let counter = 0;
                if(newsDocuments.length>0){
                    for (let i = 0; i < newsDocuments.length; i++) {
                        pdfcodes[counter]=newsDocuments[i];
                        exts[counter]='pdf';
                        counter++;      
                    }
                }
                if(pdfFiles.length > 0){
                    for(let i=0; i<pdfFiles.length; i++){
                        let bs64 = await toBase64(pdfFiles[i].file);
                        pdfcodes[counter]=bs64;
                        exts[counter]=pdfFiles[i].fileExtension;
                        counter++;
                    };
                }
                formdata.append("document_content", pdfcodes.join('@#'));
                formdata.append("document_extension", exts.join('@#'));
            }
            formdata.append('separate_by','@#');
            formdata.append('is_draft','Yes');
            setLoading(true);
            var secureHeader = new Headers();
            secureHeader.append('Authorization','Bearer ' + token);
            secureHeader.append('device_type','W');
            const requestOptions = {
                method: 'POST',
                redirect: 'follow',
                body: formdata,
                headers: secureHeader
            };

            await fetch(process.env.REACT_APP_APIURL + '/app_news' , requestOptions)
            .then(response => response.text())
            .then(result => {
                let rdata = JSON.parse(result);
                if(rdata.status !== 1){
                    setLoading(false);
                    //window.location.href = '/team/'+team._id;
                    setErrorMessage(rdata.message);
                }
                else{
                    navigate('/team/'+team._id);
                }
            })
            .catch(error => console.log('error', error));
            setLoading(false);
        }
    }

    const onSubmit = (data) => {
        let submitType = window.event.submitter.name;
        if(submitType==='draft'){
            saveAsDraft(data);
        }
        else{
            PreviewArticle(data);
        }
    }

    const handleChange = (e, key) => {
        const { value } = e.target        
        setEditorContent(value);
    }

    async function refreshGroups(){
        setLoading(true);
        let groupsData = await getGroupsV2(team._id, token);
        if(groupsData.length>0){
            let groupOptions = [];
            groupsData.forEach(element => {
                let childOptions = [];
                if(element.nest_groups.length > 0){
                    element.nest_groups.forEach(celm =>{
                        childOptions.push({'value':celm._id, 'label':celm.name});
                    });
                    groupOptions.push({'value':element._id, 'label':element.name, 'children':childOptions});
                }
                else{
                    groupOptions.push({'value':element._id, 'label':element.name});
                }
            });
            setGroups(groupOptions);
        }
        setLoading(false);      
    }

    function removeDoc(index) {
        if (window.confirm("Are you sure you want to delete this document?")) {
            setNewsDocuments([
                ...newsDocuments.slice(0, index),
                ...newsDocuments.slice(index + 1)
            ]);            
        }
    }
    
    useEffect(() => {
        refreshGroups();
        setValue('notifymail', news.notify_email==='Yes' ? true : false);
        setValue('notify_notification', news.notify_notification==='Yes' ? true : false);
        setValue('allowcomments', news.commentsEnabled);

        setValue('articletitle', news.title);
        setEditorContent(news.details);
        setValue('publish',news.published ? "true" : "false");
        setPublishStatus(news.published ? "true" : "false");
        setValue('date',new Date(news.date));
        setValue('newsdate',new Date(news.date));
        setValue('time',news.hour_new);
        setValue('allowcomments', news.commentsEnabled ? true : false);       
        setSelectedGroups(news.group_ids);
        setValue('groups', news.group_ids);

        let startDateArr = news.date.split('-');
        let startTimeArr = news.hour_new.split(':');        
        setTimepickerVal(new Date(startDateArr[0],startDateArr[1],startDateArr[2],startTimeArr[0],startTimeArr[1],'0'));

    },[]);
    
    return(
        !loading ?
        <div className='body px-5 lg:px-8 py-10'>
            <div className="rounded-xl bg-[#363F6C] py-7.5 px-5">
                <div className="form-heading text-center mb-[30px] !text-white">Create New Article</div>
                <form onSubmit={handleSubmit(onSubmit)} className="form-wrapper">
                    <div className="form-field-container mb-12">
                        <div className="label">Article Title*</div>
                        <div className='form-field-wrap user-field-wrap relative'>
                            <input 
                                type="text" id="article-name" disabled={loading} placeholder="Article Title*" className='text-field'
                                {...register("articletitle")}
                                aria-invalid={errors.articletitle ? "true" : "false"} 
                            />                                
                        </div>
                        {errors.articletitle && <p className="error mb-2.5" role="alert">{errors.articletitle?.message}</p>}
                    </div>
                    <div className='form-field-wrap mb-12 relative'>
                        <TextEditor
                            value={editorContent}
                            placeholderText="Your text goes here"
                            height={200}
                            onChange={(e) => handleChange({ target: { value: e } }, 'editor')}
                        />
                    </div>
                    <div className="flex flex-wrap mb-12">
                        <div className="w-full xl:w-1/3 md:px-2.5 xl:px-3.5">
                            <div className="form-field-container mb-12">
                                <div className="label">I'd like to...*</div>
                                <div className='form-field-wrap select-field-wrap publish-field-wrap relative'>
                                    <select {...register("publish")} onChange={(e)=>{setValue('publish', e.target.value); setPublishStatus(e.target.value)}}>
                                        <option value="true">Publish Now</option>
                                        <option value="false">Publish Later</option>
                                    </select>
                                </div>
                                {publish==='false' && <p className="mt-2.5 mb-2.5 text-[12px] leading-[16px] text-white">The PLAI scheduled send feature runs on Coordinated Universal Time (UTC). Current time in UTC is {dayjs.utc().format('HH:mm')}.</p>}
                            </div>
                        </div>
                        {publish==='false' &&
                        <div className="w-full xl:w-1/3 flex flex-wrap">
                            <div className="w-full md:w-1/2 md:px-2.5 xl:px-3.5">
                                <div className="form-field-container mb-12">
                                    <div className="label">Select Publish Date*</div>
                                    <div className='form-field-wrap select-field-wrap date-field-wrap relative'>
                                        <Controller
                                            control={control}
                                            name='newsdate'
                                            render={({ field }) => (
                                            <ReactDatePicker
                                                placeholderText='Select date'
                                                onChange={(date) => field.onChange(date)}
                                                selected={field.value}
                                                mask="____-__-__"
                                                dateFormat="dd-MM-yyyy"
                                                minDate={new Date()}                                        
                                            />
                                        )}
                                        />
                                    </div>
                                    {errors.newsdate && <p className="error mb-2.5" role="alert">{errors.newsdate?.message}</p>}
                                </div>
                            </div>
                            <div className="w-full md:w-1/2 md:px-2.5 xl:px-3.5">
                                <div className="form-field-container mb-12">
                                    <div className="label">Select Publish Time*</div>
                                    <div className='form-field-wrap select-field-wrap time-field-wrap relative'>
                                        <Controller
                                            control={control}
                                            name='time'
                                            errors={errors}
                                            render={({ field }) => (
                                                <DatePicker 
                                                    placeholder='Select Time'
                                                    format="hh:mm aa" 
                                                    onChange={(date) => {
                                                        field.onChange(dayjs(date).format('HH:mm'));
                                                    }}
                                                    caretAs={null} 
                                                    cleanable={false}
                                                    defaultValue={timepickerVal}
                                                />
                                            )}
                                        />
                                    </div>
                                    {errors.time && <p className="error mb-2.5" role="alert">{errors.time?.message}</p>}
                                </div>
                            </div>
                        </div>
                        }
                        <div className="w-full xl:w-1/3 md:px-2.5 xl:px-3.5">
                            <div className="form-field-container relative">
                                <div className="flex justify-between">
                                    <div className="label">Send this to*</div>
                                    <p className="mb-1 font-medium text-[12px] leading-[18px] text-white">
                                        {selectedGroups.length===0 ? 
                                            '0 Groups Selected':
                                            (selectedGroups.length > 1) ? selectedGroups.length + ' Groups Selected' : selectedGroups.length + ' Group Selected'}
                                    </p>
                                </div>
                                <div className='form-field-wrap select-field-wrap groups-field-wrap relative' ref={contentRef}>                            
                                    <Controller
                                        control={control}
                                        name='groups'
                                        render={({ field: { onChange, value, ref }}) => (
                                            <>
                                                <CheckTreePicker
                                                    defaultExpandAll
                                                    placeholder="Select Groups*"
                                                    data={groups}
                                                    onChange={val => {
                                                        onChange(val.map(c => c.value));
                                                        setValue('groups',val);
                                                        setSelectedGroups(val);
                                                        //handleSelectChange(val);
                                                    }}
                                                    container={()=>contentRef.current}
                                                    menuStyle={{zIndex: 100000000}}
                                                    defaultValue={selectedGroups}
                                                    value={selectedGroups}
                                                    style={{
                                                        width:'100%',
                                                        maxWidth:'500px',
                                                        borderWidth:0,
                                                        backgroundColor: '#EFF1F9',
                                                        padding: '8px 7px 8px 46px',
                                                        height: '52px',
                                                        borderRadius: 8
                                                    }}
                                                    onSelect={(item,val,event)=>{
                                                            if(item.label.toLowerCase()==="all members"){
                                                                if(item.check){
                                                                    let groupIds=[];
                                                                    groups.forEach((item)=>{
                                                                        groupIds.push(item.value);
                                                                    });
                                                                    setSelectedGroups(groupIds);
                                                                }
                                                                else{
                                                                    setSelectedGroups([]);
                                                                }
                                                            }
                                                        }
                                                    }
                                                />
                                            </>
                                        )}
                                    />                                
                                </div>
                                {errors.groups?.message && <p className="error mb-2.5" role="alert">{errors.groups?.message}</p> }
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-wrap mb-12">
                        <div className="w-full xl:w-1/3 md:px-2.5 xl:px-3.5">
                            <div className='form-field-wrap no-field-wrap mb-5 xl:mb-0 relative'>
                                <PlaiImageCropper editImage={(news.images && news.images !== "") ? news.images : ''} onFinalChange={(newImage)=>{setNewsImage(newImage)}} aspect={2 / 1}/>                            
                            </div>
                            {imageErrorMsg && <p className="error mb-2.5" role="alert">Please upload image</p> }
                        </div>
                        <div className="w-full xl:w-1/3 md:px-2.5 xl:px-3.5">
                            <div className='form-field-wrap mb-5 xl:mb-0 relative'>
                                <FilePond
                                    files={pdfFiles}
                                    onupdatefiles={setPdfFile}                                    
                                    allowMultiple={true}
                                    credits={false}
                                    allowFileTypeValidation={true}
                                    acceptedFileTypes={['application/pdf']}
                                    labelFileTypeNotAllowed='File of invalid type'
                                    //maxFiles={1}                                    
                                    labelIdle='
                                        <div class="upload-text">
                                            <h4>Upload a PDF</h4>
                                            <p class="text-center">Maximum upload file size: 2MB</p>
                                        </div>'
                                    {...register("pdfdocuments")}
                                />
                            </div>
                            {(newsDocuments.length > 0) &&
                                <div className="my-5">
                                    <div className="text-[16px] leading-[16px] text-white font-medium">Documents</div>
                                    <ul className='flex flex-wrap'>
                                        {newsDocuments.map((doc, index) => {
                                            return (
                                                <li key={index}>
                                                    <div className='mr-5 mb-5 w-[150px] h-[180px] relative'>
                                                        <DocumentView doc={doc} />
                                                        <button type="button" className='button-tiny delete absolute top-[10px] right-[10px] rounded-full' onClick={() => { removeDoc(index) }}></button>                                                                
                                                    </div>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            }
                        </div>
                        <div className="w-full xl:w-1/3 flex flex-col justify-between">
                            <div>
                                <div className="flex flex-wrap">
                                    <div className="w-full md:w-1/2 md:px-2.5 xl:px-3.5">
                                        <div className='form-field-wrap new-check-field-wrap relative'>                                
                                            <label htmlFor="notifymail" className=''>
                                                <input type="checkbox" id="notifymail" {...register("notifymail")} className='checkbox-field appearance-none'/>
                                                Send Email
                                            </label>
                                        </div>
                                    </div>
                                    <div className="w-full md:w-1/2 md:px-2.5 xl:px-3.5">
                                        <div className='form-field-wrap new-check-field-wrap relative'>                                
                                            <label htmlFor="notify_notification" className=''>
                                                <input type="checkbox" id="notify_notification" {...register("notify_notification")} className='checkbox-field appearance-none'/>
                                                Send Notification
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-wrap">
                                    <div className="w-full md:w-1/2 md:px-2.5 xl:px-3.5">
                                        <div className='form-field-wrap new-check-field-wrap relative'>                                
                                            <label htmlFor="notify_sms" className=''>
                                                <input type="checkbox" id="notify_sms" {...register("notify_sms")}
                                                    onChange={(e)=>{
                                                        if(e.target.checked){
                                                            setIsNotifysms('Yes');
                                                        }
                                                        else{
                                                            setIsNotifysms('No');
                                                        }
                                                    }}
                                                    className='checkbox-field appearance-none'/>
                                                Send SMS<br/><span className="text-[9px] leading-[9px] block">*carrier charges apply</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="w-full md:w-1/2 md:px-2.5 xl:px-3.5">
                                        <div className='form-field-wrap new-check-field-wrap relative'>                                
                                            <label htmlFor="allowcomments" className=''>
                                                <input type="checkbox" id="allowcomments" {...register("allowcomments")} className='checkbox-field appearance-none'/>
                                                Allow Comments
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                {is_notifySMS==='Yes' && 
                                <div className="w-full md:px-2.5 xl:px-3.5">
                                    <div className='form-field-wrap no-field-wrap relative'>
                                        <textarea 
                                            className='text-field' 
                                            placeholder="SMS Notification Description *"
                                            {...register("sms_details")}
                                            onChange={e => setFormattedContent(e.target.value)}
                                            value={smscontent} 
                                        ></textarea>
                                        <p className="text-right text-[12px] leading-[12px] text-white">
                                            {smscontent.length}/{limitTextarea}
                                        </p>
                                    </div>
                                    {errors.sms_details && <p className="error mb-2.5" role="alert">{errors.sms_details?.message}</p>}
                                </div>
                                }
                            </div>
                            <div className="w-full md:px-2.5 xl:px-3.5 pt-5">
                                <div className='flex justify-center lg:justify-end'>
                                    <input className='button button--large mr-2' type="submit" name="draft" value="Save As Draft" disabled={loading}/>
                                    <input className='button button--large' type="submit" name="preview" value="Preview Article" disabled={loading}/>
                                </div>
                            </div>
                        </div>                        
                    </div>
                    {errorMessage!=="" && <p className="text-red-500 mb-2.5 text-center" role="alert">{errorMessage}</p> }
                </form>
                {showSmsChargForm &&
                    <SMSChargePreview setShowForm={setShowSmsChargeForm} />
                }
            </div>
        </div>
        :
        <>
            <div className="py-5 text-center"><Spinner /></div>
        </>
    )
}

export default NewsCloneForm;