import { useState, useEffect, useContext, useRef } from "react";
import { NavLink } from "react-router-dom";
import { TeamContext } from "../../../contexts/TeamContext";
import { getConversations, getConversationsDetails } from "../../../libs/dataFunctions";
import ChatConversations from "./ChatConversations";
import Spinner from "../../../components/Spinner/Spinner";
import ChatBoard from "./ChatBoard";
import ChatSendForm from "./ChatSendForm";
import ChatWithOtherMember from "./ChatWithOtherMember";
import { useDispatch, useSelector } from "react-redux";
import { fetchConversationListAction, deleteConversationAction } from "../../../Redux/conversationListSlice";
import { fetchActiveChatAction } from "../../../Redux/activeChatSlice";
import { fetchNewCounterAction } from "../../../Redux/counterSlice";

const ChatComponent = ({socket, setCurrentChatPage, setGroupID})=>{
    const { team, token, teamUpdating } = useContext(TeamContext);
    const newmsgCounter = useSelector(state=>state.newmsgCounter);
    console.log(newmsgCounter);
    const [loading, setLoading] = useState(false);
    const [activeChatLoading, setActiveChatLoading] = useState(false);
    const [openMenu, setOpenMenu] = useState(false);
    const [showOtherMembersList, setShowOtherMembersList] = useState(false);
    const [enableArchiveMode, setArchiveMode] = useState(false);
    const [selectedMembers, setSelectedMembers] = useState([]);
    const ref = useRef();
    const lastMessageRef = useRef(null);
    const [mobShowChatboard,setmobShowChatboard] = useState(false);
    const dispatch = useDispatch();
    const [isChatActive, setIsChatActive] = useState(false);

    const fetchMembers = async() =>{
        setLoading(true);
        let [conversationListdata] = await Promise.all([
            getConversations(token, team._id)
        ]);
        
        dispatch(fetchConversationListAction(conversationListdata.data));
        dispatch(fetchNewCounterAction({'unread_chat_msg_count':conversationListdata.unread_chat_msg_count,'archive_unread_message_count':conversationListdata.archive_unread_message_count}));
        if(conversationListdata.data.length > 0){
            let [tmpData] = await Promise.all([
                getConversationsDetails(token, team._id, conversationListdata.data[0].conversation_id)
            ]); 
            if(tmpData.status===1){
                dispatch(fetchActiveChatAction({chatSource:conversationListdata.data[0],messages:tmpData.data}));
            }
            else{
                dispatch(fetchActiveChatAction({chatSource:conversationListdata.data[0],messages:[]}));
            }

            if(socket !==null){
                if(tmpData.all_read_by_conversation!==""){
                    socket.emit('read_message',tmpData.all_read_by_conversation);
                }
            }
        }
        setLoading(false);
    }

    useEffect(()=>{ 
        if(!teamUpdating){
            setIsChatActive(team.is_active_team_chat);
            fetchMembers();
        }
    },[teamUpdating]);
    
    useEffect(()=>{
        const checkIfClickedOutside = e => {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            if (ref.current && !ref.current.contains(e.target)) {
                setOpenMenu(false);
            }
        }
        document.addEventListener("mousedown", checkIfClickedOutside);      
        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    },[setOpenMenu]);
    
    const handleArchiveMode = () =>{
        if(!enableArchiveMode){
            setArchiveMode(true);
        }
        else{
            setArchiveMode(false);
            setSelectedMembers([]);
        }        
    }

    const handleMoveArchive = async() =>{
        if(window.confirm("Are you sure to archive these conversations?")){            
            selectedMembers.forEach((smember)=>{
                dispatch(deleteConversationAction(smember)); //Remove from state
            });

            var formdata = new FormData();
            formdata.append("type", "archive_conversation");
            formdata.append("conversation_ids", selectedMembers.join('@#'));
            formdata.append("team_id",team._id);
            formdata.append("separate_by","@#");

            var secureHeader = new Headers();
            secureHeader.append('Authorization','Bearer ' + token);
            secureHeader.append('device_type','W');
            const requestOptions = {
                method: 'POST',
                redirect: 'follow',
                body: formdata,
                headers: secureHeader
            };

            await fetch(process.env.REACT_APP_APIURL + '/chat' , requestOptions)
                .then(response => response.text())
                .then(result => {
                    let rdata = JSON.parse(result);
                    setSelectedMembers([]);
                    setArchiveMode(false);
                })
                .catch(error => console.log('error', error));
        }
    }

    const toggleChatHandler=async()=>{
        setLoading(true);
        var formdata = new FormData();
        formdata.append("type", "active_inactive_team_chat");
        formdata.append("team_id",team._id);

        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        await fetch(process.env.REACT_APP_APIURL + '/app_teams' , requestOptions)
            .then(response => response.text())
            .then(result => {
                setIsChatActive(!isChatActive);
                const teamLocalData = JSON.parse(localStorage.getItem(`id-${team._id}`));                
                teamLocalData.is_active_team_chat = !isChatActive;
                localStorage.setItem(`id-${team._id}`, JSON.stringify(teamLocalData));
                setLoading(false);
            })
            .catch(error => console.log('error', error));
    }
    return(
        <>
        {!loading && !teamUpdating ?
            <>
                <div className='breadcrumb flex item-center pt-1.5 pb-1 pl-5 pr-7 w-full'>
                    <NavLink to="/" className='home'>
                        <span>Dashboard</span>
                    </NavLink>
                    <span className='separator'>&lt;</span>
                    <NavLink to={`/team/${team._id}`}>
                        <span>{team.name}</span>
                    </NavLink>
                    <span className='separator'>&lt;</span>
                    Chat
                </div>
                {isChatActive ?
                    <div className="body px-5 lg:px-8 py-10">
                        <div className="ln-header flex justify-between items-center w-full flex-wrap pb-5 relative">
                            <h2 className="title mr-5 uppercase">Chat</h2>
                            <div className="sep mr-4"></div>
                            <div className="cta flex">
                                <button className={`${mobShowChatboard ? 'lg:hidden': 'hidden'} button button--yellow button-medium`} onClick={()=>setmobShowChatboard(false)}>Back</button>
                                <button onClick={()=>{handleArchiveMode()}} className='hidden lg:flex items-center justify-center w-[30px] h-[30px] mr-4 rounded-lg bg-[#363F6C] text-[#FBDB09] hover:bg-[#FBDB09] hover:text-[#363F6C]'>
                                    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M13.5015 14.8889H4.50146C4.33096 14.8889 4.16745 14.9566 4.04689 15.0771C3.92633 15.1977 3.8586 15.3612 3.8586 15.5317C3.8586 15.7022 3.92633 15.8657 4.04689 15.9863C4.16745 16.1068 4.33096 16.1746 4.50146 16.1746H13.5015C13.672 16.1746 13.8355 16.1068 13.956 15.9863C14.0766 15.8657 14.1443 15.7022 14.1443 15.5317C14.1443 15.3612 14.0766 15.1977 13.956 15.0771C13.8355 14.9566 13.672 14.8889 13.5015 14.8889ZM4.50146 13.6031H4.55931L7.24003 13.3589C7.53368 13.3296 7.80834 13.2002 8.01788 12.9924L13.8036 7.20671C14.0282 6.96947 14.1495 6.65289 14.1411 6.32634C14.1326 5.99978 13.9951 5.68989 13.7586 5.46457L11.9972 3.70314C11.7673 3.4872 11.466 3.3633 11.1507 3.355C10.8354 3.3467 10.5281 3.45459 10.2872 3.65814L4.50146 9.44385C4.29366 9.6534 4.16428 9.92805 4.13503 10.2217L3.8586 12.9024C3.84994 12.9966 3.86216 13.0915 3.89438 13.1804C3.9266 13.2693 3.97804 13.35 4.04503 13.4167C4.1051 13.4763 4.17633 13.5234 4.25466 13.5554C4.33298 13.5874 4.41685 13.6036 4.50146 13.6031ZM11.1036 4.60314L12.8586 6.35814L11.5729 7.61171L9.85003 5.88885L11.1036 4.60314Z" fill="currentColor"/>
                                    </svg>
                                </button>
                                {enableArchiveMode ?
                                    <button onClick={()=>{handleMoveArchive();}} className={`${mobShowChatboard ? 'hidden lg:flex': 'flex'} relative items-center justify-center w-[20px] lg:w-[30px] h-[20px] lg:h-[30px] mr-1.5 lg:mr-4 rounded lg:rounded-lg bg-[#363F6C] text-[#FBDB09] hover:bg-[#FBDB09] hover:text-[#363F6C]`}>
                                        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M15.405 4.68715L14.3625 3.42715C14.16 3.17215 13.8525 3.01465 13.5 3.01465H4.5C4.1475 3.01465 3.84 3.17215 3.63 3.42715L2.595 4.68715C2.3775 4.94215 2.25 5.27965 2.25 5.63965V15.0146C2.25 15.8396 2.925 16.5146 3.75 16.5146H14.25C15.075 16.5146 15.75 15.8396 15.75 15.0146V5.63965C15.75 5.27965 15.6225 4.94215 15.405 4.68715ZM4.68 4.51465H13.32L13.9275 5.24215H4.08L4.68 4.51465ZM3.75 15.0146V6.76465H14.25V15.0146H3.75ZM10.0875 8.26465H7.9125V10.5146H6L9 13.5146L12 10.5146H10.0875V8.26465Z" fill="currentColor"/>
                                        </svg>                                        
                                    </button>
                                :
                                    <button onClick={()=>{setCurrentChatPage('archive');}} className={`${mobShowChatboard ? 'hidden lg:flex': 'flex'} relative items-center justify-center w-[20px] lg:w-[30px] h-[20px] lg:h-[30px] mr-1.5 lg:mr-4 rounded lg:rounded-lg bg-[#363F6C] text-[#FBDB09] hover:bg-[#FBDB09] hover:text-[#363F6C]`}>
                                        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M15.405 4.68715L14.3625 3.42715C14.16 3.17215 13.8525 3.01465 13.5 3.01465H4.5C4.1475 3.01465 3.84 3.17215 3.63 3.42715L2.595 4.68715C2.3775 4.94215 2.25 5.27965 2.25 5.63965V15.0146C2.25 15.8396 2.925 16.5146 3.75 16.5146H14.25C15.075 16.5146 15.75 15.8396 15.75 15.0146V5.63965C15.75 5.27965 15.6225 4.94215 15.405 4.68715ZM4.68 4.51465H13.32L13.9275 5.24215H4.08L4.68 4.51465ZM3.75 15.0146V6.76465H14.25V15.0146H3.75ZM10.0875 8.26465H7.9125V10.5146H6L9 13.5146L12 10.5146H10.0875V8.26465Z" fill="currentColor"/>
                                        </svg>
                                        {newmsgCounter.archive_unread_message_count>0 &&
                                            <span className="rounded-full w-[20px] h-[20px] text-white text-[10px] leading-[10px] bg-[#ff0000] flex items-center justify-center absolute -right-2 -top-2">{newmsgCounter.archive_unread_message_count}</span>
                                        }
                                    </button>
                                }
                                <div ref={ref} className={`${mobShowChatboard ? 'hidden lg:flex': 'flex'} items-center justify-center w-[20px] lg:w-[30px] h-[20px] lg:h-[30px] mr-1.5 lg:mr-4 rounded lg:rounded-lg bg-[#363F6C] text-[#FBDB09] hover:bg-[#FBDB09] hover:text-[#363F6C] relative`}>
                                    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={()=>setOpenMenu(!openMenu)} className="cursor-pointer">
                                        <path d="M9.00001 3.8584V15.6709" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M3.09376 9.76465H14.9063" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                    <div className={`chat-conversation-menu !w-[175px] ${openMenu ? 'flex flex-col' : 'hidden'}`}>
                                        <button className='chat-conversation-menu-item' onClick={()=>{setCurrentChatPage('create-group');}}>Create Group</button>
                                        <button className='chat-conversation-menu-item' onClick={()=>{setShowOtherMembersList(true);}}>Chat With Other Member</button>
                                    </div>
                                </div>
                                { showOtherMembersList &&
                                    <ChatWithOtherMember setShowForm={setShowOtherMembersList} team={team} setmobShowChatboard={setmobShowChatboard} />
                                }
                            </div>
                        </div>
                        {(team.user_role==='admin' || team.user_role==='group_admin') &&
                            <div className="lg:flex relative">
                                <div className="lg:w-[40%] lg:max-w-[467px] px-1.5 lg:px-5 lg:mr-9 py-5 lg:py-0">
                                    <div className="text-center text-[10px] mb-2.5">
                                        Team Admins at {team.name} have oversight across all chats on PLAI to ensure club codes of conduct are adhered to and for necessary safeguarding of members.
                                    </div>
                                    <div className="flex justify-center">
                                        <div className="flex items-center mb-2.5">
                                            <span>Inactive</span>
                                            <label className="relative inline-flex items-center cursor-pointer !mb-0 mx-1.5">
                                                <input type="checkbox" value="" className="sr-only peer" onChange={()=>{toggleChatHandler()}} checked={isChatActive ? true : false} />
                                                <div className="w-11 h-6 bg-red-600 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white peer-checked:after:bg-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-600"></div>                        
                                            </label>
                                            <span>Active</span>
                                        </div>
                                    </div> 
                                </div>                            
                            </div>
                        }
                        <div className="lg:w-full lg:rounded-xl lg:bg-white lg:pl-3 lg:pr-8 lg:py-10">
                            <div className="lg:flex relative">
                                <div className={`chat-conversation-list ${mobShowChatboard ? 'hidden lg:block': 'block'} rounded-xl w-full z-10 bg-white lg:w-[40%] lg:max-w-[467px] px-1.5 lg:px-5 lg:mr-9 py-5 lg:py-0`}>
                                    <ChatConversations setActiveChatLoading={setActiveChatLoading} team={team} socket={socket} enableArchiveMode={enableArchiveMode} selectedMembers={selectedMembers} setSelectedMembers={setSelectedMembers} setmobShowChatboard={setmobShowChatboard} />
                                </div>
                                <div className={`lg:flex-1 ${!mobShowChatboard ? 'hidden lg:block': 'block'} `}>
                                    <div className="chatboard">
                                        {!activeChatLoading ?
                                            <>
                                                <ChatBoard team={team} lastMessageRef={lastMessageRef} socket={socket} setCurrentChatPage={setCurrentChatPage} setGroupID={setGroupID} setmobShowChatboard={setmobShowChatboard} isArchive={false} />
                                                <ChatSendForm team={team}  socket={socket} />                                                
                                            </>
                                        :
                                            <>
                                                <div className="text-center py-5"><Spinner /></div>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="body px-5 lg:px-8 py-10">
                        <div className="ln-header flex justify-between items-center w-full flex-wrap pb-5 relative">
                            <h2 className="title mr-5 uppercase">Chat</h2>
                            <div className="sep mr-4"></div>                            
                        </div>
                        <div className="lg:flex relative">
                            <div className="lg:w-[40%] lg:max-w-[467px] px-1.5 lg:px-5 lg:mr-9 py-5 lg:py-0">
                                <div className="mb-2.5 text-center">
                                    <p className="font-medium mb-1.5">Your chat is currently deactivated.</p>
                                    <p>Do you want to activate your Team Chat?</p>
                                </div>
                                <div className="flex justify-center">
                                    <div className="flex items-center mb-2.5">
                                        <span>Inactive</span>
                                        <label className="relative inline-flex items-center cursor-pointer !mb-0 mx-1.5">
                                            <input type="checkbox" value="" className="sr-only peer" onChange={()=>{toggleChatHandler()}} checked={isChatActive ? true : false} />
                                            <div className="w-11 h-6 bg-red-600 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white peer-checked:after:bg-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-600"></div>                        
                                        </label>
                                        <span>Active</span>
                                    </div>
                                </div> 
                            </div>                            
                        </div>                        
                    </div>
                }
            </>
        :
            <div className="text-center py-5"><Spinner /></div>
        }
        </>
    )
}

export default ChatComponent;