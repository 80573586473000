import { useState, useEffect, useContext, useRef } from 'react';
import { useParams, NavLink } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc'
import tz from 'dayjs/plugin/timezone'
import CheckTreePicker from 'rsuite/CheckTreePicker';
import CurrencyInput from 'react-currency-input-field';
import { DatePicker } from 'rsuite';
import Sidebar from '../../../components/Sidebar/Sidebar';
import Spinner from '../../../components/Spinner/Spinner';
import AdminNavbar from '../../../components/AdminNavbar/AdminNavbar';
import { TeamContextProvider, TeamContext } from '../../../contexts/TeamContext';
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import { getBookingFacilityDuration } from '../../../libs/dataFunctions';
import 'filepond/dist/filepond.min.css';
registerPlugin(FilePondPluginFileValidateType);

dayjs.extend(utc);
dayjs.extend(tz);
dayjs.extend(customParseFormat);
//dayjs.extend(isAfter);

yup.addMethod(yup.string, 'isAfterTime', function (message, ref) {
    return this.test('isAfterTime', message, function (value) {
      const { path, createError } = this;
      const startTime = this.resolve(yup.ref(ref));
      const endTime = value;
  
      // Parse the times using dayjs
      const startTimeDayjs = dayjs(startTime, 'HH:mm');
      const endTimeDayjs = dayjs(endTime, 'HH:mm');
  
      // Check if endTime is after startTime
      if (!endTimeDayjs.isAfter(startTimeDayjs)) {
        return createError({ path, message: message ?? `End time must be after start time.` });
      }
  
      return true;
    });
});

const schema = yup.object({
    facility_name: yup.string().required('Facility name is required.'),
    booking_days: yup
    .array()
    .min(1, "At least one day must be selected")
    .nullable()
    .required("At least one day must be selected"),
    start_time: yup.string().required('Please select Start Time.'),
    end_time: yup.string().required('End time is required').isAfterTime('End time must be after start time.', 'start_time'),
    price:yup.string().when('is_payment_required',{
        is:'Yes',
        then:yup.string().required('Please add price.')
    }),
}).required();

const FacilityForm=()=>{
    const { team, teamUpdating } = useContext(TeamContext);
    const [loading, setLoading] = useState(false);
    const [image, setImage] = useState([]);
    const [imageErrorMsg, setImageErrorMsg] = useState(false);
    const [selectedDays, setSelectedDays] = useState([]);
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [durationOptions, setDurationOptions] = useState([]);
    const { register, handleSubmit, setValue, watch, control, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema)
    });
    const contentRef=useRef();

    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
    ? localStorage.getItem('auth_token')
    : '';

    const daysOptions=[
        {'value':'Sunday', 'label':'Sunday'},
        {'value':'Monday', 'label':'Monday'},
        {'value':'Tuesday', 'label':'Tuesday'},
        {'value':'Wednesday', 'label':'Wednesday'},
        {'value':'Thursday', 'label':'Thursday'},
        {'value':'Friday', 'label':'Friday'},
        {'value':'Saturday', 'label':'Saturday'}
    ];

    const onSubmit=async(data)=>{
        setImageErrorMsg(false);
        setErrorMessage(null);
        setSuccessMessage(null);
        if (image.length <= 0) {
            setImageErrorMsg(true);
            return;
        }
        else{
            setLoading(true);
            var formdata = new FormData();
            formdata.append("files[]", image[0]);
            var requestOptions = {
                method: 'PUT',
                body: formdata,
                redirect: 'follow'
            };
            await fetch("https://dashboard.plaisport.com/upload_content", requestOptions)
            .then(response => response.text())
            .then(result => {
                let rdata = JSON.parse(result);
                if(rdata.status === 1){
                    if(rdata.data.length > 0 ){
                        var formdata2 = new FormData();
                        formdata2.append("type","add_facilities");
                        formdata2.append("team_id",team._id);
                        formdata2.append("name",data.facility_name);
                        formdata2.append("booking_days",data.booking_days.join('@#'));
                        formdata2.append("allowed_multiple_booking",data.allowed_multiple_booking);
                        formdata2.append("start_time",data.start_time);
                        formdata2.append("end_time",data.end_time);
                        formdata2.append("duration",data.duration);
                        formdata2.append("booking_start_top_of_hour",data.booking_start_top_of_hour);
                        formdata2.append("is_payment_required",data.is_payment_required);
                        if(data.is_payment_required==='Yes'){
                            formdata2.append("fees_management",data.fees_management);
                            formdata2.append("price",data.price);
                        }
                        formdata2.append("image",rdata.data[0]);
                        formdata2.append("message",data.message);
                        formdata2.append("separate_by",'@#');

                        var secureHeader = new Headers();
                        secureHeader.append('Authorization','Bearer ' + token);
                        secureHeader.append('device_type','W');
                        var requestOptions2 = {
                            method: 'POST',
                            body: formdata2,
                            redirect: 'follow',
                            headers: secureHeader
                        };
                        fetch(process.env.REACT_APP_APIURL + '/app_booking_facilities', requestOptions2)
                        .then(response2 => response2.text())
                        .then(result2 => {
                            setLoading(false);
                            let rdata2 = JSON.parse(result2);
                            if(rdata2.status===1){
                                setSuccessMessage(rdata2.message);
                                window.location=`/team/${team._id}/manage-facilities`;
                            }
                            else{
                                setErrorMessage(rdata2.message);
                            }
                        })
                    }
                    else{
                        setLoading(false);
                        setErrorMessage("Image not uploaded due to some technical issue, try again");
                    }
                }
                else{
                    setLoading(false);
                    setErrorMessage(rdata.message);
                }
            })
            .catch(error => console.log('error', error));
        }
    }

    const watchPaymentRequired = watch("is_payment_required");

    const fetchDurationOptions=async()=>{
        setLoading(true);        
        const [durationData] = await Promise.all([
            getBookingFacilityDuration(token,team._id)
        ]);
        setDurationOptions(durationData.data);
        setLoading(false);
    }

    useEffect(()=>{
        setLoading(true);
        if(!teamUpdating){
            fetchDurationOptions();
        }
    },[teamUpdating]);

    return(
        <>
        {!loading ? 
            <>
                <div className='breadcrumb flex item-center pt-1.5 pb-1 pl-5 pr-7 w-full'>
                    <NavLink to="/" className='home'>
                        <span>Dashboard</span>
                    </NavLink>
                    <span className='separator'>&lt;</span>
                    <NavLink to={`/team/${team._id}/manage-facilities`}>
                        Facilities
                    </NavLink>
                    <span className='separator'>&lt;</span>
                    Create Facilites
                </div>
                <div className='body px-5 lg:px-8 py-10'>
                    <div className="rounded-xl bg-[var(--color-darkblue)] py-7.5 px-5">
                        <div className="form-heading text-center mb-[30px] !text-white">Add New Facility</div>
                        <form onSubmit={handleSubmit(onSubmit)} className="form-wrapper">
                            <div className="flex flex-wrap lg:mb-12">
                                <div className="w-full xl:w-1/3 md:px-2.5 xl:px-3.5">
                                    <div className="form-field-container">
                                        <div className="label">Facility Name*</div>
                                        <div className='form-field-wrap mb-5 facility-field-wrap relative'>
                                            <input 
                                                type="text" id="facility-name" disabled={loading} placeholder="Facility Name *" className='text-field'
                                                {...register("facility_name")}
                                                aria-invalid={errors.facility_name ? "true" : "false"} 
                                            />                                
                                        </div>
                                        {errors.facility_name && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.facility_name?.message}</p>}
                                    </div>
                                </div>
                                <div className="w-full xl:w-1/3 md:px-2.5 xl:px-3.5">
                                    <div className="form-field-container">
                                        <div className="label">Avaialble Booking Days*</div>
                                        <div className='form-field-wrap mb-5 select-field-wrap time-field-wrap !w-full relative' ref={contentRef}>
                                            <Controller
                                                control={control}
                                                name='booking_days'
                                                render={({ field: { onChange, value, ref }}) => (
                                                    <CheckTreePicker
                                                        defaultExpandAll
                                                        placeholder="Select Booking Days*"
                                                        data={daysOptions}
                                                        countable={true}
                                                        onChange={val => {
                                                            onChange(val.map(c => c.value));
                                                            setValue('booking_days',val);
                                                            setSelectedDays(val);
                                                        }}
                                                        container={()=>contentRef.current}
                                                        menuStyle={{zIndex: 100000000}}
                                                        defaultValue={selectedDays}
                                                        value={selectedDays}
                                                        style={{
                                                            width:'100%',
                                                            maxWidth:'500px',
                                                            borderWidth:0,
                                                            backgroundColor: '#EFF1F9',
                                                            padding: '8px 7px 8px 46px',
                                                            height: '52px',
                                                            borderRadius: 8
                                                        }}                                                        
                                                    />                                                    
                                                )}
                                            />
                                        </div>
                                        {errors.booking_days?.message && <p className="error mb-2.5" role="alert">{errors.booking_days?.message}</p> }
                                    </div>
                                </div>                            
                            </div>
                            <div className="flex flex-wrap lg:mb-12">
                                <div className="w-full xl:w-1/3 md:px-2.5 xl:px-3.5">
                                    <div className="form-field-container">
                                        <div className="label">Can players make multiple bookings on a single day?*</div>
                                        <div className='form-field-wrap mb-5 select-field-wrap no-field-wrap relative'>
                                            <select {...register("allowed_multiple_booking")} onChange={(e)=>{setValue('allowed_multiple_booking', e.target.value);}}>
                                                <option value="Yes">Yes</option>
                                                <option value="No">No</option>
                                            </select>
                                        </div>
                                        {errors.allowed_multiple_booking && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.allowed_multiple_booking?.message}</p>}
                                    </div>
                                </div>
                                <div className="w-full xl:w-1/3 flex flex-wrap">
                                    <div className="w-full md:w-1/2 md:px-2.5 xl:px-3.5">
                                        <div className="form-field-container">
                                            <div className="label">Accept Bookings From*</div>                          
                                            <div className='form-field-wrap mb-5 select-field-wrap time-field-wrap relative'>
                                                <Controller
                                                        control={control}
                                                        name='start_time'
                                                        errors={errors}
                                                        render={({ field }) => (
                                                            <DatePicker 
                                                                placeholder='Start Time *'
                                                                format="hh:mm aa" 
                                                                onChange={(date) => {
                                                                    field.onChange(dayjs(date).format('HH:mm'));
                                                                }}
                                                                caretAs={null} 
                                                                cleanable={false}
                                                            />
                                                        )}
                                                    />
                                            </div>
                                            {errors.start_time && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.start_time?.message}</p>}
                                        </div>
                                    </div>
                                    <div className="w-full md:w-1/2 md:px-2.5 xl:px-3.5">
                                        <div className="form-field-container">
                                            <div className="label">Until*</div>                          
                                            <div className='form-field-wrap mb-5 select-field-wrap time-field-wrap relative'>
                                                <Controller
                                                    control={control}
                                                    name='end_time'
                                                    errors={errors}
                                                    render={({ field }) => (
                                                        <DatePicker 
                                                            placeholder='End Time *'
                                                            format="hh:mm aa" 
                                                            onChange={(date) => {
                                                                field.onChange(dayjs(date).format('HH:mm'));
                                                            }}
                                                            caretAs={null} 
                                                            cleanable={false}
                                                        />
                                                    )}
                                                />
                                            </div>
                                            {errors.end_time && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.end_time?.message}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full xl:w-1/3 md:px-2.5 xl:px-3.5">
                                    <div className="form-field-container">
                                        <div className="label">Booking Duration*</div>
                                        <div className='form-field-wrap mb-5 select-field-wrap time-field-wrap relative'>
                                            <select {...register("duration")} onChange={(e)=>{setValue('duration', e.target.value);}}>
                                                {durationOptions.map((duOpt)=>{
                                                    return(
                                                        <option key={`o-${duOpt.key}`} value={duOpt.key}>{duOpt.value}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                        {errors.duration && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.duration?.message}</p>}
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-wrap lg:mb-12">
                                <div className="w-full xl:w-1/3 md:px-2.5 xl:px-3.5">
                                    <div className="form-field-container">
                                        <div className="label">Do you require bookings to start at the top of the hour?</div>
                                        <div className='form-field-wrap mb-5 select-field-wrap no-field-wrap relative'>
                                            <select {...register("booking_start_top_of_hour")} onChange={(e)=>{setValue('booking_start_top_of_hour', e.target.value);}}>
                                                <option value="Yes">Yes</option>
                                                <option value="No">No</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full xl:w-1/3 md:px-2.5 xl:px-3.5">
                                    <div className="form-field-container">
                                        <div className="label">Is there a fee required to book this facility?</div>
                                        <div className='form-field-wrap mb-5 select-field-wrap no-field-wrap relative'>
                                            <select {...register("is_payment_required")} onChange={(e)=>{setValue('is_payment_required', e.target.value);}}>                                                
                                                <option value="No">No</option>
                                                <option value="Yes">Yes</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                {watchPaymentRequired==='Yes' &&
                                    <div className="w-full xl:w-1/3 flex flex-wrap">
                                        <div className="w-full md:w-1/2 md:px-2.5 xl:px-3.5">
                                            <div className="form-field-container">
                                                <div className="label">Fees Management*</div>                          
                                                <div className='form-field-wrap mb-5 select-field-wrap no-field-wrap relative'>
                                                    <select {...register("fees_management")} onChange={(e)=>{setValue('fees_management', e.target.value);}}>
                                                        <option value="pay_per_hour">Pay per hour</option>
                                                        <option value="pay_per_booking">Pay per booking</option>
                                                    </select>
                                                </div>
                                                {errors.fees_management && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.fees_management?.message}</p>}
                                            </div>
                                        </div>
                                        <div className="w-full md:w-1/2 md:px-2.5 xl:px-3.5">
                                            <div className="form-field-container">
                                                <div className="label">Add Price* (Eg {team.currency_symbol}24.00)</div>                          
                                                <div className='form-field-wrap mb-5 select-field-wrap price-field-wrap relative'>
                                                    <Controller
                                                        name="price"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <CurrencyInput
                                                                placeholder="Price*"
                                                                decimalsLimit={2}
                                                                onValueChange={(value, name) => { field.onChange(value); }}                                                            
                                                            />
                                                        )}
                                                    />
                                                </div>
                                                {errors.price && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.price?.message}</p>}
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="flex flex-wrap lg:mb-12">
                                <div className="w-full lg:w-1/3 md:px-2.5 xl:px-3.5">
                                    <div className='form-field-wrap no-field-wrap mb-5 lg:mb-0 relative'>
                                        <FilePond
                                            files={image}
                                            onupdatefiles={(fileItems) => {
                                                // Set current file objects to this.state
                                                setImage(fileItems.map((fileItem) => fileItem.file));
                                            }}
                                            allowMultiple={false}
                                            credits={false}
                                            allowFileTypeValidation={true}
                                            acceptedFileTypes={['image/png', 'image/jpeg']}
                                            labelFileTypeNotAllowed='File of invalid type'
                                            maxFiles={1}
                                            name="files"
                                            labelIdle={`
                                                <div class="upload-text">
                                                    <h4>Select Image</h4>                                                
                                                </div>`}
                                            {...register("image")}
                                        />
                                    </div>
                                    {imageErrorMsg && <p className="error mb-2.5 pt-2.5" role="alert">Please upload image</p>}
                                </div>
                                <div className="w-full lg:w-1/3 md:px-2.5 xl:px-3.5">
                                    <div className="form-field-container">
                                        <div className="label">Add a messgae</div>                          
                                        <div className='form-field-wrap mb-5 lg:mb-0 no-field-wrap relative'>
                                            <textarea 
                                                className='text-field h-[235px]' 
                                                placeholder="Add your message"
                                                {...register("message")}                                            
                                            ></textarea>
                                        </div>                                    
                                    </div>                                  
                                </div>
                                <div className="w-full xl:w-1/3 md:px-2.5 xl:px-3.5 flex items-end justify-center lg:justify-end">                                    
                                    <input className='button button--large' type="submit" name="confirm" value="Confirm" disabled={loading}/>                                    
                                </div>
                            </div>
                            <div className='flex justify-center'>
                            {errorMessage!=='' && <p className="text-red-500 mb-2.5 text-center" role="alert">{errorMessage}</p>}
                            {successMessage!=='' && <p className="text-green-500 mb-2.5 text-center" role="alert">{successMessage}</p>}
                            </div>
                        </form>
                    </div>
                </div>
            </>
        :
            <div className="py-5 text-center"><Spinner /></div>
        }
        </>
    )
}

const AddFacilities=()=>{    
    const { id } = useParams();    
    return(
        <>
            <Sidebar type="thin" />
            <TeamContextProvider id={id}>
                <div id="page" className="">
                    <AdminNavbar heading="Add Facilities" />  
                    <FacilityForm />              
                </div>
            </TeamContextProvider>
        </>
    )
}

export default AddFacilities;