import { useState, useEffect, useContext, useRef } from "react";
import { useParams, NavLink } from "react-router-dom";
import Sidebar from "../../../components/Sidebar/Sidebar";
import { TeamContext, TeamContextProvider } from "../../../contexts/TeamContext";
import AdminNavbar from "../../../components/AdminNavbar/AdminNavbar";
import { getEventDetail, getScorecardV2 } from "../../../libs/dataFunctions";
import Spinner from "../../../components/Spinner/Spinner";

const ScoreCardView=({id})=>{
    const { team, teamUpdating } = useContext(TeamContext);
    const [loading, setLoading] = useState(false);
    const [eventItem, setEventItem] = useState([]);
    const [scorecard, setScorecardData] = useState([]);
    const [active, setActive] = useState(0);
    const containerRef = useRef();


    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
    ? localStorage.getItem('auth_token')
    : '';

    const fetchScorCard=async()=>{
        setLoading(true);
        const [eventData, scorcardData] = await Promise.all([
            getEventDetail(id, team._id, token),
            getScorecardV2(token, team._id, id)
        ]);
        setEventItem(eventData);
        setScorecardData(scorcardData.data);
        setLoading(false);
    }

    useEffect(()=>{
        setLoading(true);
        if(!teamUpdating){
            fetchScorCard();
        }
    },[teamUpdating]);

    useEffect(() => {
        if(!teamUpdating && !loading){
            const el = containerRef.current.children[active];
            const top = window.pageYOffset + el.getBoundingClientRect().top;        
            window.scrollTo({top: top, left: 0, behavior: "smooth" });
        }
      }, [active]);
    
    return (        
    <>
    {!loading ?
        <>
            <div className='breadcrumb flex item-center pt-1.5 pb-1 pl-5 pr-7 w-full'>
                <NavLink to="/" className='home'>
                    <span>Dashboard</span>
                </NavLink>
                <span className='separator'>&lt;</span>
                <NavLink to={`/team/${team._id}/events`}>
                    Event
                </NavLink>
                <span className='separator'>&lt;</span>
                {eventItem.name}
            </div>
            <div className='body px-5 lg:px-8 py-10'>
                <div className='rounded-xl bg-white'>
                    <div className="tab-actionbar px-2.5 sm:px-7.5 py-4.5 mb-7.5">
                        <div className="title-col max-w-[320px] md:max-w-full mx-auto md:mx-0 mb-2.5 md:mb-0">Scorecard</div>
                    </div>
                    <div className='px-5 lg:px-8'>
                        {scorecard.length > 0 ?
                        <div ref={containerRef}>
                        {scorecard.map((scard, index)=>{
                            return(
                                <div key={index} className="mb-5">
                                    <div className="flex gap-2.5 justify-between  bg-[var(--color-darkblue)] rounded-lg px-6 py-5 cursor-pointer" onClick={()=>{setActive(index)}}>
                                        {scard.type===undefined || scard.type!=='league'?
                                        <>
                                            <div className="font-bold text-lg text-white">{scard.team_batting_name}</div>
                                            <div className="font-bold text-lg text-white flex gap-7.5 justify-between">
                                                <span>Total: {scard.runs}/{scard.wickets}, {scard.overs} Over</span>
                                                <svg className={`${active===index && 'rotate-180'}`} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M6 9L12 15L18 9" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                </svg>
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div className="font-bold text-lg text-white flex gap-7.5 justify-between">{scard.team_batting_name}</div>
                                            <svg className={`${active===index && 'rotate-180'}`} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6 9L12 15L18 9" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>                                            
                                        </>
                                        }
                                    </div>
                                    {scard.type===undefined || scard.type!=='league'?
                                    <>
                                    <div className={`${active===index ? 'block py-5' : 'hidden'}`}>
                                        <table className="w-full mb-10">
                                            <thead>
                                                <tr className="border-b border-[var(--color-dark-blue)]">
                                                    <th colSpan={2} className="uppercase font-bold text-lg text-[var(--color-darkblue)] text-left pb-5">Batting</th>
                                                    <th className="uppercase font-bold text-lg text-[var(--color-darkblue)] text-left pb-5">R</th>
                                                    <th className="uppercase font-bold text-lg text-[var(--color-darkblue)] text-left pb-5">B</th>
                                                    <th className="uppercase font-bold text-lg text-[var(--color-darkblue)] text-left pb-5">4S</th>
                                                    <th className="uppercase font-bold text-lg text-[var(--color-darkblue)] text-left pb-5">6S</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {scard.bat.map((batItem, i)=>{
                                                    return(
                                                        <tr className="border-b border-[var(--color-dark)]/20" key={i}>
                                                            <td className="w-[30%] text-base font-medium py-2.5">{batItem.batsman_name}</td>
                                                            <td className="w-[40%] text-base font-normal text-black/40 py-2.5">{batItem.how_out} {batItem.fielder_name}</td>
                                                            <td className="text-base font-medium py-2.5">{batItem.runs}</td>
                                                            <td className="text-base font-medium py-2.5">{batItem.balls}</td>
                                                            <td className="text-base font-medium py-2.5">{batItem.fours}</td>
                                                            <td className="text-base font-medium py-2.5">{batItem.sixes}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                        <table className="w-full">
                                            <thead>
                                                <tr className="border-b border-[var(--color-dark-blue)]">
                                                    <th className="uppercase font-bold text-lg text-[var(--color-darkblue)] text-left pb-5">Bowling</th>
                                                    <th className="uppercase font-bold text-lg text-[var(--color-darkblue)] text-left pb-5">O</th>
                                                    <th className="uppercase font-bold text-lg text-[var(--color-darkblue)] text-left pb-5">M</th>
                                                    <th className="uppercase font-bold text-lg text-[var(--color-darkblue)] text-left pb-5">R</th>
                                                    <th className="uppercase font-bold text-lg text-[var(--color-darkblue)] text-left pb-5">W</th>
                                                    <th className="uppercase font-bold text-lg text-[var(--color-darkblue)] text-left pb-5">WD</th>
                                                    <th className="uppercase font-bold text-lg text-[var(--color-darkblue)] text-left pb-5">NB</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {scard.bowl.map((bowlItem, i)=>{
                                                    return(
                                                        <tr className="border-b border-[var(--color-dark)]/20"  key={i}>
                                                            <td className="w-[40%] text-base font-medium py-2.5">{bowlItem.bowler_name}</td>
                                                            <td className="text-base font-normal text-black/40 py-2.5">{bowlItem.overs}</td>
                                                            <td className="text-base font-medium py-2.5">{bowlItem.maidens}</td>
                                                            <td className="text-base font-medium py-2.5">{bowlItem.runs}</td>
                                                            <td className="text-base font-medium py-2.5">{bowlItem.wickets}</td>
                                                            <td className="text-base font-medium py-2.5">{bowlItem.wides}</td>
                                                            <td className="text-base font-medium py-2.5">{bowlItem.no_balls}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                    </>
                                    :
                                    <>
                                    <div className={`${active===index ? 'block py-5' : 'hidden'}`}>
                                        <table className="w-full mb-10">
                                            <thead>
                                                <tr className="border-b border-[var(--color-dark-blue)]">
                                                    <th className="uppercase font-bold text-lg text-[var(--color-darkblue)] text-left pb-5">Team</th>
                                                    <th className="font-bold text-lg text-[var(--color-darkblue)] text-left pb-5">P</th>
                                                    <th className="font-bold text-lg text-[var(--color-darkblue)] text-left pb-5">W</th>
                                                    <th className="font-bold text-lg text-[var(--color-darkblue)] text-left pb-5">L</th>
                                                    <th className="font-bold text-lg text-[var(--color-darkblue)] text-left pb-5">T</th>
                                                    <th className="font-bold text-lg text-[var(--color-darkblue)] text-left pb-5">Inc</th>
                                                    <th className="font-bold text-lg text-[var(--color-darkblue)] text-left pb-5">Abn</th>
                                                    <th className="font-bold text-lg text-[var(--color-darkblue)] text-left pb-5">Pts</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {scard.points.map((pointItem, i)=>{
                                                    return(
                                                        <tr className="border-b border-[var(--color-dark)]/20" key={i}>
                                                            <td className="w-[50%] text-base font-medium py-2.5">{pointItem.team_name}</td>
                                                            <td className="text-base font-normal py-2.5">{pointItem.played}</td>
                                                            <td className="text-base font-medium py-2.5">{pointItem.won}</td>
                                                            <td className="text-base font-medium py-2.5">{pointItem.lose}</td>
                                                            <td className="text-base font-medium py-2.5">{pointItem.tied}</td>
                                                            <td className="text-base font-medium py-2.5">{pointItem.incomplete}</td>
                                                            <td className="text-base font-medium py-2.5">{pointItem.abandoned}</td>
                                                            <td className="text-base font-medium py-2.5">{pointItem.points}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                    </>
                                    }
                                </div>                                
                            )
                        })}
                        </div>
                        :
                        <>
                            <p className="text-center">Scorecard not found.</p>
                        </>
                        }
                    </div>
                </div>
            </div>
        </>
        :
        <>
            <div className="py-5 text-center"><Spinner /></div>
        </>
    }
    </>
    )
}

const ScoreCard=()=>{
    const { id, eventid } = useParams();
    return (
        <>
            <Sidebar type="thin" />
            <TeamContextProvider id={id}>
                <div id="page" className="page-eventdetail">
                    <AdminNavbar heading="Event" />
                    <ScoreCardView id={eventid} />
                </div>
            </TeamContextProvider>
        </>
    );
}
export default ScoreCard;