import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {useState} from 'react';
import { useForm } from 'react-hook-form';
import { useFormData } from '../../contexts/FormContext';
import Spinner from '../../components/Spinner/Spinner';

const schema = yup.object({
    otp:yup.string().required('OTP is required.'),
    password: yup.string().min(8).required('Password is required.').matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
        "Password must contain at least 8 characters, including one upper case and one number."
    ),
    conf_password: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match'),
}).required();

const ForgotPasswordStepTwo =({ formStep, nextFormStep }) => {    
    const { setFormValues, data } = useFormData();
    const [loading, setLoading] = useState(false);
    const [errorMessage,setErrorMessage] = useState('');
    const [successMessage,setSuccessMessage] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfPassword, setShowConfPassword] = useState(false);

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        mode: "all"
    });

    const changePassword = async (values)=>{
        setLoading(true);
        let formdata = new FormData();
        formdata.append('type',"set_new_password");
        formdata.append('email',data.email);
        formdata.append('new_password',values.password);
        formdata.append('verification_code',values.otp);
        var secureHeader = new Headers();
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,    
            headers: secureHeader    
        };
    
        await fetch(process.env.REACT_APP_APIURL + '/forgetpassword' , requestOptions)
        .then(response => response.text())
        .then(result => {
            setLoading(false);
            let rdata = JSON.parse(result);
            if(rdata.status !== 1){
                setErrorMessage(rdata.message);
            }
            else{
                setSuccessMessage(rdata.message);
            }
        })
        .catch(error => console.log('error', error));
        setLoading(false);
      }

    const onSubmit = async (values) => {
        setFormValues(values);
        changePassword(values);
    };
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const handleClickShowConfPassword = () => {
        setShowConfPassword(!showConfPassword);
    };

    return (
        !loading ?
        <>
            <div className={formStep === 1 ? '' : 'hidden'}>
                {successMessage === "" ?
                <>
                    <div className='auth-form-content text-center mb-[34px]'>
                        <h2>Forgot your password?</h2>
                        <p>A OTP has been sent to your email inbox.</p>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        {errorMessage!=='' &&
                        <p className='error text-center mb-2.5'>{errorMessage}</p>
                        }
                        <div className='auth-form-field-wrap mb-5 relative'>
                            <input 
                                type="text" placeholder="OTP" className='text-field'
                                {...register("otp")}
                                aria-invalid={errors.otp ? "true" : "false"} 
                            />
                        </div>
                        {errors.otp && <p className="error mb-2.5" role="alert">{errors.otp?.message}</p>}
                        <div className='auth-form-field-wrap mb-5 password-field-wrap relative'>
                            <input 
                                type={showPassword ? "text" : "password"} placeholder="Create Password" className='password-field'
                                {...register("password")} 
                                aria-invalid={errors.password ? "true" : "false"}
                            />
                            <span className={`passwordTrigger bg-no-repeat bg-center bg-contain ${showPassword ? 'toggled' : ''}`} onClick={handleClickShowPassword}></span>
                        </div>
                        {errors.password && <p className="error mb-2.5" role="alert">{errors.password?.message}</p>}
                        <div className='auth-form-field-wrap mb-3 password-field-wrap relative'>
                            <input 
                                type={showConfPassword ? "text" : "password"} placeholder="Confirm Password" className='password-field'
                                {...register("conf_password")} 
                                aria-invalid={errors.conf_password ? "true" : "false"}
                            />
                            <span className={`passwordTrigger bg-no-repeat bg-center bg-contain ${showConfPassword ? 'toggled' : ''}`} onClick={handleClickShowConfPassword}></span>
                        </div>
                        {errors.conf_password && <p className="error mb-2.5" role="alert">{errors.conf_password?.message}</p>}
                        <div className='auth-form-field-wrap flex justify-center mb-5 md:mb-10'>
                            <input className='button button--large' type="submit" value={'Submit'}/>
                        </div>
                        <div className='auth-form-field-wrap flex justify-center'>
                            <span>Already have an account? <a href="/signin" className='auth-form-link'>Login</a></span>
                        </div>
                    </form>
                </>
                :
                <>
                    <h2 className='text-center'>{successMessage}</h2>
                    <div className='auth-form-field-wrap flex justify-center'>
                        <span>Already have an account? <a href="/signin" className='auth-form-link'>Login</a></span>
                    </div>
                </>
                }
            </div>        
        </>
        :
        <>
            <div className="py-5 text-center"><Spinner /></div>
        </>
    );
}

export default ForgotPasswordStepTwo;