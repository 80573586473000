import { useState } from "react";
import AdForm from "./AdForm";
const AdsRow=({adItem, refreshAds, team})=>{
    const [updateLoading,setUpdateLoading] = useState(false);
    const [status, setAdsStatus] = useState(adItem.status);
    const [openForm, setOpenForm] = useState(false);
    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
    ? localStorage.getItem('auth_token')
    : '';
    const EditAdForm = ({setShowForm}) =>{        
        return(
            <div className="popup-form">
                <div className="popup-form-wrap relative !w-full !max-w-[900px]">                    
                    <AdForm team={team} aditem={adItem} isNew={false} setShowForm={setShowForm} refreshAds={refreshAds}/>
                </div>
            </div>
        )
    }
    
    const handleAdsStatus=async(e)=>{
        setUpdateLoading(true);
        let formdata = new FormData();
        formdata.append("type", "update_ads_status");
        formdata.append("ads_id", adItem._id);
        formdata.append("team_id",adItem.team_id);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        await fetch(process.env.REACT_APP_APIURL + '/app_ads' , requestOptions)
        .then(response => response.text())
        .then(result => {
            let rdata = JSON.parse(result);
            if(rdata.status===1){
                setAdsStatus(rdata.data.status);
            }
            setUpdateLoading(false);
        })
        .catch(error => console.log('error', error));
    }
    const deleteAds = async ()=>{
        if(window.confirm("Are you sure you want to delete this Ad?")){
            var formdata = new FormData();
            formdata.append("type", "delete_ads");
            formdata.append("ads_id",adItem._id);
            formdata.append("team_id", team._id);          
            var secureHeader = new Headers();
            secureHeader.append('Authorization','Bearer ' + token);
            secureHeader.append('device_type','W');
            const requestOptions = {
                method: 'POST',
                redirect: 'follow',
                body: formdata,
                headers: secureHeader
            };

            await fetch(process.env.REACT_APP_APIURL + '/app_ads' , requestOptions)
            .then(response => response.text())
            .then(result => {
                refreshAds();
            })
            .catch(error => console.log('error', error));
        }
    }
    return(
        <tr className='border-b border-black-100/10'>
            <td className='pt-5 pb-3.5'>
                <div className="flex flex-col md:flex-row items-center">
                    <div className="relative max-w-[150px] sm:max-w-[300px] md:max-w-[500px] max-w-[1000px] mr-2.5">
                        <img src={adItem.web_images} alt="" className="rounded-[10px]" />
                    </div>
                    <div className="font-medium text-[14px] leading-[21px] text-[#363F6C]">{adItem.title}</div>
                </div>
            </td>
            <td>
                <div className="flex items-center justify-end">                
                    {!updateLoading ?
                        <label className="relative inline-flex items-center cursor-pointer mr-1.5 !mb-0">
                            <input 
                                type="checkbox" 
                                className="sr-only peer" 
                                defaultChecked={status==='active' ? true : false} 
                                onChange={(e)=>{
                                    handleAdsStatus(e);
                                }} 
                            />
                            <div className="w-11 h-6 bg-gray-200 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-[#FBDB03] peer-checked:after:bg-[#FBDB03] after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#363F6C]"></div>
                        </label>
                        :
                        <img src="data:image/svg+xml,%3Csvg version='1.1' id='loader-1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='20px' height='20px' viewBox='0 0 40 40' enable-background='new 0 0 40 40' xml:space='preserve'%3E%3Cpath opacity='0.2' fill='%23000' d='M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z'%3E%3C/path%3E%3Cpath fill='%23000' d='M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 20 20' to='360 20 20' dur='0.5s' repeatCount='indefinite'%3E%3C/animateTransform%3E%3C/path%3E%3C/svg%3E" width={24} height={24} alt="" className="mr-1.5" />
                    }
                    <button type="button" className="button-tiny edit rounded-full mr-1.5" onClick={()=>{setOpenForm(true)}}></button>
                    <button type="button" className="button-tiny delete rounded-full" onClick={()=>{deleteAds()}}></button>
                </div>
                {openForm &&
                    <EditAdForm setShowForm={setOpenForm} />
                }
            </td>
        </tr>
    )
}
export default AdsRow;