import { useState } from "react";
import LinkForm from "./LinkForm";
import ArrowUp from "../../../assets/images/arrow-up.svg";
import ArrowUpD from "../../../assets/images/arrow-up-d.svg";
import ArrowDown from "../../../assets/images/arrow-down.svg";
import ArrowDownD from "../../../assets/images/arrow-down-d.svg";

const LinksRow = ({links, team, refreshLinks, index, totalLinks}) =>{
  const [openLinkEditForm, setOpenLinkEditForm] = useState(false);
  const [orderUpdating, setOrderUpdating] = useState(false);

  let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
    ? localStorage.getItem('auth_token')
    : '';

  const OrderUp = async()=>{
    setOrderUpdating(true);
    var formdata = new FormData();
        formdata.append("type", "change_link_order");
        formdata.append("team_id", team._id);
        formdata.append("link_id", links._id);
        formdata.append("order", "up");
        
        
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        await fetch(process.env.REACT_APP_APIURL + '/app_links' , requestOptions)
        .then(response => response.text())
        .then(result => {
            setOrderUpdating(false);
            let rdata = JSON.parse(result);
            refreshLinks();
        })
        .catch(error => console.log('error', error));
  }
  const OrderDown = async()=>{
    setOrderUpdating(true);
    var formdata = new FormData();
        formdata.append("type", "change_link_order");
        formdata.append("team_id", team._id);
        formdata.append("link_id", links._id);
        formdata.append("order", "down");
        
        
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        await fetch(process.env.REACT_APP_APIURL + '/app_links' , requestOptions)
        .then(response => response.text())
        .then(result => {
            setOrderUpdating(false);
            let rdata = JSON.parse(result);
            refreshLinks();
        })
        .catch(error => console.log('error', error));
  }
  
    const EditLinkForm = ({setShowForm, link, refreshLinks}) =>{        
      return(
          <div className="popup-form">
              <div className="popup-form-wrap popup-form-linkform relative">                    
                  <LinkForm isNew={false} linkitem={link} team={team} setShowForm={setShowForm} refreshLinks={refreshLinks}/>
              </div>
          </div>
      )
    }
    const deleteLink = async ()=>{
      if(window.confirm("Are you sure you want to delete this link?")){
        var formdata = new FormData();
        formdata.append("type", "delete_link");
        formdata.append("link_id",links._id);
        formdata.append("team_id", team._id);
        
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        await fetch(process.env.REACT_APP_APIURL + '/app_links' , requestOptions)
        .then(response => response.text())
        .then(result => {
          refreshLinks();
        })
        .catch(error => console.log('error', error));
      }
    }

    return(
        <tr className='border-b border-black-100/10'>
            <td className='pt-5 pb-3.5'>
              <div className="flex items-center">
              {team.role==='admin' &&
                <div className={`order-actions flex-col mr-2.5 sm:mr-5 w-[12px]`}>
                  {!orderUpdating ?
                    <>
                    {index===0 ? 
                      <img src={ArrowUpD} width={12} height={12} alt="" />
                      :
                      <img src={ArrowUp} width={12} height={12} alt="" className="cursor-pointer" onClick={()=>{OrderUp()}} />
                    }
                    {index===(totalLinks-1) ? 
                      <img src={ArrowDownD} width={12} height={12} alt="" />
                      :
                      <img src={ArrowDown} width={12} height={12} alt="" className="cursor-pointer" onClick={()=>{OrderDown()}} />
                    }
                    </>
                    :
                    <img src="data:image/svg+xml,%3Csvg version='1.1' id='loader-1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='20px' height='20px' viewBox='0 0 40 40' enable-background='new 0 0 40 40' xml:space='preserve'%3E%3Cpath opacity='0.2' fill='%23000' d='M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z'%3E%3C/path%3E%3Cpath fill='%23000' d='M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 20 20' to='360 20 20' dur='0.5s' repeatCount='indefinite'%3E%3C/animateTransform%3E%3C/path%3E%3C/svg%3E" width={12} height={12} alt="" />
                  }
                </div>
                }
                <a href={links.link} target="_blank" rel="noreferrer" className='flex items-center'>
                  <img src={links.image} alt="" className="rounded-full mr-5 w-[30px] h-[30px] sm:w-[50px] sm:h-[50px]"/>
                  {links.showLink ?
                  <div><span className='link-name'>{links.name}</span><br/><span className='link-url truncate text-ellipsis overflow-hidden'>{links.link}</span></div>
                  :
                  <div><span className='link-name'>{links.name}</span></div>
                  }
                </a>
              </div>
            </td>
            <td className='pt-5 pb-3.5'>
              {(links.user_role === 'admin' || links.user_role === 'group_admin') ?
                <>
                <div className="flex items-center justify-end">
                  <button type="button" className="button-tiny edit rounded-full mr-1.5" onClick={()=>{setOpenLinkEditForm(true)}}></button>
                  <button type="button" className="button-tiny delete rounded-full" onClick={()=>deleteLink()}></button>
                </div>
                {openLinkEditForm &&
                  <EditLinkForm setShowForm={setOpenLinkEditForm} link={links} refreshLinks={refreshLinks}/>
                }
                </>
              :
              null}
            </td>
        </tr>
    );
}
export default LinksRow;