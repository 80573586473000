import { useEffect, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import truncate from '../../libs/utils';
import * as DOMPurify from 'dompurify';
import dayjs from "dayjs";
import AdminNavbar from "../../components/AdminNavbar/AdminNavbar";
import Sidebar from "../../components/Sidebar/Sidebar";
import Spinner from '../../components/Spinner/Spinner';
import placeholderImg from '../../assets/images/placeholder.png';
import { getLatestNews } from '../../libs/dataFunctions';
import '../Team/Team.scss';
import {
    EmailShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    FacebookIcon,
    LinkedinIcon,
    TwitterIcon,
    WhatsappIcon,
    EmailIcon,
} from "react-share";

const LatestArticleView = ({newsid}) => {
    const [loading, setLoading] = useState(false);
    const [bookmark, setBookmark] = useState(false);
    const [bookmarkLoading, setBookmarkLoading] = useState(false);
    const [news, setNews] = useState('');
    const [searchParam] = useState(["_id"]);
    const [showShare, setShowShare] = useState(false);
    
    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
    ? localStorage.getItem('auth_token')
    : '';

    const search=(items)=>{
        return items.filter((item) => {
            return searchParam.some((newItem) => {
                return (
                    item[newItem]
                        .toString()
                        .toLowerCase()
                        .indexOf(newsid) > -1
                );
            });
        });
    }

    async function refreshLatest(){
        setLoading(true);
        const [latestsData] = await Promise.all([
            getLatestNews(token),
        ]);        
        let LatestNews = search(latestsData.data);
        setNews(LatestNews[0]);
        setLoading(false);
    }

    async function handleBookmarkAction(e){
        e.preventDefault();
        if(bookmarkLoading){
            return;
        }
        setBookmarkLoading(true);
        var formdata = new FormData();
        formdata.append("type", "bookmark_news");
        formdata.append("news_id", news._id);
        formdata.append("team_id", news.team_id);
        formdata.append("bookmark", bookmark ? 'no' : 'yes');
        formdata.append("bookmark_type",news.type);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        await fetch(process.env.REACT_APP_APIURL + '/app_news' , requestOptions)
        .then(response => {
            setBookmarkLoading(false);
            refreshLatest();
        })
        .catch(error => console.log('error', error));

        setBookmark(!bookmark);
    }

    const ShareLatestNews = ({setShowShare}) =>{
        return(
            <div className="popup-form">
                <div className="popup-form-wrap relative w-full max-w-[400px]">
                    <button type="button" className="close-popup" onClick={(e)=>{setShowShare(false)}}><span>close</span></button>
                    <div className="form-heading text-center mb-[30px]">Share</div>
                    <div className='flex flex-wrap items-center'>
                        <div className='px-2.5 mb-5'>
                            <FacebookShareButton url={process.env.REACT_APP_WEBURL+'latest/'+news._id} quote={news.title}>
                                <FacebookIcon size={32} round />
                            </FacebookShareButton>
                        </div>
                        <div className='px-2.5 mb-5'>
                            <LinkedinShareButton url={process.env.REACT_APP_WEBURL+'latest/'+news._id} title={news.title} summary={news.sub_title} source='PLAI Sports'>
                                <LinkedinIcon size={32} round />
                            </LinkedinShareButton>
                        </div>
                        <div className='px-2.5 mb-5'>                            
                            <TwitterShareButton url={process.env.REACT_APP_WEBURL+'latest/'+news._id} title={news.title}>
                                <TwitterIcon size={32} round />
                            </TwitterShareButton>
                        </div>
                        <div className='px-2.5 mb-5'>
                            <WhatsappShareButton url={process.env.REACT_APP_WEBURL+'latest/'+news._id} title={news.title}>
                                <WhatsappIcon size={32} round />
                            </WhatsappShareButton>
                        </div>
                        <div className='px-2.5 mb-5'>
                            <EmailShareButton url={process.env.REACT_APP_WEBURL+'latest/'+news._id} subject={truncate(news.title,500,'...')} body={truncate(news.details_new.replaceAll("&nbsp;", "\n\n"), 1024, '...')}>
                                <EmailIcon size={32} round />
                            </EmailShareButton>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    
    useEffect(()=>{        
        refreshLatest();        
    },[]);
    
    return(
        !loading ?
        <>        
            <div className='breadcrumb flex item-center pt-1.5 pb-1 pl-5 pr-7 w-full'>
                <NavLink to="/" className='home'>
                    <span>Dashboard</span>
                </NavLink>
                <span className='separator'>&lt;</span>
                {news.title}
            </div>            
            <div className="body news-article px-5 lg:px-2.5 lg:px-8 py-10">
                <div className='article-header mb-5'>
                    <div className='image-wrapper responsive'>
                        <img className='absolute object-cover w-full h-full' src={news.latest_image ? news.latest_image : placeholderImg} alt={news.title} height={430}/>                        
                    </div>
                    <div className='article-heading w-full flex flex-col md:flex-row items-center justify-between px-2.5 md:px-8 py-6'>
                        <h1 className='text-white text-center md:text-left'>{news.title}</h1>                        
                    </div>                    
                </div>
                <div className='article-content bg-white px-8 pt-5 pb-8 mb-6'>
                    <div className='flex flex-wrap justify-between'>
                        <div>
                            <div className='publish-by flex flex-col mb-3 order-2 md:order-1'>
                                {news.sub_title}
                            </div>
                            <div className='article-meta italic text-black mb-8'>{news ? dayjs(news.date).format("DD MMM, YYYY"): null}</div>
                        </div>
                        {
                            <div className="newsitem-cta flex order-1 md:order-2">
                                <button type="button" className={`button-tiny bookmark mr-2 rounded-full ${bookmark ? 'bookmarked' : 'dobookmark'} ${bookmarkLoading ? 'loading' : ''}`} onClick={(e)=>handleBookmarkAction(e)} title={bookmark ? 'Remove Bookmark' : 'Bookmark'}>Bookmark</button>
                                <button type="button" className="button-tiny share rounded-full" onClick={()=>{setShowShare(true)}}>Share</button>
                            </div>
                        }
                    </div>
                    <div className='article-text pb-10' dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(news.description)}} />                    
                </div>                
            </div>
            {showShare &&
                <ShareLatestNews setShowShare={setShowShare}/>
            }
        </>
        :
        <>
            <div className="py-5 text-center"><Spinner /></div>
        </>        
    )
}

const LatestNewsArticle = () =>{ 
    const { id } = useParams();
    return(
        <>
            <Sidebar type="thin"/>
            <div id="page" className="page-news-detail">
                <AdminNavbar heading="Latest News"/>
                <LatestArticleView newsid={id}/>
            </div>
        </>
    )
}
export default LatestNewsArticle;