import { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import placeholderImg from '../../../assets/images/placeholder.png';
import iconTimeTiny from '../../../assets/images/icon-time-tiny.svg';
import { getBookingJoinMembers } from '../../../libs/dataFunctions';
import Spinner from '../../../components/Spinner/Spinner';
dayjs.extend(utc);
dayjs.extend(tz);

const BookingMembersViewPopup = ({setShowForm, teamID, bookingItem}) =>{
    const [searchParam] = useState(["name"]);
    const [q, setSearchQuery] = useState("");
    const [loading, setLoading]=useState(false);
    const [membersList, setMembersList] = useState([]);

    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token') !== undefined
        ? localStorage.getItem('auth_token')
        : '';

    const fetchMemberList = async() =>{
        setLoading(true);
        let [membersListdata] = await Promise.all([
            getBookingJoinMembers(token, teamID, bookingItem._id)
        ]);

        setMembersList(membersListdata.data);        
        setLoading(false);
    }

    const search=(items)=>{
        return items.filter((item) => {
            return searchParam.some((newItem) => {
                return (
                    item[newItem]?
                        item[newItem]
                        .toString()
                        .toLowerCase()
                        .indexOf(q.toLowerCase()) > -1
                    :
                    null
                );
            });
        });
    }

    useEffect(()=>{ 
        fetchMemberList();
    },[]);

    return(                
        <div className="popup-form">
            <div className="popup-form-wrap popup-form-memberdetails !max-w-[600px] relative !px-2.5 sm:!px-8">                    
                <button type="button" className="close-popup" onClick={(e)=>{setShowForm(false);}}><span>close</span></button>
                <div className="relative">
                {!loading ?
                <>
                    {membersList.length > 0 ?
                        <>
                        <div className="chat-members-list" style={{maxHeight: '70vh', overflowY: 'auto', paddingBottom: '40px', paddingLeft: '15px', paddingRight: '15px'}}>
                            <div className="ln-header flex justify-between items-center w-full flex-wrap pb-5 relative">
                                <h2 className="title mr-5 uppercase">Booking Joined Members</h2>
                                <div className="sep mr-4"></div>
                            </div>
                            <div className="search-form-wrap flex mb-5">
                                <label htmlFor="search-form" className="search-field relative flex-1">
                                    <input
                                        type="search"
                                        name="search-field"
                                        id="search-form"
                                        className="search-input"
                                        placeholder="Search Now"
                                        value={q}
                                        onChange={(e) => {
                                            setSearchQuery(e.target.value);                                                            
                                        }}
                                    />
                                </label>
                            </div>
                            <div>
                            {search(membersList).length>0 ?
                            <>
                                {search(membersList).map((member,index)=>{
                                    return(
                                        <>
                                        <div className={`rounded-md lg:rounded-xl text-[#363F6C] font-medium text-[9px] lg:text-[16px] leading-[14px] lg:leading-[24px] shadow-[0_6px_30px_0_rgba(54,63,108,0.10)] mb-2.5 lg:mb-5 last:mb-0 px-2.5 lg:pl-3.5 lg:pr-5 py-2 bg-white flex items-center cursor-pointer`}>
                                            <div className='w-[24px] lg:w-[44px] mr-2.5'>
                                            {member.profilePicture !== '' ?  
                                                <img src={member.profilePicture} alt="" width={44} height={44} className="rounded-full w-[24px] lg:w-[44px] h-[24px] lg:h-[44px]" />
                                            :
                                                <img src={placeholderImg} alt="" width={44} height={44} className="rounded-full w-[24px] lg:w-[44px] h-[24px] lg:h-[44px]" />
                                            }
                                            </div>
                                            <div className='flex-1'>
                                                <div className='pr-2.5'>{member.name}</div>
                                            </div>                                            
                                        </div>
                                        </>
                                    )
                                })}
                            </>
                            :
                            <div>Member not found.</div>
                            }
                            </div>
                        </div>                        
                        </>
                    :
                        <div>No members available.</div>
                    }
                </>
                :
                <>
                    <div className="text-center py-5"><Spinner /></div>
                </>
                }
                </div>
            </div>
        </div>
    )
}
const ExistingBookingCard = ({BookingItem, teamID}) =>{
    const [showMembers, setShowMembers] = useState(false);

    return(
        <>
        <div className="w-full bg-white rounded-xl px-3 pt-3 pb-12 md:pb-3 flex flex-col sm:flex-row sm:justify-between mb-6 relative">
            <div className="flex flex-1 gap-5 items-center">
                <div className="w-17.5">
                    {BookingItem.profilePicture!=="" ?
                        <img src={BookingItem.profilePicture} alt="" width={70} height={70} className="w-17.5 h-17.5 rounded-full object-cover" />
                    :
                        <img src={placeholderImg} alt="" width={70} height={70} className="rounded-full"/>
                    }
                </div>
                <div className='flex-1 md:flex md:gap-4 md:justify-between md:items-start'>
                    <div className='flex-1'>
                        <div className='text-xl/6 font-bold mb-3'>{BookingItem.name}</div>
                        <div className='flex gap-2 items-center'>
                            <img src={iconTimeTiny} width={12} height={12} alt="time" />
                            <div className='text-xs'>{dayjs('1/1/1 '+BookingItem.start_time).format('hh:mm A')} to {dayjs('1/1/1 '+BookingItem.end_time).format('hh:mm A')}</div>
                        </div>
                    </div>
                    <div className='max-w-[150px]'>
                        <button type="button" onClick={()=>{setShowMembers(true)}} className='italic text-[var(--color-darkblue)] underline hover:no-underline font-medium text-sm/6 mr-3.5'>View Members</button>
                    </div>
                </div>
            </div>
            <div className="absolute bottom-0 right-0 text-sm px-6 py-2 font-semibold bg-[var(--color-darkblue)] text-[var(--color-yellow)] rounded-tl-xl rounded-br-xl">{BookingItem.payment_status}</div>            
        </div>
        {showMembers &&
            <BookingMembersViewPopup setShowForm={setShowMembers} teamID={teamID} bookingItem={BookingItem} />
        }
        </>
    )
}

export default ExistingBookingCard;