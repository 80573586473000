import { useState, useEffect } from 'react';
import * as DOMPurify from 'dompurify';
import dayjs from "dayjs";
import truncate from '../../libs/utils';
import { useNavigate } from 'react-router-dom';
import placeholderImg from '../../assets/images/placeholder-transparent.png';
import SubscriptionForm from '../SubscriptionForm/SubscriptionForm';
import {
    EmailShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    FacebookIcon,
    LinkedinIcon,
    TwitterIcon,
    WhatsappIcon,
    EmailIcon,
} from "react-share";

const LatestNewsRow = ({news,refreshLatest}) =>{
    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
    ? localStorage.getItem('auth_token')
    : '';
    const navigate = useNavigate();
    const [bookmark, setBookmark] = useState(false);
    const [bookmarkLoading, setBookmarkLoading] = useState();
    const [showShare, setShowShare] = useState(false);
    const [showSubscriptionFrom, setShowSubscriptionForm] = useState(false);

    const FormSubscription = ({setShowForm}) =>{        
        return(
            <div className="popup-form">
                <div className="popup-form-wrap !w-full !max-w-[500px] relative">                    
                    <SubscriptionForm setShowForm={setShowForm} subfor={'user'}/>
                </div>
            </div>
        )
    }
    
    useEffect(()=>{
        setBookmark(news.bookmark === 'yes' ? true : false);
    },[news]);
    
    async function handleBookmarkAction(e){
        e.preventDefault();
        if(bookmarkLoading){
            return;
        }
        setBookmarkLoading(true);
        var formdata = new FormData();
        formdata.append("type", "bookmark_news");
        formdata.append("news_id", news._id);
        formdata.append("team_id", news.team_id);
        formdata.append("bookmark", bookmark ? 'no' : 'yes');
        formdata.append("bookmark_type",news.type);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        await fetch(process.env.REACT_APP_APIURL + '/app_news' , requestOptions)
        .then(response => {
            setBookmarkLoading(false)
            refreshLatest();
        })
        .catch(error => console.log('error', error));

        setBookmark(!bookmark);
    }

    const handleLatestDetail = ()=>{
        if(news.type==='news'){
            navigate('/team/'+news.team_id+'/news/'+news._id);
        }
        else{
            navigate('/latest/'+news._id,{
                state:{
                    news:news,
                }          
            });
        }
    }
    
    const ShareLatestNews = ({setShowShare}) =>{
        return(
            <div className="popup-form">
                <div className="popup-form-wrap relative w-full max-w-[400px]">
                    <button type="button" className="close-popup" onClick={(e)=>{setShowShare(false)}}><span>close</span></button>
                    <div className="form-heading text-center mb-[30px]">Share</div>
                    <div className='flex flex-wrap items-center'>
                        <div className='px-2.5 mb-5'>
                            <FacebookShareButton url={process.env.REACT_APP_WEBURL+'latest/'+news._id} quote={news.title}>
                                <FacebookIcon size={32} round />
                            </FacebookShareButton>
                        </div>
                        <div className='px-2.5 mb-5'>
                            <LinkedinShareButton url={process.env.REACT_APP_WEBURL+'latest/'+news._id} title={news.title} summary={news.sub_title} source='PLAI Sports'>
                                <LinkedinIcon size={32} round />
                            </LinkedinShareButton>
                        </div>
                        <div className='px-2.5 mb-5'>                            
                            <TwitterShareButton url={process.env.REACT_APP_WEBURL+'latest/'+news._id} title={news.title}>
                                <TwitterIcon size={32} round />
                            </TwitterShareButton>
                        </div>
                        <div className='px-2.5 mb-5'>
                            <WhatsappShareButton url={process.env.REACT_APP_WEBURL+'latest/'+news._id} title={news.title}>
                                <WhatsappIcon size={32} round />
                            </WhatsappShareButton>
                        </div>
                        <div className='px-2.5 mb-5'>
                            <EmailShareButton url={process.env.REACT_APP_WEBURL+'latest/'+news._id} subject={truncate(news.title,500,'...')} body={truncate(news.details_new.replaceAll("&nbsp;", "\n\n"), 1024, '...')}>
                                <EmailIcon size={32} round />
                            </EmailShareButton>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    var newsinfo = '';
    if(!news.is_ads){
        newsinfo = truncate(news.details_new, 230, '...');
    }
    var newstitle = '';
    if(!news.is_ads){
        newstitle = truncate(news.title, 60, '...');
    }
    
    return(
        <>
        <div className="ln-item flex bg-white">
            <img src={news.images!=='' ? news.images : placeholderImg} alt="" width={44} height={44} className=" rounded-full mr-2.5 cursor-pointer" onClick={()=>{handleLatestDetail(news.sub)}}/>
            <div className="informations pt-[6px] flex-1">
                <div className='flex justify-between items-center w-full mb-5'>
                    <h5 className="flex-1 uppercase cursor-pointer" onClick={()=>{handleLatestDetail(news.sub)}}>{newstitle}</h5>
                    <div className="status hidden md:block max-w-[260px] text-right ml-1.5">
                        <span className={`uppercase block text-center ${(news.type==='latest' || (news.type==='news' && news.published)) ? 'publish': 'unpublish'}`}>
                            {(news.type==='latest' || (news.type==='news' && news.published)) ? 'Published' : 
                            <>
                                {news.released ?
                                    `Unpublished`
                                :
                                    <>
                                        {`SCHEDULED FOR ${dayjs(news.date).format("DD MMM YYYY")} at ${news.hour_new}`}
                                    </>
                                }
                            </>
                            }
                        </span>
                    </div>
                </div>
                <div className="mb-4 latest-news-content" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(newsinfo)}} />
                <div className='ln-footer flex flex-col sm:flex-row sm:items-center justify-between'>
                    <div className='ln-social flex items-center'>
                        <button type="button" className={`ln-bookmark mr-2 ${bookmark ? 'bookmarked' : 'dobookmark'} ${bookmarkLoading ? 'loading' : ''}`} onClick={(e)=>handleBookmarkAction(e)} title={bookmark ? 'Remove Bookmark' : 'Bookmark'}></button>
                        <button type="button" className='ln-share mr-3 cursor-pointer' onClick={()=>{setShowShare(true)}}></button>
                        <div className='ln-date italic'>{dayjs(news.date).format("DD MMM, YYYY")}</div>
                    </div>
                    <div className="status md:hidden max-w-[260px] text-center mt-5 sm:mt-0 mx-auto sm:mx-0">
                        <span className={`uppercase inline-block text-center ${(news.type==='latest' || (news.type==='news' && news.published)) ? 'publish': 'unpublish'}`}>
                            {(news.type==='latest' || (news.type==='news' && news.published)) ? 'Published' : 
                            <>
                                {news.released ?
                                    `Unpublished`
                                :
                                    <>
                                        {`SCHEDULED FOR ${dayjs(news.date).format("DD MMM YYYY")} at ${news.hour_new}`}
                                    </>
                                }
                            </>
                            }
                        </span>
                    </div>
                    {/*news.latest_type==='video' ?
                    <div className='ln-video'>Play now</div>
                    :
                    null*/}
                </div>
            </div>
        </div>
        {showShare &&
            <ShareLatestNews setShowShare={setShowShare}/>
        }
        
        </>
    )
}

export default LatestNewsRow;