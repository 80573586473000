import {useContext, useState, useEffect} from 'react';
import { useParams, NavLink } from 'react-router-dom';
import Sidebar from "../../../components/Sidebar/Sidebar";
import AdminNavbar from "../../../components/AdminNavbar/AdminNavbar";
import { TeamContextProvider, TeamContext } from "../../../contexts/TeamContext";
import Spinner from '../../../components/Spinner/Spinner';
import { getPromoCode } from '../../../libs/dataFunctions';
import Nofacilities from "../../../assets/images/noemptyother.png";
import DiscountCodeCard from './DiscountCodeCard';
import DiscountCodeForm from './DiscountCodeForm';

const ManageDiscountCodesList=()=>{
    const { team, teamUpdating } = useContext(TeamContext);
    const [loading,setLoading] = useState(false);
    const [discountCodeList, setDiscountCodeList] = useState([]);
    const [showDiscountForm, setShowDiscountForm] = useState(false);

    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
    ? localStorage.getItem('auth_token')
    : '';

    const fetchDiscountCodes=async()=>{
        setLoading(true);
        const [discountCodeData] = await Promise.all([
            getPromoCode(token, team._id)
        ]);
        setDiscountCodeList(discountCodeData.data);
        setLoading(false);
    }
    useEffect(()=>{        
        if(!teamUpdating){
            fetchDiscountCodes();
        }
    },[teamUpdating]);
    return(
        <>
            {!loading && !teamUpdating ?
            <>
            <div className='breadcrumb flex item-center pt-1.5 pb-1 pl-5 pr-7 w-full'>
                <NavLink to="/" className='home'>
                    <span>Dashboard</span>
                </NavLink>
                <span className='separator'>&lt;</span>
                <NavLink to={`/team/${team._id}/shop`}>
                    Shop
                </NavLink>
                <span className='separator'>&lt;</span>
                Manage Discount Codes
            </div>
            <div className='body px-5 lg:px-8 py-10'>
                <div className='rounded-xl bg-white pb-10'>
                    <div className="tab-actionbar px-2.5 sm:px-7.5 py-4.5 mb-7.5">
                        <div className="title-col max-w-[320px] md:max-w-full mx-auto md:mx-0 mb-2.5 md:mb-0">Discount Codes</div>                        
                    </div>
                    <div className='px-5 lg:px-8'>
                        {discountCodeList.length>0 ?
                        <>
                        <div className='grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-7.5 pb-7 mb-10'>
                            {discountCodeList.map((discountItem)=>{
                                return(
                                    <DiscountCodeCard promoItem={discountItem} key={discountItem._id} fetchDiscountCodes={fetchDiscountCodes} token={token} currencyCode={team.currency_symbol} />
                                )
                            })}
                        </div>
                        <div className='flex items-center justify-center'>
                            <button type='button' className='button button--large flex gap-1.5 items-center' onClick={()=>{setShowDiscountForm(true)}}>
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9 2.69971V15.2997" stroke="currentColor" strokeWidth="2"/>
                                    <path d="M15.3003 8.99951L2.70029 8.99951" stroke="currentColor" strokeWidth="2"/>
                                </svg>
                                <span>Add Discount Code</span>
                            </button>
                        </div>
                        </>
                        :
                        <>
                            <div className="empty text-center">
                                <div className="image"><img src={Nofacilities} alt="Plaisport" width={228} /></div>                                        
                                <div className="title mb-5">No Discount Code Yet?</div>
                                <div className="description mb-8">Discount Codes are not available yet.</div>
                                <button type="button" className='button button-yellow button-yellow-add' onClick={(e)=>{setShowDiscountForm(true)}}>Add Discount Code</button>
                            </div>
                        </>
                        }
                    </div>
                </div>
            </div>
            {showDiscountForm &&
                <DiscountCodeForm setShowForm={setShowDiscountForm} isNew={true} teamID={team._id} fetchDiscountCodes={fetchDiscountCodes} />
            }
            </>
            :
            <>
                <div className="py-5 text-center"><Spinner /></div>
            </>
            }
        </>
    )
}
const ManageDiscountCodes = () =>{
    const { id } = useParams();
    return(
        <>
            <Sidebar type="thin" />
            <TeamContextProvider id={id}>
                <div id="page" className="">
                    <AdminNavbar heading="Manage Discount Codes" />  
                    <ManageDiscountCodesList />              
                </div>
            </TeamContextProvider>
        </>
    )
}

export default ManageDiscountCodes;