import { useEffect, useState } from "react";
import AddCardForm from "./AddCardForm";
import { getMyPaymentCards } from "../../../libs/dataFunctions";
import Spinner from "../../../components/Spinner/Spinner";
import MyCard from "./MyCard";

const MyCards = () => {
    const [loading, setLoading] = useState(false);
    const [showCardForm, setShowCardForm] = useState(false);
    const [cardsFetchError, setCardsFetchError] = useState('');
    const [paymentCards, setPaymentCards] = useState([]);

    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
    ? localStorage.getItem('auth_token')
    : '';

    const AddNewCard = ({setShowForm}) =>{
        return(
            <div className="popup-form">
                <div className="popup-form-wrap w-full !max-w-[600px] relative">                    
                    <AddCardForm setShowForm={setShowForm} refreshMyCards={refreshMycards}/>
                </div>
            </div>
        )
    }
    const refreshMycards = async()=>{
        setLoading(true);
        setCardsFetchError('');
        let mycardsData = await getMyPaymentCards(token);
        if(mycardsData.data && mycardsData.data.length > 0){
            setPaymentCards(mycardsData.data);
        }
        else{
            setCardsFetchError(mycardsData.message);
        }
        setLoading(false);
    }
    useEffect(()=>{
        refreshMycards();
    },[]);
    return(
        !loading ?
        <>
            <div className="my-cards">
                <div className="ln-header flex justify-between items-center w-full flex-wrap pb-5 relative">
                    <h2 className="title mr-5">Saved cards</h2>
                    <div className="sep mr-5 sm:mr-11"></div>
                    <button type="button" className="button" onClick={()=>{setShowCardForm(true)}}>Add Card</button>
                </div>                
                {paymentCards.length > 0 ?
                <>
                    <ul className='flex flex-wrap -mx-2.5'>
                        {paymentCards.map((card,index) => {
                            return (
                                <li key={card.id} className={`px-2 mb-4 w-full md:w-1/2 max-w-[280px] mx-2.5`}>
                                    <MyCard paymentcard={card} refreshMyCards={refreshMycards}/>
                                </li>
                            )
                        })}
                    </ul>
                </>
                :
                <p className="mb-5">{cardsFetchError}</p>
                }
            </div>
            {showCardForm &&
                <AddNewCard setShowForm={setShowCardForm} />
            }
        </>
        :
        <>
           <div className="py-5 text-center"><Spinner /></div>
        </>
    );
}

export default MyCards;