import { useContext, useState, useEffect,useRef } from "react";
import { TeamContext, TeamContextProvider } from "../../../contexts/TeamContext";
import { useParams, useLocation, NavLink, useNavigate } from "react-router-dom";
import Sidebar from "../../../components/Sidebar/Sidebar";
import AdminNavbar from "../../../components/AdminNavbar/AdminNavbar";
import { getProductOrders } from "../../../libs/dataFunctions";
import Spinner from "../../../components/Spinner/Spinner";
import ProductOrderRow from "./ProductOrderRow";


function ProductOrdersView({id}){
    const { team, token, teamUpdating } = useContext(TeamContext);
    const [ ordersLoading, setOrdersLoading ] = useState(false);
    const [ OrdersList, setOrdersList ] = useState([]);
    const [q, setSearchQuery] = useState("");
    const [searchParam] = useState(["firstName","lastName","email"]);
    const location  = useLocation();
    var product = location.state.product;
    
    const search=(items)=>{
        return items.filter((item) => {
            return searchParam.some((newItem) => {
                return (
                    item[newItem]
                        .toString()
                        .toLowerCase()
                        .indexOf(q.toLowerCase()) > -1
                );
            });
        });
    }

    const rereshProductOrders= async() =>{
        setOrdersLoading(true);
        let productOrderData=await getProductOrders(team._id, id ,token);        
        setOrdersList(productOrderData.data);
        setOrdersLoading(false);
    }
    const ref = useRef();
    useEffect(()=>{
        setOrdersLoading(true);
        if(!teamUpdating && team.role==='admin'){
            rereshProductOrders();
        }
    },[team]);
    return(
        !ordersLoading ?
        <>
            <div className='breadcrumb flex item-center pt-1.5 pb-1 pl-5 pr-7 w-full'>
                <NavLink to="/" className='home'>
                    <span>Dashboard</span>
                </NavLink>
                <span className='separator'>&lt;</span>
                <NavLink to={`/team/${team._id}/shop`}>
                    Shop
                </NavLink>
                <span className='separator'>&lt;</span>
                <NavLink to={`/team/${team._id}/shop/${id}`}>
                    {product.name}
                </NavLink>
                <span className='separator'>&lt;</span>
                Orders
            </div>
            <div className='body product-orders px-5 lg:px-8 py-10'>                
                <div className="pb-[77px] bg-white rounded-xl">
                    <div className="tab-actionbar px-2.5 sm:px-7.5 py-[18px] flex flex-col md:flex-row justify-between items-center">
                        <div className="title-col mb-2.5 md:mb-0">Orders List</div>
                        <div className="cta-col max-w-[320px] md:max-w-full mx-auto md:mx-0 flex justify-center md:justify-end flex-wrap items-center relative" ref={ref}>
                            <label htmlFor="search-form" className="search-field relative mr-3.5 mb-2.5 md:mb-0">
                                <input
                                    type="search"
                                    name="search-field"
                                    id="search-form"
                                    className="search-input"
                                    placeholder="Search Now"
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                />                        
                            </label>
                                                        
                        </div>               
                    </div>
                    <div className="shop-orders-list">                        
                        <div className="md:table w-full">
                            <div className="hidden md:table-header-group table-head">
                                <div className="table-row">
                                    <div className="table-cell pl-[30px] border-b border-black-100/10 w-[120px]"></div>
                                    <div className="table-cell py-[18px] border-b border-black-100/10">Name</div>
                                    <div className="table-cell py-[18px] border-b border-black-100/10">Address</div>
                                    <div className="table-cell py-[18px] border-b border-black-100/10">Date</div>
                                    <div className="table-cell py-[18px] w-[120px] border-b border-black-100/10">Amount</div>                                    
                                </div>
                            </div>
                            <div className="md:table-row-group table-body">                            
                                {search(OrdersList) !== undefined ? (
                                    <>
                                        {search(OrdersList).map((orders) => {                        
                                            return (                                            
                                                <ProductOrderRow orders={orders} key={orders.date} />
                                            )
                                        }
                                        )}
                                    </>
                                )
                                :null}                                
                            </div>
                        </div>              
                    </div>
                </div>
            </div>
        </>
        :
        <>
            <div className="py-5 text-center"><Spinner /></div>
        </>
    );
}

function ProductOrders(){
    const { id, productid } = useParams();

    return(
        <>
            <Sidebar type="thin"/>          
            <TeamContextProvider id={id}>  
                <div id="page" className="page-productorders">
                    <AdminNavbar heading="Product Orders"/>
                    <ProductOrdersView id={productid}/>
                </div>            
            </TeamContextProvider>
        </>
    )
}

export default ProductOrders;