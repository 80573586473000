import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Spinner from "../../../components/Spinner/Spinner";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import imageIcon from "../../../assets/images/icon-uploadimage.svg";
import { getGalleryFolder } from "../../../libs/dataFunctions";
import 'filepond/dist/filepond.min.css';
registerPlugin(FilePondPluginFileValidateType);

const schema = yup.object({
    folder_name: yup.string().required('Folder name is required.'),
}).required();

const GalleryMediaForm = ({setShowForm, teamId, galleryId, fetchGalleryFolderMedia, isInsideFolder})=>{
    const [loading,setLoading] = useState(false);
    const [errorMessage,setErrorMessage] = useState('');
    const [imageFiles, setImageFile] = useState([]);
    const [galleryList,setGalleryList] = useState([]);
    
    const { register, handleSubmit, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });
    
    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
    ? localStorage.getItem('auth_token')
    : '';
    
    const onSubmit = async(data) => {        
        mediaUpload(data);        
    }

    const mediaUpload = async(data)=>{
        setErrorMessage('');
        if(imageFiles.length>0){
            setLoading(true);
            var formdata = new FormData();
            imageFiles.forEach((imgFile)=>{
                formdata.append("files[]", imgFile);
            })        
            var requestOptions = {
                method: 'PUT',
                body: formdata,
                redirect: 'follow'
            };
            await fetch("https://dashboard.plaisport.com/upload_content", requestOptions)
            .then(response => response.text())
            .then(result => {
                setErrorMessage('')
                let rdata = JSON.parse(result);
                if(rdata.status === 1){
                    setLoading(false);
                    if(rdata.data.length > 0 ){
                        var formdata2 = new FormData();
                        formdata2.append("type","add_media");
                        formdata2.append("team_id", teamId);
                        formdata2.append("media_folder_id", data.folder_name);
                        formdata2.append("images", rdata.data.join('@#'));
                        formdata2.append("separate_by",'@#');
                        var secureHeader = new Headers();
                        secureHeader.append('Authorization','Bearer ' + token);
                        secureHeader.append('device_type','W');
                        var requestOptions2 = {
                            method: 'POST',
                            body: formdata2,
                            redirect: 'follow',
                            headers: secureHeader
                        };
                        fetch(process.env.REACT_APP_APIURL + '/app_gallery', requestOptions2)
                        .then(response2 => response2.text())
                        .then(result2 => {
                            setLoading(false);
                            let rdata2 = JSON.parse(result2);
                            if(rdata2.status===1){
                                setShowForm(false);
                                if(isInsideFolder && data.folder_name===galleryId){
                                    fetchGalleryFolderMedia();
                                }
                            }
                            else{
                                setErrorMessage(rdata2.message);
                            }
                        })
                    }
                    else{
                        setLoading(false);
                        setErrorMessage("Image not uploaded due to some technical issue, try again");
                    }
                }
                else{
                    setLoading(false);
                    setErrorMessage(rdata.message);
                }
            })
            .catch(error => console.log('error', error));
        }
        else{
            setErrorMessage("Please upload image");
        }
    }

    async function fetchGalleryFolders(){
        setLoading(true);
        let galleryFoldersData = await getGalleryFolder(token,teamId);
        if(galleryFoldersData.data.length>0){
            let folderOptions = [];
            galleryFoldersData.data.forEach(element => {
                //if(element.name!=='All Images'){
                    folderOptions.push({'value':element._id, 'label':element.name});                
                //}
            });
            setGalleryList(folderOptions);
        }
        setLoading(false);
    }

    useEffect(()=>{
        fetchGalleryFolders();
        if(galleryId!==null){
            console.log(galleryId);
            setValue('folder_name',galleryId);
        }
    },[]);

    return(
        <>
        <div className="popup-form">
        {!loading ?        
        <div className="popup-form-wrap popup-form-linkform relative !bg-[#363F6C]">
            <div className="form-heading text-center mb-[30px] !text-white">Upload Image</div>
            <button type="button" className="close-popup" onClick={(e)=>{setShowForm(false)}}><span>close</span></button>
            <form onSubmit={handleSubmit(onSubmit)} className="form-wrapper link-form-wrapper">
                <div className="flex flex-wrap">
                    <div className="w-full md:px-2.5">
                        <div className="form-field-container mb-5 md:mb-12">
                            <div className="label">Select Folder Name</div>
                            <div className='form-field-wrap select-field-wrap no-field-wrap relative'>
                                <select {...register("folder_name")} onChange={(e)=>{setValue('folder_name', e.target.value);}}>
                                    <option value={""}>Select Folder Name</option>
                                    {galleryList.map((folderItem)=>{
                                        return(
                                            <option value={folderItem.value} key={folderItem.value}>{folderItem.label}</option>
                                        )
                                    })}
                                </select>
                            </div>
                            {errors.folder_name && <p className="error mt-2.5 mb-2.5" role="alert">{errors.folder_name?.message}</p>}
                        </div>
                        <div className="form-field-container mb-5 md:mb-12">
                            <div className='form-field-wrap relative'>                                    
                                <FilePond
                                    files={imageFiles}
                                    onupdatefiles={(fileItems) => {
                                        // Set current file objects to this.state
                                        setImageFile(fileItems.map((fileItem) => fileItem.file));
                                    }}
                                    allowMultiple={true}
                                    credits={false}
                                    allowFileTypeValidation={true}
                                    acceptedFileTypes={['image/png', 'image/jpeg']}
                                    labelFileTypeNotAllowed='File of invalid type'
                                    maxFiles={10}
                                    name="files"
                                    labelIdle={`
                                        <div class="upload-text">
                                            <div className="upload-icon mx-auto">
                                                <img src=${imageIcon} width={75} height={63} alt="" class="mx-auto" />
                                            </div>
                                            <h4>Select Image</h4>
                                            <p className="text-[12px] itallic">Images will show on your team page once <br/>
                                            approved by a Team Administrator</p>
                                        </div>`}
                                    {...register("files")}
                                    />
                            </div>
                        </div>
                    </div>
                    {errorMessage!=='' && <p className="text-red-500 mb-2.5 text-center" role="alert">{errorMessage}</p>}
                    <div className="w-full px-2.5">
                        <div className='flex justify-center'>
                            <input className='button' type="submit" value={'Upload'} disabled={loading}/>
                        </div>
                    </div>
                </div>
            </form>
        </div>        
        :
            <div className="py-5 text-center"><Spinner /></div>
        }
        </div>
        </>
    )
}

export default GalleryMediaForm;