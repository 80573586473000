import dayjs from "dayjs";
import IsMobile from "../../../components/isMobile";

const MemberOrderRow = ({orders}) =>{
    const isMobile = IsMobile(window);    
    return(
      !isMobile?
        <>
          <div className="table-row">
            <div className='table-cell py-[20px] pl-[30px] pr-1.5 text-[#363F6C] align-middle'>{orders.product_name!=="" ? orders.product_name : 'Product'}</div>
            <div className='table-cell py-[20px] pr-1.5 min-w-[140px] align-middle'>{/*orders['product_id']*/}</div>
            <div className='table-cell py-[20px] pr-1.5 min-w-[140px] align-middle'>{dayjs(orders.date).format("MMM DD YYYY hh:mm A")}</div>
            <div className='table-cell py-[20px] pr-1.5 w-[120px] !font-medium align-middle'>{orders.amount}</div>
          </div>
      </>
      :
      <>
        <div className="px-2.5 py-2.5 border-b border-black-100/10">
          <div className='text-[16px] leading-[24px] text-[#363F6C] font-bold mb-2.5'>{orders.product_name!=="" ? orders.product_name : 'Product'}</div>
          <div className="text-[12px] leading-[14px] flex mb-2.5">
              <span className="flex-1 mr-1.5">Order on <strong>{dayjs(orders.date).format("DD MMM YYYY")}</strong></span>
              <div className='w-[100px] text-right font-bold'>{orders.amount}</div>
          </div>
          <div className='orderinfo'>
              <div className="text-[12px] leading-[14px] flex mb-2.5">
                  <span className="flex-1 mr-1.5">SKU: {/*orders.product_id*/}</span>
              </div>
          </div>
        </div>
      </>
    );
}


export default MemberOrderRow;