// Require Editor JS files.
import 'froala-editor/js/froala_editor.pkgd.min.js'
import 'froala-editor/js/plugins/link.min.js'
import 'froala-editor/js/plugins/char_counter.min.js'
import 'froala-editor/js/plugins/image.min.js';
// Require Editor CSS files.
import 'froala-editor/css/froala_style.min.css'
import 'froala-editor/css/froala_editor.pkgd.min.css'

import FroalaEditor from 'react-froala-wysiwyg'

function TextEditor(props) {
  
  const config = {
    placeholderText: props.placeholderText,
    charCounterCount: props.charCount!==undefined ? true : false,
    height: props.height,
    attribution: false,
    imageDefaultAlign: 'left',
    imagePaste: true,
    imagePasteProcess: true,
    imageResize: false,
    imageEditButtons: ['imageDisplay', 'imageAlign', 'imageInfo', 'imageRemove'],
    key: 'eHE5C-11G2C1F2G1A5C3D-17xekbC-7H-7aB-16dD1qG-10yiB3B9B6A5C2B4C3H3G3C2==',
    charCounterMax: props.charLimit!==undefined ? props.charLimit : -1,
    zIndex: 999999,
    events:{
      "image.beforeUpload": function (files) {
        var editor = this;
        if (files.length) {
            // Create a File Reader.
            var reader = new FileReader();
            // Set the reader to insert images when they are loaded.
            reader.onload = function (e) {
                var result = e.target.result;
                editor.image.insert(result, null, null, editor.image.get());
            };
            // Read image as base64.
            reader.readAsDataURL(files[0]);
        }
        editor.popups.hideAll();
        // Stop default upload chain.
        return false;
      }      
    }
}

  return (
    <div className="editor">
      <FroalaEditor tag="textarea" config={config} model={props.value} onModelChange={props.onChange} />
    </div>
  )
}

export default TextEditor;