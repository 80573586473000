import { useState, useEffect, useRef, useCallback } from 'react';
import placeholderImg from '../../../assets/images/placeholder.png';

const CFGSThreeMemRow = ({memberitem,setIsCheckPlayers, isCheckplayers, index, players, setPlayers, price, minPrice, maxPrice}) =>{
    const [value,onChange]=useState(price);
    const range = useRef(null);
    const maxValRef = useRef(null);
    const handleClickMembers = e => {
        const { id, checked, value } = e.target;
        let tmpObj = {};
        Object.assign(tmpObj, JSON.parse(value));        
        setIsCheckPlayers([...isCheckplayers, tmpObj]);
        if (!checked) {
            setIsCheckPlayers(isCheckplayers.filter((item) => {return !item._id.includes(id)}));
        }
    };
    const getPercent = useCallback(
        val => Math.round(((val - minPrice) / (maxPrice - minPrice)) * 100),
        [minPrice,maxPrice]
    )
    useEffect(() => {
        if (maxValRef.current) {
            const maxPercent = getPercent(value);            
            if (range.current) {
                range.current.style.width = `${maxPercent}%`
            }
        }
    }, [value, getPercent]);

    useEffect(() => {
        onChange(price);
    }, [price]);

    const handleOnSubmit=()=>{
        const playerIndex = players.findIndex(element => element._id === memberitem._id );
        let newArray = [...players];
        newArray[playerIndex] = {...newArray[playerIndex], price: parseFloat(value), updatedBefore: true}        
        setPlayers(newArray);
    }

    return (
        <div className="w-full xl:w-1/2 flex mb-5 items-center px-2.5">
            <label key={memberitem._id} className='text-[12px] mr-3 text-black font-light cursor-pointer'>
                <input                    
                    type="checkbox"
                    name={memberitem._id}
                    id={memberitem._id}
                    value={JSON.stringify(memberitem)}
                    className='checkbox-field appearance-none h-4 w-4 rounded-sm bg-white mt-1 align-top float-left mr-2 cursor-pointer'
                    onChange={handleClickMembers}
                    checked={isCheckplayers.some((item)=>{ return item._id===memberitem._id })}
                />
            </label>
            <div className="w-full p-2.5 flex justify-between rounded-[16px] bg-[#EFF1F9] items-center">
                <div className="flex items-center w-full">
                    {memberitem.profilePicture !=="" ?
                        <img src={memberitem.profilePicture} width="50" height="50" className="rounded-full w-[50px] mr-[18px]" alt="" />
                    :
                        <img src={placeholderImg} width="50" height="50" className="rounded-full w-[50px] mr-[18px]" alt="" />
                    }
                    <div className='flex-1'>
                        <div className="pl-[16px] pr-2.5 text-[#363F6C] text-[16px] leading-[24px] font-semibold">{memberitem.name}</div>
                        <div className="relative w-full">
                            <div className="single_rangeslider-container flex items-center h-[10px] mb-[15px]">
                                <input 
                                    type="range" 
                                    className='single-thumb'
                                    min={minPrice} 
                                    max={maxPrice} 
                                    value={value}
                                    step={0.5}
                                    ref={maxValRef}
                                    onChange={({ target: { value: radius } }) => {
                                                onChange(radius);
                                            }}
                                    onMouseUp={()=>{handleOnSubmit()}}
                                />
                                <div className="single_rangeslider relative w-full">
                                    <div className="single_rangeslider__track absolute rounded-[14px] h-[10px] bg-[#d3d6e4] w-full z-10"></div>
                                    <div ref={range} className="single_rangeslider__range bg-[#363F6C] z-20 absolute rounded-[14px] h-[10px]"></div>                                
                                </div>
                            </div>
                            <div className='flex justify-between'>
                                <div className="text-[12px] leading-[18px]">${parseFloat(minPrice).toFixed(1)}m</div>
                                <div className="text-[12px] leading-[18px] font-semibold text-[#363F6C]">${parseFloat(value).toFixed(1)}m</div>
                                <div className="text-[12px] leading-[18px]">${parseFloat(maxPrice).toFixed(1)}m</div>
                            </div>
                        </div>
                    </div>
                </div>                
            </div>
        </div>
    )
}

export default CFGSThreeMemRow;