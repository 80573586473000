import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import * as yup from 'yup';
//import imageCompression from 'browser-image-compression';
import { confirmAlert } from "react-confirm-alert";
import { useAuthState } from "../../../contexts/AuthContext";
import { yupResolver } from "@hookform/resolvers/yup";
import Spinner from "../../../components/Spinner/Spinner";
import AndroidIcon from '../../../assets/images/icon-android.svg';
import IosIcon from '../../../assets/images/icon-ios.svg';
import webIcon from '../../../assets/images/icon-web.svg';
import CountriesList from "../../../libs/countries";
import TextEditor from "../../../components/TextEditor";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import 'filepond/dist/filepond.min.css';
import 'react-confirm-alert/src/react-confirm-alert.css';

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const schema = yup.object({
    fullname:yup.string().required('Full name is required.'),
    email: yup.string().email().required('Email address is required.'),
    country: yup.string().required('Please select Country.'),
    team_name: yup.string().required('Team name is required.'),
}).required();

const ReportIssue=()=>{   
    const [loading, setLoading] = useState(false);
    const [editorContent2,setEditorContent2] = useState('');
    const [images, setImages] = useState([]);
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    
    const { register, handleSubmit, setValue,formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema)
    });
    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
    ? localStorage.getItem('auth_token')
    : '';
    const userDetails = useAuthState();
    
    const handleChange2 = (e, key) => {
        const { value } = e.target        
        setEditorContent2(value);
    }
    
    const successPrompt=()=>{
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                <div className='confirm-ui'>
                    <div className="font-semibold uppercase text-[22px] leading-[30px] text-center mb-5">PLAI</div>
                    <p className="text-center text-[14px] leading-[18px] mb-5 uppercase">Your Report Has Been Received!</p>
                    <p className="text-center text-[14px] leading-[18px] mb-5">A member of the team will be in touch really soon to provide the additional support that you need.</p>
                    <div className="flex justify-center">
                        <button className="button button-blue mx-1" onClick={()=>{
                            onClose();
                            window.location='/settings/report_issue';
                        }}>Ok</button>
                    </div>
                </div>
                );
            }
        });
    }

    const onSubmit=async(data)=>{
        setErrorMessage(null);
        setSuccessMessage(null);
        setLoading(true);
        if(images.length > 0){  
            let imageformdata = new FormData();          
            /*for(let i=0; i<images.length; i++){
                await imageCompression(images[i].file,{
                    maxSizeMB: 2,
                    useWebWorker: true,
                    alwaysKeepResolution: true,
                    initialQuality:0.5
                }).then(function (compFile) {                    
                    const convertedFile = new File([compFile], images[i].file.name, {
                        type: images[i].file.type,
                        lastModified: Date.now(),
                    });                    
                    imageformdata.append("files[]", convertedFile);
                });                
            };*/
            //uplaoded without compression
            for(let i=0; i<images.length; i++){
                imageformdata.append("files[]", images[i].file);               
            };

            var requestOptions = {
                method: 'PUT',
                body: imageformdata,
                redirect: 'follow'
            };
            await fetch("https://dashboard.plaisport.com/upload_content_v2", requestOptions)
            .then(response => response.text())
            .then(result => {
                let rdata = JSON.parse(result);
                if(rdata.status === 1){
                    if(rdata.data?.images!==undefined && rdata.data?.images.length > 0 ){
                        let formdata = new FormData();
                        formdata.append('type','report_an_issue');
                        formdata.append('submissionType','bug');
                        formdata.append('device',data.device_type);
                        formdata.append('fullName',data.fullname);
                        formdata.append('email',data.email);
                        formdata.append('country',data.country);
                        formdata.append('isTeamMember', data.ismember);
                        formdata.append('description', data.team_name);
                        formdata.append('TeamMemberDescription', editorContent2);
                        formdata.append('content',rdata.data.images.join('@#'));
                        formdata.append('separate_by','@#');
                        var secureHeader = new Headers();
                        secureHeader.append('Authorization','Bearer ' + token);
                        secureHeader.append('device_type','W');
                        const requestOptions = {
                            method: 'POST',
                            redirect: 'follow',
                            body: formdata,
                            headers: secureHeader
                        };
                        fetch(process.env.REACT_APP_APIURL + '/addfeedback' , requestOptions)
                        .then(response => response.text())
                        .then(result2 => {
                            setLoading(false);
                            let rdata2 = JSON.parse(result2);
                            if(rdata2.status !== 1){
                                setErrorMessage(rdata2.message);
                            }
                            else{
                                reset();
                                //setSuccessMessage(rdata2.message);
                                successPrompt();
                            }
                        })
                        .catch(error => console.log('error', error));
                    }
                    else{
                        setLoading(false);
                        setErrorMessage("Image not uploaded due to some technical issue, try again");
                    }
                }
                else{
                    setLoading(false);
                    setErrorMessage(rdata.message);
                }
            });
        }
        else{
            let formdata = new FormData();
            formdata.append('type','report_an_issue');
            formdata.append('submissionType','bug');
            formdata.append('device',data.device_type);
            formdata.append('fullName',data.fullname);
            formdata.append('email',data.email);
            formdata.append('country',data.country);
            formdata.append('isTeamMember', data.ismember);
            formdata.append('description', data.team_name);
            formdata.append('TeamMemberDescription', editorContent2);
            formdata.append('separate_by','@#');
            var secureHeader = new Headers();
            secureHeader.append('Authorization','Bearer ' + token);
            secureHeader.append('device_type','W');
            const requestOptions = {
                method: 'POST',
                redirect: 'follow',
                body: formdata,
                headers: secureHeader
            };
            await fetch(process.env.REACT_APP_APIURL + '/addfeedback' , requestOptions)
            .then(response => response.text())
            .then(result => {
                setLoading(false);
                let rdata = JSON.parse(result);
                if(rdata.status !== 1){
                    setErrorMessage(rdata.message);
                }
                else{
                    reset();
                    //setSuccessMessage(rdata.message);
                    successPrompt();
                }
            })
            .catch(error => console.log('error', error));
        }
    }

    useEffect(()=>{
        setValue('device_type','W');
        setValue('ismember','Y');
        setValue('fullname',userDetails?.user?.FirstName+' '+userDetails?.user?.LastName);
        setValue('email',userDetails?.user?.user);
        setValue('country',userDetails?.user?.country);
    },[]);

    return(
        !loading ?
        <>
            <div className="myteams_section">
                <div className="myteams_top">
                    <div className="ln-header flex justify-between items-center w-full pb-5 relative">
                        <h2 className="title mr-5">Report an issue</h2>
                        <div className="sep mr-5 sm:mr-11"></div> 
                    </div>
                    <div className="uppercase font-medium text-[28px] leading-[48px]">PLAI public feedback forum</div>
                    <p className="leading-[16px] text-[12px] font-light text-zinc-600 mb-[26px]">PLAI are a people-first organisation and your input is truly important - Your submission has been processed and sent directly to the CEO (Global). You'll always have a seat at the top table with PLAI.</p>
                </div>
                <div className="issue-form">
                    <form onSubmit={handleSubmit(onSubmit)} className="form-wrapper">
                        <div className="flex flex-wrap w-full">
                            <div className="w-full md:w-2/3 lg:w-3/4 xl:w-1/2 md:px-2.5">
                                <div className="font-light text-zinc-600 leading-[24px] text-[16px]">Select Device*</div>
                                <div className='mb-6 button-group flex flex-wrap relative'>
                                    <div className="w-full sm:w-1/3 sm:pr-2.5">
                                        <input type="radio" {...register("device_type")} value="W" id="devicetype-w" className="peer hidden" />
                                        <label
                                            htmlFor="devicetype-w"
                                            className="flex items-center justify-center text-[18px] leading-[22px] font-medium w-full h-[54px] py-[5px] cursor-pointer select-none rounded-lg bg-[#EFF1F9] peer-checked:bg-[#FBDB03] peer-checked:text-black"
                                            >
                                                <img src={webIcon} alt="" width={19} height={22} className="mr-3.5" />Web
                                            </label>
                                    </div>
                                    <div className="w-full sm:w-1/3 sm:pr-2.5">
                                        <input type="radio" {...register("device_type")} value="I" id="devicetype-i" className="peer hidden" />
                                        <label
                                            htmlFor="devicetype-i"
                                            className="flex items-center justify-center text-[18px] leading-[22px] font-medium w-full h-[54px] py-[5px] cursor-pointer select-none rounded-lg bg-[#EFF1F9] peer-checked:bg-[#FBDB03] peer-checked:text-black"
                                            >
                                                <img src={IosIcon} alt="" width={19} height={22} className="mr-3.5" />iOS
                                            </label>
                                    </div>
                                    <div className="w-full sm:w-1/3">
                                        <input type="radio" {...register("device_type")} value="A" id="devicetype-a" className="peer hidden" />
                                        <label
                                            htmlFor="devicetype-a"
                                            className="flex items-center justify-center text-[18px] leading-[22px] font-medium w-full h-[54px] py-[5px] cursor-pointer select-none rounded-lg bg-[#EFF1F9] peer-checked:bg-[#FBDB03] peer-checked:text-black"
                                            >
                                                <img src={AndroidIcon} alt="" width={19} height={22} className="mr-3.5" />Android
                                            </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-wrap w-full">
                            <div className="w-full md:w-1/2 md:px-2.5">
                                <div className="font-light text-zinc-600 leading-[24px] text-[16px]">Full Name:</div>
                                <div className='form-field-wrap mb-5 no-field-wrap relative'>
                                    <input 
                                        type="text" id="full-name" disabled={loading} placeholder="Full Name" className='text-field'
                                        {...register("fullname")}
                                        aria-invalid={errors.fullname ? "true" : "false"} 
                                    />                                
                                </div>
                                {errors.fullname && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.fullname?.message}</p>}
                            </div>
                            <div className="w-full md:w-1/2 md:px-2.5">
                                <div className="font-light text-zinc-600 leading-[24px] text-[16px]">Email:</div>
                                <div className='form-field-wrap mb-5 no-field-wrap relative'>
                                    <input 
                                        type="email" id="article-name" disabled={loading} placeholder="Email" className='email-field'
                                        {...register("email")}
                                        aria-invalid={errors.email ? "true" : "false"} 
                                    />                                
                                </div>
                                {errors.email && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.email?.message}</p>}
                            </div>
                        </div>
                        <div className="flex flex-wrap w-full mb-5">
                            <div className="w-full md:w-1/2 md:px-2.5">
                                <div className="font-light text-zinc-600 leading-[24px] text-[16px]">Location:</div>
                                <div className='form-field-wrap mb-5 no-field-wrap relative'>
                                    <select {...register("country")} onChange={(e)=>setValue('country', e.target.value, { shouldValidate: true })}>
                                        <option value="">Select Country *</option>
                                        {CountriesList.map((c,index)=>{
                                            return(
                                                <option key={index} value={c.name}>{c.name}</option>
                                            );
                                        })}
                                    </select>
                                </div>
                                {errors.country && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.country?.message}</p>}
                            </div>
                            <div className="w-full md:w-1/2 md:px-2.5">
                                <div className="form-field-wrap button-group-wrap mb-5 flex flex-col md:flex-row lg:flex-col xl:flex-row justify-between items-center pt-5">
                                    <div className="flex-1 button-grop-label">Are you currently a member of a Team or Club on PLAI?*</div>
                                    <div className="r-button-group w-[148px] flex">
                                        <div className="mr-4">
                                            <input type="radio" {...register("ismember")} value="N" id="ismember-no" className="peer hidden" />
                                            <label
                                                htmlFor="ismember-no"
                                                className="flex items-center justify-center text-[18px] leading-[22px] font-medium py-[5px] w-[76px] h-[54px] cursor-pointer select-none rounded-lg text-center bg-[#EFF1F9] peer-checked:bg-[#FBDB03] peer-checked:text-black"
                                                >No</label>
                                        </div>
                                        <div>
                                            <input type="radio" {...register("ismember")} value="Y" id="ismember-yes" className="peer hidden" />
                                            <label
                                                htmlFor="ismember-yes"
                                                className="flex items-center justify-center text-[18px] leading-[22px] font-medium py-[5px] w-[76px] h-[54px] cursor-pointer select-none rounded-lg text-center bg-[#EFF1F9] peer-checked:bg-[#FBDB03] peer-checked:text-black"
                                                >Yes</label>
                                        </div>                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-full mb-6">
                            <div className="font-light text-zinc-600 leading-[24px] text-[16px]">If Yes, name your primary PLAI Sports Team here...(Put N/A if you answer is No)</div>
                            <div className='form-field-wrap mb-5 no-field-wrap relative'>
                                <input 
                                    type="text" id="team-name" disabled={loading} placeholder="Your Primary Team" className='text-field'
                                    {...register("team_name")}
                                />
                            </div>
                            {errors.team_name && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.team_name?.message}</p>}
                        </div>
                        <div className="w-full mb-6">
                            <div className='form-field-wrap mb-5 no-field-wrap relative z-10'>
                                <TextEditor
                                    value={editorContent2}
                                    placeholderText="Describe to us your issue..."
                                    height={200}
                                    onChange={(e) => handleChange2({ target: { value: e } }, 'editor')}
                                />
                            </div>                            
                        </div>
                        <div className="flex flex-wrap">
                            <div className="w-full md:w-1/2 md:px-2.5">
                                <div className='form-field-wrap mb-5 relative'>
                                    <FilePond                                        
                                        files={images}
                                        onupdatefiles={setImages}
                                        allowMultiple={true}
                                        credits={false}
                                        allowFileTypeValidation={true}
                                        acceptedFileTypes={['image/png', 'image/jpeg']}
                                        labelFileTypeNotAllowed='File of invalid type'
                                        allowImageTransform={true}
                                        labelIdle='
                                            <div class="upload-icon mx-auto"></div>
                                            <div class="upload-text">
                                                <h4>Upload images</h4>
                                                <p class="text-center">Maximum upload file size: 2MB</p>
                                            </div>'
                                        {...register("imagess")}
                                    />
                                </div>
                            </div>
                            <div className="w-full md:w-1/2 md:px-2.5 flex flex-col items-center justify-center">
                                <p className="text-center font-light text-[12px] leading-[18px] mb-[25px]">Never submit your passwords here.<br/>Check out our <a href="https://plaisport.com/plai-privacy-policy/" target="_blank" rel="noreferrer" className="font-medium underline hover:no-underline">privacy policy</a>.</p>
                                <input className='button button--large mx-auto' type="submit" value="Submit" disabled={loading}/>
                            </div>
                        </div>
                        {errorMessage!=='' && <p className="text-red-500 mb-2.5 -mt-2.5" role="alert">{errorMessage}</p>}
                        {successMessage!=='' && <p className="text-green-600 mb-2.5 -mt-2.5" role="alert">{successMessage}</p>}
                    </form>
                </div>
            </div>
        </>
       :
       <>
           <div className="py-5 text-center"><Spinner /></div>
       </>
    );
}

export default ReportIssue;