import { useState, useContext, useEffect } from "react";
import NoNews from "../../../assets/images/noemptyother.png";
import { TeamContext } from "../../../contexts/TeamContext";
import { getAds } from "../../../libs/dataFunctions";
import AdsRow from "./AdsRow";
import Spinner from "../../../components/Spinner/Spinner";
import AdForm from "./AdForm";
import TabSubscriptions from "../Subscriptions/TabSubscriptions";

const TabAds=()=>{
    const [adsList, setAdsList] = useState([]);
    const [adsLoading, setAdsLoading] = useState(false);
    const [openForm, setOpenForm] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [showSubscription, setShowSubscription] = useState(false);
    
    const { team} = useContext(TeamContext);
    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
	? localStorage.getItem('auth_token')
	: '';

    async function refreshAds() {
        setErrorMessage(null);
        setAdsLoading(true);
        const [adsData] = await Promise.all([
            getAds(team._id,token),
        ]);
        
        if(adsData.status===1){
            setAdsList(adsData.data);
        }
        else{
            setErrorMessage(adsData.message);
        }
        setAdsLoading(false);
    }

    const NewAdForm = ({setShowForm}) =>{        
        return(
            <div className="popup-form">
                <div className="popup-form-wrap relative !w-full !max-w-[900px]">                    
                    <AdForm team={team} isNew={true} setShowForm={setShowForm} refreshAds={refreshAds}/>
                </div>
            </div>
        )
    }
    useEffect(()=>{
        refreshAds();
    },[]);

    return(
        !showSubscription ?
        <>
            {!adsLoading ?
            <>  
                <div className="tab-actionbar px-2.5 sm:px-7.5 py-[18px] flex flex-col md:flex-row justify-between items-center">
                    <div className="title-col max-w-[320px] md:max-w-full mx-auto md:mx-0 mb-2.5 md:mb-0">Ads List</div>
                    <div className="cta-col max-w-[320px] md:max-w-full mx-auto md:mx-0 flex justify-center md:justify-end flex-wrap items-center relative">                    
                        {(team.role==="admin" && team.subscription_type !== undefined && team.subscription_type === "add_ads") &&
                        <>
                            <button type="button" className="button button-action button-new mr-3.5" onClick={(e)=>{setOpenForm(true)}}>Add new Ad</button>                            
                        </>
                        }                    
                    </div>
                    {openForm &&
                        <NewAdForm setShowForm={setOpenForm} />
                    }
                </div>
                <div className="w-full px-2.5 sm:px-7.5 pb-[77px]">
                    {adsList && adsList.length > 0 ? 
                    <table className="table-auto w-full">
                        <tbody>
                        {adsList.map((aditem) => {  
                            return (
                                <AdsRow adItem={aditem} refreshAds={refreshAds} team={team} key={aditem._id} />
                            )
                        })}
                        </tbody>
                    </table>                
                    :
                    <div className="empty text-center">
                        <div className="image"><img src={NoNews} alt="Plaisport" width={228} /></div>
                        <div className="title mb-5">No Ads Yet?</div>
                        <div className="description mb-8">{errorMessage}</div>
                        {(team.role==="admin" && team.subscription_type !== undefined && team.subscription_type === "add_ads") &&
                            <button type="button" className='button button-yellow button-yellow-add' onClick={(e)=>{setOpenForm(true)}}>New Ads</button>
                        }
                    </div>
                    }
                </div>
            </>
            :
            <>
                <div className="py-5 text-center"><Spinner /></div>
            </>
        }
        </>
        :
        <>
            <TabSubscriptions setShowSubscription={setShowSubscription} />
        </>
    )
}

export default TabAds;