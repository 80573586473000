import { useState, useEffect } from "react";
import Spinner from "../../../components/Spinner/Spinner";
import { getNewMembersListForChat } from "../../../libs/dataFunctions";
import placeholderImg from '../../../assets/images/placeholder.png';
import { useDispatch } from "react-redux";
import { newMemberChatAction } from "../../../Redux/activeChatSlice";

const ChatMemberRow = ({member, team, setShowForm, setLoading, setmobShowChatboard}) =>{
    const dispatch = useDispatch();

    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token') !== undefined
        ? localStorage.getItem('auth_token')
        : '';

    const handleSelectMember= async()=>{
        setLoading(true);
        setmobShowChatboard(true);
        var formdata = new FormData();
        formdata.append("type", "start_conversation");
        formdata.append("user_sub",member.sub);
        formdata.append("team_id",team._id);
        
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        await fetch(process.env.REACT_APP_APIURL + '/chat' , requestOptions)
            .then(response => response.text())
            .then(result => {
                setLoading(false);
                let rdata = JSON.parse(result);
                if(rdata.status===1){
                    dispatch(newMemberChatAction(rdata.data));
                    setShowForm(false);
                }
                else{
                    alert(rdata.message);
                }
            })
            .catch(error => console.log('error', error));
    }
    
    return(
        <div className={`rounded-md lg:rounded-xl text-[#363F6C] font-medium text-[9px] lg:text-[16px] leading-[14px] lg:leading-[24px] shadow-[0_6px_30px_0_rgba(54,63,108,0.10)] mb-2.5 lg:mb-5 last:mb-0 px-2.5 lg:pl-3.5 lg:pr-5 py-2 bg-white flex items-center cursor-pointer`} onClick={()=>handleSelectMember()}>
            <div className='w-[24px] lg:w-[44px] mr-2.5'>
            {member.profilePicture !== '' ?  
                <img src={member.profilePicture} alt="" width={44} height={44} className="rounded-full w-[24px] lg:w-[44px] h-[24px] lg:h-[44px] cursor-pointer" />
            :
                <img src={placeholderImg} alt="" width={44} height={44} className="rounded-full w-[24px] lg:w-[44px] h-[24px] lg:h-[44px] cursor-pointer" />
            }
            </div>
            <div className='member-info flex-1'>
                <div className='pr-2.5'>{member.name}</div>
            </div>
            <div className="w-[30px] lg:w-[40px] pl-2.5">
                <svg className="w-[18px] h-[18px] lg:w-[25px] lg:h-[25px]" width="25" height="25" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2.76026 11.4797C2.76026 6.65974 6.66762 2.75238 11.4876 2.75238C16.3076 2.75238 20.215 6.65974 20.215 11.4797C20.215 16.2997 16.3076 20.2071 11.4876 20.2071C10.0114 20.2071 8.61881 19.8399 7.39823 19.1916L3.95217 20.1716C3.79201 20.217 3.62263 20.2188 3.46154 20.1768C3.30044 20.1349 3.15346 20.0507 3.03578 19.9329C2.9181 19.8152 2.83398 19.6682 2.79213 19.5071C2.75027 19.3459 2.75218 19.1766 2.79766 19.0164L3.77762 15.571C3.10789 14.3114 2.7585 12.9063 2.76026 11.4797ZM7.74732 9.60959C7.74732 9.77492 7.813 9.93348 7.92991 10.0504C8.04681 10.1673 8.20537 10.233 8.3707 10.233H14.6045C14.7699 10.233 14.9284 10.1673 15.0453 10.0504C15.1622 9.93348 15.2279 9.77492 15.2279 9.60959C15.2279 9.44426 15.1622 9.2857 15.0453 9.16879C14.9284 9.05188 14.7699 8.98621 14.6045 8.98621H8.3707C8.20537 8.98621 8.04681 9.05188 7.92991 9.16879C7.813 9.2857 7.74732 9.44426 7.74732 9.60959ZM8.3707 12.7265C8.20537 12.7265 8.04681 12.7922 7.92991 12.9091C7.813 13.026 7.74732 13.1846 7.74732 13.3499C7.74732 13.5152 7.813 13.6738 7.92991 13.7907C8.04681 13.9076 8.20537 13.9733 8.3707 13.9733H12.111C12.2763 13.9733 12.4349 13.9076 12.5518 13.7907C12.6687 13.6738 12.7344 13.5152 12.7344 13.3499C12.7344 13.1846 12.6687 13.026 12.5518 12.9091C12.4349 12.7922 12.2763 12.7265 12.111 12.7265H8.3707Z" fill="#363F6C"/>
                </svg>
            </div>
        </div>
    )
}

const ChatWithOtherMember = ({setShowForm, team, setmobShowChatboard}) =>{
    const [searchParam] = useState(["name"]);
    const [q, setSearchQuery] = useState("");
    const [loading, setLoading]=useState(false);
    const [membersList, setMembersList] = useState([]);
    
    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token') !== undefined
        ? localStorage.getItem('auth_token')
        : '';

    const fetchMemberList = async() =>{
        setLoading(true);
        let [membersListdata] = await Promise.all([
            getNewMembersListForChat(token, team._id)
        ]);

        setMembersList(membersListdata.data);        
        setLoading(false);
    }

    const search=(items)=>{
        return items.filter((item) => {
            return searchParam.some((newItem) => {
                return (
                    item[newItem]?
                        item[newItem]
                        .toString()
                        .toLowerCase()
                        .indexOf(q.toLowerCase()) > -1
                    :
                    null
                );
            });
        });
    }

    useEffect(()=>{ 
        fetchMemberList();
    },[]);

    return(  
              
        <div className="popup-form">
            <div className="popup-form-wrap popup-form-memberdetails !max-w-[600px] relative !px-2.5 sm:!px-8">                    
                <button type="button" className="close-popup" onClick={(e)=>{setShowForm(false);}}><span>close</span></button>
                <div className="relative">
                {!loading ?
                <>
                    {membersList.length > 0 ?
                        <>
                        <div className="chat-members-list" style={{maxHeight: '70vh', overflowY: 'auto', paddingBottom: '40px', paddingLeft: '15px', paddingRight: '15px'}}>
                            <div className="ln-header flex justify-between items-center w-full flex-wrap pb-5 relative">
                                <h2 className="title mr-5 uppercase">Chat With Member</h2>
                                <div className="sep mr-4"></div>
                            </div>
                            <div className="search-form-wrap flex mb-5">
                                <label htmlFor="search-form" className="search-field relative flex-1">
                                    <input
                                        type="search"
                                        name="search-field"
                                        id="search-form"
                                        className="search-input"
                                        placeholder="Search Now"
                                        value={q}
                                        onChange={(e) => {
                                            setSearchQuery(e.target.value);                                                            
                                        }}
                                    />
                                </label>
                            </div>
                            <div>
                            {search(membersList).length>0 ?
                            <>
                                {search(membersList).map((member,index)=>{
                                    return(
                                        <ChatMemberRow member={member} key={index} team={team} setShowForm={setShowForm} setLoading={setLoading} setmobShowChatboard={setmobShowChatboard} />
                                    )
                                })}
                            </>
                            :
                            <div>Member not found.</div>
                            }
                            </div>
                        </div>                        
                        </>
                    :
                        <div>No members available.</div>
                    }
                </>
                :
                <>
                    <div className="text-center py-5"><Spinner /></div>
                </>
                }
                </div>
            </div>
        </div>
    )
}

export default ChatWithOtherMember;