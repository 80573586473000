import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DOMPurify from 'dompurify';
import placeholderImg from '../../../assets/images/placeholder.png';
import GroupForm from './GroupForm';
import ArrowUp from "../../../assets/images/arrow-up.svg";
import ArrowUpD from "../../../assets/images/arrow-up-d.svg";
import ArrowDown from "../../../assets/images/arrow-down.svg";
import ArrowDownD from "../../../assets/images/arrow-down-d.svg";
import IsMobile from '../../../components/isMobile';

const GroupsChildRow = ({group, team, refreshGroups, index, totalGroups}) =>{
    const [showEditForm, setShowEditForm] = useState(false);
    const navigate = useNavigate();
    const [orderUpdating, setOrderUpdating] = useState(false);
    const ismobile=IsMobile(window);
    const GroupEditForm = ({setShowEditForm, group, isChild, team}) =>{        
      return(
          <div className="popup-form">
              <div className="popup-form-wrap popup-form-group relative">                    
                  <GroupForm group={group} team={team} setShowForm={setShowEditForm} isChild={true} hasChild={false} refreshGroups={refreshGroups} isListview={true} isAddForm={false} />
              </div>
          </div>
      )
    }
    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
      ? localStorage.getItem('auth_token')
      : '';
    const OrderUp = async()=>{
      setOrderUpdating(true);
      var formdata = new FormData();
          formdata.append("type", "change_group_order");
          formdata.append("team_id", team._id);
          formdata.append("group_id", group._id);
          formdata.append("order", "up");
          
          
          var secureHeader = new Headers();
          secureHeader.append('Authorization','Bearer ' + token);
          secureHeader.append('device_type','W');
          const requestOptions = {
              method: 'POST',
              redirect: 'follow',
              body: formdata,
              headers: secureHeader
          };
  
          await fetch(process.env.REACT_APP_APIURL + '/app_groups' , requestOptions)
          .then(response => response.text())
          .then(result => {
              setOrderUpdating(false);
              let rdata = JSON.parse(result);
              refreshGroups();
          })
          .catch(error => console.log('error', error));
    }
    const OrderDown = async()=>{
      setOrderUpdating(true);
      var formdata = new FormData();
          formdata.append("type", "change_group_order");
          formdata.append("team_id", team._id);
          formdata.append("group_id", group._id);
          formdata.append("order", "down");
          
          
          var secureHeader = new Headers();
          secureHeader.append('Authorization','Bearer ' + token);
          secureHeader.append('device_type','W');
          const requestOptions = {
              method: 'POST',
              redirect: 'follow',
              body: formdata,
              headers: secureHeader
          };
  
          await fetch(process.env.REACT_APP_APIURL + '/app_groups' , requestOptions)
          .then(response => response.text())
          .then(result => {
              setOrderUpdating(false);
              let rdata = JSON.parse(result);
              refreshGroups();
          })
          .catch(error => console.log('error', error));
    }
    
    const deleteGroup = async ()=>{
      if(window.confirm("Are you sure you want to delete this group?")){
        var formdata = new FormData();
        formdata.append("type", "delete_group");
        formdata.append("group_id",group._id);
        formdata.append("team_id", team._id);
        
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        await fetch(process.env.REACT_APP_APIURL + '/app_groups' , requestOptions)
        .then(response => response.text())
        .then(result => {
            refreshGroups();
        })
        .catch(error => console.log('error', error));
      }
    }
    function viewGroup() {      
        navigate('/team/'+team._id+'/group/'+group._id,{
          state:{
            group:group,
            team:team,
          }          
        })      
    }
    
    return(
        <>
        <div className='flex justify-between'>
            <div className='pt-5 pb-3.5 flex-1'>
              <div className="flex items-center">
                {team.role==='admin' && 
                  <>                  
                    <div className={`order-actions flex-col mr-5 w-[12px]`}>
                      {!orderUpdating ?
                        <>
                        {index===0 ? 
                          <img src={ArrowUpD} width={12} height={12} alt="" />
                          :
                          <img src={ArrowUp} width={12} height={12} alt="" className="cursor-pointer" onClick={()=>{OrderUp()}} />
                        }
                        {index===(totalGroups-1) ? 
                          <img src={ArrowDownD} width={12} height={12} alt="" />
                          :
                          <img src={ArrowDown} width={12} height={12} alt="" className="cursor-pointer" onClick={()=>{OrderDown()}} />
                        }
                        </>
                        :
                        <img src="data:image/svg+xml,%3Csvg version='1.1' id='loader-1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='20px' height='20px' viewBox='0 0 40 40' enable-background='new 0 0 40 40' xml:space='preserve'%3E%3Cpath opacity='0.2' fill='%23000' d='M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z'%3E%3C/path%3E%3Cpath fill='%23000' d='M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 20 20' to='360 20 20' dur='0.5s' repeatCount='indefinite'%3E%3C/animateTransform%3E%3C/path%3E%3C/svg%3E" width={12} height={12} alt="" />
                      }                    
                    </div>
                  </>
                }
                <span className={`group-name cursor-pointer`} onClick={()=>viewGroup()}>
                  <div>{group.name}</div>
                  {group.description!=="" &&
                    <div className='text-[10px]' dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(group.description)}}/>
                  }
                </span>                
              </div>
              {ismobile &&
                <div className="flex items-center justify-start pt-2.5">
                {group.members.length > 0 &&
                  <div className="members">
                      {group?.members.map((teamMember,index)=>{
                          if(index > 4){return ''}
                          let eclass='';
                          let profilePic='';
                          profilePic=(teamMember.profilePicture && teamMember.profilePicture !== "") ? teamMember.profilePicture : placeholderImg;
                          if(index!==0){
                              eclass = '-ml-2.5';
                          }                        
                          return <img src={profilePic} key={index} alt="" className={`inline-block relative object-cover object-center rounded-full w-7 h-7 ${eclass}`} />
                      })}
                      <span className='total-members'>({group.members.length})</span>
                  </div>
                  }
                </div>
              }
            </div>            
            {!ismobile &&
              <div className='md:pt-5 md:pb-3.5 self-center'>
                <div className="flex items-center justify-end">
                {group.members.length > 0 &&
                  <div className="members">
                      {group?.members.map((teamMember,index)=>{
                          if(index > 4){return ''}
                          let eclass='';
                          let profilePic='';
                          profilePic=(teamMember.profilePicture && teamMember.profilePicture !== "") ? teamMember.profilePicture : placeholderImg;
                          if(index!==0){
                              eclass = '-ml-2.5';
                          }                        
                          return <img src={profilePic} key={index} alt="" className={`inline-block relative object-cover object-center rounded-full w-7 h-7 ${eclass}`} />
                      })}
                      <span className='total-members'>({group.members.length})</span>
                  </div>
                  }
                </div>
              </div>
            }            
            <div className='pt-5 pb-3.5 w-[80px] selft-top md:self-center'>
            {group.name.toLowerCase()!=='all members' &&
              <>
                {(group.user_role === 'admin' || group.user_role==='group_admin') &&
                  <div className="flex items-center justify-end">
                    <button type="button" className="button-tiny edit rounded-full mr-1.5" onClick={()=>{setShowEditForm(true)}}></button>
                    <button type="button" className="button-tiny delete rounded-full" onClick={()=>{deleteGroup()}}></button>
                  </div>
                }
                {showEditForm &&
                    <GroupEditForm group={group} isChild={false} team={team} setShowEditForm={setShowEditForm} />
                }
              </>
            }
            </div>
            {!ismobile &&
              <div className='md:px-5 pt-5 pb-3.5 w-[90px] md:w-[175px] text-right selft-top md:self-center'></div>
            }
        </div>
        </>
    );
}
export default GroupsChildRow;