import { logout, useAuthDispatch } from "../../../contexts/AuthProvider";
import logouticon from '../../../assets/images/logout-image.svg';

const Logout=()=>{   
    const dispatch = useAuthDispatch() // read dispatch method from context
    const handleLogoutClick = () =>{
        logout(dispatch);
        window.location.href = '/signin';
    }
    const handleNoLogoutClick = ()=>{
        window.location.href = '/';
    }
    return(
       
        <>
            <div className="my_order_section">
                <div className="my_order_top">
                    <div className="ln-header flex justify-between items-center w-full pb-5 relative">
                        <h2 className="title mr-5">Log Out</h2>
                        <div className="sep mr-5 sm:mr-11"></div> 
                    </div>
                </div>
                <div className="my_order_bottom flex justify-center item-center w-full text-center">
                   <div className="logout-main ">
                       <div className="logout_image flex justify-center">
                          <img src={logouticon} alt='logouticon' width={251} height={252} />
                       </div>
                       <div className="logout_content">
                          <p> Are you sure you want to</p>
                          <h3>Logout of PLAI?</h3>                     
                       </div>
                       <div className="logout_btn my-8">
                          <ul className="flex justify-center item-center w-full text-center">
                              <li><button type="button" className="btn-grey yellow-bg mr-7" onClick={()=>{handleNoLogoutClick()}}>No</button></li>
                              <li><button type="button" className="btn-grey" onClick={()=>{handleLogoutClick()}}>Yes</button></li>
                          </ul>
                       </div>
                   </div>
                </div>
            </div>
        </>
       
    );
}

export default Logout;