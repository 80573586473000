import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import ReactDatePicker from "react-datepicker";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import * as yup from 'yup';
import Spinner from "../../../components/Spinner/Spinner";
import { getMembers, getAllProducts, getProductCategories } from "../../../libs/dataFunctions";
import MemberWithGroupInput from "../../../components/MemberWithGroupInput/MemberWithGroupInput";

dayjs.extend(utc);
dayjs.extend(tz);

const schema = yup.object({
    product_id: yup.string().required('Select a product.'),
    members: yup
    .array()
    .min(1, "Select at least one member for this request")
    .nullable()
    .required("Select at least one member for this request"),
    payment_request_name:yup.string()
    .when('product_id',{
        is:'custom_payment',
        then:yup.string().required('Enter Product Name')
    }),
    custom_amount:yup.number()
    .when('product_id',{
        is:'custom_payment',
        then:yup.number().min(2,"Amount should be 2 or more.").required('Enter price amount').typeError('Enter valid price amount')
    }).nullable(),
    category:yup.string()
    .when('product_id',{
        is:'custom_payment',
        then:yup.string().required('Select category')
    }).nullable(),
    duration_of_membership:yup.string()
    .when('category',{
        is:'Memberships',
        then:yup.string().required('Select Expiry Duration')
    }).nullable(),
}).required();

const PaymentRequestForm = ({team, setShowForm, refreshShopOrders, parentGroups}) =>{
    const [loading,setLoading] = useState(false);
    const [errorMessage,setErrorMessage] = useState(null);
    const [successMessage,setSuccessMessage] = useState(null);
    const [categories, setCategories] = useState([]);
    const [membershipDurations, setMembershipdurations] = useState([]);
    const [productList, setProductList] = useState([]);
    const [members, setMembers] = useState([]);
    const [selectedMembersList, setSelectedMembersList] = useState([]);
    const [selectProduct, setSelectedProduct] = useState(null);
    
    const { register, handleSubmit, setValue, watch, control, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema)
    });

    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
    ? localStorage.getItem('auth_token')
    : '';

    const RequestSubmit = async(data)=>{
        setLoading(true);
        setErrorMessage(null);
        setSuccessMessage(null);
        var formdata = new FormData();
        formdata.append("type", "payment_link");
        formdata.append("team_id", team._id);        
        formdata.append("member_ids", data.members.join());
        if(data.product_id==='custom_payment'){
            formdata.append("product_id", "");
            formdata.append("payment_request_name",data.payment_request_name);
            formdata.append("category",data.category);
            formdata.append("custom_amount",data.custom_amount * 100);
            if(data.category==="Memberships"){
                formdata.append('duration_type', data.duration_type);
                if(data.duration_type==='variable'){
                    formdata.append('duration_of_membership', data.duration_of_membership);
                }
                else{
                    formdata.append('duration_of_membership', dayjs(data.duration_of_membership).format("YYYY-MM-DD"));
                }
            }
        }
        else{
            formdata.append("product_id", data.product_id);
        }
        
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        await fetch(process.env.REACT_APP_APIURL + '/app_shop' , requestOptions)
        .then(response => response.text())
        .then(result => {
            setLoading(false);
            let rdata = JSON.parse(result);
            if(rdata.status !== 1){
                setErrorMessage(rdata.message);
            }
            else{
                reset();
                setSuccessMessage(rdata.message);
                setShowForm(false);
                refreshShopOrders('get_orders');
            }
        })
        .catch(error => console.log('error', error));
    }

    const onSubmit = async(data) => {
        RequestSubmit(data);
    }

    async function refreshData(){
        setLoading(true);
        const [membersData, productsData, categoriesData] = await Promise.all([
            getMembers(team._id, token),
            getAllProducts(team._id, token),
            getProductCategories(team._id, token)
        ]); 
        let membersOptions = [];
        membersData.forEach(element => {
            membersOptions.push({'value':element.sub, 'label':element.name});
        });
        setMembers(membersOptions);
        setCategories(categoriesData.category);
        setMembershipdurations(categoriesData.duration_of_membership);
        setProductList(productsData);
        setLoading(false);        
    }
    
    useEffect(()=>{
        refreshData();
        setValue('duration_type','fixed');
    },[]);

    const watchProductCatg = watch("category");
    const watchMemDurationtype = watch("duration_type");

    return (
        !loading ?
        <>
            <div className="popup-form">
                <div className="popup-form-wrap !w-full !max-w-[650px] !px-7.5 relative">
                    <button type="button" className="close-popup" onClick={(e)=>{setShowForm(false)}}><span>close</span></button>
                    <div className="bg-[#363F6C] py-7.5 px-5 rounded-xl">
                        <div>
                            <div className="form-heading text-center mb-[30px] !text-white">Send Payment Request</div>
                            <form onSubmit={handleSubmit(onSubmit)} className="form-wrapper">
                                <div className="form-field-container mb-5">
                                    <div className="label">Select Product*</div>
                                    <div className='form-field-wrap no-field-wrap relative'>
                                        <select {...register("product_id")} onChange={(e)=>{setValue('product_id', e.target.value, { shouldValidate: true }); setSelectedProduct(e.target.value);}}>
                                            <option value="">Select Product*</option>
                                            {productList.map((c,index)=>{
                                                return(
                                                    <option key={index} value={c._id}>{c.name}</option>
                                                );
                                            })}
                                            <option value="custom_payment">Custom Payment</option>
                                        </select>
                                    </div>
                                    {errors.product_id?.message && <p className="error mb-2.5" role="alert">{errors.product_id?.message}</p> }
                                </div>
                                <div className="form-field-container mb-5">
                                    <div className="flex justify-between">
                                        <div className="label">Select Multiple Members*</div>
                                        <p className="mb-1 font-medium text-[12px] leading-[18px] text-white">
                                            {selectedMembersList.length} SELECTED
                                        </p>
                                    </div>
                                    <div className='form-field-wrap relative'>
                                        <Controller
                                            control={control}
                                            name='members'
                                            render={({ field: { onChange, value, ref }}) => (
                                                <MemberWithGroupInput teamID={team._id} 
                                                    token={token} 
                                                    parentsGroups={parentGroups} 
                                                    selectedMembers={selectedMembersList} 
                                                    onChange={val=>{
                                                        onChange(val.map(c => c.sub));
                                                        setSelectedMembersList(val);
                                                    }} 
                                                    onChangeMain={setMembers} 
                                                />
                                            )}
                                        />
                                    </div>                                    
                                    {errors.members?.message && <p className="error mb-2.5" role="alert">{errors.members?.message}</p> }
                                </div>
                                {selectProduct==='custom_payment' &&
                                <>
                                    <div className="form-field-container mb-5">
                                        <div className="label">Name*</div>
                                        <div className='form-field-wrap no-field-wrap relative'>
                                            <input 
                                                type="text" disabled={loading} className='text-field'
                                                {...register("payment_request_name")}
                                            />                                
                                        </div>
                                        {errors.payment_request_name?.message && <p className="error mb-2.5" role="alert">{errors.payment_request_name?.message}</p> }
                                    </div>
                                    <div className="form-field-container mb-5">
                                        <div className="label">Price*</div>
                                        <div className='form-field-wrap no-field-wrap relative'>
                                            <input 
                                                type="number" disabled={loading} className='text-field'
                                                {...register("custom_amount")}
                                            />
                                        </div>
                                        {errors.custom_amount?.message && <p className="error mb-2.5" role="alert">{errors.custom_amount?.message}</p> }
                                    </div>
                                    <div className="form-field-container mb-5">
                                        <div className="label">Select Category*</div>
                                        <div className='form-field-wrap select-field-wrap no-field-wrap relative'>
                                            <select {...register("category")} onChange={(e) => { setValue('category', e.target.value);}}>
                                                <option value="">Select Category*</option>
                                                {categories.length > 0 &&
                                                    categories.map((catg, index) => {
                                                        return <option value={catg} key={index}>{catg}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                        {errors.category?.message && <p className="error mb-2.5" role="alert">{errors.category?.message}</p> }
                                    </div>
                                    {watchProductCatg==='Memberships' &&
                                        <>
                                        <div className="form-field-container">
                                            <div className="label mb-1.5">Membership Duration*</div>
                                            <div className="r-button-group w-[148px] flex">
                                                <div className="mr-4">
                                                <input type="radio" {...register("duration_type")} value="fixed" id="duration_type-fixed" className="peer hidden" 
                                                    onChange={(e)=>{
                                                        setValue("duration_type",e.target.value);
                                                        setValue("duration_of_membership","");
                                                    }}/>
                                                <label
                                                    htmlFor="duration_type-fixed"
                                                    className="block py-[5px] w-[90px] cursor-pointer select-none rounded-[42px] text-center bg-[#EFF1F9] peer-checked:bg-[#FBDB03] peer-checked:text-black"
                                                    >Fixed</label>
                                                </div>
                                                <div>
                                                <input type="radio" {...register("duration_type")} value="variable" id="duration_type-variable" className="peer hidden" 
                                                    onChange={(e)=>{
                                                        setValue("duration_type",e.target.value);
                                                        setValue("duration_of_membership","");
                                                    }}
                                                />
                                                <label
                                                    htmlFor="duration_type-variable"
                                                    className="block py-[5px] w-[112px] cursor-pointer select-none rounded-[42px] text-center bg-[#EFF1F9] peer-checked:bg-[#FBDB03] peer-checked:text-black"
                                                    >Variable</label>
                                                </div>
                                            </div>
                                        </div>
                                        {watchMemDurationtype==='variable' ?
                                            <div className="form-field-container mb-5">
                                                <div className="label">Expiry Duration*</div>
                                                <div className='form-field-wrap mb-5 select-field-wrap no-field-wrap relative'>
                                                    <select {...register("duration_of_membership")} onChange={(e) => { setValue('duration_of_membership', e.target.value);}}>
                                                        <option value="">Expiry Duration*</option>
                                                        {membershipDurations.length > 0 &&
                                                            membershipDurations.map((memduration, index) => {
                                                                return <option value={memduration} key={index}>{memduration}</option>
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                                {errors.duration_of_membership?.message && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.duration_of_membership?.message}</p>}
                                            </div>
                                            :
                                            <div className="form-field-container mb-5">
                                                <div className="label">Expiry Date*</div>
                                                <div className='form-field-wrap mb-5 select-field-wrap date-field-wrap relative'>
                                                    <Controller
                                                        control={control}
                                                        name='duration_of_membership'
                                                        errors={errors}
                                                        render={({ field, ref }) => (
                                                            <ReactDatePicker
                                                                placeholderText='Expiry Date*'
                                                                minDate={new Date()}
                                                                onChange={(date) => {
                                                                    field.onChange(date); 
                                                                    setValue('duration_of_membership',date);
                                                                }}
                                                                //utcOffset={timeZone}
                                                                selected={field.value}
                                                                mask="____-__-__"
                                                                dateFormat="dd-MM-yyyy"
                                                                inputRef={ref}                                     
                                                            />
                                                        )}
                                                    />
                                                </div>
                                                {errors.duration_of_membership?.message && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.duration_of_membership?.message}</p>}
                                            </div>
                                        }
                                        </>
                                    }                             
                                </>
                                }
                                <div className='flex justify-center'>
                                    <input className='button' type="submit" value="Send" disabled={loading}/>
                                </div>
                                {errorMessage!=='' && <p className="text-red-500 mb-2.5 text-center" role="alert">{errorMessage}</p>}
                                {successMessage!=='' && <p className="text-green-600 mb-2.5 text-center" role="alert">{successMessage}</p>}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
        :
        <>
        <div className="popup-form">
            <div className="popup-form-wrap !w-full !max-w-[650px] !px-7.5 relative">
                <div className="py-5 text-center"><Spinner /></div>
            </div>
        </div>
        </>
    );
}

export default PaymentRequestForm;