import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {useState} from 'react';
import { useForm } from 'react-hook-form';
import { useFormData } from '../../contexts/FormContext';
import Spinner from '../../components/Spinner/Spinner';

const schema = yup.object({
    email: yup.string().email().required('Email address is required.'),    
}).required();

const ForgotPasswordStepOne =({ formStep, nextFormStep }) => {
    const { setFormValues } = useFormData();
    const [loading, setLoading] = useState(false);
    const [errorMessage,setErrorMessage] = useState('');
    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        mode: "all"
    });

    const onSubmit = async (values) => {
        setFormValues(values);
        let formdata = new FormData();
        formdata.append("type","verification_code");
        formdata.append("email",values.email);
        var secureHeader = new Headers();
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };
    
        await fetch(process.env.REACT_APP_APIURL + '/forgetpassword' , requestOptions)
        .then(response => response.text())
        .then(result => {
            setLoading(false);
            let rdata = JSON.parse(result);
            if(rdata.status !== 1){
                setErrorMessage(rdata.message);
            }
            else{
                nextFormStep();
            }
        })
        .catch(error => console.log('error', error));
        
    };
    
    return (
        !loading ?
        <>
            <div className={formStep === 0 ? '' : 'hidden'}>
                <div className='auth-form-content text-center mb-[34px]'>
                    <h2>Forgot your password?</h2>
                    <p>Please enter your registered email address so we can send you a verification code to reset your password.</p>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    {errorMessage!=='' &&
                        <p className='error text-center mb-2.5'>{errorMessage}</p>
                    }
                    <div className='auth-form-field-wrap mb-5 email-field-wrap relative'>
                        <input 
                            type="email" placeholder="Your Email Address" className='email-field'
                            {...register("email")}
                            aria-invalid={errors.email ? "true" : "false"} 
                        />
                    </div>
                    {errors.email && <p className="error mb-2.5" role="alert">{errors.email?.message}</p>}                
                    <div className='auth-form-field-wrap flex justify-center mb-5 md:mb-10'>
                        <input className='button button--large' type="submit" value={'Submit'}/>
                    </div>
                    <div className='auth-form-field-wrap flex justify-center'>
                        <span>Already have an account? <a href="/signin" className='auth-form-link'>Login</a></span>
                    </div>
                </form>
            </div>
        </>
        :
        <>
            <div className="py-5 text-center"><Spinner /></div>
        </>
    );
}

export default ForgotPasswordStepOne;