import { useState, useEffect } from 'react';

function IsMobile(wind) {
    const [width, setWidth] = useState(wind.innerWidth);
    
    useEffect(() => {
        function handlewindSizeChange() {
            setWidth(wind.innerWidth);
        }

        wind.addEventListener('resize', handlewindSizeChange);
        return () => {
            wind.removeEventListener('resize', handlewindSizeChange);
        }
    }, [wind, setWidth]);

    const isMobile = width < 768;
    return isMobile
}

export default IsMobile;