import { useContext, useEffect, useState, useRef } from "react";
import { TeamContext } from "../../../contexts/TeamContext";
import LinksRow from "./LinksRow";
import redirect from '../../../assets/images/nolinks.png';
import Spinner from "../../../components/Spinner/Spinner";
import { getLinks } from "../../../libs/dataFunctions";
import LinkForm from "./LinkForm";

const TabLinks=()=>{
    const { team, token } = useContext(TeamContext);
    const [links, setLinks] = useState([]);
    const [totalLinks, setTotalLinks] = useState(0);
    const [linksLoading, setLinksLoading] = useState(false);
    const [openForm, setOpenForm] = useState(false);
    const [q, setSearchQuery] = useState("");    
    const [searchParam] = useState(["name"]);

    async function refreshLinks(){
        setLinksLoading(true);
        let linksData= await getLinks(team._id, token);
        setLinks(linksData);
        setTotalLinks(linksData.length);
        setLinksLoading(false);
    }

    const NewLinkForm = ({setShowForm}) =>{        
        return(
            <div className="popup-form">
                <div className="popup-form-wrap popup-form-linkform relative">                    
                    <LinkForm isNew={true} team={team} setShowForm={setShowForm} refreshLinks={refreshLinks}/>
                </div>
            </div>
        )
    }

    const search=(items)=>{
        return items.filter((item) => {
            return searchParam.some((newItem) => {
                return (
                    item[newItem]
                        .toString()
                        .toLowerCase()
                        .indexOf(q.toLowerCase()) > -1
                );
            });
        });
    }

    let scrl = useRef(null);
    const [scrollX, setscrollX] = useState(0);
    const [scrolEnd, setscrolEnd] = useState(false);
    const scrollCheck = () => {
        setscrollX(scrl.current.scrollLeft);
        if (
            Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
            scrl.current.offsetWidth
        ) {
            setscrolEnd(true);
        } else {
            setscrolEnd(false);
        }
    };
    const slide = (shift) => {
        scrl.current.scrollLeft += shift;
        setscrollX(scrollX + shift);

        if (
            Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
            scrl.current.offsetWidth
        ) {
            setscrolEnd(true);
        } else {
            setscrolEnd(false);
        }
    }

    useEffect(()=>{
        refreshLinks();
    },[]);
    
    return(
        !linksLoading ?
        <>
            <div className="tab-actionbar px-2.5 sm:px-7.5 py-[18px] flex flex-col md:flex-row justify-between items-center">
                <div className="title-col mb-2.5 md:mb-0">Links List</div>
                <div className="cta-col flex flex-col md:flex-row items-center">
                    <label htmlFor="search-form" className="search-field relative mr-3.5 mb-2.5 md:mb-0">
                        <input
                            type="search"
                            name="search-field"
                            id="search-form"
                            className="search-input"
                            placeholder="Search Links"
                            value={q}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />                        
                    </label>
                    {(team.role==='admin' || (team.role==='member' && team.admin_groups_new !== undefined && team.admin_groups_new.length>0)) &&
                        <button type="button" className="button button-action button-new" onClick={(e)=>{setOpenForm(true)}}>Add Link</button>
                    }
                    {openForm &&
                        <NewLinkForm setShowForm={setOpenForm} />
                    }
                </div>
            </div>
            {links && links.length > 0 ?
                <div className="links-list px-2.5 sm:px-7.5 pb-[77px]">                    
                    <table className="table-auto w-full">
                        <tbody>
                            {search(links).sort((a, b) => {
                                    return new Date(b.dateStart).getTime() - new Date(a.dateStart).getTime()
                                }).map((links,index) => {                        
                                    return (
                                        <LinksRow links={links} team={team} refreshLinks={refreshLinks} totalLinks={totalLinks} index={index} key={index} />
                                    )
                                })
                            }                            
                        </tbody>
                    </table>                    
                </div>
                :
                <div className="empty text-center">
                    <div className="image"><img src={redirect} alt="Plaisport" width={228} /></div>
                    <div className="title mb-5">No Links Yet?</div>
                    <div className="description mb-8">No Links Found</div>
                    {(team.role==='admin' || (team.role==='member' && team.admin_groups_new !== undefined && team.admin_groups_new.length>0)) &&
                        <button type="button" className='button button-yellow button-yellow-add' onClick={(e)=>{setOpenForm(true)}}>Add Links</button>
                    }
                </div>
            }
        </>
        :
        <>
            <div className="py-5 text-center"><Spinner /></div>
        </>
    );
}

export default TabLinks;