import { useContext, useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import * as DOMPurify from 'dompurify';
import moment from 'moment';
import { NavLink, useNavigate } from 'react-router-dom';
import { useAuthState } from '../../../contexts/AuthContext';
import { TeamContext, TeamContextProvider } from '../../../contexts/TeamContext';
import AdminNavbar from "../../../components/AdminNavbar/AdminNavbar";
import Sidebar from "../../../components/Sidebar/Sidebar";
import placeholderImg from '../../../assets/images/placeholder.png';
import ProductForm from './ProductForm';
import ProductCloneForm from './ProductCloneForm';
import Spinner from '../../../components/Spinner/Spinner';
import { getProductDetail, getTransitionFees, checkInstallmentPlanActive } from '../../../libs/dataFunctions';
import DocumentView from '../../../components/DocumentView/DocumentView';
import InfoIcon from '../../../assets/images/info.svg';
import InfoIconWhite from '../../../assets/images/info-white.svg';
import CheckoutForm from '../../../components/Stripe/StripeCard';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import RequestGiftPopup from './RequestGiftPopup';

const ProductView = ({ id }) => {
    const userDetail = useAuthState();
    const { team, teamUpdating } = useContext(TeamContext);
    const [loading, setLoading] = useState(false);
    const [loadingTrans, setTransLoading] = useState(false);
    const [addToCartloading, setAddtoCartLoading] = useState(false);
    const [product, setProduct] = useState([]);
    const [openForm, setOpenForm] = useState(false);
    const [openCloneForm, setOpenCloneForm] = useState(false);
    const [quantity, setQuantity] = useState(1);
    const [isAdded, setIsAdded] = useState(false);
    const [payInInstallment, setPayInInstallment] = useState(false);
    const [showInstallmentDetails, setShowInstallmentDetails] = useState(false);
    const [clientSecret, setClientSecret] = useState(false);
    const [isBuySingle, setIsBuySingle] = useState(false);
    const [singleBuyEmi, setSingleBuyEmi] = useState(null);
    const [receiptEmail, setReceiptEmail] = useState(userDetail?.user?.user);
    const [referenceChild, setReferenceChild] = useState('');
    const [address, setAddress] = useState('');
    const [error, setError] = useState('');
    const [intentId, setIntentId] = useState(null);
    const [loadingSecrete, setLoadingSecrete] = useState(false);
    const [transactionFee, setTransactionFee] = useState(0);
    const [infoPopup, setInfoPopup] = useState(false);
    const [showMoreOpt, setShowMoreOpt] = useState(false);

    const [tip, setTip] = useState(false);
    const [tipAmount, setTipAmount] = useState(0);
    const [tipTransfer, setTipTransfer] = useState('club');

    const [selectedSize, setSelectedSize] = useState('');
    const [selectedSleeve, setSelectedSleeve] = useState('');
    const [selectedColor, setSelectedColor] = useState('');
    const [selectedGender, setSelectedGender] = useState('');
    const [shirtNumber, setShirtNumber] = useState('');
    const [shirtName, setShirtName] = useState('');

    const [promocode, setPromocode] = useState('');
    const [promoloading, setPromoLoading] = useState(false);
    const [promoCodeError, setPromoCodeerror] = useState(null);
    const [promoCodeSuccess, setPromoCodeSuccess] = useState(false);
    const [promoDiscountAmount, setPromoDiscountAmount] = useState(0);

    const [singleBuyloading, setSingleBuyloading] = useState(false);

    const [requestGiftPopup, setRequestGiftPopup] = useState(false);

    const [errorState, setErrorState] = useState({sizeError:false, sleeveError:false, colorError:false, genderError:false, shirtNumberError:false, shirtNameError:false});

    const navigate = useNavigate();

    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token') !== undefined
        ? localStorage.getItem('auth_token')
        : '';


    const refreshProduct = async () => {
        setLoading(true);
        let ProductData = await getProductDetail(id, team._id, token);
        setProduct(ProductData.data);

        if(ProductData.data.size!==undefined && ProductData.data.size.length > 0){
            setSelectedSize(ProductData.data.size[0]);
        }
        if(ProductData.data.sleeve!==undefined && ProductData.data.sleeve.length > 0){
            setSelectedSleeve(ProductData.data.sleeve[0]);
        }
        if(ProductData.data.color!==undefined && ProductData.data.color.length > 0){
            setSelectedColor(ProductData.data.color[0]);
        }
        if(ProductData.data.gender!==undefined && ProductData.data.gender.length > 0){
            setSelectedGender(ProductData.data.gender[0]);
        }
        setLoading(false);
    }

    const deletDocument=async(docfile)=>{
        if (window.confirm("Are you sure you want to delete this document?")) {
            var formdata = new FormData();
            formdata.append("type", "delete_document");
            formdata.append("product_id", product._id);
            formdata.append("team_id", team._id);
            formdata.append('document_url', docfile);

            var secureHeader = new Headers();
            secureHeader.append('Authorization', 'Bearer ' + token);
            secureHeader.append('device_type', 'W');
            const requestOptions = {
                method: 'POST',
                redirect: 'follow',
                body: formdata,
                headers: secureHeader
            };

            await fetch(process.env.REACT_APP_APIURL + '/app_shop', requestOptions)
                .then(response => response.text())
                .then(result => {
                    refreshProduct();
                })
                .catch(error => console.log('error', error));
        }
    }

    const applyPromoCode=async()=>{
        setPromoCodeerror(null);
        if(promocode!==""){
            setPromoLoading(true);            
            setPromoCodeSuccess(false);
            let formdata = new FormData();
            formdata.append('type', 'apply_promocode');
            formdata.append('team_id', team._id);
            formdata.append('promocode',promocode);
            if(payInInstallment){
                formdata.append('amount',((singleBuyEmi * quantity)));             
            }
            else{
                formdata.append('amount',((product.display_price * quantity)));
            }
            
            var secureHeader = new Headers();
            secureHeader.append('Authorization', 'Bearer ' + token);
            const requestOptions = {
                method: 'POST',
                redirect: 'follow',
                body: formdata,
                headers: secureHeader
            };
            await fetch(process.env.REACT_APP_APIURL + '/app_promocode', requestOptions)
                .then(response => response.text())
                .then(result => {
                    setPromoLoading(false);
                    var rs = JSON.parse(result);
                    if (rs.status!==1) {
                        setPromoCodeerror(rs?.message);
                    }
                    else {
                        setPromoCodeSuccess(true);
                        setPromoDiscountAmount(rs?.discount_amount);
                    }
                })
                .catch(error => console.log('error', error));
        }
        else{
            setPromoCodeerror("Please Enter Promocode");
        }
    }
    const getPaymentIntent = async () => {
        setLoadingSecrete(true);
        let formdata = new FormData();
        formdata.append('type', 'stripe_payment_intent');
        formdata.append('receiptEmail', receiptEmail);
        formdata.append('notes',referenceChild);
        formdata.append('teamId', team._id);
        formdata.append("quantity", quantity);
        formdata.append('tip', tip);
        formdata.append('address', address);
        formdata.append('itemId', product._id);
        formdata.append("purchase_in_installment", payInInstallment ? 'Yes' : 'No');
        formdata.append("promocode",promocode);
        
        if(tip){
            formdata.append('tip_amount',tipAmount);
            formdata.append('transfer_tip_to',tipTransfer);
        }
        if(product.is_personalize){
            if(shirtNumber!==''){
                formdata.append('shirt_no', shirtNumber);
            }
            if(shirtName!==''){
                formdata.append('shirt_name', shirtName);
            }
        }
        if(product.size!==undefined && product.size.length > 0 && selectedSize!==''){
            formdata.append('size',selectedSize);
        }
        if(product.sleeve!==undefined && product.sleeve.length > 0 && selectedSleeve!==''){
            formdata.append('sleeve',selectedSleeve);
        }
        if(product.color!==undefined && product.color.length > 0 && selectedColor!==''){
            formdata.append('color',selectedColor);
        }
        if(product.gender!==undefined && product.gender.length > 0 && selectedGender!==''){
            formdata.append('gender',selectedGender);
        }
        var secureHeader = new Headers();
        secureHeader.append('Authorization', 'Bearer ' + token);
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };
        await fetch(process.env.REACT_APP_APIURL + '/app_stripe_payment_intent', requestOptions)
            .then(response => response.text())
            .then(result => {
                setLoadingSecrete(false);
                var rs = JSON.parse(result);
                if (!rs?.data?.success) {
                    setError(rs?.message);
                }
                else {
                    setIntentId(rs?.data?.intent?.id);
                    setClientSecret(rs?.data?.intent?.client_secret)
                }
            })
            .catch(error => console.log('error', error));
    }

    const deleteProductAction=async()=>{
        setLoading(true);
        var formdata = new FormData();
        formdata.append("type", "delete_product");
        formdata.append("product_id",product._id);
        formdata.append("team_id", team._id);
        
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        await fetch(process.env.REACT_APP_APIURL + '/app_shop' , requestOptions)
        .then(response => response.text())
        .then(result => {
            setLoading(false);
            navigate('/team/' + team._id + '/shop');
        })
        .catch(error => console.log('error', error));
    }

    const requestGiftAction=()=>{
        let newState={sizeError:false, sleeveError:false, colorError:false, genderError:false, shirtNumberError:false, shirtNameError:false};
        let hasError = false;
        if(product.is_personalize){
            if(shirtNumber===''){
                hasError=true;
                newState.shirtNumberError=true;
            }
            if(shirtName===''){
                hasError=true;
                newState.shirtNameError=true;
            }
        }
        if(product.size!==undefined && product.size.length > 0){
            if(selectedSize===''){
                hasError=true;
                newState.sizeError=true;
            }
        }
        if(product.sleeve!==undefined && product.sleeve.length > 0){
            if(selectedSleeve===''){
                hasError=true;
                newState.sleeveError=true;
            }
        }
        if(product.color!==undefined && product.color.length > 0){
            if(selectedColor===''){
                hasError=true;
                newState.colorError=true;
            }
        }
        if(product.gender!==undefined && product.gender.length > 0){
            if(selectedGender===''){
                hasError=true;
                newState.genderError = true;
            }
        }        
        setErrorState(newState);

        if(!hasError){
            setRequestGiftPopup(true);
        }
    }

    const deleteProduct=()=>{
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                <div className='confirm-ui'>
                    <div className="font-semibold uppercase text-[22px] leading-[30px] text-center mb-5">PLAI</div>
                    <p className="text-center text-[14px] leading-[18px] mb-5">
                        There are prior orders associated with this item. Those prior records will remain intact. Are you sure you wish to delete this item from your shop, this item won't be recoverable once deleted.
                    </p>
                    <div className="flex justify-center">
                        <button className="button button-blue mx-1" onClick={onClose}>No</button>
                        <button className="button mx-1"
                        onClick={() => { 
                            deleteProductAction();                       
                            onClose();
                        }}
                        >
                        Yes
                        </button>
                    </div>
                </div>
                );
            }
        });
    }

    const increment = () => {
        setQuantity(prevquantity => product.quantity > prevquantity ? ++prevquantity : product.quantity);
    }

    const decrement = () => {
        setQuantity(prevquantity => prevquantity > 1 ? --prevquantity : 1);
    }

    /*const increment2 = () => {
        setQuantity(prevquantity => product.quantity > prevquantity ? ++prevquantity : product.quantity);
    }

    const decrement2 = () => {
        setQuantity(prevquantity => prevquantity > 1 ? --prevquantity : 1);
    }*/

    const handleChangeSize=(e)=>{
        if(e.target.value!==''){
            setErrorState({...errorState, sizeError:false});
        }
        setSelectedSize(e.target.value);
    }
    const handleChangeColor=(e)=>{
        if(e.target.value!==''){
            setErrorState({...errorState, colorError:false});
        }
        setSelectedColor(e.target.value);
    }
    const handleChangeSleeve=(e)=>{
        if(e.target.value!==''){
            setErrorState({...errorState, sleeveError:false});
        }
        setSelectedSleeve(e.target.value);
    }
    const handleChangeGender=(e)=>{
        if(e.target.value!==''){
            setErrorState({...errorState, genderError:false});
        }
        setSelectedGender(e.target.value);
    }
    const handleChnageShirtNumber=(e)=>{
        if(e.target.value!==''){
            setErrorState({...errorState, shirtNumberError:false});
        }
        setShirtNumber(e.target.value);
    }
    const handleChnageShirtName=(e)=>{
        if(e.target.value!==''){
            setErrorState({...errorState, shirtNameError:false});
        }
        setShirtName(e.target.value);
    }

    const calculateTransitionFeesfee=async()=>{
        setTransLoading(true);
        var amount = product.display_price * quantity;
        var transitionData = await getTransitionFees(team.currency_code, token, amount);
        setTransactionFee(transitionData.total_transaction);
        setTipAmount(transitionData.tip);
        setTransLoading(false);
    }
    const ref = useRef();
    const promoRef = useRef();
    useEffect(()=>{
        const checkIfClickedOutside = e => {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            if (showMoreOpt && ref.current && !ref.current.contains(e.target)) {
                setShowMoreOpt(false);
            }
        }
        document.addEventListener("mousedown", checkIfClickedOutside);      
        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    },[showMoreOpt]);
    
    const singleBuyNow=()=>{
        let newState={sizeError:false, sleeveError:false, colorError:false, genderError:false, shirtNumberError:false, shirtNameError:false};
        let hasError = false;
        if(product.is_personalize){
            if(shirtNumber===''){
                hasError=true;
                newState.shirtNumberError=true;
            }
            if(shirtName===''){
                hasError=true;
                newState.shirtNameError=true;
            }
        }
        if(product.size!==undefined && product.size.length > 0){
            if(selectedSize===''){
                hasError=true;
                newState.sizeError=true;
            }
        }
        if(product.sleeve!==undefined && product.sleeve.length > 0){
            if(selectedSleeve===''){
                hasError=true;
                newState.sleeveError=true;
            }
        }
        if(product.color!==undefined && product.color.length > 0){
            if(selectedColor===''){
                hasError=true;
                newState.colorError=true;
            }
        }
        if(product.gender!==undefined && product.gender.length > 0){
            if(selectedGender===''){
                hasError=true;
                newState.genderError = true;
            }
        }        
        setErrorState(newState);
        if(!hasError){
            if(payInInstallment){
                checkPreviousInstallmentActiveForSingle();                
            }
            else{
                setIsBuySingle(true);
            }            
        }
    }

    const checkPreviousInstallmentActiveForSingle=async()=>{
        setSingleBuyloading(true);
        var installmentData = await checkInstallmentPlanActive(token, team._id, product._id);
        setSingleBuyloading(false);
        if(installmentData.status===2){
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                    <div className='confirm-ui !max-w-[500px]'>
                        <div className="font-semibold uppercase text-[22px] leading-[30px] text-center mb-5">PLAI</div>
                        <div className='text-center mb-5' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(installmentData.message.replace(/\n/g, "<br />")) }} />
                        <div className="flex justify-center">
                            <button className="button button-blue mx-1" onClick={() => {
                                onClose();
                            }}>Cancel</button>
                            <button className="button mx-1"
                            onClick={() => {
                                setIsBuySingle(true);
                                onClose();
                            }}
                            >
                            Yes
                            </button>
                        </div>
                    </div>
                    );
                }
            });
        }        
    }

    const addToBasket=()=>{
        let newState={sizeError:false, sleeveError:false, colorError:false, genderError:false, shirtNumberError:false, shirtNameError:false};
        let hasError = false;
        if(product.is_personalize){
            if(shirtNumber===''){
                hasError=true;
                newState.shirtNumberError=true;
            }
            if(shirtName===''){
                hasError=true;
                newState.shirtNameError=true;
            }
        }
        if(product.size!==undefined && product.size.length > 0){
            if(selectedSize===''){
                hasError=true;
                newState.sizeError=true;
            }
        }
        if(product.sleeve!==undefined && product.sleeve.length > 0){
            if(selectedSleeve===''){
                hasError=true;
                newState.sleeveError=true;
            }
        }
        if(product.color!==undefined && product.color.length > 0){
            if(selectedColor===''){
                hasError=true;
                newState.colorError=true;
            }
        }
        if(product.gender!==undefined && product.gender.length > 0){
            if(selectedGender===''){
                hasError=true;
                newState.genderError = true;
            }
        }
        
        setErrorState(newState);
        
        if(!hasError){
            if(payInInstallment)   {
                checkPreviousInstallmentActive();
            }
            else{
                addToBasketAction();
            }
        }
    }

    const checkPreviousInstallmentActive=async()=>{
        setAddtoCartLoading(true);
        var installmentData = await checkInstallmentPlanActive(token, team._id, product._id);
        setAddtoCartLoading(false);
        if(installmentData.status===2){
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                    <div className='confirm-ui !max-w-[500px]'>
                        <div className="font-semibold uppercase text-[22px] leading-[30px] text-center mb-5">PLAI</div>
                        <div className='text-center mb-5' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(installmentData.message.replace(/\n/g, "<br />")) }} />
                        <div className="flex justify-center">
                            <button className="button button-blue mx-1" onClick={() => {
                                onClose();
                            }}>Cancel</button>
                            <button className="button mx-1"
                            onClick={() => {
                                addToBasketAction();
                                onClose();
                            }}
                            >
                            Yes
                            </button>
                        </div>
                    </div>
                    );
                }
            });
        }        
    }

    const addToBasketAction=async()=>{
        setAddtoCartLoading(true);
        setIsAdded(false);
        var formdata = new FormData();
        formdata.append("type", "add_to_cart");
        formdata.append("product_id", product._id);
        formdata.append("team_id", team._id);
        formdata.append("quantity", quantity);
        formdata.append("purchase_in_installment", payInInstallment ? 'Yes' : 'No');

        if(product.is_personalize){
            if(shirtNumber!==''){
                formdata.append('shirt_no', shirtNumber);
            }
            if(shirtName!==''){
                formdata.append('shirt_name', shirtName);
            }
        }
        if(product.size!==undefined && product.size.length > 0 && selectedSize!==''){
            formdata.append('size',selectedSize);
        }
        if(product.sleeve!==undefined && product.sleeve.length > 0 && selectedSleeve!==''){
            formdata.append('sleeve',selectedSleeve);
        }
        if(product.color!==undefined && product.color.length > 0 && selectedColor!==''){
            formdata.append('color',selectedColor);
        }
        if(product.gender!==undefined && product.gender.length > 0 && selectedGender!==''){
            formdata.append('gender',selectedGender);
        }

        var secureHeader = new Headers();
        secureHeader.append('Authorization', 'Bearer ' + token);
        secureHeader.append('device_type', 'W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        await fetch(process.env.REACT_APP_APIURL + '/app_checkout', requestOptions)
            .then(response => response.text())
            .then(result => {
                setAddtoCartLoading(false);
                setIsAdded(true);
            })
            .catch(error => console.log('error', error));
    }

    useEffect(() => {
        if (isBuySingle) {
            calculateTransitionFeesfee();
        }
    }, [isBuySingle, quantity]);

    useEffect(() => {
        if (!teamUpdating) {
            refreshProduct();
        }
        else {
            setLoading(true);
        }
    }, [teamUpdating]);
    return (
        !loading ?
            <>
                {
                    infoPopup &&
                    <div className="overlay" onClick={() => {
                        setInfoPopup(false);
                    }}></div>
                }
                <div className='breadcrumb flex item-center pt-1.5 pb-1 pl-5 pr-7 w-full'>
                    <NavLink to="/" className='home'>
                        <span>Dashboard</span>
                    </NavLink>
                    <span className='separator'>&lt;</span>
                    <NavLink to={`/team/${team._id}/shop`}>
                        Shop
                    </NavLink>
                    <span className='separator'>&lt;</span>
                    {product.name}
                </div>
                <div className="body px-5 lg:px-8 py-10">
                    <div className="product-contents rounded-xl bg-white p-7.5">
                        <div className='flex flex-wrap items-start'>
                            <div className='w-full md:w-1/2 relative mb-5 md:mb-0 md:pr-5 lg:pr-10'>
                                <div className='image-wrapper max-w-[430px] mx-auto'>
                                    {product.image !== '' ?
                                        <img src={product.image} alt="" className='object-cover rounded-[14px]' />
                                        :
                                        <img src={placeholderImg} alt="" className='object-cover rounded-[14px]' />
                                    }
                                </div>
                            </div>
                            <div className='w-full md:w-1/2'>
                                <div className='product-info-head flex flex-wrap justify-between mb-5'>
                                    <h1>{product.name}</h1>                                    
                                    {(team.role==='admin' || (team.role==='member' && team.admin_groups_new !== undefined && team.admin_groups_new.length>0)) &&
                                        <div className='product-cta mb-[14px] relative' ref={ref}>
                                            <button type="button" className="more-cta rounded-full bg-[#FBDB09] w-[44px] h-[44px] flex items-center justify-center mr-4 hover:bg-white" onClick={(e)=>{setShowMoreOpt(!showMoreOpt);}}>
                                                <svg width="6" height="21" viewBox="0 0 6 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M3.16615 5.19413C4.46227 5.19413 5.51297 4.14342 5.51297 2.84731C5.51297 1.55119 4.46227 0.500488 3.16615 0.500488C1.87004 0.500488 0.819336 1.55119 0.819336 2.84731C0.819336 4.14342 1.87004 5.19413 3.16615 5.19413Z" fill="#363F6C"/>
                                                    <path d="M3.16615 12.8472C4.46227 12.8472 5.51297 11.7965 5.51297 10.5004C5.51297 9.20427 4.46227 8.15356 3.16615 8.15356C1.87004 8.15356 0.819336 9.20427 0.819336 10.5004C0.819336 11.7965 1.87004 12.8472 3.16615 12.8472Z" fill="#363F6C"/>
                                                    <path d="M3.16615 20.5005C4.46227 20.5005 5.51297 19.4498 5.51297 18.1537C5.51297 16.8576 4.46227 15.8069 3.16615 15.8069C1.87004 15.8069 0.819336 16.8576 0.819336 18.1537C0.819336 19.4498 1.87004 20.5005 3.16615 20.5005Z" fill="#363F6C"/>
                                                </svg>
                                            </button>
                                            <ul className={`sort-actions w-[175px] text-[14px] leading-[18px] text-black absolute overflow-hidden rounded-lg bg-white top-full right-0 ${!showMoreOpt ? 'hidden' : ''}`}>
                                                <li><div className='cursor-pointer py-1.5' onClick={(e) => {navigate('/team/' + team._id + '/shop/' + product._id + '/orders', {
                                                    state: {
                                                        product: product
                                                    }
                                                });}}>Orders</div></li>
                                                <li><div className='cursor-pointer py-1.5' onClick={(e) => {setOpenCloneForm(true); setShowMoreOpt(false);}}>Duplicate</div></li>
                                                <li><div className='cursor-pointer py-1.5' onClick={(e) => {setOpenForm(true); setShowMoreOpt(false);}}>Edit</div></li>                                                
                                                <li><div className='cursor-pointer py-1.5' onClick={(e) => {setShowMoreOpt(false); deleteProduct()}}>Delete</div></li>
                                            </ul>
                                        </div>
                                    }                                    
                                    <div className='product-price w-full'>
                                        <span>{team.currency_symbol}{(product.display_price_with_transactionfee / 100).toFixed(2)}</span>
                                    </div>
                                </div>
                                {product.description !== "" ?
                                    <div className='product-detail pb-7 mb-7' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(product.description) }} />
                                    :
                                    null
                                }
                                <hr className='mb-8' />
                                {!clientSecret && !isBuySingle &&
                                    <>
                                    <div className='flex flex justify-between items-center w-full mb-8 max-w-[448px]'>
                                        <span className="mr-5">Select Quantity</span>
                                        <span className="flex-1 h-px mr-5 bg-[#6E7079]"></span>
                                        <div className="quantity-field flex justify-center items-center">
                                            <button className="quantity-btn quantity-minus" onClick={decrement}></button>
                                            <input className="quantity-input" type="text" value={quantity} readOnly />
                                            <button className="quantity-btn quantity-plus" onClick={increment}></button>
                                        </div>
                                    </div>
                                    {product.size!==undefined && product.size.length>0 &&
                                    <>
                                    <div className='flex flex justify-between items-center w-full mb-8 max-w-[448px]'>
                                        <span className="mr-5">Select Size</span>
                                        <span className="flex-1 h-px mr-5 bg-[#6E7079]"></span>
                                        <select name="size" className='!bg-white !w-31 border border-black !pl-2.5 !pr-10 !py-2 !text-sm !rounded-xl' value={selectedSize||''} onChange={(e)=>{handleChangeSize(e)}}>
                                            <option value="">Select Size</option>
                                            {product.size.map((size,index)=>{
                                                return(
                                                    <option key={index} value={size}>{size}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                    {errorState.sizeError && <p className='error text-red-600 mb-2.5 -mt-5 text-sm'>Please select Size</p>}
                                    </>
                                    }
                                    {product.sleeve!==undefined && product.sleeve.length>0 &&
                                    <>
                                    <div className='flex flex justify-between items-center w-full mb-8 max-w-[448px]'>
                                        <span className="mr-5">Select Sleeve</span>
                                        <span className="flex-1 h-px mr-5 bg-[#6E7079]"></span>
                                        <select name="sleeve" className='!bg-white !w-31 border border-black !pl-2.5 !pr-10 !py-2 !text-sm !rounded-xl' value={selectedSleeve||''} onChange={(e)=>{handleChangeSleeve(e)}}>
                                            <option value="">Select Sleeve</option>
                                            {product.sleeve.map((sleeve,index)=>{
                                                return(
                                                    <option key={index} value={sleeve}>{sleeve}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                    {errorState.sleeveError && <p className='error text-red-600 mb-2.5 -mt-5 text-sm'>Please select Sleeve</p>}
                                    </>
                                    }
                                    {product.color!==undefined && product.color.length>0 &&
                                    <>
                                    <div className='flex flex justify-between items-center w-full mb-8 max-w-[448px]'>
                                        <span className="mr-5">Select Color</span>
                                        <span className="flex-1 h-px mr-5 bg-[#6E7079]"></span>
                                        <select name="color" className='!bg-white !w-31 border border-black !pl-2.5 !pr-10 !py-2 !text-sm !rounded-xl' value={selectedColor||''} onChange={(e)=>{handleChangeColor(e)}}>
                                            <option value="">Select Color</option>
                                            {product.color.map((color,index)=>{
                                                return(
                                                    <option key={index} value={color}>{color}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                    {errorState.colorError && <p className='error text-red-600 mb-2.5 -mt-5 text-sm'>Please select Color</p>}
                                    </>
                                    }
                                    {product.gender!==undefined && product.gender.length>0 &&
                                    <>
                                    <div className='flex flex justify-between items-center w-full mb-8 max-w-[448px]'>
                                        <span className="mr-5">Select Gender</span>
                                        <span className="flex-1 h-px mr-5 bg-[#6E7079]"></span>
                                        <select name="gender" className='!bg-white !w-31 border border-black !pl-2.5 !pr-10 !py-2 !text-sm !rounded-xl' value={selectedGender||''} onChange={(e)=>{handleChangeGender(e)}}>
                                            <option value="">Select Gender</option>
                                            {product.gender.map((gender,index)=>{
                                                return(
                                                    <option key={index} value={gender}>{gender}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                    {errorState.genderError && <p className='error text-red-600 mb-2.5 -mt-5 text-sm'>Please select Gender</p>}
                                    </>
                                    }
                                    {product.is_personalize!==undefined && product.is_personalize &&
                                    <>
                                        <div className='mb-8 max-w-[448px]'>
                                            <span className="mb-1.5 block">Shirt Number</span>
                                            <input type="text" name="shirt_number" maxLength="3" value={shirtNumber||''} className='!bg-white border border-black !px-2.5 !py-2 !text-sm !rounded-xl' onChange={(e)=>handleChnageShirtNumber(e)} />
                                        </div>
                                        {errorState.shirtNumberError && <p className='error text-red-600 mb-2.5 -mt-5 text-xs'>Please enter Shirt Number</p>}
                                        <div className='mb-8 max-w-[448px]'>
                                            <span className="mb-1.5 block">Shirt Name</span>
                                            <input type="text" name="shirt_name" value={shirtName||''} className='!bg-white border border-black !px-2.5 !py-2 !text-sm !rounded-xl' onChange={(e)=>handleChnageShirtName(e)} />
                                        </div>
                                        {errorState.shirtNameError && <p className='error text-red-600 mb-2.5 -mt-5 text-xs'>Please enter Shirt Name</p>}
                                    </>
                                    }
                                    </>
                                }
                                {isBuySingle &&
                                    <>
                                    {!loadingTrans ?
                                        <>
                                        <div className='flex flex justify-between items-center w-full mb-8 max-w-[448px]'>
                                            <span className="mr-5">Quantity</span>
                                            <span className="flex-1 h-px mr-5 bg-[#6E7079]"></span>
                                            <div className="font-medium">{quantity}</div>
                                        </div>
                                        {selectedSize!=='' &&
                                        <div className='flex flex justify-between items-center w-full mb-8 max-w-[448px]'>
                                            <span className="mr-5">Size</span>
                                            <span className="flex-1 h-px mr-5 bg-[#6E7079]"></span>
                                            <div className="font-medium">{selectedSize}</div>
                                        </div>
                                        }
                                        {selectedSleeve!=='' &&
                                        <div className='flex flex justify-between items-center w-full mb-8 max-w-[448px]'>
                                            <span className="mr-5">Sleeve</span>
                                            <span className="flex-1 h-px mr-5 bg-[#6E7079]"></span>
                                            <div className="font-medium">{selectedSleeve}</div>
                                        </div>
                                        }
                                        {selectedColor!=='' &&
                                        <div className='flex flex justify-between items-center w-full mb-8 max-w-[448px]'>
                                            <span className="mr-5">Color</span>
                                            <span className="flex-1 h-px mr-5 bg-[#6E7079]"></span>
                                            <div className="font-medium">{selectedColor}</div>
                                        </div>
                                        }
                                        {selectedGender!=='' &&
                                        <div className='flex flex justify-between items-center w-full mb-8 max-w-[448px]'>
                                            <span className="mr-5">Gender</span>
                                            <span className="flex-1 h-px mr-5 bg-[#6E7079]"></span>
                                            <div className="font-medium">{selectedGender}</div>
                                        </div>
                                        }
                                        {product.is_personalize!==undefined && product.is_personalize &&
                                        <>
                                            {shirtNumber!=='' &&
                                                <div className='mb-8 max-w-[448px]'>
                                                    <span className="mb-1.5 block">Shirt Number</span>
                                                    <div className="font-medium">{shirtNumber}</div>
                                                </div>
                                            }
                                            {shirtName!=='' &&
                                                <div className='mb-8 max-w-[448px]'>
                                                    <span className="mb-1.5 block">Shirt Name</span>
                                                    <div className="font-medium">{shirtName}</div>
                                                </div>
                                            }
                                        </>
                                        }
                                        </>
                                        :
                                        <>
                                            <img src="data:image/svg+xml,%3Csvg version='1.1' id='loader-1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='20px' height='20px' viewBox='0 0 40 40' enable-background='new 0 0 40 40' xml:space='preserve'%3E%3Cpath opacity='0.2' fill='%23000' d='M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z'%3E%3C/path%3E%3Cpath fill='%23000' d='M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 20 20' to='360 20 20' dur='0.5s' repeatCount='indefinite'%3E%3C/animateTransform%3E%3C/path%3E%3C/svg%3E" width={30} height={30} alt="" />
                                        </>
                                    }
                                    </>
                                }
                                {!clientSecret && product?.allow_installment &&
                                    <>
                                        <hr className='mb-8' />
                                        <div className='flex flex justify-between items-center w-full mb-8 max-w-[448px]'>
                                            <div className="mr-5  flex">
                                                <p className='mr-2 text-[#363F6C]'>You can pay in Installments</p> <span className='cursor-pointer relative'>
                                                    <img src={InfoIcon} alt="" id="installment-info"/>
                                                </span>
                                                <Tooltip anchorSelect="#installment-info">
                                                    <div className="w-[280px]">
                                                        <h3 className="uppercase text-[22px] leading-[33px] font-semibold border-b border-b-[#FBDB03] border-b-solid text-center">PLAI INSTALMENT PLANS</h3>
                                                        <div className="mb-4 text-center text-[14px] leading-[19px]">
                                                            PLAI are passionate about removing barriers between people and sport.Sport is for everybody, cost should not be a barrier to entry.You can now break down your team purchase into more manageable chunks, by choosing the PLAI Payment Plan!
                                                        </div>
                                                    </div>
                                                </Tooltip>
                                            </div>
                                            <button className='text-[#363F6C]' onClick={() => {
                                                setShowInstallmentDetails(!showInstallmentDetails);
                                            }}>{showInstallmentDetails ? 'Hide' : 'View'}</button>
                                        </div>
                                        {
                                            showInstallmentDetails &&
                                            <div className='mb-8'>
                                                {
                                                    product?.installments?.map((installment, index) => {
                                                        return(
                                                            <div className='flex justify-between w-full max-w-[448px] text-sm my-1.5' key={index}>
                                                                <p className=''>{installment.title}</p>
                                                                <div className='flex'>
                                                                    <p className='mr-2'>{index===0 ? 'Today' : (installment.date!=="" ? moment(installment.date).format('Do MMM'): "")}</p>
                                                                    <p>{team.currency_symbol}{Number(installment.price / 100).toFixed(2)}</p>
                                                                </div>
                                                            </div>
                                                        );
                                                    })
                                                }
                                                <span className='italic text-[#363F6C] text-xs underline cursor-pointer'>Instalment Plan above is inclusive of the Instalment Plan Activation Fee.</span>
                                            </div>
                                        }
                                        <hr className='mb-8' />
                                        <div className='flex flex justify-between items-center w-full mb-8 max-w-[448px]'>
                                            <div className="mr-5  flex"><p className='mr-2'>Pay in Instalments?</p></div>
                                            <div className='flex'>
                                                <input className={`button button-medium installment-button mr-1 ${payInInstallment ? 'btn-disable' : ''}`} type="button" value="No" onClick={() => {
                                                    setPayInInstallment(false);
                                                    setSingleBuyEmi(null);
                                                }} />
                                                <input className={`button button-medium installment-button ml-1 ${!payInInstallment ? 'btn-disable' : ''}`} type="button" value="Yes" onClick={() => {
                                                    setPayInInstallment(true);
                                                    setSingleBuyEmi(product?.installments[0]?.price);
                                                }} />
                                            </div>
                                        </div>
                                    </>
                                }
                                {!isBuySingle &&
                                    <>
                                        <div className='mb-4 flex justify-center max-w-[442px]'>
                                            <button className='text-sm font-medium uppercase text-[var(--color-darkblue)] underline hover:no-underline' onClick={()=>{requestGiftAction();}}>Request As A Gift</button>
                                        </div>
                                        <div className='product-buy-actions text-center md:text-left flex flex-wrap items-center'>
                                            {!singleBuyloading ?
                                                <button type="button" className='product-button product-button-yellow mr-2.5 mb-5' onClick={() => {
                                                    singleBuyNow();
                                                }}>Buy Now</button>
                                            :
                                                <img src="data:image/svg+xml,%3Csvg version='1.1' id='loader-1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='20px' height='20px' viewBox='0 0 40 40' enable-background='new 0 0 40 40' xml:space='preserve'%3E%3Cpath opacity='0.2' fill='%23000' d='M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z'%3E%3C/path%3E%3Cpath fill='%23000' d='M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 20 20' to='360 20 20' dur='0.5s' repeatCount='indefinite'%3E%3C/animateTransform%3E%3C/path%3E%3C/svg%3E" width={40} height={40} alt="" />
                                            }
                                            {!addToCartloading ?
                                                <button type="button" className='product-button product-button-yellow mb-5' onClick={() => { addToBasket() }}>Add to Basket</button>
                                                :
                                                <img src="data:image/svg+xml,%3Csvg version='1.1' id='loader-1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='20px' height='20px' viewBox='0 0 40 40' enable-background='new 0 0 40 40' xml:space='preserve'%3E%3Cpath opacity='0.2' fill='%23000' d='M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z'%3E%3C/path%3E%3Cpath fill='%23000' d='M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 20 20' to='360 20 20' dur='0.5s' repeatCount='indefinite'%3E%3C/animateTransform%3E%3C/path%3E%3C/svg%3E" width={40} height={40} alt="" />
                                            }
                                            {isAdded &&
                                                <div className='text-left p-3.5 rounded-[14px] text-white bg-[#363F6C] ml-5'>
                                                    Product added to basket.<br /><a className='button button-action inline-block my-1.5' href={`/team/${team._id}/shop`}>Continue shop</a> or <a className='button button-action inline-block my-1.5' href={`/team/` + team._id + '/shop/mycart'}>Proceed to Checkout</a>
                                                </div>
                                            }
                                        </div>
                                        {requestGiftPopup &&
                                            <RequestGiftPopup setShowForm={setRequestGiftPopup} product={product} teamID={team._id} token={token} size={selectedSize} color={selectedColor} sleeve={selectedSleeve} gender={selectedGender} shirtName={shirtName} shirtNumber={shirtNumber} />
                                        }
                                    </>
                                }
                                {isBuySingle ?
                                    <>
                                        {!clientSecret &&
                                            <>
                                                <div className='cart-detail mb-5'>
                                                    {/*
                                                        <div className='cart-detail-row flex justify-between pb-2.5 mb-2.5'>
                                                            <div className='cart-detail-label'>Item Total</div>
                                                            <div className='cart-detail-value'>{team.currency_symbol}{((product.display_price * quantity) / 100).toFixed(2)}</div>
                                                        </div>
                                                        <div className='cart-detail-row flex justify-between pb-2.5 mb-2.5'>
                                                            <div className='cart-detail-label'>Transaction Fee</div>
                                                            <div className='cart-detail-value'>
                                                                {!loading ?
                                                                <>
                                                                {team.currency_symbol}{(transactionFee / 100).toFixed(2)}
                                                                </>
                                                                :
                                                                <img src="data:image/svg+xml,%3Csvg version='1.1' id='loader-1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='20px' height='20px' viewBox='0 0 40 40' enable-background='new 0 0 40 40' xml:space='preserve'%3E%3Cpath opacity='0.2' fill='%23000' d='M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z'%3E%3C/path%3E%3Cpath fill='%23000' d='M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 20 20' to='360 20 20' dur='0.5s' repeatCount='indefinite'%3E%3C/animateTransform%3E%3C/path%3E%3C/svg%3E" width={12} height={12} alt="" />
                                                                }
                                                            </div>
                                                        </div>
                                                    */}
                                                    <div className='text-left p-3.5 rounded-[14px] text-white bg-[#363F6C] mb-5'>
                                                        <div className='flex justify-between justify-center'>
                                                            <div className="uppercase">Add A Tip : <span className='text-[#FBDB09]'>{team.currency_symbol}{(tipAmount / 100).toFixed(2)}</span></div>
                                                            <div>
                                                                <label className="relative inline-flex items-center cursor-pointer">
                                                                    <input type="checkbox" value="" className="sr-only peer" onChange={()=>{setTip(!tip)}} checked={tip ? true : false} />
                                                                    <div className="w-11 h-6 bg-[rgba(255,255,255,0.1)] peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white peer-checked:after:bg-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#FBDB09]"></div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        {tip &&
                                                            <div className='flex'>
                                                                <div className="flex items-center mr-4">
                                                                    <input id="tip-club" type="radio" value="club" name="transfer_tip_to" onChange={(e)=>{setTipTransfer('club');}} className="w-4 h-4 text-white bg-white border-white" checked={tipTransfer==='club' ? true : false} />
                                                                    <label for="tip-club" className="ml-2 mb-0 text-[12px] text-white">Tip your CLUB</label>
                                                                </div>
                                                                <div className="flex items-center">
                                                                    <input id="tip-donate" type="radio" value="charity" name="transfer_tip_to" onChange={(e)=>{setTipTransfer('charity');}} className="w-4 h-4 text-white bg--white border-white" checked={tipTransfer==='charity' ? true : false} />
                                                                    <label for="tip-donate" className="ml-2 mb-0 text-[12px] text-white inline-flex">Make a DONATION <span className='cursor-pointer relative ml-2.5'><img src={InfoIconWhite} alt="" id="tip-info"/></span></label>
                                                                        <Tooltip anchorSelect="#tip-info" className='z-[10000]'>
                                                                            <div className="w-[280px]">
                                                                                <h3 className="uppercase text-[22px] leading-[33px] font-semibold border-b border-b-[#FBDB03] border-b-solid text-center">PLAI</h3>
                                                                                <div className="mb-4 text-center text-[12px] leading-[19px]">
                                                                                    <p className='mb-2.5'>The PLAIground foundation seeks to change lives through the vehicle of sport.</p>
                                                                                    <p className='mb-2.5'>A registered charity, the foundation commits to using the kind donations from the PLAI sport family to build sporting facilities in low socioeconomic communities that are in desperate need of support.</p>
                                                                                    <p className='mb-2.5'>The vision, is to use the vehicle of sport to keep children off the streets and away from trouble, instead investing energy and vision into excelling in sport.</p>
                                                                                    <p>Round up your purchase today and help us to change as many young lives as possible.</p>
                                                                                </div>
                                                                            </div>
                                                                        </Tooltip>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                    {promoDiscountAmount!==0 &&
                                                        <div className='cart-detail-row flex justify-between pb-2.5 mb-2.5'>
                                                            <div className='cart-detail-label'>Discount</div>
                                                            <div className='cart-detail-value'>
                                                                {team.currency_symbol}{(promoDiscountAmount / 100).toFixed(2)}
                                                            </div>
                                                        </div>
                                                    }
                                                    <div className='cart-detail-row flex justify-between pb-2.5'>
                                                        <div className='cart-detail-label'>
                                                            Total
                                                        </div>
                                                        <div className='cart-detail-value'>
                                                            {!loading ?
                                                                <>
                                                                    {payInInstallment ? 
                                                                        <>
                                                                            {tip ?
                                                                                <>
                                                                                    {team.currency_symbol}{(((singleBuyEmi * quantity) + transactionFee + tipAmount - promoDiscountAmount) / 100).toFixed(2)}
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    {team.currency_symbol}{(((singleBuyEmi * quantity) + transactionFee - promoDiscountAmount) / 100).toFixed(2)}
                                                                                </>
                                                                            }
                                                                        </>
                                                                        :
                                                                        <>
                                                                            {tip ?
                                                                                <>
                                                                                    {team.currency_symbol}{(((product.display_price * quantity) + transactionFee + tipAmount - promoDiscountAmount) / 100).toFixed(2)}
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    {team.currency_symbol}{(((product.display_price * quantity) + transactionFee - promoDiscountAmount) / 100).toFixed(2)}
                                                                                </>
                                                                            }
                                                                        </>
                                                                    }
                                                                    <p className='text-[10px] leading-[12px] font-light'>inclusive of all fees and taxes</p>
                                                                </>
                                                                :
                                                                <img src="data:image/svg+xml,%3Csvg version='1.1' id='loader-1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='20px' height='20px' viewBox='0 0 40 40' enable-background='new 0 0 40 40' xml:space='preserve'%3E%3Cpath opacity='0.2' fill='%23000' d='M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z'%3E%3C/path%3E%3Cpath fill='%23000' d='M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 20 20' to='360 20 20' dur='0.5s' repeatCount='indefinite'%3E%3C/animateTransform%3E%3C/path%3E%3C/svg%3E" width={12} height={12} alt="" />
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className='flex justify-between items-center gap-4 mt-5'>
                                                        <input type='text' defaultValue={''} onChange={(e)=>{setPromocode(e.target.value)}} placeholder='Enter Discount Code' className='!pl-4' ref={promoRef} readOnly={promoCodeSuccess ? true : false} />
                                                        {promoCodeSuccess ?
                                                        <>                                                            
                                                            <button type='button' className='button button-blue' onClick={()=>{setPromoCodeSuccess(false); setPromoDiscountAmount(0); setPromocode(''); promoRef.current.value=''}}>Remove</button>
                                                        </>                                                            
                                                        :
                                                        <>
                                                            {!promoloading ?
                                                                <button type='button' className='button button-blue' onClick={()=>{applyPromoCode()}}>Apply</button>
                                                                :
                                                                <img src="data:image/svg+xml,%3Csvg version='1.1' id='loader-1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='20px' height='20px' viewBox='0 0 40 40' enable-background='new 0 0 40 40' xml:space='preserve'%3E%3Cpath opacity='0.2' fill='%23000' d='M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z'%3E%3C/path%3E%3Cpath fill='%23000' d='M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 20 20' to='360 20 20' dur='0.5s' repeatCount='indefinite'%3E%3C/animateTransform%3E%3C/path%3E%3C/svg%3E" width={12} height={12} alt="" />
                                                            }
                                                        </>                                                            
                                                        }
                                                    </div>
                                                    {promoCodeError!==null && <p className="error text-red-600 mb-2.5">{promoCodeError}</p>}
                                                </div>
                                                <div className='cart-form'>
                                                    <div className='form-field-wrap mb-5 email-field-wrap relative'>
                                                        <input
                                                            type="email" placeholder="Your Email Address" className='email-field'
                                                            value={userDetail?.user?.user}
                                                            onChange={(e) => {
                                                                setReceiptEmail(e.target.value)
                                                            }}
                                                        />
                                                    </div>
                                                    <div className='form-field-wrap mb-5 location-field-wrap relative'>
                                                        <input
                                                            type="text" placeholder="Your Address" className='text-field'
                                                            onChange={(e) => {
                                                                setAddress(e.target.value)
                                                            }}
                                                        />
                                                    </div>
                                                    <div className='form-field-wrap mb-5 no-field-wrap relative'>
                                                        <input
                                                            type="text" placeholder="Reference... e.g. Child's Name" className='text-field'
                                                            onChange={(e) => {
                                                                setReferenceChild(e.target.value)
                                                            }}
                                                        />
                                                    </div>                                                    
                                                    {error !== '' &&
                                                        <p className="error text-red-600 mb-2.5">{error}</p>
                                                    }
                                                    {!loading &&
                                                        <button type='button' className='product-button product-button-yellow' disabled={loadingSecrete} onClick={() => getPaymentIntent()}>
                                                            {!loadingSecrete ?
                                                                'Proceed to payment'
                                                                :
                                                                'Wait'
                                                            }
                                                        </button>
                                                    }
                                                </div>
                                            </>
                                        }
                                        {clientSecret &&
                                            <>
                                                <div className='cart-detail mb-5'>
                                                    {promoDiscountAmount!==0 &&
                                                        <div className='cart-detail-row flex justify-between pb-2.5 mb-2.5'>
                                                            <div className='cart-detail-label'>Discount</div>
                                                            <div className='cart-detail-value'>
                                                                {team.currency_symbol}{(promoDiscountAmount / 100).toFixed(2)}
                                                            </div>
                                                        </div>
                                                    }
                                                    <div className='cart-detail-row flex justify-between pb-2.5 mb-2.5'>
                                                        <div className='cart-detail-label'>{payInInstallment ? 'TO PAY TODAY' :  'Total'}</div>
                                                        <div className='cart-detail-value'>
                                                            {payInInstallment ? 
                                                            <>
                                                                {tip ?
                                                                    <>
                                                                        {team.currency_symbol}{(singleBuyEmi / 100).toFixed(2)} X {quantity} = {team.currency_symbol}{(((singleBuyEmi * quantity) + transactionFee + tipAmount) / 100).toFixed(2)}
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {team.currency_symbol}{(singleBuyEmi / 100).toFixed(2)} X {quantity} = {team.currency_symbol}{(((singleBuyEmi * quantity) + transactionFee) / 100).toFixed(2)}
                                                                    </>
                                                                }
                                                            </>
                                                            :
                                                            <>
                                                                {tip ?
                                                                    <>
                                                                        {team.currency_symbol}{(product.display_price / 100).toFixed(2)} X {quantity} = {team.currency_symbol}{(((product.display_price * quantity) + transactionFee + tipAmount) / 100).toFixed(2)}
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {team.currency_symbol}{(product.display_price / 100).toFixed(2)} X {quantity} = {team.currency_symbol}{(((product.display_price * quantity) + transactionFee) / 100).toFixed(2)}
                                                                    </>
                                                                }
                                                            </>
                                                            }
                                                            <p className='text-[10px] leading-[12px] font-light text-right'>inclusive of all fees and taxes</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="cart-form payment-form">
                                                    <CheckoutForm client_secret={clientSecret} intent_id={intentId} isSingle={true} />
                                                </div>
                                            </>
                                        }
                                    </>
                                    :
                                    <>
                                        {(product.users && product.users.length) > 0 &&
                                            <div className='article-groups flex flex-wrap items-center pt-10 mb-5'>
                                                <h3>Users</h3>
                                                {product.users && product.users.map((user, index) => {
                                                    return (
                                                        <div className="ml-2 mb-4 font-medium text-[14px] leading-[21px] py-[4px] px-[19px] rounded-[14px] bg-[#3139610d]" key={index}>{user.name}</div>
                                                    )
                                                })
                                                }
                                            </div>
                                        }
                                        {(product.documents !== undefined && product.documents.length > 0) &&
                                            <div className='documents'>
                                                <h3>Documents</h3>
                                                <ul className='flex flex-wrap'>
                                                    {product.documents.map((doc, index) => {
                                                        return (
                                                            <li key={index}>
                                                                <div className='mr-5 mb-5 w-[150px] h-[180px] relative'>
                                                                    <DocumentView doc={doc} />
                                                                    {(product.user_role === 'admin' || product.user_role === 'group_admin') &&
                                                                        <>
                                                                            <button type="button" className='button-tiny delete absolute top-[10px] right-[10px] rounded-full' onClick={() => { deletDocument(doc) }}></button>
                                                                        </>
                                                                    }
                                                                </div>
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                            </div>
                                        }
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {openForm &&
                    <ProductForm product={product} team={team} setShowForm={setOpenForm} refreshProduct={refreshProduct} />
                }
                {openCloneForm &&
                    <ProductCloneForm product={product} team={team} setShowForm={setOpenCloneForm} refreshProduct={refreshProduct} />
                }
            </>
            :
            <>
                <div className="py-5 text-center"><Spinner /></div>
            </>
    );
}

function ProductDetail() {
    const { id, productid } = useParams();

    return (
        <>
            <Sidebar type="thin" />
            <TeamContextProvider id={id}>
                <div id="page" className="page-product-detail">
                    <AdminNavbar heading="Product" />
                    <ProductView id={productid} />
                </div>
            </TeamContextProvider>
        </>
    )
}

export default ProductDetail;