import placeholderImg from '../../../assets/images/placeholder.png';

const AskedMemberRow = ({teamMember, setIsCheckMembers, isCheckMembers, askMembers, setIsCheckAllMembers}) =>{
    const handleClickMembers = e => {
        const { id, checked, value } = e.target;
        let tmpObj = {};
        Object.assign(tmpObj, JSON.parse(value));        
        setIsCheckMembers([...isCheckMembers, tmpObj]);
        
        let tmpList = [...isCheckMembers, tmpObj];
        if (!checked) {
            tmpList = isCheckMembers.filter((item) => {return !item._id.includes(id)});
            setIsCheckMembers(tmpList);
        }
        if(tmpList.length === askMembers.length){
            setIsCheckAllMembers(true);
        }
        else{
            setIsCheckAllMembers(false);
        }
    };

    return(
        <>
        <div className='w-full md:w-1/2 px-2'>
            <div className="flex justify-between items-center px-5 py-2.5 rounded-[16px] border-[#363F6C] border-[1px] mb-5">
                <div className="flex-1 flex items-center">
                    {teamMember.profilePicture !== '' ? 
                    <img src={teamMember.profilePicture} alt="" width={50} height={50} className="rounded-full cursor-pointer mr-4"/>
                    :
                    <img src={placeholderImg} alt="" width={50} height={50} className="rounded-full cursor-pointer mr-4"/>                  
                    }
                    <div className='uppercase'>{teamMember.name}</div>
                </div>
                <div className="w-[20px] flex items-center justify-end">
                    <label key={teamMember._id} className='text-[12px] mr-3 text-black font-light cursor-pointer'>
                        <input                    
                            type="checkbox"
                            name={teamMember._id}
                            id={teamMember._id}
                            value={JSON.stringify(teamMember)}
                            className='checkbox-field appearance-none h-4 w-4 rounded-sm bg-white mt-1 align-top float-left mr-2 cursor-pointer'
                            onChange={handleClickMembers}
                            checked={isCheckMembers.some((item)=>{ return item._id===teamMember._id })}
                        />
                    </label>
                </div>
            </div>
        </div>
        </>
    );
}

export default AskedMemberRow;