import { useState, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from "react-hook-form";
import * as yup from 'yup';
import dayjs from 'dayjs';
import ReactDatePicker from "react-datepicker";
import Spinner from '../../../components/Spinner/Spinner';
import { getProductsByCatg } from '../../../libs/dataFunctions';

const schema = yup.object({
    product_id: yup.string().required('Please select product.'),
    date: yup.string().required('Please select purchase date.'),
    membership_name: yup.string().when('product_id',{
        is:'new_product',
        then:yup.string().required('Please Enter Product Name.')
    }),
    price: yup.number().when('product_id',{
        is:'new_product',
        then:yup.number().min(2,"Amount should be 2 or more.").required('Enter price amount').typeError('Enter valid price amount')
    }),
    duration_of_membership: yup.string().when('product_id',{
        is:'new_product',
        then:yup.string().required('Please Select Duration Of Membership.')
    }),
    paid_through: yup
    .string()
    .required("Select an option."),
    other_paid_type: yup.string().when('paid_through',{
        is:'Other',
        then:yup.string().required('Please Enter Other Payment Type.')
    }),
}).required();

const schema2 = yup.object({
    date: yup.string().required('Please select purchase date.'),
    membership_name: yup.string().required('Please Enter Product Name.'),
    price: yup.number().min(2,"Amount should be 2 or more.").required('Enter price amount').typeError('Enter valid price amount'),
    duration_of_membership: yup.string().required('Please Select Duration Of Membership.'),
    paid_through: yup
    .string()
    .required("Select an option."),
    other_paid_type: yup.string().when('paid_through',{
        is:'Other',
        then:yup.string().required('Please Enter Other Payment Type.')
    }),
}).required();

const MembershipCardForm = ({setShowForm, team, teamMember}) =>{
    const [loading,setLoading] = useState(false);
    const [products, setProducts] = useState([]);
    const [apiResponseMessage, setApiResponseMessage] = useState(null);
    const [fetchError, setFetchError] = useState(null);
    const [paymentType, setPaymentType] = useState(null);
    const [productValue, setProductValue] = useState(null);

    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
        ? localStorage.getItem('auth_token')
        : '';

    const { register, handleSubmit, setValue, reset, watch, control, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });

    const { register: register2, setValue: setValue2, reset : reset2, watch: watch2, control: control2, formState: { errors: errors2 }, handleSubmit: handleSubmit2,} = useForm({
        resolver: yupResolver(schema2)
    });
    
    const onSubmit=async(data)=>{
        setLoading(true);
        setApiResponseMessage(null);

        var formdata = new FormData();
        formdata.append("type", "manual_add_membership_card");
        formdata.append("team_id", team._id);
        formdata.append("sub", teamMember.sub);
        if(teamMember.same_child_subs.length > 0){
            formdata.append("same_child_subs",teamMember.same_child_subs.join('@#'));
        }
        if(data.paid_through!=='Other'){
            formdata.append("paid_through", data.paid_through);
        }
        else{
            formdata.append("paid_through", data.other_paid_type);
        }

        if(data.product_id==='new_product'){
            formdata.append("is_new_product", "Yes");
            formdata.append("membership_name", data.membership_name);
            formdata.append("price", data.price * 100);
            formdata.append("duration_of_membership", data.duration_of_membership);
        }
        else{
            formdata.append("is_new_product", "No");
            formdata.append("product_id", data.product_id);
        }        
        formdata.append("date", dayjs(data.date).format("YYYY-MM-DD"));
        
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata, 
            headers: secureHeader
        };
        await fetch(process.env.REACT_APP_APIURL + '/app_shop' , requestOptions)
        .then(response => response.text())
        .then(result => {
            setLoading(false);
            let rdata = JSON.parse(result);
            
            if(rdata.status !== 1){
                setApiResponseMessage(rdata.message);
            }
            else{
                setApiResponseMessage(rdata.message);
                reset();
                //setShowForm(false);
            }
        })
        .catch(error => console.log('error', error));
    }

    const onSubmit2=async(data)=>{
        setLoading(true);
        setApiResponseMessage(null);
        var formdata = new FormData();
        formdata.append("type", "manual_add_membership_card");
        formdata.append("team_id", team._id);
        formdata.append("sub", teamMember.sub);
        if(teamMember.same_child_subs.length > 0){
            formdata.append("same_child_subs",teamMember.same_child_subs.join('@#'));
        }
        if(data.paid_through!=='Other'){
            formdata.append("paid_through", data.paid_through);
        }
        else{
            formdata.append("paid_through", data.other_paid_type);
        }

        formdata.append("is_new_product", "Yes");
        formdata.append("membership_name", data.membership_name);
        formdata.append("price", data.price * 100);

        formdata.append('duration_type', data.duration_type);
        if(data.duration_type==='variable'){
            formdata.append('duration_of_membership', data.duration_of_membership);
        }
        else{
            formdata.append('duration_of_membership', dayjs(data.duration_of_membership).format("YYYY-MM-DD"));
        }

        formdata.append("date", dayjs(data.date).format("YYYY-MM-DD"));
        
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata, 
            headers: secureHeader
        };
        await fetch(process.env.REACT_APP_APIURL + '/app_shop' , requestOptions)
        .then(response => response.text())
        .then(result => {
            setLoading(false);
            let rdata = JSON.parse(result);
            
            if(rdata.status !== 1){
                setApiResponseMessage(rdata.message);
            }
            else{
                setApiResponseMessage(rdata.message);
                reset();
                //setShowForm(false);
            }
        })
        .catch(error => console.log('error', error));
    }

    const fetchCatgProducts = async()=>{
        setLoading(true);
        const [productsData] = await Promise.all([
            getProductsByCatg(team._id,'Memberships',token),
        ]);
        if(productsData.data.length > 0){
            setProducts(productsData.data);       
        }
        else{
            setProducts([]); 
            setFetchError(productsData.message);
        }
        setLoading(false);
    }
    useEffect(()=>{
        fetchCatgProducts();
        setValue("duration_type",'fixed');
        setValue2("duration_type",'fixed');
    },[]);

    const watchMemDurationtype = watch("duration_type");
    const watchMemDurationtype2 = watch2("duration_type");

    return(
         
        <div className="popup-form">
            <div className="popup-form-wrap popup-form-memberdetails relative !px-2.5 sm:!px-8">                    
                <button type="button" className="close-popup" onClick={(e)=>{setShowForm(false);}}><span>close</span></button>
                <div className="bg-[#363F6C] py-7.5 px-5 rounded-xl">
                    <div className="form-heading text-center mb-[30px] !text-white">Add Membership Card</div>
                    {!loading ?
                        <>
                        {products.length > 0 ?
                            <form onSubmit={handleSubmit(onSubmit)} className="form-wrapper">
                                <div className="form-field-container mb-5">
                                    <div className="label">Select Product*</div>
                                    <div className='form-field-wrap select-field-wrap no-field-wrap relative'>
                                        <select {...register("product_id")} onChange={(e)=>{setValue('product_id', e.target.value); setProductValue(e.target.value);}}>
                                            <option value="">Select Product*</option>
                                            {products.map((product)=>{
                                                return(
                                                    <option value={product._id} key={product._id}>{product.name}</option>
                                                )
                                            })}
                                            <option value="new_product">Add New Product</option>
                                        </select>
                                    </div>
                                    {errors.product_id && <p className="error mb-2.5" role="alert">{errors.product_id?.message}</p>}
                                </div>
                                {productValue==='new_product' &&
                                <>
                                    <div className="form-field-container mb-5">
                                        <div className="label">Product Name*</div>
                                        <div className='form-field-wrap mb-5 no-field-wrap relative'>
                                            <input type='text' disabled={loading} placeholder="Product Name" className='text-field'
                                                {...register("membership_name")}
                                            />                                
                                        </div> 
                                        {errors.membership_name?.message && <p className="error mb-2.5" role="alert">{errors.membership_name?.message}</p> }                               
                                    </div>
                                    <div className="form-field-container mb-5">
                                        <div className="label">Product Price*</div>
                                        <div className='form-field-wrap mb-5 no-field-wrap relative'>
                                            <input type='text' disabled={loading} placeholder="e.g. 5" className='text-field'
                                                {...register("price")}
                                            />                                
                                        </div> 
                                        {errors.price?.message && <p className="error mb-2.5" role="alert">{errors.price?.message}</p> }                               
                                    </div>
                                    <div className="form-field-container mb-5">
                                        <div className="label mb-1.5">Duration Of Membership*</div>
                                        <div className="r-button-group w-[148px] flex">
                                            <div className="mr-4">
                                            <input type="radio" {...register("duration_type")} value="fixed" id="duration_type-fixed" className="peer hidden" 
                                                onChange={(e)=>{
                                                    setValue("duration_type",e.target.value);
                                                    setValue("duration_of_membership","");
                                                }}/>
                                            <label
                                                htmlFor="duration_type-fixed"
                                                className="block py-[5px] w-[90px] cursor-pointer select-none rounded-[42px] text-center bg-[#EFF1F9] peer-checked:bg-[#FBDB03] peer-checked:text-black"
                                                >Fixed</label>
                                            </div>
                                            <div>
                                            <input type="radio" {...register("duration_type")} value="variable" id="duration_type-variable" className="peer hidden" 
                                                onChange={(e)=>{
                                                    setValue("duration_type",e.target.value);
                                                    setValue("duration_of_membership","");
                                                }}
                                            />
                                            <label
                                                htmlFor="duration_type-variable"
                                                className="block py-[5px] w-[112px] cursor-pointer select-none rounded-[42px] text-center bg-[#EFF1F9] peer-checked:bg-[#FBDB03] peer-checked:text-black"
                                                >Variable</label>
                                            </div>
                                        </div>
                                    </div>
                                    {watchMemDurationtype==='variable' ?
                                        <div className="form-field-container mb-5">
                                            <div className="label">Expiry Duration*</div>
                                            <div className='form-field-wrap mb-5 select-field-wrap no-field-wrap relative'>
                                                <select {...register("duration_of_membership")} onChange={(e)=>{setValue('duration_of_membership', e.target.value, { shouldValidate: true });}}>
                                                    <option value="">Expiry Duration*</option>
                                                    <option value="7 Day">7 Day</option>
                                                    <option value="1 Month">1 Month</option>
                                                    <option value="3 Month">3 Month</option>
                                                    <option value="6 Month">6 Month</option>
                                                    <option value="1 Year">1 Year</option>
                                                </select>
                                            </div>
                                            {errors.duration_of_membership?.message && <p className="error mb-2.5" role="alert">{errors.duration_of_membership?.message}</p> }
                                        </div>
                                        :
                                        <div className="form-field-container">
                                            <div className="label">Expiry Date*</div>
                                            <div className='form-field-wrap mb-5 select-field-wrap date-field-wrap relative'>
                                                <Controller
                                                    control={control}
                                                    name='duration_of_membership'
                                                    errors={errors}
                                                    render={({ field, ref }) => (
                                                        <ReactDatePicker
                                                            placeholderText='Expiry Date*'
                                                            minDate={new Date()}
                                                            onChange={(date) => {field.onChange(date);}}
                                                            selected={field.value}
                                                            mask="____-__-__"
                                                            dateFormat="dd-MM-yyyy"
                                                            inputRef={ref}
                                                            onKeyDown={(e) => {
                                                                e.preventDefault();
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </div>
                                            {errors.duration_of_membership?.message && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.duration_of_membership?.message}</p>}
                                        </div>
                                    }
                                </>
                                }
                                <div className="form-field-container mb-5">
                                    <div className="label">Purchase Date*</div>
                                    <div className='form-field-wrap select-field-wrap no-field-wrap relative'>
                                        <Controller
                                            control={control}
                                            name='date'
                                            render={({ field }) => (
                                            <ReactDatePicker
                                                placeholderText='Select date'
                                                onChange={(date) => field.onChange(date)}
                                                selected={field.value}
                                                mask="____-__-__"
                                                dateFormat="dd-MM-yyyy"
                                                maxDate={new Date()}
                                                onKeyDown={(e) => {
                                                    e.preventDefault();
                                                }}                                      
                                            />
                                        )}
                                        />
                                    </div>
                                    {errors.date && <p className="error mb-2.5" role="alert">{errors.date?.message}</p>}
                                </div>
                                <div className="form-field-container mb-5">
                                    <div className="label">Paid Through*</div>
                                    <div className='form-field-wrap mb-5 select-field-wrap no-field-wrap relative'>
                                        <select {...register("paid_through")} onChange={(e)=>{setValue('paid_through', e.target.value, { shouldValidate: true }); setPaymentType(e.target.value);}}>
                                            <option value="">Select Paid Type*</option>
                                            <option value="Cash">Cash</option>
                                            <option value="Bank Transfer">Bank Transfer</option>
                                            <option value="Cheque">Cheque</option>
                                            <option value="Other">Other</option>
                                        </select>
                                    </div>
                                    {errors.paid_through?.message && <p className="error mb-2.5" role="alert">{errors.paid_through?.message}</p> }
                                </div>
                                {paymentType==='Other' &&
                                    <>
                                    <div className="form-field-container">
                                        <div className="label">Other *</div>
                                        <div className='form-field-wrap mb-5 no-field-wrap relative'>
                                            <textarea disabled={loading} placeholder="Type here" className='text-field'
                                                {...register("other_paid_type")}
                                            />                                
                                        </div> 
                                        {errors.other_paid_type?.message && <p className="error mb-2.5" role="alert">{errors.other_paid_type?.message}</p> }                               
                                    </div>                            
                                    </>
                                }
                                {apiResponseMessage!==null &&
                                    <div className='flex justify-center mb-5'>
                                        <p className='error text-center mb-2.5 !text-[#FBDB09]'>{apiResponseMessage}</p>
                                    </div>
                                }
                                <div className="w-full md:px-2.5 xl:px-3.5">
                                    <div className='flex justify-center'>
                                        <input className='button button-medium' type="submit" value="Add" disabled={loading}/>
                                    </div>
                                </div>                            
                            </form>
                        :
                            <form onSubmit={handleSubmit2(onSubmit2)} className="form-wrapper">
                                <div className="form-field-container mb-5">
                                    <div className="label">Product Name*</div>
                                    <div className='form-field-wrap mb-5 no-field-wrap relative'>
                                        <input type='text' disabled={loading} placeholder="Product Name" className='text-field'
                                            {...register2("membership_name")}
                                        />                                
                                    </div> 
                                    {errors2.membership_name?.message && <p className="error mb-2.5" role="alert">{errors2.membership_name?.message}</p> }                               
                                </div>
                                <div className="form-field-container mb-5">
                                    <div className="label">Product Price*</div>
                                    <div className='form-field-wrap mb-5 no-field-wrap relative'>
                                        <input type='text' disabled={loading} placeholder="e.g. 5" className='text-field'
                                            {...register2("price")}
                                        />                                
                                    </div> 
                                    {errors2.price?.message && <p className="error mb-2.5" role="alert">{errors2.price?.message}</p> }                               
                                </div>
                                <div className="form-field-container mb-5">
                                    <div className="label mb-1.5">Duration Of Membership*</div>
                                    <div className="r-button-group w-[148px] flex">
                                        <div className="mr-4">
                                        <input type="radio" {...register2("duration_type")} value="fixed" id="duration_type-fixed" className="peer hidden" 
                                            onChange={(e)=>{
                                                setValue2("duration_type",e.target.value);
                                                setValue2("duration_of_membership","");
                                            }}/>
                                        <label
                                            htmlFor="duration_type-fixed"
                                            className="block py-[5px] w-[90px] cursor-pointer select-none rounded-[42px] text-center bg-[#EFF1F9] peer-checked:bg-[#FBDB03] peer-checked:text-black"
                                            >Fixed</label>
                                        </div>
                                        <div>
                                        <input type="radio" {...register2("duration_type")} value="variable" id="duration_type-variable" className="peer hidden" 
                                            onChange={(e)=>{
                                                setValue2("duration_type",e.target.value);
                                                setValue2("duration_of_membership","");
                                            }}
                                        />
                                        <label
                                            htmlFor="duration_type-variable"
                                            className="block py-[5px] w-[112px] cursor-pointer select-none rounded-[42px] text-center bg-[#EFF1F9] peer-checked:bg-[#FBDB03] peer-checked:text-black"
                                            >Variable</label>
                                        </div>
                                    </div>
                                </div>
                                {watchMemDurationtype2==='variable' ?
                                    <div className="form-field-container mb-5">
                                        <div className="label">Expiry Duration*</div>
                                        <div className='form-field-wrap mb-5 select-field-wrap no-field-wrap relative'>
                                            <select {...register2("duration_of_membership")} onChange={(e)=>{setValue2('duration_of_membership', e.target.value, { shouldValidate: true });}}>
                                                <option value="">Expiry Duration*</option>
                                                <option value="7 Day">7 Day</option>
                                                <option value="1 Month">1 Month</option>
                                                <option value="3 Month">3 Month</option>
                                                <option value="6 Month">6 Month</option>
                                                <option value="1 Year">1 Year</option>
                                            </select>
                                        </div>
                                        {errors2.duration_of_membership?.message && <p className="error mb-2.5" role="alert">{errors2.duration_of_membership?.message}</p> }
                                    </div>
                                    :
                                    <div className="form-field-container">
                                        <div className="label">Expiry Date*</div>
                                        <div className='form-field-wrap mb-5 select-field-wrap date-field-wrap relative'>
                                            <Controller
                                                control={control2}
                                                name='duration_of_membership'
                                                errors={errors}
                                                render={({ field, ref }) => (
                                                    <ReactDatePicker
                                                        placeholderText='Expiry Date*'
                                                        minDate={new Date()}
                                                        onChange={(date) => {field.onChange(date);}}
                                                        selected={field.value}
                                                        mask="____-__-__"
                                                        dateFormat="dd-MM-yyyy"
                                                        inputRef={ref}
                                                        onKeyDown={(e) => {
                                                            e.preventDefault();
                                                        }}
                                                    />
                                                )}
                                            />
                                        </div>
                                        {errors2.duration_of_membership?.message && <p className="error mb-2.5 -mt-2.5" role="alert">{errors2.duration_of_membership?.message}</p>}
                                    </div>
                                }
                                <div className="form-field-container mb-5">
                                    <div className="label">Purchase Date*</div>
                                    <div className='form-field-wrap select-field-wrap no-field-wrap relative'>
                                        <Controller
                                            control={control2}
                                            name='date'
                                            render={({ field }) => (
                                            <ReactDatePicker
                                                placeholderText='Select date'
                                                onChange={(date) => field.onChange(date)}
                                                selected={field.value}
                                                mask="____-__-__"
                                                dateFormat="dd-MM-yyyy"
                                                maxDate={new Date()}
                                                onKeyDown={(e) => {
                                                    e.preventDefault();
                                                }}                                      
                                            />
                                        )}
                                        />
                                    </div>
                                    {errors2.date && <p className="error mb-2.5" role="alert">{errors2.date?.message}</p>}
                                </div>
                                <div className="form-field-container mb-5">
                                    <div className="label">Paid Through*</div>
                                    <div className='form-field-wrap mb-5 select-field-wrap no-field-wrap relative'>
                                        <select {...register2("paid_through")} onChange={(e)=>{setValue2('paid_through', e.target.value, { shouldValidate: true }); setPaymentType(e.target.value);}}>
                                            <option value="">Select Paid Type*</option>
                                            <option value="Cash">Cash</option>
                                            <option value="Bank Transfer">Bank Transfer</option>
                                            <option value="Cheque">Cheque</option>
                                            <option value="Other">Other</option>
                                        </select>
                                    </div>
                                    {errors2.paid_through?.message && <p className="error mb-2.5" role="alert">{errors2.paid_through?.message}</p> }
                                </div>
                                {paymentType==='Other' &&
                                    <>
                                    <div className="form-field-container">
                                        <div className="label">Other *</div>
                                        <div className='form-field-wrap mb-5 no-field-wrap relative'>
                                            <textarea disabled={loading} placeholder="Type here" className='text-field'
                                                {...register2("other_paid_type")}
                                            />                                
                                        </div> 
                                        {errors2.other_paid_type?.message && <p className="error mb-2.5" role="alert">{errors2.other_paid_type?.message}</p> }                               
                                    </div>                            
                                    </>
                                }
                                {apiResponseMessage!==null &&
                                    <div className='flex justify-center mb-5'>
                                        <p className='error text-center mb-2.5 !text-[#FBDB09]'>{apiResponseMessage}</p>
                                    </div>
                                }
                                <div className="w-full md:px-2.5 xl:px-3.5">
                                    <div className='flex justify-center'>
                                        <input className='button button-medium' type="submit" value="Add" disabled={loading}/>
                                    </div>
                                </div>                            
                            </form>
                        }
                        </>
                        :
                        <>
                            <div className="py-5 text-center"><Spinner /></div>
                        </>
                    }
                </div>
            </div>
        </div>
        
    )
}

export default MembershipCardForm;