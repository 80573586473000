import { useEffect, useState, useCallback, useRef } from "react";
import { useForm, Controller } from 'react-hook-form';
//import 'rsuite/dist/rsuite-no-reset.min.css';
import CheckTreePicker from 'rsuite/CheckTreePicker';
import DatePicker from 'rsuite/DatePicker';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc'
import tz from 'dayjs/plugin/timezone'
import ReactDatePicker from "react-datepicker";
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";
import Spinner from "../../../components/Spinner/Spinner";
import TextEditor from "../../../components/TextEditor";
import PlaiImageCropper from "../../../components/ImageCropper/ImageCropper";
import { getGroupsV2, getAllProducts } from "../../../libs/dataFunctions";
import SMSEventChargePreviewForm from "./SmsChargeForm";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import 'filepond/dist/filepond.min.css';
import CoachesWithRoleInputV2 from "../../../components/CoachesWithRoleInputv2/CoachesWithRoleInputv2";
import { generateRandomName } from "../../../components/ImageCropper/cropUtils";

registerPlugin(FilePondPluginFileValidateType);
dayjs.extend(utc);
dayjs.extend(tz);

const schema = yup.object({
    event_name: yup.string().required('Event name is required.'),
    event_type: yup.string().required('Event type is required.'),
    sub_type: yup.string().when('event_type',{
        is:'Game',
        then:yup.string().required('Please Select Game Type.')
    }),
    autoAccept: yup.string().required('Please select Request to join.'),
    dateStart: yup.string().required('Please select Event Start Date.'),
    timeStart: yup.string().required('Please select Event Start Time.')
    .test('min_start_time',"Start time should be greater than current time.",function(value){
        if(value===undefined){
            return false;
        }
        const { dateStart } = this.parent;
        if (dayjs(new Date()).format("YYYYMMDD") === dayjs(dateStart).format("YYYYMMDD")) {
            var a = value.split(":");            
            return (dayjs(new Date().setHours(Number(a[0]),Number(a[1]))) > dayjs(new Date())) 
        } else {
            return true;
        }
    }),
    dateEnd: yup.string().when('is_multiday',{
        is:'Yes',
        then:yup.string().required('Please select Event End Date.')
    }),
    timeEnd: yup.string().required('Please select Event End Time.')
    .test('min_end_time',"End time should be greater than current time.",function(value){
        if(value===undefined){
            return false;
        }
        const { dateEnd } = this.parent;
        if (dayjs(new Date()).format("YYYYMMDD") === dayjs(dateEnd).format("YYYYMMDD")) {
            var a = value.split(":");            
            return (dayjs(new Date().setHours(Number(a[0]),Number(a[1]))) > dayjs(new Date())) 
        } else {
            return true;
        }
    }),
    groups: yup
    .array()
    .min(1, "At least one group must be selected")
    .nullable()
    .required("At least one group must be selected"),
    unlimitedCapacity: yup.string().required('Select capacity.'),
    minimum_attendance_count:yup.number()
        .when('minimum_attendance',{
            is:'Yes',
            then:yup.number().min(1,'Minium 1 is required').required('Enter valid number of attendance').typeError('Enter valid number of attendance')
        }).nullable(),
    sms_details:yup.string().when('notify_sms',{
        is:'Yes',
        then:yup.string().required('Give SMS Notification Description')
    })
}).required();

const NewEventForm = ({team}) =>{
    const [locationValue,setLocationValue] = useState(null);
    const [unlimitedCapacity, setUnlimitedCapacity] = useState('Yes');
    const [isRecurrringEvent, setIsReurringEvent] = useState('No');
    const [isMinimumAttandance,setMinimumAttendance] = useState('No');
    const [isMutiday, setIsMutiDay] = useState('Yes');
    const [latlong,setLatLong] = useState([]);
    const [loading,setLoading] = useState(false);
    const [eventDescription, setEventDescEditor]= useState("");
    const [errorMessage,setErrorMessage] = useState(false);
    const [image, setEventImage] = useState(null);
    const [coaches, setCoaches] = useState([]);
    const [selectedCoachesList, setSelectedCoachesList] = useState([]);
    const [groups, setGroups] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([]);
    
    const [reminderState, setReminderState] = useState('No');
    const [reminderTimeErr, setReminderTimeErr] = useState(false);
    const [pdfFiles, setPdfFile] = useState([]);
    const [minEndDate, setMinEndDate] = useState(new Date());
    const [is_notifySMS,setIsNotifysms] = useState('No');
    const [showSmsChargForm,setShowSmsChargeForm] = useState(false);
    const limitTextarea = 270;
    const [smscontent, setSmsContent] = useState('');
    const [fomrSData,setFormSData] = useState([]);
    const [isAddedProduct, setIsAddedProduct] = useState('No');
    const [products, setProducts] = useState([]);
    const [showStripeForm,setShowStripeForm] = useState(false);
    const [parentGroups,setParentGroups] = useState([]);
    const contentRef=useRef();

    const { register, handleSubmit, setValue, watch, control,formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });
    
    const reminderTimeList = [
        {key:15,value:"15 minutes before"}, 
        {key:30,value:"30 minutes before"}, 
        {key:60,value:"1 hour before"}, 
        {key:120,value:"2 hours before"},
        {key:360,value:"6 hours before"},
        {key:1440,value:"1 day before"}, 
        {key:2880, value:"2 days before"},
        {key:4320, value:"3 days before"},
        {key:5760, value:"4 days before"},
        {key:7200, value:"5 days before"}];
    
    const EnableStripeAccount = ({setShowForm}) =>{
        const [connectStripErr, setConnectStripeErr] = useState(null);
        const [loadingStripe, setLoadingStripe] = useState(false);

        const handleConnectStripe = async()=>{
            setLoadingStripe(true);
            var formdata = new FormData();
            formdata.append("team_id", team._id);
            var secureHeader = new Headers();
            secureHeader.append('Authorization','Bearer ' + token);
            secureHeader.append('device_type','W');
            const requestOptions = {
                method: 'POST',
                redirect: 'follow',
                body: formdata,
                headers: secureHeader
            };
            await fetch(process.env.REACT_APP_APIURL + '/app_stripe_connect' , requestOptions)
            .then(response => response.text())
            .then(result => {
                let rdata = JSON.parse(result);
                setLoadingStripe(false);
                if(rdata.status !== 1){                    
                    setConnectStripeErr(rdata.message);
                }
                else{
                    //window.location.href=rdata.data.redirct_url;
                    window.open(
                        rdata.data.redirct_url,
                        '_blank' // <- This is what makes it open in a new window.
                    );
                }
            })
            .catch(error => console.log('error', error));
        }
        return(
            <div className="popup-form">
                <div className="popup-form-wrap relative !w-full !max-w-[500px]">
                    <button type="button" className="close-popup" onClick={(e)=>{setShowForm(false)}}><span>close</span></button>
                    <p className="text-center mb-5">Connect your stipe account to maximise your team revenue on PLAI.</p>
                    <div className="flex justify-center">
                        {!loadingStripe ? 
                        <>
                            <button type="button" className="button mx-2.5" onClick={(e)=>{handleConnectStripe()}}>Connect Stripe</button>
                            <button type="button" className="button mx-2.5" onClick={(e)=>{setShowForm(false)}}>Cancel</button>
                            </>
                            :
                            <>
                                <img src="data:image/svg+xml,%3Csvg version='1.1' id='loader-1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='20px' height='20px' viewBox='0 0 40 40' enable-background='new 0 0 40 40' xml:space='preserve'%3E%3Cpath opacity='0.2' fill='%23000' d='M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z'%3E%3C/path%3E%3Cpath fill='%23000' d='M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 20 20' to='360 20 20' dur='0.5s' repeatCount='indefinite'%3E%3C/animateTransform%3E%3C/path%3E%3C/svg%3E" width={40} height={40} alt="" />
                            </>
                        }
                    </div>
                    {connectStripErr!==null &&
                        <div className="text-red-500 text-center">{connectStripErr}</div>
                    }
                </div>
            </div>
        )
    }

    const SMSChargePreview = ({setShowForm}) => {
        return(
            <div className="popup-form">
                <div className="popup-form-wrap relative !w-full !max-w-[500px]">
                    <SMSEventChargePreviewForm setShowForm={setShowForm} team={team} selectedGroups={selectedGroups} fomrSData={fomrSData} />
                </div>
            </div>
        )
    }
    const setFormattedContent = useCallback(
        (text) => {
            setSmsContent(text.slice(0, limitTextarea));
        },
        [limitTextarea, setSmsContent]
    );
    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
    ? localStorage.getItem('auth_token')
    : '';
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
    
    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            const base64String = reader.result
                    .replace('data:', '')
                    .replace(/^.+,/, '');
            resolve(base64String);
        }
        reader.onerror = error => reject(error);
    });

    const submitNewEvent = async(data) =>{
        let submitflag = true;
        setErrorMessage('');
        if(dayjs(data.dateEnd).format("YYYYMMDD") < dayjs(data.dateStart).format("YYYYMMDD")){
            submitflag = false;
            setErrorMessage('End Date should be same or future date from start date');            
        }
        else if(dayjs(data.dateEnd).format("YYYYMMDD") === dayjs(data.dateStart).format("YYYYMMDD")){
            var etimearr = data.timeEnd.split(":");
            var stimearr = data.timeStart.split(":");
            
            if((dayjs(new Date(data.dateEnd).setHours(Number(etimearr[0]),Number(etimearr[1]))) - dayjs(new Date(data.dateStart).setHours(Number(stimearr[0]),Number(stimearr[1])))) <= 0){
                submitflag = false;
                setErrorMessage('End time should be greater than start time.');
            }
        }
        if(data.reminder_notification==='Yes'){            
            if(data.reminder_time === null || data.reminder_time === ""){
                submitflag=false;
                setReminderTimeErr(true);
                return;
            }            
        }
        if(submitflag){
            if(is_notifySMS==='Yes'){
                let previewData = [];
                previewData["type"] = "create_event";
                previewData["team_id"] = team._id;
                previewData["name"] = data.event_name;
                previewData["event_type"] = data.event_type;
                if(data.event_type==='Game'){
                    previewData["sub_type"] = data.sub_type;
                }
                previewData["unlimitedCapacity"] = data.unlimitedCapacity;
                previewData["autoAccept"] = data.autoAccept;
                previewData["description"] = eventDescription;
                previewData["timezone"] = timeZone;
                if(data.unlimitedCapacity==="No"){
                    previewData["capacity"] = data.capacity;
                }
                else{
                    previewData["capacity"] = 10000;
                }
                if(data.dateStart){
                    previewData["dateStart"] = dayjs(data.dateStart).format("YYYY-MM-DD");
                }
                if(data.timeStart){
                    previewData["timeStart"] = data.timeStart;
                }
                if(data.dateEnd){
                    previewData["dateEnd"] = dayjs(data.dateEnd).format("YYYY-MM-DD");
                }
                if(data.timeEnd){
                    previewData["timeEnd"] = data.timeEnd;
                }
                if(data.is_recurringevent==='No'){
                    previewData["recurring"] = 'ONCE';
                }
                else{
                    previewData["recurring"] = data.recurring;
                }
                if(data.is_recurringevent === 'Yes'){
                    previewData['recurringQty'] = data.recurringQty;
                }
                if(data.minimum_attendance === 'Yes'){
                    previewData['minimum_attendance'] = 'Yes';
                    previewData['minimum_attendance_count'] = data.minimum_attendance_count;
                }
                else{
                    previewData['minimum_attendance'] = 'No';
                }

                previewData["notify_email"] = data.notify_email ? 'Yes' : 'No';
                previewData["notify_notification"] = data.notify_notification ? 'Yes' : 'No';
                previewData["notify_sms"] = data.notify_sms ? 'Yes' : 'No';
                previewData["sms_details"] = data.sms_details;
                previewData["commentsEnabled"] = data.commentsEnabled ? 'Yes' : 'No';
                if(isAddedProduct==='Yes'){
                    previewData["is_added_product"] = 'Yes';
                    previewData["product_id"] = data.product_id;
                }
                else{
                    previewData["is_added_product"] = 'No';
                }
                if(data.reminder_notification==='Yes'){            
                    previewData["reminder_time"] = data.reminder_time;
                    var rtindex = reminderTimeList.findIndex(p => p.key === parseInt(data.reminder_time));
                    previewData["reminder_time_display"] = reminderTimeList[rtindex].value;
                }
                
                /*if(selectedCoaches.length > 0){
                    previewData["coaches"] = selectedCoaches.join('@#');
                }*/

                if(selectedCoachesList.length > 0){
                    let selectedCoaches = [];
                    selectedCoachesList.forEach((scoach)=>{
                        if(scoach.role==='OTHER'){
                            selectedCoaches.push({'sub':scoach.sub, 'coach_role':scoach.custom_role});
                        }
                        else{
                            selectedCoaches.push({'sub':scoach.sub, 'coach_role':scoach.role});
                        }                    
                    });
                    
                    previewData["coaches_new"] = JSON.stringify(selectedCoaches);
                }
                else{
                    previewData["coaches_new"] = "";
                }

                if(selectedGroups.length>0){
                    previewData["group_ids"] = selectedGroups.join('@#');
                }
                if(image!==null){
                    var formdatas3 = new FormData();
                    let tmpFilename = generateRandomName();
                    const imgFileObj = new File([image], tmpFilename+'.webp', { lastModified: new Date().getTime(), type: image.type })
                    formdatas3.append("files[]", imgFileObj);
                    var requestOptionss3 = {
                        method: 'PUT',
                        body: formdatas3,
                        redirect: 'follow'
                    };
                    const responsesApp = await fetch("https://dashboard.plaisport.com/upload_content", requestOptionss3);
                    const resultsApp = await responsesApp.text();
                    const rdatasApp = JSON.parse(resultsApp);
                    previewData["content"] = rdatasApp.data[0];
                }
                if(locationValue!==null && locationValue.label!==undefined){
                    previewData['location'] =locationValue.label;
                }
                if(latlong.lng !== undefined){
                    previewData['longitude'] = latlong.lng;
                    previewData['latitude'] = latlong.lat;
                }
                if(pdfFiles.length > 0){
                    let pdfcodes = [];
                    let exts = [];
                    for(let i=0; i<pdfFiles.length; i++){
                        pdfcodes[i] = await toBase64(pdfFiles[i].file);
                        exts[i] = pdfFiles[i].fileExtension;
                    };
                    previewData["document_content"] = pdfcodes.join('@#');
                    previewData["document_extension"] = exts.join('@#');
                }
                previewData['separate_by'] = '@#';
                previewData["hasclone"] = false;
                setFormSData(previewData);
                setShowSmsChargeForm(true);
            }
            else{
                setLoading(true);
                var formdata = new FormData();
                formdata.append("type", "create_event");
                formdata.append("team_id", team._id);
                formdata.append("name", data.event_name);
                formdata.append("event_type", data.event_type);
                if(data.event_type==='Game'){
                    formdata.append("sub_type", data.sub_type);
                }
                formdata.append("unlimitedCapacity", data.unlimitedCapacity);
                formdata.append("autoAccept", data.autoAccept);
                formdata.append("description", eventDescription);
                formdata.append("timezone", timeZone);
                if(data.unlimitedCapacity==="No"){
                    formdata.append("capacity", data.capacity);
                }
                else{
                    formdata.append("capacity", 10000);
                }
                if(data.dateStart){
                    formdata.append("dateStart", dayjs(data.dateStart).format("YYYY-MM-DD"));
                }
                if(data.timeStart){
                    formdata.append("timeStart", data.timeStart);
                }
                if(data.dateEnd){
                    formdata.append("dateEnd", dayjs(data.dateEnd).format("YYYY-MM-DD"));
                }
                if(data.timeEnd){
                    formdata.append("timeEnd", data.timeEnd);
                }
                if(data.is_recurringevent==='No'){
                    formdata.append("recurring", 'ONCE');
                }
                else{
                    formdata.append("recurring", data.recurring);
                }
                if(data.is_recurringevent === 'Yes'){
                    formdata.append('recurringQty', data.recurringQty);
                }
                if(isAddedProduct==='Yes'){
                    formdata.append("is_added_product", 'Yes');
                    formdata.append("product_id", data.product_id);
                }
                else{
                    formdata.append("is_added_product", 'No');
                }
                formdata.append("notify_email", data.notify_email ? 'Yes':'No');
                formdata.append("notify_notification", data.notify_notification ? 'Yes':'No');
                formdata.append("notify_sms", data.notify_sms ? 'Yes':'No');
                formdata.append("commentsEnabled", data.commentsEnabled ? 'Yes':'No');
                if(data.reminder_notification==='Yes'){            
                    formdata.append("reminder_time",data.reminder_time);
                    var rtindex2 = reminderTimeList.findIndex(p => p.key === parseInt(data.reminder_time));
                    formdata.append("reminder_time_display",reminderTimeList[rtindex2].value);                    
                }
                if(data.minimum_attendance === 'Yes'){
                    formdata.append('minimum_attendance','Yes');
                    formdata.append('minimum_attendance_count',data.minimum_attendance_count);
                }
                else{
                    formdata.append('minimum_attendance','No');
                }
                if(selectedCoachesList.length > 0){
                    let selectedCoaches = [];
                    selectedCoachesList.forEach((scoach)=>{
                        if(scoach.role==='OTHER'){
                            selectedCoaches.push({'sub':scoach.sub, 'coach_role':scoach.custom_role});
                        }
                        else{
                            selectedCoaches.push({'sub':scoach.sub, 'coach_role':scoach.role});
                        }                    
                    });
                    
                    formdata.append("coaches_new", JSON.stringify(selectedCoaches));
                }
                else{
                    formdata.append("coaches_new", "");
                }

                if(selectedGroups.length>0){
                    formdata.append("group_ids", selectedGroups.join('@#'));
                }
                if(image!==null){
                    var formdatas4 = new FormData();
                    let tmpFilename = generateRandomName();
                    const imgFileObj = new File([image], tmpFilename+'.webp', { lastModified: new Date().getTime(), type: image.type })
                    formdatas4.append("files[]", imgFileObj);
                    var requestOptionss4 = {
                        method: 'PUT',
                        body: formdatas4,
                        redirect: 'follow'
                    };
                    const responsesApp = await fetch("https://dashboard.plaisport.com/upload_content", requestOptionss4);
                    const resultsApp = await responsesApp.text();
                    const rdatasApp = JSON.parse(resultsApp);
                    formdata.append('content',rdatasApp.data[0]);
                }
                if(locationValue!==null && locationValue.label!==undefined){
                    formdata.append('location',locationValue.label);
                }
                if(latlong.lng !== undefined){
                    formdata.append('longitude',latlong.lng);
                    formdata.append('latitude',latlong.lat);
                }
                if(pdfFiles.length > 0){
                    let pdfcodes = [];
                    let exts = [];
                    for(let i=0; i<pdfFiles.length; i++){
                        pdfcodes[i] = await toBase64(pdfFiles[i].file);
                        exts[i] = pdfFiles[i].fileExtension;
                    };
                    formdata.append("document_content", pdfcodes.join('@#'));
                    formdata.append("document_extension", exts.join('@#'));
                }
                formdata.append('separate_by','@#');
                var secureHeader = new Headers();
                secureHeader.append('Authorization','Bearer ' + token);
                secureHeader.append('device_type','W');
                const requestOptions = {
                    method: 'POST',
                    redirect: 'follow',
                    body: formdata,
                    headers: secureHeader
                };
                
                await fetch(process.env.REACT_APP_APIURL + '/app_event' , requestOptions)
                .then(response => response.text())
                .then(result => {
                    setLoading(false);
                    let rdata = JSON.parse(result);
                    if(rdata.status !== 1){
                        setErrorMessage(rdata.message);
                    }
                    else{
                        window.location.href = '/team/'+team._id+'/events';
                    }
                })
                .catch(error => console.log('error', error));
            }
        }
    }

   
    const onSubmit = (data) => {
        submitNewEvent(data);        
    }

    async function refreshCoaches(){
        setLoading(true);        
        const [groupsData, productsData] = await Promise.all([
            getGroupsV2(team._id, token),
            getAllProducts(team._id, token)
        ]);
        
        let groupOptions = [];
        let parentGroupOptions = [];
        
        groupsData.forEach(element => {
            let childOptions = [];
            parentGroupOptions.push({'value':element._id, 'label':element.name});
            if(element.nest_groups.length > 0){
                element.nest_groups.forEach(celm =>{
                    childOptions.push({'value':celm._id, 'label':celm.name});
                });
                groupOptions.push({'value':element._id, 'label':element.name, 'children':childOptions});                
            }
            else{
                groupOptions.push({'value':element._id, 'label':element.name});
            }
        });
                
        setParentGroups(parentGroupOptions);
        setGroups(groupOptions);
        setProducts(productsData);        
        setLoading(false);      
    }

    useEffect(()=>{
        refreshCoaches();
        setValue('unlimitedCapacity','Yes');
        setValue('reminder_notification','No');
    },[]);

    useEffect(()=>{
        if(locationValue!==null){
            geocodeByAddress(locationValue.label)
            .then(results => getLatLng(results[0]))
            .then(({ lat, lng }) =>{
                    setLatLong({ lat, lng });
                }
            );
        }        
    },[locationValue]);
    
    const handleChange = (e, key) => {
        const { value } = e.target        
        setEventDescEditor(value);
    }

    const watchEventType = watch("event_type");

    return(
        !loading ?
        <>
            <div className='body px-5 lg:px-8 py-10'>
                <div className='rounded-xl bg-[#363F6C] py-7.5 px-5'>
                    <form onSubmit={handleSubmit(onSubmit)} className="form-wrapper">
                        <div className="flex flex-wrap lg:mb-12">
                            <div className="w-full xl:w-1/3 md:px-2.5 xl:px-3.5">
                                <div className="form-field-container">
                                    <div className="label">Event Name*</div>
                                    <div className='form-field-wrap mb-5 user-field-wrap relative'>
                                        <input 
                                            type="text" id="event-name" disabled={loading} placeholder="Event Name *" className='text-field'
                                            {...register("event_name")}
                                            aria-invalid={errors.event_name ? "true" : "false"} 
                                        />                                
                                    </div>
                                    {errors.event_name && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.event_name?.message}</p>}
                                </div>
                            </div>
                            <div className="w-full xl:w-1/3 flex flex-wrap">
                                <div className={`w-full ${watchEventType==='Game' ? 'xl:w-1/2':''} md:px-2.5 xl:px-3.5`}>
                                    <div className="form-field-container">
                                        <div className="label">Event Type*</div>
                                        <div className='form-field-wrap mb-5 select-field-wrap type-field-wrap relative'>
                                            <select {...register("event_type")} onChange={(e)=>setValue('event_type', e.target.value, { shouldValidate: true })}>
                                                <option value="">Event Type *</option>
                                                <option value="Competition">Competition</option>
                                                <option value="Game">Game</option>
                                                <option value="Race">Race</option>
                                                <option value="Social Event">Social Event</option>
                                                <option value="Practice">Practice</option>
                                                <option value="Other">Other</option>
                                            </select>
                                        </div>
                                        {errors.event_type && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.event_type?.message}</p>}
                                    </div>
                                </div>
                                {watchEventType==='Game' &&
                                    <div className={`w-full xl:w-1/2 md:px-2.5 xl:px-3.5`}>
                                        <div className="form-field-container mb-5">
                                            <div className="label">Game Type*</div>
                                            <div className='form-field-wrap mb-5 select-field-wrap no-field-wrap relative'>
                                                <select {...register("sub_type")} onChange={(e)=>{setValue('sub_type', e.target.value, { shouldValidate: true });}}>
                                                    <option value="">Game Type*</option>
                                                    <option value="League">League</option>
                                                    <option value="Cup">Cup</option>
                                                    <option value="Tournament">Tournament</option>
                                                    <option value="Friendly">Friendly</option>
                                                    <option value="Tour">Tour</option>
                                                    <option value="Other">Other</option>
                                                </select>
                                            </div>
                                            {errors.sub_type?.message && <p className="error mb-2.5" role="alert">{errors.sub_type?.message}</p> }
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="w-full xl:w-1/3 md:px-2.5 xl:px-3.5">
                                <div className="form-field-container">
                                    <div className="label">Event Location</div>
                                    <div className='form-field-wrap mb-5 select-field-wrap location-field-wrap relative'>
                                        <GooglePlacesAutocomplete
                                            apiKey={process.env.REACT_APP_GAPIKEY}
                                            selectProps={{
                                                //locationValue,
                                                onChange: setLocationValue,
                                                //components: { DropdownIndicator },
                                                styles: {
                                                    control: (baseStyles) => ({
                                                        ...baseStyles,
                                                        borderWidth: 0,
                                                        backgroundColor: '#EFF1F9',
                                                        padding: '6px 7px 6px 46px'
                                                    }),
                                                    option: (baseStyles) => ({
                                                        ...baseStyles,
                                                        backgroundColor: '#ffffff',
                                                        zIndex: 10000
                                                    }),
                                                    menu: (baseStyles) => ({
                                                        ...baseStyles,
                                                        zIndex:1000,
                                                        backgroundColor: '#ffffff'
                                                    }),
                                                },
                                                theme:(theme) => ({
                                                    ...theme,
                                                    borderRadius: 8,                                                
                                                }),
                                            }}                                    
                                        />                                
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-wrap lg:mb-12">
                            <div className="w-full xl:w-1/3 md:px-2.5 xl:px-3.5">
                                <div className="form-field-container">
                                    <div className="label">Is this a multi-day event?*</div>
                                    <div className='form-field-wrap mb-5 select-field-wrap no-field-wrap relative'>
                                        <select {...register("is_multiday")} onChange={(e)=>{setValue('is_multiday', e.target.value, { shouldValidate: true }); setIsMutiDay(e.target.value)}}>
                                            <option value="Yes">Yes</option>
                                            <option value="No">No</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full lg:w-2/3 flex flex-wrap">
                                <div className="w-full md:w-1/2 lg:w-1/4 md:px-2.5 xl:px-3.5">
                                    <div className="form-field-container">
                                        <div className="label">Start Date*</div>                          
                                        <div className='form-field-wrap mb-5 select-field-wrap date-field-wrap relative'>
                                            <Controller
                                                control={control}
                                                name='dateStart'
                                                errors={errors}
                                                render={({ field, ref }) => (
                                                    <ReactDatePicker
                                                        placeholderText='Start Date*'
                                                        minDate={new Date()}
                                                        onChange={(date) => {
                                                            field.onChange(date); 
                                                            setMinEndDate(date);
                                                            setValue('dateEnd',date);
                                                        }}
                                                        //utcOffset={timeZone}
                                                        selected={field.value}
                                                        mask="____-__-__"
                                                        dateFormat="dd-MM-yyyy"
                                                        inputRef={ref}                                     
                                                    />
                                                )}
                                            />
                                        </div>
                                        {errors.dateStart && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.dateStart?.message}</p>}
                                    </div>
                                </div>
                                {isMutiday==='Yes' &&
                                    <div className="w-full md:w-1/2 lg:w-1/4 md:px-2.5 xl:px-3.5">
                                        <div className="form-field-container">
                                            <div className="label">End Date*</div>
                                            <div className='form-field-wrap mb-5 select-field-wrap date-field-wrap relative'>
                                                <Controller
                                                    control={control}
                                                    name='dateEnd'
                                                    errors={errors}
                                                    render={({ field, ref }) => (
                                                        <ReactDatePicker
                                                            placeholderText='End Date *'
                                                            minDate={minEndDate}
                                                            onChange={(date) => {field.onChange(date)}}
                                                            selected={field.value}
                                                            mask="____-__-__"
                                                            dateFormat="dd-MM-yyyy"
                                                            inputRef={ref}
                                                        />
                                                    )}
                                                />
                                            </div>
                                            {errors.dateEnd && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.dateEnd?.message}</p>}
                                        </div>
                                    </div>
                                }
                                <div className="w-full md:w-1/2 lg:w-1/4 md:px-2.5 xl:px-3.5">
                                    <div className="form-field-container relative">
                                        <div className="label">Start Time*</div> 
                                        <div className='form-field-wrap mb-5 select-field-wrap time-field-wrap relative'>
                                            <Controller
                                                    control={control}
                                                    name='timeStart'
                                                    errors={errors}
                                                    render={({ field }) => (
                                                        <DatePicker 
                                                            placeholder='Start Time *'
                                                            format="hh:mm aa" 
                                                            onChange={(date) => {
                                                                field.onChange(dayjs(date).format('HH:mm'));
                                                            }}
                                                            caretAs={null} 
                                                            cleanable={false}
                                                        />
                                                    )}
                                                />
                                        </div>
                                        {errors.timeStart && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.timeStart?.message}</p>}
                                    </div>
                                </div>
                                <div className="w-full md:w-1/2 lg:w-1/4 md:px-2.5 xl:px-3.5">
                                    <div className="form-field-container">
                                        <div className="label">End time*</div>
                                        <div className='form-field-wrap mb-5 select-field-wrap time-field-wrap relative'>
                                            <Controller
                                                control={control}
                                                name='timeEnd'
                                                errors={errors}
                                                render={({ field }) => (
                                                    <DatePicker 
                                                        placeholder='End Time *'
                                                        format="hh:mm aa" 
                                                        onChange={(date) => {
                                                            field.onChange(dayjs(date).format('HH:mm'));
                                                        }}
                                                        caretAs={null} 
                                                        cleanable={false}
                                                    />
                                                )}
                                            />
                                        </div>
                                        {errors.timeEnd && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.timeEnd?.message}</p>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="flex flex-wrap lg:mb-12">
                            <div className="w-full md:w-1/2 lg:w-1/6 md:px-2.5">
                                <div className="form-field-container">
                                    <div className="label">Requests to join*</div>
                                    <div className='form-field-wrap mb-5 select-field-wrap no-field-wrap relative'>
                                        <select {...register("autoAccept")} onChange={(e)=>setValue('autoAccept', e.target.value, { shouldValidate: true })}>
                                            <option value="">Requests to join*</option>
                                            <option value="Yes">Auto Accept</option>
                                            <option value="No">Manual Accept</option>
                                        </select>
                                    </div>
                                    {errors.autoAccept && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.autoAccept?.message}</p>}
                                </div>
                            </div>
                            <div className="w-full md:w-1/2 lg:w-1/6 md:px-2.5">
                                <div className="form-field-container">
                                    <div className="label">Is this a recurring event*</div>
                                    <div className='form-field-wrap mb-5 select-field-wrap no-field-wrap relative'>
                                        <select {...register("is_recurringevent")} onChange={(e)=>{setValue('is_recurringevent', e.target.value, { shouldValidate: true }); setIsReurringEvent(e.target.value)}}>
                                            <option value="No">No</option>
                                            <option value="Yes">Yes</option>                                        
                                        </select>
                                    </div>                                
                                </div>
                            </div>
                            {isRecurrringEvent==='Yes' &&
                                <div className="w-full md:w-1/2 lg:w-1/6 md:px-2.5">
                                    <div className="form-field-container">
                                        <div className="label">How Often*</div>
                                        <div className='form-field-wrap mb-5 select-field-wrap repeat-field-wrap relative'>
                                            <select {...register("recurring")} onChange={(e)=>{setValue('recurring', e.target.value, { shouldValidate: true });}}>
                                                <option value="DAILY">Daily</option>
                                                <option value="WEEKLY">Weekly</option>
                                                <option value="MONTHLY">Monthly</option>
                                                <option value="YEARLY">Annual</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            }
                            {isRecurrringEvent==='Yes' &&
                            <div className="w-full md:w-1/2 lg:w-1/6 md:px-2.5">
                                <div className="form-field-container">
                                    <div className="label">No. of Times*</div>
                                    <div className='form-field-wrap mb-5 no-field-wrap relative'>
                                        <input 
                                            type="number" min="0" className='text-field' placeholder="How many times?"
                                            {...register("recurringQty")} 
                                        />                                
                                    </div>
                                </div>
                            </div>
                            }
                            <div className="w-full md:w-1/2 lg:w-1/6 md:px-2.5">
                                <div className="form-field-container">
                                    <div className="label">Send Reminder*</div>
                                    <div className='form-field-wrap mb-5 select-field-wrap no-field-wrap relative'>
                                        <select {...register("reminder_notification")} onChange={(e)=>{setValue("reminder_notification",e.target.value, { shouldValidate: true }); setReminderState(e.target.value);}}>
                                            <option value="No">No</option>
                                            <option value="Yes">Yes</option>                                        
                                        </select>
                                    </div>                                
                                </div>
                            </div>
                            {reminderState==='Yes' &&
                                <div className="w-full md:w-1/2 lg:w-1/6 md:px-2.5">
                                    <div className="form-field-container">
                                        <div className="label">Reminder Time*</div>
                                        <div className='form-field-wrap mb-5 select-field-wrap no-field-wrap relative'>
                                            <select {...register("reminder_time")} onChange={(e)=>{setValue('reminder_time', e.target.value, { shouldValidate: true});}}>
                                                <option value="">Reminder Before *</option>
                                                {reminderTimeList.map((reminderTime)=>{
                                                    return(
                                                        <option value={reminderTime.key} key={reminderTime.key}>{reminderTime.value}</option>
                                                    )
                                                })}
                                                </select>                                    
                                        </div>
                                        {reminderTimeErr && <p className="error mb-2.5 -mt-2.5" role="alert">Please select reminder time</p>}
                                    </div>
                                </div>
                            } 
                        </div>

                        <div className="flex flex-wrap lg:mb-12">
                            <div className="w-full lg:w-1/3 flex flex-wrap">
                                <div className="w-full md:w-1/2 md:px-2.5 xl:px-3.5">
                                    <div className="form-field-container">
                                        <div className="label">Unlimited Capacity*</div>
                                        <div className='form-field-wrap mb-5 select-field-wrap capacity-field-wrap relative'>
                                            <select {...register("unlimitedCapacity")} onChange={(e)=>{setValue('unlimitedCapacity', e.target.value, { shouldValidate: true }); setUnlimitedCapacity(e.target.value)}}>
                                                <option value="Yes">Yes</option>
                                                <option value="No">No</option>
                                            </select>
                                        </div>
                                        {errors.unlimitedCapacity && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.unlimitedCapacity?.message}</p>}
                                    </div>                                
                                </div>                            
                                {unlimitedCapacity==='No' &&
                                <div className="w-full md:w-1/2 md:px-2.5 xl:px-3.5">
                                    <div className="form-field-container">
                                        <div className="label">Event Capacity*</div>
                                        <div className='form-field-wrap mb-5 user-field-wrap relative'>
                                            <input 
                                                type="number" min="0" className='text-field'
                                                {...register("capacity")} 
                                            />                                
                                        </div>
                                    </div>
                                </div>
                                } 
                            </div>
                            <div className="w-full lg:w-1/3 md:px-2.5 xl:px-3.5">
                                <div className="form-field-container z-[1000000]">
                                    <div className="flex justify-between">
                                        <div className="label">Select Coaches</div>
                                        <p className="mb-1 font-medium text-[12px] leading-[18px] text-white">{(selectedCoachesList && selectedCoachesList.length) > 0 ? selectedCoachesList.length : 0} Members Selected</p>
                                    </div>
                                    <div className='form-field-wrap mb-5 select-field-wrap user-field-wrap relative'>                            
                                        {/*
                                        <Controller
                                            control={control}
                                            name='coaches'
                                            render={({ field: { onChange, value, ref }}) => (
                                                <Select
                                                    inputRef={ref}
                                                    value={selectedCoachesOut}
                                                    className="react-select-container"
                                                    classNamePrefix="react-select"
                                                    components={{ DropdownIndicator }}
                                                    placeholder={<div>Select Coaches</div>}
                                                    onChange={val => {
                                                        onChange(val.map(c => c.value));
                                                        handleSelectChange(val);
                                                    }}
                                                    options={coaches}
                                                    isMulti
                                                    styles={{
                                                        control: (baseStyles, state) => ({
                                                            ...baseStyles,
                                                            borderWidth: 0,
                                                            backgroundColor: '#EFF1F9',
                                                            padding: '6px 7px 6px 45px'
                                                        }),
                                                        menu: (baseStyles) => ({
                                                            ...baseStyles,
                                                            zIndex:1000,
                                                            backgroundColor: '#ffffff'
                                                        })
                                                    }}
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        borderRadius: 8,                                                
                                                    })}
                                                />
                                            )}
                                        />
                                        */}
                                        <CoachesWithRoleInputV2 teamID={team._id} token={token} selectedCoaches={selectedCoachesList} parentsGroups={parentGroups} options={coaches} onChange={setSelectedCoachesList} onChangeMain={setCoaches} />
                                    </div>                                
                                </div>                            
                            </div>
                            <div className="w-full lg:w-1/3 md:px-2.5 xl:px-3.5">
                                <div className="form-field-container z-[1000000]">
                                    <div className="flex justify-between">
                                        <div className="label">Sending to*</div>
                                        <p className="mb-1 font-medium text-[12px] leading-[18px] text-white">
                                            {selectedGroups.length===0 ? 
                                                '0 Groups Selected':
                                                (selectedGroups.length > 1) ? selectedGroups.length + ' Groups Selected' : selectedGroups.length + ' Group Selected'}
                                        </p>
                                    </div>                                    
                                    <div className='form-field-wrap mb-5 select-field-wrap capacity-field-wrap relative' ref={contentRef}>                            
                                        <Controller
                                                control={control}
                                                name='groups'
                                                render={({ field: { onChange, value, ref }}) => (
                                                    <CheckTreePicker
                                                        defaultExpandAll
                                                        block={true}
                                                        placeholder="Select Groups*"
                                                        data={groups}
                                                        onChange={val => {
                                                            onChange(val.map(c => c.value));
                                                            setValue('groups',val);
                                                            setSelectedGroups(val);
                                                        }}
                                                        container={()=>contentRef.current}
                                                        onSelect={(item,val,event)=>{
                                                                if(item.label.toLowerCase()==="all members"){
                                                                    if(item.check){
                                                                        let groupIds=[];
                                                                        groups.forEach((item)=>{
                                                                            groupIds.push(item.value);
                                                                        });
                                                                        setSelectedGroups(groupIds);
                                                                    }
                                                                    else{
                                                                        setSelectedGroups([]);
                                                                    }
                                                                }
                                                            }
                                                        }
                                                        menuStyle={{zIndex: 100000000}}
                                                        defaultValue={selectedGroups}
                                                        value={selectedGroups}
                                                        style={{
                                                            width:'100%',
                                                            maxWidth:'500px',
                                                            borderWidth:0,
                                                            backgroundColor: '#EFF1F9',
                                                            padding: '8px 7px 8px 46px',
                                                            height: '52px',
                                                            borderRadius: 8
                                                        }}                                                        
                                                    />
                                                )}
                                        />
                                    </div>
                                    {errors.groups?.message && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.groups?.message}</p> }
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-wrap lg:mb-12">
                            <div className="w-full lg:w-1/3 md:px-2.5 xl:px-3.5">
                                <div className="form-field-container">
                                    <div className="label">Required Minimum Attendance*</div>
                                    <div className='form-field-wrap mb-5 select-field-wrap no-field-wrap relative'>
                                        <select {...register("minimum_attendance")} onChange={(e)=>{setValue('minimum_attendance', e.target.value, { shouldValidate: true }); setMinimumAttendance(e.target.value)}}>
                                            <option value="No">No</option>
                                            <option value="Yes">Yes</option>
                                        </select>
                                    </div>
                                    {errors.minimum_attendance && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.minimum_attendance?.message}</p>}
                                </div>                                
                            </div>                            
                            {isMinimumAttandance==='Yes' &&
                            <div className="w-full lg:w-1/3 md:px-2.5 xl:px-3.5">
                                <div className="form-field-container">
                                    <div className="label">How Many?*</div>
                                    <div className='form-field-wrap mb-5 user-field-wrap relative'>
                                        <input 
                                            type="number" min="1" className='text-field'
                                            {...register("minimum_attendance_count")} 
                                        />                                
                                    </div>
                                    {errors.minimum_attendance_count && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.minimum_attendance_count?.message}</p>}
                                </div>
                            </div>
                            }
                        </div>

                        <div className="md:px-2.5 xl:px-3.5 lg:mb-12">
                            <div className="form-field-wrap mb-5 relative">
                                <TextEditor
                                    value={eventDescription}
                                    placeholderText="Enter Event Information..."
                                    height={200}
                                    onChange={(e) => handleChange({ target: { value: e } }, 'editor')}
                                />
                            </div>
                        </div>
                        <div className="flex flex-wrap lg:mb-12">
                            <div className="w-full lg:w-1/3 md:px-2.5 xl:px-3.5">
                                <div className='form-field-wrap no-field-wrap mb-5 lg:mb-0 relative'>
                                    <PlaiImageCropper editImage='' onFinalChange={(newImage)=>{setEventImage(newImage)}} aspect={2 / 1}/>
                                </div>
                            </div>
                            <div className="w-full lg:w-1/3 md:px-2.5 xl:px-3.5">
                                <div className='form-field-wrap no-field-wrap mb-5 lg:mb-0 relative'>
                                    <FilePond
                                        files={pdfFiles}
                                        onupdatefiles={setPdfFile}                                    
                                        allowMultiple={true}
                                        credits={false}
                                        allowFileTypeValidation={true}
                                        acceptedFileTypes={['application/pdf']}
                                        labelFileTypeNotAllowed='File of invalid type'
                                        //maxFiles={1}                                    
                                        labelIdle='
                                            <div class="upload-text">
                                                <h4>Upload a PDF</h4>
                                                <p class="text-center">Maximum upload file size: 2MB</p>
                                            </div>'
                                        {...register("pdfdocuments")}
                                    />
                                </div>
                            </div>
                            <div className="w-full lg:w-1/3 flex flex-col justify-between">
                                <div>
                                    <div className="flex flex-wrap">
                                        <div className="w-full md:w-1/2 md:px-2.5 xl:px-3.5">
                                            <div className='form-field-wrap new-check-field-wrap relative'>                                
                                                <label htmlFor="notify_email" className=''>
                                                    <input type="checkbox" id="notify_email" {...register("notify_email")} className='checkbox-field appearance-none'/>
                                                    Send Email
                                                </label>
                                            </div>
                                        </div>
                                        <div className="w-full md:w-1/2 md:px-2.5 xl:px-3.5">
                                            <div className='form-field-wrap new-check-field-wrap relative'>                                
                                                <label htmlFor="notify_notification" className=''>
                                                    <input type="checkbox" id="notify_notification" {...register("notify_notification")} className='checkbox-field appearance-none'/>
                                                    Send Notification
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex flex-wrap">
                                        <div className="w-full md:w-1/2 md:px-2.5 xl:px-3.5">
                                            <div className='form-field-wrap new-check-field-wrap relative'>                                
                                                <label htmlFor="notify_sms" className=''>
                                                    <input type="checkbox" id="notify_sms" {...register("notify_sms")}
                                                        onChange={(e)=>{
                                                            if(e.target.checked){
                                                                setIsNotifysms('Yes');
                                                            }
                                                            else{
                                                                setIsNotifysms('No');
                                                            }
                                                        }}
                                                        className='checkbox-field appearance-none'/>
                                                    Send SMS<br/><span className="text-[9px] leading-[9px] block">*carrier charges apply</span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="w-full md:w-1/2 md:px-2.5 xl:px-3.5">
                                            <div className='form-field-wrap new-check-field-wrap relative'>                                
                                                <label htmlFor="commentsEnabled" className=''>
                                                    <input type="checkbox" id="commentsEnabled" {...register("commentsEnabled")} className='checkbox-field appearance-none'/>
                                                    Allow Comments
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    {is_notifySMS==='Yes' && 
                                    <div className="w-full md:px-2.5 xl:px-3.5">
                                        <div className='form-field-wrap no-field-wrap relative'>
                                            <textarea 
                                                className='text-field' 
                                                placeholder="SMS Notification Description *"
                                                {...register("sms_details")}
                                                onChange={e => setFormattedContent(e.target.value)}
                                                value={smscontent} 
                                            ></textarea>
                                            <p className="text-right text-[12px] leading-[12px] text-white">
                                                {smscontent.length}/{limitTextarea}
                                            </p>
                                        </div>
                                        {errors.sms_details && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.sms_details?.message}</p>}
                                    </div>
                                    }
                                    <div className="flex flex-wrap">
                                        {team.stripeEnabled ?
                                        <>
                                        <div className="w-full md:px-2.5 xl:px-3.5">
                                            <div className='form-field-wrap new-check-field-wrap relative'>                                
                                                <label htmlFor="is_added_product" className=''>
                                                    <input type="checkbox" id="is_added_product" {...register("is_added_product")}
                                                        onChange={(e)=>{
                                                            if(e.target.checked){
                                                                setIsAddedProduct('Yes');
                                                            }
                                                            else{
                                                                setIsAddedProduct('No');
                                                            }
                                                        }}
                                                        className='checkbox-field appearance-none'/>
                                                        Link this event to shop item
                                                </label>
                                            </div>
                                        </div>
                                        {isAddedProduct==='Yes' &&
                                            <div className="w-full md:px-2.5 xl:px-3.5">
                                                <div className="form-field-container">
                                                    <div className="label">Shop Item*</div>
                                                    <div className='form-field-wrap mb-5 select-field-wrap no-field-wrap relative'>
                                                        <select {...register("product_id")} onChange={(e)=>setValue('product_id', e.target.value, { shouldValidate: true })}>
                                                            {products.length>0 &&
                                                                <>
                                                                    {products.map((product)=>{
                                                                        return(
                                                                            <option key={product._id} value={product._id}>{product.name}</option>
                                                                        )
                                                                    })
                                                                }
                                                                </>
                                                            }
                                                        </select>
                                                    </div>
                                                    {errors.product_id && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.product_id?.message}</p>}
                                                </div>
                                            </div>
                                        }
                                        </>
                                        :
                                        <>
                                        <div className="w-full md:px-2.5 xl:px-3.5">
                                            <div className='form-field-wrap new-check-field-wrap relative'>                                
                                                <label htmlFor="is_added_product" className="cursor-pointer relative before:content-[''] before:absolute before:w-[20px] before:h-full before:top-0 before:left-0 before:z-10" onClick={()=>{setShowStripeForm(true)}}>
                                                    <input type="checkbox" id="notify_sms" {...register("is_added_product")}                                                        
                                                        className='checkbox-field appearance-none' disabled/>
                                                        Link this event to shop item
                                                </label>
                                            </div>
                                        </div>
                                        {showStripeForm &&
                                            <EnableStripeAccount setShowForm={setShowStripeForm} />
                                        }
                                        </>
                                    }
                                    </div>
                                </div>
                                <div className="w-full md:px-2.5 xl:px-3.5 pt-5">
                                    <div className='flex justify-center lg:justify-end'>
                                        <input className='button button--large' type="submit" value="Create Event" disabled={loading}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {!loading && errorMessage ?
                            <div className="w-full">
                                <div className='flex justify-center mb-10'>
                                    <p className='error text-center mb-2.5 text-[#ff0000]'>{errorMessage}</p>
                                </div>
                            </div>
                            : null
                        }
                    </form>
                    {showSmsChargForm &&
                        <SMSChargePreview setShowForm={setShowSmsChargeForm} />
                    }
                </div>
            </div>
        </>
        :
        <>
            <div className="py-5 text-center"><Spinner /></div>
        </>
    )
}
export default NewEventForm;