import { useState, useEffect } from 'react';
import { useLocation, NavLink, useNavigate } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import dayjs from 'dayjs';
import * as DOMPurify from 'dompurify';
import AdminNavbar from "../../../components/AdminNavbar/AdminNavbar";
import Sidebar from "../../../components/Sidebar/Sidebar";
import GroupForm from './GroupForm';
import GroupMemberRow from './GroupMemberRow';
import IsMobile from '../../../components/isMobile';
import AddGroupMember from './AddGroupMember';
import { getGroupMembers } from '../../../libs/dataFunctions';

const GroupsDetail = ()=>{
    const [showEditForm, setShowEditForm] = useState(false);
    const [showExportOptions,setShowExportOptions] = useState(false);
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [isCheck, setIsCheck] = useState([]);
    const [isCheckAllMembers, setIsCheckAllMembers] = useState(false);
    const [isCheckMembers, setIsCheckMembers] = useState([]);
    const [showAddMemberForm, setShowAddMemberForm] = useState(false);
    const isMobile = IsMobile(window);
    const navigate = useNavigate();
    const location  = useLocation();
    var group = location.state.group;
    var team = location.state.team;
    const [groupMembers, setGroupMembers] = useState([]);
    const [loading, setLoading] = useState(false);
    
    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
    ? localStorage.getItem('auth_token')
    : '';
    
    const GroupEditForm = ({setShowEditForm, group, team}) =>{        
        return(
            <div className="popup-form">
                <div className="popup-form-wrap popup-form-group relative">                    
                    <GroupForm group={group} team={team} setShowForm={setShowEditForm} isListview={false} isAddForm={false} isChild={group.is_child_group} hasChild={group.nest_groups.length>0 ? true : false} />
                </div>
            </div>
        )
    }

    const AddGroupMemberPopup = ({setShowForm}) => {
        return(
            <div className="popup-form">
                <div className="popup-form-wrap relative !w-full !max-w-[850px]">
                    <AddGroupMember team={team} group={group} setShowForm={setShowForm} refreshGroupMembers={refreshGroupMembers} />
                </div>
            </div>
        )
    }

    const deleteGroup = async ()=>{
        if(window.confirm("Are you sure you want to delete this group?")){
            var formdata = new FormData();
            formdata.append("type", "delete_group");
            formdata.append("group_id",group._id);
            formdata.append("team_id", team._id);
            
            var secureHeader = new Headers();
            secureHeader.append('Authorization','Bearer ' + token);
            secureHeader.append('device_type','W');
            const requestOptions = {
                method: 'POST',
                redirect: 'follow',
                body: formdata,
                headers: secureHeader
            };
  
            await fetch(process.env.REACT_APP_APIURL + '/app_groups' , requestOptions)
            .then(response => response.text())
            .then(result => {
                navigate('/team/'+team._id+'/groups');
            })
            .catch(error => console.log('error', error));
        }
    }
    
    const exportFields = [
        {
            key: "firstName",
            label: "First Name"
        },
        {
            key: "lastName",
            label: "Last Name"
        },
        {
            key: "name",
            label: "Full Name"
        },
        {
            key: "email",
            label: "Email"
        },
        {
            key: "phoneNumber",
            label: "Phone"
        },
        {
            key: "dateOfBirth",
            label: "Date of Birth"
        },
        {
            key: "role",
            label: "Role"
        },
    ];
    
    const handleSelectAll = e => {
        setIsCheckAll(!isCheckAll);
        setIsCheck(exportFields.map(li => li));
        if (isCheckAll) {
            setIsCheck([]);
        }
    };
    const handleClick = e => {
        const { id, checked, value } = e.target;
        let tmpObj = {};
        Object.assign(tmpObj, JSON.parse(value));        
        setIsCheck([...isCheck, tmpObj]);
        if (!checked) {
            setIsCheck(isCheck.filter((item) => {return !item.key.includes(id)}));
        }
    };
    const handleSelectAllMembers = e => {
        setIsCheckAllMembers(!isCheckAllMembers);
        setIsCheckMembers(groupMembers.map(item => item));
        if (isCheckAllMembers) {
            setIsCheckMembers([]);
        }
    };
    const fieldLists = exportFields.map((field) => {
        return (              
            <label key={field.key} className='text-[12px] mr-3 text-black font-light cursor-pointer'>
                <input                    
                    type="checkbox"
                    name={field.label}
                    id={field.key}
                    value={JSON.stringify(field)}
                    className='checkbox-field appearance-none h-4 w-4 rounded-sm bg-white mt-1 align-top float-left mr-2 cursor-pointer'
                    onChange={handleClick}
                    checked={isCheck.some((item)=>{ return item.key===field.key })}
                />
                {field.label}
            </label>
        );
    });

    async function refreshGroupMembers(){
        setLoading(true);
        let groupMembersData = await getGroupMembers(team._id, token,group._id);
        setGroupMembers(groupMembersData);
        setLoading(false);
    }

    useEffect(()=>{
        refreshGroupMembers();
    },[]);

    return(        
        <>
            <Sidebar type="thin"/>
            <div id="page" className="page-group-members group-members">
                <AdminNavbar heading="Group" />
                <div className='breadcrumb flex item-center pt-1.5 pb-1 pl-5 pr-7 w-full'>
                    <NavLink to="/" className='home'>
                        <span>Dashboard</span>
                    </NavLink>
                    <span className='separator'>&lt;</span>
                    <NavLink to={`/team/${team._id}/groups`}>
                        Group
                    </NavLink>     
                </div>
                <div className='page-header flex flex-col md:flex-row justify-end md:justify-between md:items-end px-5 lg:px-8 py-6'>
                    <div>
                        <h1>{group.name}</h1>
                        {group.description!=="" &&
                            <div className='group-desc' dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(group.description)}}/>
                        }
                    </div>
                    {((team.role === 'admin' || group.user_role==='group_admin') && (group.name.toLowerCase()!=='all members')) &&
                        <div className='groups-actions'>
                            <button type="button" className='button button-white button-white-edit mr-2.5' onClick={(e)=>{setShowEditForm(true)}}>Edit</button>
                            <button type="button" className='button button-white' onClick={()=>deleteGroup()}>Delete</button>
                        </div>
                    }
                </div>
                {showEditForm &&
                    <GroupEditForm group={group} team={team} setShowEditForm={setShowEditForm} />
                }
                <div className="groups body px-2.5 sm:px-5 lg:px-8 py-10">
                    <div className="px-2.5 sm:px-7.5 py-[18px] flex flex-col md:flex-row justify-between items-center">
                        <div className="title-col mb-2.5 md:mb-0">Members List</div>
                        <div className="cta-col flex flex-col md:flex-row items-center">
                        {(team.role === 'admin' || group.user_role==='group_admin') &&
                                <>
                                    <div className="flex items-center">
                                        <button type="button" className="button button-action button-export w-[89px]" onClick={()=>{setShowExportOptions(!showExportOptions)}}>Export</button>
                                        <button type="button" className="button button-action ml-2.5" onClick={()=>{setShowAddMemberForm(!showAddMemberForm)}}>Add Member</button>
                                    </div>
                                </>
                            }                    
                        </div>
                        {showAddMemberForm &&
                            <AddGroupMemberPopup setShowForm={setShowAddMemberForm} />
                        }
                    </div>

                    {groupMembers.length > 0 && 
                        <div className="team-members-list pb-[77px]">
                            {!isMobile ?
                            <table className='table-auto w-full bg-white rounded-[10px]'>
                                <thead>
                                <tr>
                                        <th></th>
                                        <th colSpan={5}>
                                        {showExportOptions && 
                                            <div className="flex flex-wrap py-3.5">
                                                <label className='text-[12px] mr-5 text-[#363F6C] font-semibold cursor-pointer'>
                                                    <input 
                                                        type="checkbox"
                                                        name="selectAll"
                                                        id="selectAll"
                                                        className='checkbox-field appearance-none h-4 w-4 rounded-sm bg-white mt-1 align-top float-left mr-2 cursor-pointer'
                                                        onChange={handleSelectAll}
                                                        checked={isCheckAll} />
                                                        All Fields
                                                </label>
                                                {fieldLists}
                                                {(isCheckMembers.length>0 && isCheck.length>0) &&
                                                    <CSVLink 
                                                        className="button button-action button-blue w-[89px]"
                                                        data={isCheckMembers.map((memberObj, index) => {
                                                            let rowData={}, columnData;
                                                            columnData = isCheck.map((isc)=>{
                                                                let colData={};
                                                                if(isc.key === 'dateOfBirth'){
                                                                    colData.dateOfBirth=memberObj[isc.key]!=='' ? dayjs(memberObj[isc.key]).format('DD-MM-YYYY') : (memberObj['is_above_18'] ? 'Over 18 years' : 'Under 18 years');
                                                                }
                                                                if(isc.key === 'firstName'){
                                                                    colData.firstName=memberObj[isc.key];
                                                                }
                                                                if(isc.key === 'lastName'){
                                                                    colData.lastName=memberObj[isc.key];
                                                                }
                                                                if(isc.key === 'name'){
                                                                    colData.name=memberObj[isc.key];
                                                                }
                                                                if(isc.key === 'email'){
                                                                    colData.email=memberObj[isc.key];
                                                                }                                                            
                                                                if(isc.key === 'phoneNumber'){
                                                                    colData.phoneNumber=memberObj.phoneNumber.toString();
                                                                }
                                                                if(isc.key === 'role'){
                                                                    if(memberObj.user_role !== undefined){
                                                                        colData.role=memberObj.user_role==="group_admin" ? 'GROUP ADMIN' : memberObj.user_role.toUpperCase();
                                                                    }
                                                                    else{
                                                                        colData.role=null;
                                                                    }
                                                                }
                                                                return colData;
                                                            });

                                                            for(let i = 0; i < columnData.length; i++ ) {
                                                                Object.assign(rowData, columnData[i]);
                                                            }
                                                            
                                                            return rowData;
                                                        })}
                                                        filename={'members-' + group._id + '.csv'}
                                                        headers={isCheck}>
                                                            Export
                                                    </CSVLink>
                                                }
                                            </div>
                                        }
                                        </th>
                                    </tr>
                                    <tr className='border-b border-black-100/10'>
                                        <th className='md:pl-[30px] w-[90px] md:w-[120px]'>
                                            {showExportOptions && 
                                                <label className='text-[12px] text-[#363F6C] font-semibold cursor-pointer flex'>
                                                    <input 
                                                        type="checkbox"
                                                        name="selectAll"
                                                        id="selectAll"
                                                        className='checkbox-field appearance-none h-4 w-4 rounded-sm bg-white mt-1 align-top float-left mr-2 cursor-pointer'
                                                        onChange={handleSelectAllMembers}
                                                        checked={isCheckAllMembers} />
                                                        All
                                                </label>
                                            }
                                        </th>
                                        <th className="text-left px-2.5 py-[18px]">Name</th>
                                        {(team.role === 'admin' || group.user_role==='group_admin') &&
                                            <>
                                            <th className="text-left px-2.5 py-[18px] hidden lg:table-cell">Email</th>
                                            <th className="text-left px-2.5 py-[18px] hidden lg:table-cell">Contact</th>
                                            <th className="text-left px-2.5 py-[18px] hidden md:table-cell">Date of Birth</th>
                                            <th></th>
                                            </>
                                        }                                    
                                    </tr>
                                </thead>
                                <tbody>
                                {groupMembers.map((member,index) => {
                                    return(
                                        <GroupMemberRow key={index} showExportOptions={showExportOptions} isCheckMembers={isCheckMembers} setIsCheckMembers={setIsCheckMembers} member={member} team={team} group={group} refreshGroupMembers={refreshGroupMembers} />
                                    )
                                })
                                }
                                </tbody>
                            </table>
                            :
                                <div className='bg-white rounded-[10px] pt-[22px] pb-[77px]'>
                                    {showExportOptions && 
                                        <div className="flex flex-col py-3.5 px-3">
                                            <label className='text-[12px] mb-2 text-[#363F6C] font-semibold cursor-pointer'>
                                                <input 
                                                    type="checkbox"
                                                    name="selectAll"
                                                    id="selectAll"
                                                    className='checkbox-field appearance-none h-4 w-4 rounded-sm bg-white mt-1 align-top float-left mr-2 cursor-pointer'
                                                    onChange={handleSelectAll}
                                                    checked={isCheckAll} />
                                                    All Fields
                                            </label>
                                            {fieldLists}
                                            {(isCheckMembers.length>0 && isCheck.length>0) &&
                                                <CSVLink 
                                                    className="button button-action button-blue w-[89px] mb-2"
                                                    data={isCheckMembers.map((memberObj, index) => {
                                                        let rowData={}, columnData;
                                                        columnData = isCheck.map((isc)=>{
                                                            let colData={};
                                                            if(isc.key === 'dateOfBirth'){
                                                                colData.dateOfBirth=memberObj[isc.key]!=="" ? dayjs(memberObj[isc.key]).format('DD-MM-YYYY') : (memberObj['is_above_18'] ? 'Over 18 years' : 'Under 18 years');
                                                            }
                                                            if(isc.key === 'firstName'){
                                                                colData.firstName=memberObj[isc.key];
                                                            }
                                                            if(isc.key === 'lastName'){
                                                                colData.lastName=memberObj[isc.key];
                                                            }
                                                            if(isc.key === 'name'){
                                                                colData.name=memberObj[isc.key];
                                                            }
                                                            if(isc.key === 'email'){
                                                                colData.email=memberObj[isc.key];
                                                            }                                                            
                                                            if(isc.key === 'phoneNumber'){
                                                                colData.phoneNumber=memberObj.phoneNumber.toString();
                                                            }
                                                            if(isc.key === 'role'){
                                                                if(memberObj.user_role !== undefined){
                                                                    colData.role=memberObj.user_role==="group_admin" ? 'GROUP ADMIN' : memberObj.user_role.toUpperCase();
                                                                }
                                                                else{
                                                                    colData.role=null;
                                                                }
                                                            }
                                                            return colData;
                                                        });

                                                        for(let i = 0; i < columnData.length; i++ ) {
                                                            Object.assign(rowData, columnData[i]);
                                                        }
                                                        
                                                        return rowData;
                                                    })}
                                                    filename={'members-' + group._id + '.csv'}
                                                    headers={isCheck}>
                                                        Export
                                                </CSVLink>
                                            }
                                        </div>
                                    }
                                    {groupMembers.map((member,index) => {
                                        return(
                                            <GroupMemberRow key={index} isMobile={isMobile} showExportOptions={showExportOptions} isCheckMembers={isCheckMembers} setIsCheckMembers={setIsCheckMembers} member={member} team={team} group={group} refreshGroupMembers={refreshGroupMembers} />
                                        )
                                    })}
                                </div>
                            }
                        </div>
                    }                                        
                </div>
            </div>
        </>
    );
}

export default GroupsDetail;