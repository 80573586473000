import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc'
import tz from 'dayjs/plugin/timezone'
import * as yup from 'yup';
import ReactDatePicker from "react-datepicker";
import { useAuthDispatch, updateUser } from "../../contexts/AuthProvider";
import Spinner from "../../components/Spinner/Spinner";
import CountriesList from "../../libs/countries";
import PhoneCodeList from '../../libs/phonecodes';
import PlaiImageCropper from "../../components/ImageCropper/ImageCropper";
import iconMale from "../../assets/images/icon-male.svg";
import iconFemale from "../../assets/images/icon-female.svg";
import { getDisplayEcbConsent } from "../../libs/dataFunctions";
import { generateRandomName } from "../../components/ImageCropper/cropUtils";

dayjs.extend(utc);
dayjs.extend(tz);
const schema = yup.object({
    firstName: yup.string().required('First name is required.'),
    lastName: yup.string().required('Last name is required.'),
    country: yup.string().required('Country is required.'),    
    birthDate: yup.string().when('bdatenottosay',{
        is:false,
        then:yup.string().required('Birthdate is required').nullable()
    }).nullable(),
    phonecode: yup.string().required('Phone Code is required.'),
    phone: yup.number().typeError('Mobile number is required.'),        
}).required();

const ProfileForm = ({user, setShowForm, refreshProfile}) =>{
    const [loading,setLoading] = useState(false);
    const [profileImage, setProfileImage] = useState(null);
    const [errorMessage,setErrorMessage] = useState(false);
    const [genderErr, setGenderErr] = useState(false);
    const [isBdateNotPreffer, setBdateNotPreffer] = useState(false);
    const [isShowEcbConcent, setShowEcbConcent] = useState(false);
    const dispatch = useAuthDispatch();
    const [ecbStatus, setECbStatus] = useState(null);
    const { register, handleSubmit, setValue, control, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });
    
    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
    ? localStorage.getItem('auth_token')
    : '';

    const fetchDisplayEcb=async()=>{
        setLoading(true);
        const [ecbConcent] = await Promise.all([          
            getDisplayEcbConsent(token),
        ]);
        setLoading(false);
        setShowEcbConcent(ecbConcent.display_ecb_consent);
        setECbStatus(ecbConcent.ecb_consent ? 'Yes' : 'No');
    }

    const onSubmit = async(data) => {
        setLoading(true);
        if(data.gender!==null || data.nottosay){
            var formdata = new FormData();            
            formdata.append("type", "updateProfile");
            formdata.append("firstName", data.firstName);
            formdata.append("lastName", data.lastName);
            formdata.append("email", data.email);
            formdata.append("country", data.country);
            formdata.append("phoneNumber", data.phone);
            formdata.append("phoneCode", data.phonecode);
            if(!data.bdatenottosay){
                formdata.append("dateOfBirth",dayjs(data.birthDate).format('YYYY-MM-DD'));
                formdata.append("is_above_18", "");
            }
            else{
                formdata.append("dateOfBirth","");
                formdata.append("is_above_18",data.is_above_18==='Yes' ? "Yes" : "No");
            }
            if(isShowEcbConcent){
                formdata.append("ecb_consent",ecbStatus==='Yes' ? "true" : "false");
            }
            formdata.append("bio", data.bio);
            formdata.append('separate_by','@#');
            formdata.append("gender",!data.nottosay ? data.gender : 'prefer_not_to_say');

            if(profileImage!==null){
                var formdatas3 = new FormData();
                let tmpFilename = generateRandomName();
                const imgFileObj = new File([profileImage], tmpFilename+'.webp', { lastModified: new Date().getTime(), type: profileImage.type })
                formdatas3.append("files[]", imgFileObj);
                var requestOptionss3 = {
                    method: 'PUT',
                    body: formdatas3,
                    redirect: 'follow'
                };
                const responsesApp = await fetch("https://dashboard.plaisport.com/upload_content", requestOptionss3);
                const resultsApp = await responsesApp.text();
                const rdatasApp = JSON.parse(resultsApp);
                formdata.append('content',rdatasApp.data[0]);
            }
            
            var secureHeader = new Headers();
            secureHeader.append('Authorization','Bearer ' + token);
            secureHeader.append('device_type','W');            
            try {
                let response = await updateUser(dispatch, formdata, secureHeader);            
                if (response.status === 0){
                    return;
                }
                else{
                    window.location.href="/profile";                    
                }
            } catch (error) {
                console.log(error);
            }
        }
        else{
            if(data.gender===null && data.nottosay===false){
                setGenderErr(true);
            }
            else{
                setGenderErr(false);
            }
        }
    };
    
    useEffect(() => {
        setValue('firstName',user.firstName);
        setValue('lastName',user.lastName);
        setValue('email',user.email);        
        if(user.dateOfBirth!==""){
            setValue('birthDate',new Date(user.dateOfBirth));
            setBdateNotPreffer(false);
        }
        else{
            setValue('bdatenottosay',true);
            setBdateNotPreffer(true);
            if(user.is_above_18){
                setValue('is_above_18','Yes');                
            }
            else{
                setValue('is_above_18','No');
            }
        }
        setValue('country',user.country);
        setValue('phonecode',user.phoneCode);
        setValue('phone',user.phoneNumber);
        if(user.gender.toLowerCase()==='male' || user.gender.toLowerCase()==='female'){
            setValue('gender',user.gender.toLowerCase());
        }
        else{
            setValue('nottosay',true);
        }
        setValue('bio',user.bio);   
        fetchDisplayEcb();
    },[]);

    return(
        !loading ?
        <>
            <button type="button" className="close-popup" onClick={(e)=>{setShowForm(false)}}><span>close</span></button>
            <div className="form-heading text-center mb-[30px]">Update Profile</div>
            {!loading && errorMessage ? <p className='error text-center mb-2.5'>{errorMessage}</p> : null}
            <form onSubmit={handleSubmit(onSubmit)} className="form-wrapper product-form-wrapper">
                <div className="flex flex-wrap">  
                    <div className="w-full lg:w-1/2 lg:px-2.5">
                        <div className='form-field-wrap mb-5 user-field-wrap relative'>
                            <input 
                                type="text" id="first-name" disabled={loading} placeholder="First name *" className='text-field'
                                {...register("firstName")}
                                aria-invalid={errors.firstName ? "true" : "false"} 
                            />                                
                        </div>
                        {errors.firstName && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.firstName?.message}</p>}
                        <div className='form-field-wrap mb-5 user-field-wrap relative'>
                            <input 
                                type="text" id="last-name" disabled={loading} placeholder="Last name *" className='text-field'
                                {...register("lastName")}
                                aria-invalid={errors.lastName ? "true" : "false"} 
                            />                                
                        </div>
                        {errors.lastName && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.lastName?.message}</p>}
                        <div className='form-field-wrap mb-5 email-field-wrap relative'>
                            <input 
                                type="email" id="email-name" disabled={loading} placeholder="Email *" className='email-field'
                                {...register("email")}
                                aria-invalid={errors.email ? "true" : "false"} 
                            />                                
                        </div>
                        {errors.email && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.email?.message}</p>}
                        <div className='form-field-wrap mb-5 select-field-wrap date-field-wrap relative'>
                            <Controller
                                control={control}
                                name='birthDate'
                                errors={errors}
                                render={({ field, ref }) => (
                                <ReactDatePicker
                                    placeholderText='DD/MMM/YYYY'
                                    onChange={(date) => field.onChange(date)}
                                    selected={field.value}
                                    maxDate={new Date()}
                                    mask="__-___-____"
                                    dateFormat="dd/MMM/yyyy"
                                    inputRef={ref}
                                    showYearDropdown
                                />
                            )}
                            />
                        </div>
                        {errors.birthDate && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.birthDate?.message}</p>}                
                        <div className='form-field-wrap mb-5 check-field-wrap relative !p-0'>
                            <label htmlFor="bdatenottosay" className='!text-[12px] font-normal leading-[12px] uppercase justify-end items-center'>
                                <input 
                                    type="checkbox" id="bdatenottosay" className='checkbox-field appearance-none h-3 w-3 rounded-sm bg-white align-top float-left mr-1 cursor-pointer'
                                    {...register("bdatenottosay")}
                                    onChange={(e)=>{
                                        if(e.target.checked){setBdateNotPreffer(true); setValue('is_above_18','Yes'); }else{setBdateNotPreffer(false);}
                                    }}
                                />I'D PREFER NOT TO SHARE MY DOB
                            </label>
                        </div>
                        {isBdateNotPreffer &&
                            <div className="form-field-wrap button-group-wrap mb-5 flex justify-between items-center">
                                <div className="flex-1 button-grop-label">Are you over the age of 18?</div>
                                <div className="r-button-group w-[148px] flex">
                                    <div className="mr-4">
                                    <input type="radio" {...register("is_above_18")} value="Yes" id="is_above_18-yes" className="peer hidden" />
                                    <label
                                        htmlFor="is_above_18-yes"
                                        className="block py-[5px] w-[66px] cursor-pointer select-none rounded-[42px] text-center bg-[#EFF1F9] peer-checked:bg-[#FBDB03] peer-checked:text-black"
                                        >Yes</label>
                                    </div>
                                    <div>
                                    <input type="radio" {...register("is_above_18")} value="No" id="is_above_18-no" className="peer hidden" />
                                    <label
                                        htmlFor="is_above_18-no"
                                        className="block py-[5px] w-[66px] cursor-pointer select-none rounded-[42px] text-center bg-[#EFF1F9] peer-checked:bg-[#FBDB03] peer-checked:text-black"
                                        >No</label>
                                    </div>
                                </div>
                            </div>
                        }
                        <div className='form-field-wrap mb-5 location-field-wrap relative'>
                            <select {...register("country")} onChange={(e)=>setValue('country', e.target.value, { shouldValidate: true })}>
                                <option value="">Select Country *</option>
                                {CountriesList.map((c,index)=>{
                                    return(
                                        <option key={index} value={c.name}>{c.name}</option>
                                    );
                                })}
                            </select>
                        </div>
                        {errors.country && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.country?.message}</p>}
                        <div className='flex flex-wrap'>
                            <div className='form-field-wrap w-full sm:w-1/3 mb-5 no-field-wrap relative sm:pr-2.5'>
                                <select {...register("phonecode")} onChange={(e)=>setValue('phonecode', e.target.value, { shouldValidate: true })}>
                                    <option value="">Code *</option>
                                    {PhoneCodeList.map((c,index)=>{
                                        return(
                                            <option key={index} value={c.code}>{c.code}</option>
                                        );
                                    })}
                                </select>
                            </div>
                            <div className='form-field-wrap w-full sm:w-2/3 mb-5 no-field-wrap relative'>
                                <input 
                                    type="tel" id="phone" placeholder="Mobile Number *" className='text-field'
                                    {...register("phone")}
                                />                                
                            </div>
                        </div>
                        {errors.phonecode && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.phonecode?.message}</p>}
                        {errors.phone && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.phone?.message}</p>}
                        <div className="form-field-wrap button-group-wrap mb-5 flex flex-wrap justify-between items-center">
                            <div className="flex-1 button-grop-label gender-field-label">Select Gender</div>
                            <div className="r-button-group flex">
                                <div className="mr-4">
                                <input type="radio" {...register("gender")} value="male" id="gender-male" className="peer hidden" onChange={(e)=>{setValue('nottosay',false); setValue('gender','male')}} />
                                <label
                                    htmlFor="gender-male"
                                    className="gender-label block py-[5px] flex items-center cursor-pointer select-none rounded-[42px] text-center bg-[#EFF1F9] peer-checked:bg-[#FBDB03] peer-checked:text-black"
                                    ><img src={iconMale} width={18} height={18} alt="" className='mr-2'/>Male</label>
                                </div>
                                <div>
                                <input type="radio" {...register("gender")} value="female" id="gender-female" className="peer hidden" onChange={(e)=>{setValue('nottosay',false); setValue('gender','female')}} />
                                <label
                                    htmlFor="gender-female"
                                    className="gender-label block py-[5px] flex items-center cursor-pointer select-none rounded-[42px] text-center bg-[#EFF1F9] peer-checked:bg-[#FBDB03] peer-checked:text-black"
                                    ><img src={iconFemale} width={18} height={18} alt="" className='mr-2'/>Female</label>
                                </div>
                            </div>
                        </div>
                        <div className='auth-form-field-wrap mb-5 check-field-wrap relative !p-0'>
                            <label htmlFor="nottosay" className='!text-[12px] font-normal leading-[12px] uppercase justify-end items-center'>
                                <input 
                                    type="checkbox" id="nottosay" className='checkbox-field appearance-none h-3 w-3 rounded-sm bg-white align-top float-left mr-1 cursor-pointer'
                                    {...register("nottosay")}
                                    onChange={(e)=>{
                                        if(e.target.checked){setValue('gender','')}
                                    }}
                                />I'D PREFER NOT TO SHARE MY GENDER
                            </label>
                        </div>
                        {genderErr && <p className="error mb-2.5 -mt-2.5" role="alert">Please either gender or prefer not to say.</p>}
                    </div>                                      
                    <div className="w-full lg:w-1/2 lg:px-2.5">                        
                        <div className='form-field-wrap mb-5 relative'>
                            <PlaiImageCropper editImage={(user?.profilePicture && user?.profilePicture !== "" !== "") ? user.profilePicture : ''} onFinalChange={(newImage)=>{setProfileImage(newImage)}} aspect={1 / 1}/>
                        </div>
                        <div className='form-field-wrap mb-5 relative'>
                            <textarea 
                                type="text" id="bio" disabled={loading} placeholder="Bio" className='textarea-field'
                                {...register("bio")}
                            ></textarea>
                        </div>                        
                    </div>
                    {isShowEcbConcent &&
                        <div className="w-full px-2.5 mb-5 pt-2 border-t border-slate-400">
                            <h2 className="text-md font-semibold mb-2">Your club is integrated with Play-Cricket on PLAI...</h2>
                            <p className='mb-2 text-sm'>Choose YES to get information from the England and Wales Cricket Board (ECB) such as tickets, offers, news, competitions, prize draws and other ways to get involved in cricket.</p>
                            <div className='flex justify-center gap-2.5 mb-5'>
                                <button type='button' className={`button ${ecbStatus==='Yes' && 'active'}`} onClick={()=>setECbStatus('Yes')}>Yes</button>
                                <button type='button' className={`button ${ecbStatus==='No' && 'active'}`} onClick={()=>setECbStatus('No')}>No</button>
                            </div>
                            <p className='mb-2 text-sm'>If you choose ‘YES’, PLAI will provide your registration information to the ECB. The ECB’s privacy policy which explains how your personal data will be used is available at <a href="http://www.ecb.co.uk/privacy" target="_blank" rel="noreferrer" className='underline'>www.ecb.co.uk/privacy</a></p>                        
                        </div>
                    }
                    <div className="w-full px-2.5">
                        <div className='flex justify-center'>
                            <input className='button button--large' type="submit" value="Update" disabled={loading}/>
                        </div>
                    </div>
                </div>
            </form>
        </>
        :
        <>
            <div className="py-5 text-center"><Spinner /></div>
        </>
    );
}
export default ProfileForm;