import { useState, useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Spinner from "../../../components/Spinner/Spinner";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import imageIcon from "../../../assets/images/icon-uploadimage.svg";
import 'filepond/dist/filepond.min.css';
registerPlugin(FilePondPluginFileValidateType);

const schema = yup.object({
    folder_name: yup.string().required('Folder name is required.'),
}).required();

const GalleryForm = ({setShowForm, isNew, teamId, fetchGalleryFolders, isInsideFolder, galfolderData})=>{
    const [loading,setLoading] = useState(false);
    const [errorMessage,setErrorMessage] = useState('');
    const [imageFiles, setImageFile] = useState([]);

    const { register, handleSubmit, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });
    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
    ? localStorage.getItem('auth_token')
    : '';
    const limitInput = 20;
    const [folderName, setFolderName] = useState('');

    const onSubmit = async(data) => {
        if(isNew){
            newFolderCreate(data);
        }
        else{   
            updateFolder(data);
        }
    }

    const newFolderCreate = async(data)=>{
        setErrorMessage('')
        if(imageFiles.length>0){
            setLoading(true);
            var formdata = new FormData();
            formdata.append("files[]", imageFiles[0]);
            var requestOptions = {
                method: 'PUT',
                body: formdata,
                redirect: 'follow'
            };
            await fetch("https://dashboard.plaisport.com/upload_content", requestOptions)
            .then(response => response.text())
            .then(result => {
                let rdata = JSON.parse(result);
                if(rdata.status === 1){
                    if(rdata.data.length > 0 ){
                        var formdata2 = new FormData();
                        formdata2.append("type","create_folder");
                        formdata2.append("team_id", teamId);
                        formdata2.append("name", data.folder_name);
                        formdata2.append("image", rdata.data[0]);
                        formdata2.append("description",data.folder_desc);
                        var secureHeader = new Headers();
                        secureHeader.append('Authorization','Bearer ' + token);
                        secureHeader.append('device_type','W');
                        var requestOptions2 = {
                            method: 'POST',
                            body: formdata2,
                            redirect: 'follow',
                            headers: secureHeader
                        };
                        fetch(process.env.REACT_APP_APIURL + '/app_gallery', requestOptions2)
                        .then(response2 => response2.text())
                        .then(result2 => {
                            setLoading(false);
                            let rdata2 = JSON.parse(result2);
                            if(rdata2.status===1){
                                setShowForm(false);
                                if(!isInsideFolder){
                                    fetchGalleryFolders();
                                }                            
                            }
                            else{
                                setErrorMessage(rdata2.message);
                            }
                        })
                    }
                    else{
                        setLoading(false);
                        setErrorMessage("Image not uploaded due to some technical issue, try again");
                    }
                }
                else{
                    setLoading(false);
                    setErrorMessage(rdata.message);
                }
            })
            .catch(error => console.log('error', error));
        }
        else{
            setErrorMessage("Please upload cover image");
        }
    }

    const updateFolder = async(data)=>{
        setErrorMessage('')
        if(imageFiles.length>0){
            setLoading(true);
            var formdata = new FormData();
            formdata.append("files[]", imageFiles[0]);
            var requestOptions = {
                method: 'PUT',
                body: formdata,
                redirect: 'follow'
            };
            await fetch("https://dashboard.plaisport.com/upload_content", requestOptions)
            .then(response => response.text())
            .then(result => {
                let rdata = JSON.parse(result);
                if(rdata.status === 1){
                    if(rdata.data.length > 0 ){
                        var formdata2 = new FormData();
                        formdata2.append("type","update_folder");
                        formdata2.append("team_id", teamId);
                        formdata2.append("media_folder_id", galfolderData._id);
                        formdata2.append("name", data.folder_name);
                        formdata2.append("image", rdata.data[0]);
                        formdata2.append("description",data.folder_desc);
                        var secureHeader = new Headers();
                        secureHeader.append('Authorization','Bearer ' + token);
                        secureHeader.append('device_type','W');
                        var requestOptions2 = {
                            method: 'POST',
                            body: formdata2,
                            redirect: 'follow',
                            headers: secureHeader
                        };
                        fetch(process.env.REACT_APP_APIURL + '/app_gallery', requestOptions2)
                        .then(response2 => response2.text())
                        .then(result2 => {
                            setLoading(false);
                            let rdata2 = JSON.parse(result2);
                            if(rdata2.status===1){
                                setShowForm(false);
                                fetchGalleryFolders();                                                            
                            }
                            else{
                                setErrorMessage(rdata2.message);
                            }
                        })
                    }
                    else{
                        setLoading(false);
                        setErrorMessage("Image not uploaded due to some technical issue, try again");
                    }
                }
                else{
                    setLoading(false);
                    setErrorMessage(rdata.message);
                }
            })
            .catch(error => console.log('error', error));
        }
        else{
            setLoading(true);
            var formdata2 = new FormData();
            formdata2.append("type","update_folder");
            formdata2.append("team_id", teamId);
            formdata2.append("media_folder_id", galfolderData._id);
            formdata2.append("name", data.folder_name);
            formdata2.append("description",data.folder_desc);
            var secureHeader = new Headers();
            secureHeader.append('Authorization','Bearer ' + token);
            secureHeader.append('device_type','W');
            var requestOptions2 = {
                method: 'POST',
                body: formdata2,
                redirect: 'follow',
                headers: secureHeader
            };
            await fetch(process.env.REACT_APP_APIURL + '/app_gallery', requestOptions2)
            .then(response2 => response2.text())
            .then(result2 => {
                setLoading(false);
                let rdata2 = JSON.parse(result2);
                if(rdata2.status===1){
                    setShowForm(false);
                    fetchGalleryFolders();                                                
                }
                else{
                    setErrorMessage(rdata2.message);
                }
            })
        }
    }

    const setFormattedName = useCallback(
        (text) => {
            setFolderName(text.slice(0, limitInput));
        },
        [limitInput, setFolderName]
    );

    useEffect(()=>{
        if(!isNew){
            setValue('folder_name', galfolderData.name);
            setValue('folder_desc', galfolderData.description);
        }
    },[]);

    return(
        <>
        <div className="popup-form">
        {!loading ?        
        <div className="popup-form-wrap popup-form-linkform relative !bg-[#363F6C]">
            <div className="form-heading text-center mb-[30px] !text-white">Create Folder</div>
            <button type="button" className="close-popup" onClick={(e)=>{setShowForm(false)}}><span>close</span></button>
            <form onSubmit={handleSubmit(onSubmit)} className="form-wrapper link-form-wrapper">
                <div className="flex flex-wrap">
                    <div className="w-full md:px-2.5">
                        <div className="form-field-container mb-5">
                            <div className="label">Folder name *</div>
                            <div className='form-field-wrap no-field-wrap relative'>
                                <input 
                                    type="text" 
                                    id="folder_name" 
                                    disabled={loading} 
                                    placeholder="Folder name *" 
                                    className='text-field'
                                    {...register("folder_name")}
                                    onChange={e => setFormattedName(e.target.value)}
                                    aria-invalid={errors.folder_name ? "true" : "false"}
                                    readOnly={(isNew || galfolderData.name!=='All Images') ? false : true}
                                />
                                <p className="text-right text-[12px] leading-[12px] pt-1.5 text-white">
                                    {folderName.length}/{limitInput}
                                </p>
                            </div>
                            {errors.folder_name && <p className="error mt-2.5 mb-2.5" role="alert">{errors.folder_name?.message}</p>}
                        </div>
                    </div>
                    <div className="w-full md:w-1/2 md:px-2.5">
                        <div className="form-field-container mb-5 md:mb-12">
                            <div className="label">Folder Description</div>
                            <div className='form-field-wrap relative'>
                                <textarea
                                    id="folder-desc" 
                                    disabled={loading} 
                                    placeholder="Add Description" 
                                    className='textarea-field'
                                    {...register("folder_desc")}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="w-full md:w-1/2 md:px-2.5">
                        <div className="form-field-container mb-5 md:mb-12">
                            <div className="label">Cover Image</div>
                            <div className='form-field-wrap relative'>                                    
                                <FilePond
                                    files={imageFiles}
                                    //onupdatefiles={setImageFile}                                    
                                    onupdatefiles={(fileItems) => {
                                        // Set current file objects to this.state
                                        setImageFile(fileItems.map((fileItem) => fileItem.file));
                                    }}
                                    allowMultiple={false}
                                    credits={false}
                                    allowFileTypeValidation={true}
                                    acceptedFileTypes={['image/png', 'image/jpeg']}
                                    labelFileTypeNotAllowed='File of invalid type'
                                    maxFiles={1}
                                    name="files"
                                    labelIdle={`
                                        <div class="upload-text">
                                            <div className="upload-icon mx-auto">
                                                <img src=${imageIcon} width={75} height={63} alt="" class="mx-auto" />
                                            </div>
                                            <h4>Select Cover Image</h4>                                                
                                        </div>`}
                                    {...register("files")}
                                    />
                            </div>
                        </div>
                    </div>
                    {errorMessage!=='' && <p className="text-red-500 mb-2.5 text-center" role="alert">{errorMessage}</p>}
                    <div className="w-full px-2.5">
                        <div className='flex justify-center'>
                            <input className='button' type="submit" value={isNew ? 'Create' : 'Update'} disabled={loading}/>
                        </div>
                    </div>
                </div>
            </form>
        </div>        
        :
            <div className="py-5 text-center"><Spinner /></div>
        }
        </div>
        </>
    )
}

export default GalleryForm;