import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Spinner from '../../components/Spinner/Spinner';

const schema = yup.object({
    email: yup.string().email('Must be a valid email address').required('Email address is required.'),
}).required();

const ClubNotFoundPopup = ({setShowForm}) =>{
    const [loading, setLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema)
    });

    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
    ? localStorage.getItem('auth_token')
    : '';
    
    const onSubmit=async(data)=>{            
        setLoading(true);
        var formdata = new FormData();
        formdata.append("type", "contact_club_manager");
        formdata.append("email", data.email);
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        await fetch(process.env.REACT_APP_APIURL + '/app_teams' , requestOptions)
        .then(response => response.text())
        .then(result => {
            setLoading(false);
            let rdata = JSON.parse(result);
            if(rdata.status !== 1){
                setSuccessMessage(rdata.message);
            }
            else{
                setSuccessMessage(rdata.message);
                reset();
                setShowForm(false);              
            }
        })
        .catch(error => console.log('error', error));
    }

    return(
        <div className="popup-form">
            <div className="popup-form-wrap relative !w-[400px] !px-7.5">
                <button type="button" className="close-popup" onClick={(e)=>{setShowForm(false)}}><span>close</span></button>
                <div className='flex flex-col align-center justify-center'>
                    <div className='text-[#363F6C] uppercase mb-2.5 text-center font-semibold'>It looks like your team or club might not be using PLAI yet?</div>
                    <div className='text-[#363F6C] uppercase mb-5 text-center font-semibold'>Share with us the email address of your primary Club Administrator and we’ll reach out to them!</div>
                    {!loading ?
                    <form onSubmit={handleSubmit(onSubmit)} className="form-wrapper flex justify-center flex-col">
                        <label className='text-black text-center'>Coach/Manager Email</label>
                        <div className='form-field-wrap mb-5 email-field-wrap relative'>
                            <input 
                                type="email" id="email" disabled={loading} placeholder="Email *" className='text-field'
                                {...register("email")}
                                aria-invalid={errors.email ? "true" : "false"} 
                            />
                        </div>
                        {errors.email && <p className="error mb-2.5 -mt-2.5" role="alert">{errors.email?.message}</p>}
                        <div className='flex justify-center'>
                            <input className='button button--large' type="submit" value="Submit" disabled={loading}/>
                        </div>
                        {successMessage!=='' &&
                            <div className='text-green-600 font-medium text-center text-[16px] leading-[24px] pt-5'>{successMessage}</div>
                        }
                    </form>
                    :
                    <>
                        <div className="py-5 text-center"><Spinner /></div>
                    </>
                    }
                </div>
            </div>
        </div>
    )
}

export default ClubNotFoundPopup;