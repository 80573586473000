import { useState, useEffect, useContext } from "react";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import Sidebar from "../../../components/Sidebar/Sidebar";
import AdminNavbar from "../../../components/AdminNavbar/AdminNavbar";
import MyCartRow from "./MyCartRow";
import { TeamContextProvider, TeamContext } from "../../../contexts/TeamContext";
import { getMyCart } from "../../../libs/dataFunctions";
import Spinner from "../../../components/Spinner/Spinner";
import NoNews from "../../../assets/images/noproduct.png";

const MyCartView = () =>{
    const {team, teamUpdating} = useContext(TeamContext);
    const [loading, setLoading] = useState(false);
    const [mycartdata,setMyCartData] = useState([]);
    const [isCartEmpty, setIsCartEmpty]  = useState(false);
    const [cartTotal, setCartTotal]  = useState(0);
    const navigate = useNavigate();
    
    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
    ? localStorage.getItem('auth_token')
    : '';

    const refreshMyCart = async()=>{
        setLoading(true);
        let myCartData = await getMyCart(token, team._id);
        if(myCartData.status===1){
            setMyCartData(myCartData.data);
            setCartTotal(myCartData.total_amount);
        }
        else{
            setMyCartData([]);
            setCartTotal(0);
        }
        setLoading(false);
    }

    useEffect(()=>{
        if(!teamUpdating){
            refreshMyCart();
        }
        else{
            setLoading(true);
        }
    },[teamUpdating]);

    return (
        !loading ?
        <>
            <div className='breadcrumb flex item-center pt-1.5 pb-1 pl-5 pr-7 w-full'>
                <NavLink to="/" className='home'>
                    <span>Dashboard</span>
                </NavLink>
                <span className='separator'>&lt;</span>
                <NavLink to={`/team/${team._id}/shop`}>
                    Shop
                </NavLink>
                <span className='separator'>&lt;</span>
                My Basket
            </div>
            <div className="body px-5 lg:px-8 py-10">
                <div className="ln-header flex justify-between items-center w-full pb-5 relative">
                    <h2 className="title mr-5 uppercase">My Basket</h2>
                    <div className="sep mr-5 sm:mr-11"></div> 
                </div>
                <div className="rounded-xl bg-white p-7.5">
                    {mycartdata.length > 0 ?
                        <>
                            <div className="cart-items">
                                {mycartdata.map((cartitem)=>{
                                    return(
                                        <div className="cart-item flex flex-col lg:flex-row border-b border-black-100/10 mb-7 pb-7" key={cartitem._id}>
                                            <MyCartRow cartitem={cartitem} team={team} refreshMyCart={refreshMyCart} />
                                        </div>
                                    )
                                })}
                            </div>
                            <div className="checkout-action flex justify-end pt-12">
                                <button className="button button--large" onClick={(e)=>{navigate('/team/'+team._id+'/shop/checkout')}}>Checkout</button>
                            </div>
                        </>
                        :
                        <div className="empty text-center">
                            <div className="image"><img src={NoNews} alt="Plaisport" width={228} /></div>
                            <div className="title mb-5">Empty Cart?</div>
                            <div className="description mb-8">
                                <span>Your cart is empty, to buy our products click below.</span>                        
                            </div>
                            <button type="button" className='button button-yellow button-yellow-add' onClick={(e)=>{navigate('/team/'+team._id+'/shop')}}>Shop</button>
                        </div>
                    }
                </div>
            </div>
        </>
        :
        <>
            <div className="py-5 text-center"><Spinner /></div>
        </>
    )
}

const MyCart = () => {
    const { id } = useParams();
    return (
        <>
            <Sidebar type="thin"/>
            <TeamContextProvider id={id}>
                <div id="page" className="page-product-detail">
                    <AdminNavbar heading="My Cart"/>
                    <MyCartView />
                </div>
            </TeamContextProvider>
        </>
    )
}
export default MyCart;