import { useState } from 'react';
import StarRating from './StarRatings';
import placeholderImg from '../../../assets/images/placeholder.png';

const CFGSTwoMemSelRow = ({memberitem,setPlayerMain, playerList, setPlayerList, setIsCheckPlayers, isCheckplayers, index, iconplayers, setIconPlayers, proplayers, setProPlayers, rookieplayers, setRookiePlayers, setIsCheckAll, isCheckAll}) =>{
    const handleClickMembers = e => {
        const { id, checked, value } = e.target;
        let tmpObj = {};
        Object.assign(tmpObj, JSON.parse(value));        
        setIsCheckPlayers([...isCheckplayers, tmpObj]);
        let tmpList = [...isCheckplayers, tmpObj];
        if (!checked) {
            tmpList = isCheckplayers.filter((item) => {return !item._id.includes(id)});
            setIsCheckPlayers(tmpList);
        }
        if(tmpList.length === playerList.length){
            setIsCheckAll(true);
        }
        else{
            setIsCheckAll(false);
        }
    };
    const handleRating = (rate) => {
        if(rate===1){
            memberitem.rating=rate;
            setRookiePlayers(oldArray => [...oldArray,memberitem] );
            setIconPlayers((current)=>current.filter((mitem) => mitem._id !== memberitem._id));
            setProPlayers((current)=>current.filter((mitem) => mitem._id !== memberitem._id));
            setPlayerList((current)=>current.filter((mitem) => mitem._id !== memberitem._id));
            setPlayerMain((current)=>current.filter((mitem) => mitem._id !== memberitem._id));
        }
        else if(rate===2){
            memberitem.rating=rate;
            setProPlayers(oldArray => [...oldArray,memberitem] );
            setIconPlayers((current)=>current.filter((mitem) => mitem._id !== memberitem._id));
            setRookiePlayers((current)=>current.filter((mitem) => mitem._id !== memberitem._id));
            setPlayerList((current)=>current.filter((mitem) => mitem._id !== memberitem._id));
            setPlayerMain((current)=>current.filter((mitem) => mitem._id !== memberitem._id));
        }
        else if(rate===3){
            memberitem.rating=rate;
            setIconPlayers(oldArray => [...oldArray,memberitem] );
            setProPlayers((current)=>current.filter((mitem) => mitem._id !== memberitem._id));
            setRookiePlayers((current)=>current.filter((mitem) => mitem._id !== memberitem._id));
            setPlayerList((current)=>current.filter((mitem) => mitem._id !== memberitem._id));
            setPlayerMain((current)=>current.filter((mitem) => mitem._id !== memberitem._id));
        }
    }
    return (
        <div className="w-full mb-5 flex items-center">
            <label key={memberitem._id} className='text-[12px] mr-3 text-black font-light cursor-pointer'>
                <input                    
                    type="checkbox"
                    name={memberitem._id}
                    id={memberitem._id}
                    value={JSON.stringify(memberitem)}
                    className='checkbox-field appearance-none h-4 w-4 rounded-sm bg-white mt-1 align-top float-left mr-2 cursor-pointer'
                    onChange={handleClickMembers}
                    checked={isCheckplayers.some((item)=>{ return item._id===memberitem._id })}
                />
            </label>
            <div className="w-full p-2.5 flex justify-between rounded-[16px] bg-[#EFF1F9] items-center">
                <div className="flex items-center">
                    {memberitem.profilePicture !=="" ?
                        <img src={memberitem.profilePicture} width="50" height="50" className="rounded-full" alt="" />
                    :
                        <img src={placeholderImg} width="50" height="50" className="rounded-full" alt="" />
                    }
                    <span className="pl-[16px] pr-2.5 text-[#363F6C] text-[16px] leading-[24px] font-semibold">{memberitem.name}</span>
                </div>
                <div>
                    <StarRating onChange={handleRating} ratingValue={0}/>
                </div>
            </div>
        </div>
    )
}

export default CFGSTwoMemSelRow;